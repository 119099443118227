import { gql } from "@apollo/client";

export const UPDATE_VIDEO = gql`
    mutation setVideo($date:String!, $racetrack_id:String!, $url:String!) {
        video(date:$date, racetrack_id:$racetrack_id, url:$url) {
            date
            racetrack_id
            url
            active
        }
    }
`