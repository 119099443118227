import React, { useState, useRef } from "react";

const Dropdown = ({
  children,
  buttonIcon = "person_outline",
  buttonText,
  position,
  iconPosition = "right",
  closeOnClickChildren = false,
  classes = {},
}) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);
  const menuRef = useRef(null);

  const handleOnClickOutside = ({target}) => {
    if(!target.contains(containerRef.current)) {
        setShow(false)
    }
  }

  const handleOnClickMenu = ({target}) => {
    if(menuRef.current && menuRef.current.contains(target) && closeOnClickChildren) {
        setShow(false);
    }
  };

  return (
    <div className={`relative ${classes.container || ''}`} ref={containerRef}>
      {show && <div className="h-full w-screen left-0 top-0 fixed z-0" onClick={handleOnClickOutside}/>}
      <button
        className={`
            font-bold text-dark text-center inline-flex items-center py-3 px-4 hover:bg-backL2 transition-colors 
            ${ classes.button || ''}
        `}
        type="button"
        onClick={() => setShow(!show)}
      >
        <div className="w-full flex justify-between items-center">
          <div className="font-base flex gap-x-1 w-full">
            { iconPosition === "left" && buttonIcon }
            { buttonText && (
                <span className="w-full whitespace-nowrap text-ellipsis overflow-hidden">
                {buttonText}
                </span>
            )}
            { iconPosition === "right" && buttonIcon }
          </div>
        </div>
      </button>

      {show ? (
        <div
          className={`
            min-w-[1rem] absolute bg-white text-base z-[9999] list-none divide-y divide-gray-100 rounded shadow-2xl shadow-black
            ${position || ''}
            ${classes.menu || ''}
          `}
        >
            <ul 
                aria-labelledby="dropdown" 
                onClick={handleOnClickMenu} 
                ref={menuRef}
                className={`pt-5 pb-4 ${classes.list || ''}`}
                >
                {children}
            </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
