import React, { useContext, useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { BaseContext } from "contexts/BaseContext";
import { UserContext } from "contexts/UserContext";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import Breadcrumb from "components/ui/Breadcrumb";
import { parse } from "date-fns";
import DELETE_USER from "queries/auth/deleteUser";
import { useNavigate } from "react-router-dom";
import signOut from "utilities/functions/signOut";
import UserCard from "./UserCard";
import UserForm from "./UserForm";

import profileText from "texts/pages/profileText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { session, setSession } = useContext(UserContext);
  const { language } = useContext(BaseContext);
  const [confirmation, setConfirmation] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    alias: "",
    name: "",
    lastname: "",
    dateOfBirth: "",
    documentNumber: "",
    documentType: "",
    gender: "",
    genderOther: "",
    about: "",
    image: null,
    survey: "",
    surveyOther: "",
    surveyRacetrack: "",
  });
  const [openDelete, setOpenDelete] = useState(false);

  const [deleteAccount, { data: dataDelete, loading: loadingDelete }] =
    useMutation(DELETE_USER, { onError(e) {} });

  useEffect(() => {
    let mounted = true;

    if (session && mounted) {
      let surveyAux = "";
      let surveyOtherAux = "";
      let surveyRacetrackAux = "";

      if (session.survey?.includes("Hipódromo")) {
        surveyAux = "Hipódromo";
        surveyRacetrackAux = session.survey.split(":")[1];
      } else if (session.survey && session.survey[0] === "O") {
        surveyAux = "O";
        surveyOtherAux = session.survey.split(":")[1];
      } else {
        surveyAux = session.survey;
      }

      setUserData({
        ...userData,
        dateOfBirth: session.dateOfBirth
          ? parse(session.dateOfBirth, "dd-MM-yyyy", new Date())
          : "",
        email: session.email || "",
        documentType: session.documentType || "",
        documentNumber: session.documentNumber || "",
        name: session.name || "",
        lastname: session?.lastname || "",
        alias: session.alias || "",
        image: session.image || "",
        about: session.about || "",
        survey: surveyAux,
        surveyOther: surveyOtherAux,
        surveyRacetrack: surveyRacetrackAux,
        gender: session.gender ? session.gender[0] : "",
        genderOther: session.gender
          ? session.gender[0] === "O"
            ? session.gender.split(":")[1]
            : ""
          : "",
      });
    }
    return () => {
      mounted = false;
    };
  }, [session]);

  const onClose = () => {
    setOpenDelete(false);
  };

  const onChangeConfirmation = ({ target }) => {
    setConfirmation(target.value);
  };

  const handleDelete = () => {
    deleteAccount();
  };

  const handleSignOut = async () => {
    await signOut(client, setSession);
    navigate("/auth/signin");
  };

  useEffect(() => {
    let mounted = true;
    if (dataDelete && !loadingDelete && mounted) {
      handleSignOut();
    }
    return () => (mounted = false);
  }, [dataDelete]);

  return (
    <div className="flex flex-col w-full h-full">
      <Breadcrumb
        path={[
          { href: "/app/main", text: "Main" },
          { href: "", text: "Profile" },
        ]}
      />
      <div className="md:flex w-full h-full">
        <div className="h-auto w-full md:flex md:w-7/12 px-10 py-4">
          <UserForm
            userData={userData}
            setUserData={setUserData}
            setOpenDelete={setOpenDelete}
          />
        </div>
        <div className="h-auto w-full md:flex md:w-5/12 px-10 py-4">
          <UserCard userData={userData} setUserData={setUserData} />
        </div>
      </div>
      <Dialog
        open={openDelete}
        TransitionComponent={Transition}
        onClose={onClose}
      >
        <DialogTitle>{profileText[language].deleteTitle}</DialogTitle>
        <DialogContent>
          <p className="text-backD1 font-roboto">
            {profileText[language].deleteDesc}
          </p>
          <TextField
            value={confirmation}
            onChange={onChangeConfirmation}
            size="small"
            variant="standard"
            inputProps={{ maxLength: 10 }}
            className="w-full"
          />
        </DialogContent>
        <DialogActions>
          {loadingDelete ? (
            <div className="px-4">
              <CircularProgress size={30} />
            </div>
          ) : (
            <>
              <Button onClick={onClose} className="text-backD2">
                {profileText[language].cancel}
              </Button>
              <Button
                onClick={handleDelete}
                disabled={confirmation !== profileText[language].deleteConfirm}
                className="text-danger"
              >
                {profileText[language].confirm}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
