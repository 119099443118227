import { gql } from "@apollo/client";

export const GET_HORSERACE_BY_URL = gql`
query PUBLIC_HorseRaceByShort($short: String!){
  horseRaceByShort(short: $short ){
    id,
    date,
    racetrack_id,
    betamt_uom,
    category,
    competitors
    {
      pos,
      rail,
      horse_id,
      horse_name,
      horse_details {
        color
        country
        gender
      },
      age,
      jockey_id,
      jockey_name,
      jockey_details {
        country,
        ismale,
        isactive
      },
      stable_id,
      stable_name,
      carer_name,
      jKg,
      hKg,
      wind_dist,
      bet_amt,
      withdrawn,
      rodo,
      desmonta,
    }
    condition,
    course,
    crdate,
    distance,
    distance_uom,
    wintime,
    minutes,
    seconds,
    milliseconds,
    name,
    prizes{
      prize1,
      prize2,
      prize3,
      prize4,
      prize5,
      prize6,
      prize7,
      prize8
    },
    raceno,
    total_races,
    status,
    type,
    weight,
  }
}`;