import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';

const Notifications = () => {
    const [anchor, setAnchor] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const menuOpen = Boolean(anchor);

    return ( 
        <>
            <IconButton 
                onClick={(e)=>setAnchor(e.currentTarget)} 
                className="material-icons text-secondary text-3xl leading-none"
            >
                notifications
            </IconButton>
            <Menu
                id="notifications-menu"
                anchorEl={anchor}
                open={menuOpen}
                onClose={()=>setAnchor(null)}
            >
                {
                    notifications.length === 0 ? (
                        <div className='px-4'>
                            No items
                        </div>
                    ) : (
                        notifications.map((notif, index)=>(
                            <MenuItem key={`notif-${index}`}>
                                {JSON.stringify(notif)}
                            </MenuItem>
                        ))
                    )
                }
            </Menu>
        </>
    );
}
 
export default Notifications;