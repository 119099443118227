import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_HORSERACE_BY_URL } from "queries/horserace";
import { GET_PREDICTION_BY_URL } from "queries/predictions";
import { Navigate, useParams } from "react-router-dom";
import Banner from "./Banner";
import { RACE_TRACKS } from "queries/racetrack";
import Head from "./Head";
import Table from "./Table";
import Prizes from "./Prizes";
import PredictionCard from "./PredictionCard";

const Prediction = () => {
  const { short_url } = useParams();
  if (!short_url) <Navigate to="/landing" replace />;

  const [currentRace, setCurrentRace] = useState();
  const [raceTracks, setRaceTracks] = useState({});
  const [prediction, setPrediction] = useState();

  const {
    data: dataRace,
    loading: loadingRace,
  } = useQuery(GET_HORSERACE_BY_URL, {
    variables: { short: short_url },
    skip: !short_url,
  });

  const {
    data: dataPrediction,
    loading: loadingPrediction,
  } = useQuery(GET_PREDICTION_BY_URL, {
    variables: { url: short_url },
    skip: !short_url,
  });

  const {
    data: dataRaceTracks,
    loading: loadingRaceTracks,
  } = useQuery(RACE_TRACKS);

  useEffect(() => {
    let mounted = true;
    if (dataRace && !loadingRace && mounted) {
      setCurrentRace(dataRace.horseRaceByShort);
    }
    return () => (mounted = false);
  }, [dataRace, loadingRace]);

  useEffect(() => {
    let mounted = true;
    if (dataPrediction && !loadingPrediction && mounted) {
      setPrediction(dataPrediction.predictionByUrl);
    }
    return () => (mounted = false);
  }, [dataPrediction, loadingPrediction]);

  useEffect(() => {
    let mounted = true;
    if (dataRaceTracks && !loadingRaceTracks) {
      let auxRaceTracks = {};
      dataRaceTracks.raceTracks?.forEach((RT) => {
        auxRaceTracks[RT.id] = RT;
      });
      setRaceTracks(auxRaceTracks);
    }
    return () => (mounted = false);
  }, [dataRaceTracks, loadingRaceTracks]);

  return (
    <div className="bg-back h-full">
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex justify-center flex-col w-full md:w-8/12">
          {loadingRace ? (
            <CircularProgress size={120} />
          ) : (
            currentRace && (
              <>
                <Banner
                  RT={currentRace.racetrack_id}
                  raceTracks={raceTracks}
                  raceDate={currentRace.date}
                  loadingRace={loadingRace}
                />
                <Head currentRace={currentRace} />
                <Prizes
                  RT={currentRace.racetrack_id}
                  currentRace={currentRace}
                  raceTracks={raceTracks}
                />
                <Table currentRace={currentRace} prediction={prediction}/>
              </>
            )
          )}
        </div>
        <div className="flex flex-col w-full md:w-4/12 p-4">
            {
                loadingPrediction || !prediction ? (
                    <CircularProgress size={120}/>
                ) : (
                    <>
                        <PredictionCard
                            prediction={prediction}
                            currentRace={currentRace}
                            raceTracks={raceTracks}
                        />
                    </>
                )
            }
        </div>
      </div>
    </div>
  );
};

export default Prediction;
