import React, { useContext } from 'react';
import { BaseContext } from 'contexts/BaseContext';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from 'react-router-dom';

import signUpText from 'texts/pages/signUpText';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessDialog = ({
    open,
    setOpen,
    email = "",
    domain = "",
}) => {
    const { language } = useContext(BaseContext);
    const navigate = useNavigate();

    const onClose = () => {
        setOpen(false);
    }

    const handleConfirm = () => {
        onClose();
        navigate("/auth/signin");
    }

    return (
        <Dialog open={open} TransitionComponent={Transition} onClose={onClose}>
            <DialogTitle>{signUpText[language].welcome}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`${signUpText[language].confirmText1} ${email}@`}
                <b className='my-0'>{domain} </b>
                {`${signUpText[language].confirmText2}`}
              </DialogContentText>
              <p className='my-0 text-base font-roboto text-backD2'>{signUpText[language].confirmSpam}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm} color="primary">
                {signUpText[language].confirm}
              </Button>
            </DialogActions>
        </Dialog>
    );
}
 
export default SuccessDialog;