import React, { useContext } from "react";
import { BaseContext } from "contexts/BaseContext";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import Slide from "@mui/material/Slide";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";

import reviewText from "texts/components/reviewText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RatingDialog = ({
  open,
  onClose,
  score = 0,
  comment = "",
  onChange,
  onChangeHover,
  hover,
  onSave,
  loading,
}) => {
  const { language } = useContext(BaseContext);

  const ratingLabels = {
    0.5: reviewText[language][0.5],
    1: reviewText[language][1],
    1.5: reviewText[language][1.5],
    2: reviewText[language][2],
    2.5: reviewText[language][2.5],
    3: reviewText[language][3],
    3.5: reviewText[language][3.5],
    4: reviewText[language][4],
    4.5: reviewText[language][4.5],
    5: reviewText[language][5],
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={onClose}>
      <DialogTitle>{reviewText[language].ratingTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{reviewText[language].ratingDesc}</DialogContentText>
        <div className="flex items-center gap-x-2">
          <Rating
            size="large"
            name="score"
            precision={0.5}
            value={parseFloat(score)}
            onChange={onChange}
            onChangeActive={onChangeHover}
          />
          <p>{score !== null && ratingLabels[hover !== -1 ? hover : score]}</p>
        </div>
        <TextField
          name="comment"
          multiline
          rows="5"
          label={reviewText[language].commentLabel}
          color="secondary"
          inputProps={{ maxLength: 500 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <p
                  className={`-mb-16 ${
                    comment?.length === 500 ? "text-danger" : "text-secondary"
                  }`}
                >
                  {`${comment?.length}/500`}
                </p>
              </InputAdornment>
            ),
          }}
          fullWidth
          value={comment}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress size={24} className="m-4"/>
        ) : (
          <Button onClick={() => onSave({ score: score, comment: comment })}>
            {reviewText[language].save}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;
