import ArrowButton from "components/ui/ArrowButton";
import TagsChips from "components/ui/Head/Chips/TagsChips";
import WeatherIcon from "components/ui/WeatherIcon";
import { BaseContext } from "contexts/BaseContext";
import { MainContext } from "contexts/MainContext";
import { format } from "date-fns";
import React, { useContext } from "react";
import sideContentText from "texts/components/sideContentText";
import raceNumberText from "texts/general/raceNumberText";
import CompetitorSection from "./CompetitorSection";
import CompSlider from "./CompSlider";
import Head from "./Head";

const SideContent = ({
  currentCompetitor = {},
  competitors = [],
  compIndex = 0,
  setCompIndex,
}) => {
  const { dayRaces, currentIndex, setCurrentIndex } = useContext(MainContext);
  const { language, locales } = useContext(BaseContext);


  const currentRace = dayRaces?.[currentIndex] || {};

  return (
    <div className="bg-white h-full w-full shadow-xl">
      <div className="bg-backD1.5 w-full py-2 px-4 flex items-center justify-between">
        <p className="text-white text-sm my-0 leading-none">
        {currentRace?.date && format(
            new Date(currentRace?.date),
            language === "en"
              ? "EEE, MMMM d HH:mm'h' "
              : "EEE, d 'de' MMMM  HH:mm'h' ",
            { locale: locales[language] }
          )}  
        </p>
        {
          currentRace?.weatherCode && (
            <div className="flex items-center gap-x-1">
              <WeatherIcon
                weatherCode={currentRace.weatherCode}
                size={24}
                color="#FFF"
                className="m-0 p-0 leading-none"
              />
              <p className="my-0 text-white">{`${currentRace.weatherTemp}°C`}</p>
            </div>
          )
        }
        {/* <p className="text-white text-sm my-0 leading-none">20°C</p> */}
      </div>
      <Head currentRace={currentRace}/>
      <div className="w-10/12 h-[1px] bg-back mx-auto my-2"/>
      <CompSlider 
        competitors={competitors}
        compIndex={compIndex}
        setCompIndex={setCompIndex}
      />
      {
        !competitors[compIndex] ? (
          <div className="w-full flex items-center justify-center gap-x-9">
            <div className="h-[300px] w-1/3 rounded-lg bg-backL2 animate-pulse"/>
            <div className="h-[340px] w-1/3 mt-14 rounded-lg bg-backL2 animate-pulse"/>
          </div>
        ) : (
          <CompetitorSection 
            competitor={competitors[compIndex]}
            details={competitors[compIndex]?.jockey_details}
          />
        )
      }
    </div>
  );
};

export default SideContent;
