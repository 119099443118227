import { gql } from "@apollo/client";

const SEARCH = gql`
  query ($contains: String!) {
    search(contains: $contains) {
      __typename
      ... on HorseRace {
        name
        date
        racetrack_id
        distance
        destacada
        condition
        weight
        type
        course
        category
        status
        minutes
        seconds
        milliseconds
        raceno
      }
      __typename
      ... on JockeySearch {
        idJockey: _id
        name
        pic_url
        country
        isactive
        ismale
        racetracks
      }
      __typename
      ... on Horse {
        idHorse: _id
        name
        pic_url
        stable_name
        birth
        debut
        color
        country
        sire
        sired
        sires
        dam
        damd
        dams
        gender
        main
        birth_year
        dam_cty
        dam_year
        damd_cty
        dams_cty
        sire_cty
        sire_year
        sired_ty
        sires_cty
      }
    }
  }
`;

export default SEARCH;
