import React, { useContext } from 'react';
import { SplideSlide } from '@splidejs/react-splide';
import horse_bg03 from 'assets/horse_bg03_lower.webp';
import homeText from 'texts/pages/homeText';
import { BaseContext } from 'contexts/BaseContext';

const Slide1 = () => {
    const { language } = useContext(BaseContext);

    const content = [
        {
            icon: 'devices',
            title: homeText[language].squareTitle1,
            description: homeText[language].squareDesc1,
        },
        {
            icon: 'autorenew',
            title: homeText[language].squareTitle2,
            description: homeText[language].squareDesc2,
        },
        {
            icon: 'people',
            title: homeText[language].squareTitle3,
            description: homeText[language].squareDesc3,
        },
        {
            icon: 'done',
            title: homeText[language].squareTitle4,
            description: homeText[language].squareDesc4,
        },
        {
            icon: 'trending_up',
            title: homeText[language].squareTitle5,
            description: homeText[language].squareDesc5,
        },
        {
            icon: 'favorite',
            title: homeText[language].squareTitle6,
            description: homeText[language].squareDesc6,
        },
    ]
    return ( 
    <SplideSlide className="h-full">
       <div
            className='h-full w-full bg-cover bg-center flex items-center justify-center'
            style={{backgroundImage:`url(${horse_bg03})`}}
        >
            <div className='h-full w-full absolute z-0 bg-black bg-opacity-50'/>
            <div 
                className='
                    flex items-center gap-y-16 overflow-y-auto flex-wrap
                    absolute py-32 left-0 top-0 right-0 bottom-0
                    md:relative md:pt-0 md:pb-10 md:max-w-7xl
                '
            >
                {
                    content.map(item=>(
                    <div 
                        className='flex flex-col items-center justify-start w-full md:w-1/3 px-16'
                        key={item.title}
                    >
                        <span className='material-icons text-white text-6xl'>{item.icon}</span>
                        <h1 className='text-white text-center text-xl md:text-3xl'>{item.title}</h1>
                        <h3 className='text-white text-center text-base md:text-xl'>{item.description}</h3>
                    </div>
                    ))
                }
            </div>
        </div>
    </SplideSlide>
 );
}
 
export default Slide1;