import React, { useContext, useRef, useState } from "react";
import { BaseContext } from "contexts/BaseContext";

import AvatarEditor from "react-avatar-editor";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import profileText from "texts/pages/profileText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UploadAvatar = ({ setUserData, userData }) => {
  const { language } = useContext(BaseContext);
  const inputRef = useRef(null);
  const editorRef = useRef(null);
  const [uncroppedImage, setUncroppedImage] = useState();
  const [imageData, setImageData] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: userData?.image,
  });

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setUncroppedImage(e.target.files[0]);
    setImageData((image) => ({
      ...image,
      img: url,
      file: e.target.files[0],
      cropperOpen: true,
    }));
  };

  const handleSlider = (e, value) => {
    setImageData(image => ({
      ...image,
      zoom: value
    }));
  };

  const handleCancel = () => {
    setImageData((image) => ({
      ...image,
      cropperOpen: false,
    }));
  };

  const handleSave = (e) => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      setImageData((image) => ({
        ...image,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      }));

      setUserData((userData) => ({
        ...userData,
        image: croppedImg,
      }));
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        className="mb-4 rounded-full"
        onClick={() => inputRef.current.click()}
      >
        {profileText[language].pickNew}
      </Button>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleFileChange}
        className="hidden"
      />

      <Dialog
        open={imageData.cropperOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="edit-avatar-dialog-slide-title"
        aria-describedby="edit-avatar-dialog-slide-description"
      >
        <DialogContent>
          <h2 className="my-0">{profileText[language].newImage}</h2>
          <AvatarEditor
            ref={editorRef}
            image={imageData.img}
            width={200}
            height={200}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            rotate={0}
            scale={imageData.zoom}
          />
          <Slider
            value={imageData.zoom}
            min={1}
            max={10}
            step={0.1}
            onChange={handleSlider}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCancel}>
            {profileText[language].cancel}
          </Button>
          <Button variant="text" onClick={handleSave}>
            {profileText[language].save}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadAvatar;
