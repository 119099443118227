import React, { useState, useEffect, useContext } from "react";

//Contexts
import { RaceContext } from "contexts/RaceContext";

import IconButton from "@mui/material/IconButton";

const Animations = ({ dayRaces, currentRace, fullWidth }) => {
  const { raceTracks } = useContext(RaceContext);

  const [displayButton, setDisplayButton] = useState(false);
  const [coordinates, setCoordinates] = useState("");
  const [sourceList, setSourceList] = useState({});

  const handleEnded = (e) => {
    setDisplayButton(true);
    // e.play();
  };

  const onClickPlay = (e) => {
    e.preventDefault();
    let id = Object.keys(sourceList).find((src) =>
      sourceList[src].includes(currentRace.id)
    );
    let vid = document.getElementById(`trackAnimation-${id}`);
    if (vid) {
      vid.currentTime = 0;
      vid.play();
    }
    setDisplayButton(false);
  };

  const findCoord = (currentRaceTrackId) => {
    if (raceTracks) {
      let found = raceTracks[currentRaceTrackId];
      setCoordinates(found?.url_map || {});
    }
  };

  useEffect(() => {
    if (currentRace && raceTracks) {
      let id = Object.keys(sourceList).find((src) =>
        sourceList[src].includes(currentRace.id)
      );
      let vid = document.getElementById(`trackAnimation-${id}`);
      if (vid) {
        vid.currentTime = 0;
        vid.play();
      }
      setDisplayButton(false);
      findCoord(currentRace.racetrack_id);
    }
  }, [currentRace]);

  useEffect(() => {
    let mounted = true;
    
    if (dayRaces) {
      let auxSourceList = {};
      dayRaces.forEach((race) => {
        const course = race.course.includes("Cesped") ? "GRASS" : "SAND";
        const RT = race.racetrack_id;
        const distance = race.distance;
        let url = `https://storage.googleapis.com/invasor-assets/racetrack/${RT}/${course}_${distance}.mp4`;
        if (!auxSourceList[url]) {
          auxSourceList[url] = [race.id];
        } else {
          auxSourceList[url].push(race.id);
        }
      });
      if (mounted) {
        setSourceList(auxSourceList);
      }
    }
    return () => {
      mounted = false;
    };
  }, [dayRaces]);

  return (
    <div className="bg-white rounded-lg shadow p-2 w-full md:h-[15.2rem] relative">
      {Object.keys(sourceList).map((src, index) => {
        return (
          <div key={`${src}`}>
            <video
              autoPlay
              muted
              playsInline
              id={`trackAnimation-${src}`}
              key={`${src}`}
              className={`
                ${ fullWidth ? "w-full h-auto" : "m-0 xs:m-0 h-36 sm:h-52 sm:m-2 relative" }
                ${ sourceList[src]?.includes(currentRace?.id) ? 'flex' : 'hidden'}
              `}
              onContextMenu={(e) => e.preventDefault()}
              onEnded={handleEnded}
            >
              <source
                src={`${src}`}
                id={`trackAnimationSrc-${index}`}
                type="video/mp4"
              />
            </video>

            {displayButton && (
              <IconButton
                className="absolute bottom-6 right-3 bg-black text-white material-icons p-1 flex items-center justify-center rounded-full hover:bg-secondaryD2"
                onClick={onClickPlay}
              >
                play_arrow
              </IconButton>
            )}
            <a target="_blank" rel="noreferrer" href={coordinates}>
              <IconButton className="absolute bottom-[4rem] right-3 bg-black text-white material-icons p-1 flex items-center justify-center rounded-full hover:bg-secondaryD2">
                place
              </IconButton>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default Animations;
