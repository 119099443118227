import React, { useEffect, useState } from "react";

import { format } from "date-fns";

import CustomStepper from "./Stepper";
import CustomStep from "./Stepper/Step";
import UnsavedClassicStep from "./StepIcons/UnsavedClassic";
import UnsavedNormalStepIcon from "./StepIcons/UnsavedNormal";
import ClassicStepIcon from "./StepIcons/Classic";
import NormalStepIcon from "./StepIcons/Normal";

const Steps = ({ dayRaces, currentIndex, setCurrentIndex, changed = [] }) => {

  const [suspended, setSuspended] = useState(false);

  const onClickStepper = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    let mounted = true;
    if (currentIndex && dayRaces && dayRaces[currentIndex]) {
      const currentRace = dayRaces[currentIndex];
      let auxSuspended = true;

      dayRaces.forEach((race) => {
        if (!race.suspended) {
          auxSuspended = false;
        }
      });

      if (mounted) {
        if (!auxSuspended && currentRace.suspended) {
          setSuspended("current");
        } else if (!auxSuspended && !currentRace.suspended) {
          setSuspended(false);
        } else if (auxSuspended && dayRaces.length > 0) {
          setSuspended("all");
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [currentIndex, dayRaces]);

  return (
    <CustomStepper activeStep={currentIndex}>
      {dayRaces?.map((race, index) => (
        <CustomStep
          key={index}
          onClick={() => onClickStepper(index)}
          text={ `${format(new Date(race.date), `HH:mm`)} ${ race?.suspended && suspended !== "all" ? "⚠️" : ""}`}
          icon={
            changed && changed[index] ? (
              ["clasico", "g1", "g2", "g3", "handicap", "especial"].includes(
                race.type?.toLowerCase()
              ) || race.destacada ? (
                <UnsavedClassicStep
                  icon={index}
                  dayRaces={dayRaces}
                  currentIndex={currentIndex}
                />
              ) : (
                <UnsavedNormalStepIcon
                  icon={index}
                  dayRaces={dayRaces}
                  currentIndex={currentIndex}
                />
              )
            ) : ["clasico", "g1", "g2", "g3", "handicap", "especial"].includes(
                race.type?.toLowerCase()
              ) || race.destacada ? (
              <ClassicStepIcon
                icon={index}
                dayRaces={dayRaces}
                currentIndex={currentIndex}
              />
            ) : (
              <NormalStepIcon
                icon={index}
                dayRaces={dayRaces}
                currentIndex={currentIndex}
              />
            )
          }
        />
      ))}
    </CustomStepper>
  );
};

export default Steps;
