export const mainText = { 
    en: {  
        cardPrizes:'Awards',
        welcome:'Welcome 🎉',
        cardBets:'Bets',
        past:'Apparently, there was no race on this date.',
        future:'There is no race data on the selected date yet.',
        present:'Apparently, there are no races today.',
        videoNotFound:'Live video not available.',
        videoNotFoundPast:'Horseracing video not available.',
        liveUnavailable:'Live video is unavailable.',
        subSuccess:'The payment was successful!',
        subPending:<>The payment is pending approval by Mercado Pago. <br/> When approved, you will be able to enjoy all the content.</>,
        subFailure: 'The payment could not be made.',
        source: 'Source: ',
        videoProblems1: 'If you have issues trying to watch, please click',
        videoProblems2: 'here',
        racetrackDialogTitle:'Choose the racecourse',
    },
    es: {  
        cardPrizes:'Premios',
        welcome:'Bienvenido 🎉',
        cardBets:'Apuestas',
        past:'Al parecer, no se corrió en esta fecha. No tenemos datos registrados.',
        future:'Aún no hay datos de carreras para la fecha seleccionada.',
        present:'Al parecer, hoy no se corre. No tenemos carreras registradas.',
        videoNotFound:'Video en vivo no disponible.',
        videoNotFoundPast:'Video de la carrera no disponible.',
        liveUnavailable:'El video en vivo no está disponible.',
        subSuccess:'¡El pago se realizó con éxito!',
        subPending:<>El pago está pendiente de aprobación por Mercado Pago. <br/> Cuando se apruebe, podrás disfrutar de todo el contenido.</>,
        subFailure:'El pago no pudo ser realizado.',
        source: 'Fuente: ',
        videoProblems1: 'Si tienes problemas para ver el video, prueba',
        videoProblems2: 'aquí',
        racetrackDialogTitle:'Selecciona un hipódromo',
    },
    pt: {  
        cardPrizes:'Prêmios',
        welcome:'Bem-vindo 🎉',
        cardBets:'Apuestas',
        past:'Não temos dados cadastrados.',
        future:'Ainda não há dados de corrida na data selecionada.',
        present:'Hoje não tem corridas.',
        videoNotFound:'Vídeo ao vivo não disponível.',
        videoNotFoundPast:'Vídeo da corrida não disponível.',
        liveUnavailable:'O vídeo ao vivo não está disponível.',
        subSuccess:'O pagamento foi realizado com sucesso!',
        subPending:<>O pagamento está pendente de aprovação do Mercado Pago. <br/> Quando aprovado, você poderá desfrutar de todo o conteúdo.</>,
        subFailure:'O pagamento não pôde ser feito.',
        source: 'Fonte: ',
        videoProblems1: 'Se você tiver problemas ao tentar assistir, clique',
        videoProblems2: 'aqui',
        racetrackDialogTitle:'Escolha o hipódromo',
    },
}
