import { gql } from "@apollo/client/core";

const GET_ME = gql`
  {
    me {
      id
      alias
      country
      gender
      survey
      about
      email
      role
      name
      lastname
      dateOfBirth
      documentNumber
      documentType
      isVerified
      firstLogin
      image
      about
      createdAt
      updatedAt
    }
  }
`;

export default GET_ME;