import React, { useState, useEffect, useContext } from "react";
import { BaseContext } from "contexts/BaseContext";

import ReviewButton from "components/ui/Review";
import SkeletonTop from "./UpcomingMounts/SkeletonTop";
import MainInfo from "./MainInfo";

import jockeyDetailText from "texts/pages/jockeyText";

import {
  UPDATE_JOCKEYRATING,
  GET_JOCKEYRATING_BY_USER,
} from "queries/score";
import { useMutation, useLazyQuery } from "@apollo/client";
import UpcomingMounts from "./UpcomingMounts";


const SideContent = ({ jockeyData, jockey_id, setHorseToAdd }) => {
  const { language } = useContext(BaseContext);
  
  const [userRating, setUserRating] = useState({ score: 0, comment: "" });

  const [
    updateRating,
    {
      data: dataSaveRating,
      loading: loadingSaveRating,
      error: errorSaveRating,
    },
  ] = useMutation(UPDATE_JOCKEYRATING, { onError(err) {} });

  const [getJockeyRating, { data: dataRating, loading: loadingRating }] =
    useLazyQuery(GET_JOCKEYRATING_BY_USER, {
      fetchPolicy: "cache-and-network",
    });

  const setHorsefilter = (horse) => {
    setHorseToAdd(horse.horse_name);
  };

  const handleReviewSave = (review) =>
    new Promise((resolve, reject) => {
      try {
        updateRating({
          variables: {
            jockeyId: jockeyData.id,
            score: parseFloat(review.score),
            comment: review.comment || "",
          },
        }).then((data) => {
          if (!data.errors) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      } catch (e) {
        reject(false);
      }
    });

  useEffect(() => {
    if (dataRating && !loadingRating) {
      setUserRating({
        score: dataRating.jockeyRatingByUser[0]?.score,
        comment: dataRating.jockeyRatingByUser[0]?.comment,
      });
    }
  }, [dataRating]);

  useEffect(() => {
    if (jockeyData) {
      getJockeyRating({ variables: { jockeyId: jockeyData?.id } });
    }
  }, [jockeyData]);

  return (
    <div className="h-full bg-white p-4">
      {jockeyData ? (
        <MainInfo jockeyData={jockeyData} jockey_id={jockey_id}/>
      ) : (
        <SkeletonTop />
      )}

      <div className="bg-gray-100 h-0.5 w-full" />

      {jockeyData ? (
        <div className="flex w-full items-center justify-center p-4">
          <ReviewButton
            initialScore={userRating.score || 0}
            initialComment={userRating.comment || ""}
            onSave={handleReviewSave}
            dataSave={dataSaveRating}
            loadingSave={loadingSaveRating}
            errorSave={errorSaveRating}
            mode="button"
          />
        </div>
      ) : (
        <div className="my-4 h-11 bg-gray-300 animate-pulse w-52 rounded-full mx-auto" />
      )}

      <div className="bg-gray-100 h-0.5 w-full" />

      <div className="flex justify-center items-center py-4">
        {jockeyData ? (
          jockeyData.racetracks?.map((rt, index) => (
            <div className="mx-2" key={`rt-${index}`}>
              <img
                key={`rt-${rt}`}
                src={`https://storage.googleapis.com/invasor-assets/racetrack/${rt}/logo.png`}
                className="block h-8 w-auto"
                style={{ filter: "invert(0.8)" }}
                alt=""
              />
            </div>
          ))
        ) : (
          <>
            <div className="bg-gray-300 w-14 animate-pulse mx-2 h-8 rounded" />
            <div className="bg-gray-300 w-14 animate-pulse mx-2 h-8 rounded" />
            <div className="bg-gray-300 w-14 animate-pulse mx-2 h-8 rounded" />
          </>
        )}
      </div>

      <div className="bg-gray-100 h-0.5 w-full" />

      <div>
        <h5 className="font-medium text-lg mt-4">
          {jockeyDetailText[language].upcoming}
        </h5>
        {jockeyData && Object.keys(jockeyData.next_mounts).length ? (
          <UpcomingMounts jockeyData={jockeyData} setHorseToAdd={setHorsefilter}/>
        ) : (
          <h5 className="md:text-xl my-8 text-backD1 text-center font-bold">
            {jockeyDetailText[language].noRace}
          </h5>
        )}
      </div>
    </div>
  );
};

export default SideContent;
