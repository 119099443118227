import { gql } from "@apollo/client"

export const GET_WEATHER_BY_DAY_RT = gql`
    query ($date: String!, $racetrack_id: String!){
        weatherByDayRT(date:$date, racetrack_id: $racetrack_id)
        {
            date
            tempC
            weatherCode
        }
    }
`