const searchText = { 
    en: {
        horses:'Horses',
        jockeys:'Jockeys',
        race:'Races',
        color:{
            ZC: 'Color ZC',
            A: 'Color A',
            Z: 'Color Z',
            T: 'Color T',
            ZD: 'Color ZD',
        },
        gender:{
            true:'Jockey',
            false:'Jocketa',
        },
        horseGender:{
            M:'Horse',
            H:'Mare',
        },
        notFound: 'No results found.',
        generic:'Generic',
        searchResults:'Search results',
        grass:'Grass',
        sand:'Sand',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Classic',
        l:'L',
        especial:'Special',
        noData:'There is no historic data.',
        age:'Years',
        debutant:'Debutant',
    },
    es: {  
        horses:'Caballos',
        jockeys:'Jockeys',
        race:'Carreras',
        color:{
            ZC: 'Color ZC',
            A: 'Color A',
            Z: 'Color Z',
            T: 'Color T',
            ZD: 'Color ZD',
        },
        gender:{
            true:'Jockey',
            false:'Jocketa',
        },
        horseGender:{
            M:'Caballo',
            H:'Yegua',
        },
        notFound: 'No se encontraron resultados.',
        generic:'Genérico',
        searchResults:'Resultados de búsqueda',
        grass:'Césped',
        sand:'Arena',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Clásico',
        l:'L',
        especial:'Especial',
        noData:'No registramos datos históricos de este ejemplar.',
        age:'Años',
        debutant:'Debutante',
    },
    pt: {  
        horses:'Cavalos',
        jockeys:'Jóqueis',
        race:'Corridas',
        color:{
            ZC: 'Color ZC',
            A: 'Color A',
            Z: 'Color Z',
            T: 'Color T',
            ZD: 'Color ZD',
        },
        gender:{
            true:'Jóquei',
            false:'Jóquei',
        },
        horseGender:{
            M:'Cavalo',
            H:'Égua',
        },
        notFound: 'Nenhum resultado encontrado.',
        generic:'Genérico',
        searchResults:'Resultados da pesquisa',
        grass:'Grama',
        sand:'Areia',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Clássico',
        l:'L',
        especial:'Especial',
        noData:'Não há dados históricos.',
        age:'Anos',
        debutant:'Debutante',
    },
}

export default searchText;