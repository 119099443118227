import { gql } from "@apollo/client/core";

export const GET_HORSERACE_BY_DATE_RT = gql`
  query HorseRaceDateRT($date: String!, $racetrack_id: String!) {
    horseRaceDateRT(date: $date, racetrack_id: $racetrack_id) {
      id
      date
      crdate
      suspended
      racetrack_id
      destacada
      betamt_uom
      category
      type
      competitors {
        pos
        rail
        horse_id
        horse_name
        horse_details {
          color
          country
          crdate
          debut
          dam
          damd
          dams
          sire
          sires
          sired
          birth_year
          dam_cty
          dam_year
          damd_cty
          dams_cty
          sire_cty
          sired_ty
          sires_cty
          gender
        }
        age
        jockey_id
        jockey_name
        jockey_details {
          country
          ismale
          isactive
        }
        stable_id
        stable_name
        carer_name
        jKg
        hKg
        rating {
          score
          comment
        }
        wind_dist
        bet_amt
        withdrawn
        rodo
        desmonta
      }
      condition
      course
      distance
      milliseconds
      minutes
      name
      prizes {
        prize1
        prize2
        prize3
        prize4
        prize5
        prize6
        prize7
        prize8
      }
      raceno
      seconds
      status
      weight
      weatherCode
      weatherTemp
    }
  }
`;
