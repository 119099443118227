import distanceByKey from "utilities/objects/distanceByKey";

const addHistoryToResults = (auxHistory, auxHorses) => {
  let finalHorses = auxHorses;
  auxHistory.forEach((comp) => {
    let index = auxHorses.findIndex((horse) => horse.idHorse === comp._id);
    let worstPos = 0;
    if (index >= 0) {
      let auxChartData = { labels: [], data: [] };
      let auxCurrentHistory = comp.result?.slice()?.reverse();
      auxCurrentHistory.forEach((race) => {
        var histComp = race?.competitors[0];
        if (histComp.pos && !distanceByKey[histComp.pos]?.disablePos) {
          auxChartData.labels.push(
            `${histComp.pos}${race.racetrack_id.substring(2, 3)}`
          );
          auxChartData.data.push(parseInt(histComp.pos));
          if (worstPos < histComp.pos) {
            worstPos = histComp.pos;
          }
        } else if (histComp.pos) {
          auxChartData.labels.push(`${histComp.pos.toUpperCase()}`);
          auxChartData.data.push(0);
        } else if (histComp.withdrawn) {
          auxChartData.labels.push(`${"RET"}`);
          auxChartData.data.push(null);
        } else {
          auxChartData.labels.push(`${"-"}`);
          auxChartData.data.push(null);
        }
      });
      finalHorses[index].age = comp.result?.[0]?.competitors?.[0]?.age;
      finalHorses[index].chartData = auxChartData;
    }
  });
  return finalHorses;
};

export {
    addHistoryToResults,
}