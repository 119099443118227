import React, { useContext, useRef } from "react";
import { BaseContext } from "contexts/BaseContext";

import html2canvas from "html2canvas";
import IconButton from "@mui/material/IconButton";
import { predictionsText } from "texts/components/predictionsText";
import { format } from "date-fns";
import { getPredictionFromString } from "components/ui/Predictions/functions";
import WeatherIcon from "components/ui/WeatherIcon";

const PredictionCard = ({ prediction, currentRace, raceTracks }) => {
  const predictionsRef = useRef(null);
  const { language } = useContext(BaseContext);
  const RT = currentRace?.racetrack_id;
  const course = currentRace?.course?.toLowerCase()?.includes("arena")
    ? "sand"
    : "grass";
  const predictionsArray = [
    getPredictionFromString(prediction?.first, false),
    getPredictionFromString(prediction?.second, false),
    getPredictionFromString(prediction?.third, false),
    getPredictionFromString(prediction?.fourth, false),
  ];

  const handleDownload = () => {
    html2canvas(predictionsRef.current)?.then((canvas) => {
      const a = document.createElement("a");
      a.href = canvas.toDataURL();
      a.download = `${format(
        new Date(currentRace?.date),
        "yyyyMMdd_HHmm"
      )}_${RT}_${prediction?.alias}_${prediction?.short_url}.png`;
      a.click();
    });
  };

  const alterStyle = {
    true: "bg-yellow-300 text-black",
    false: "bg-backD2 text-white",
  };

  return (
    <div
      className="rounded-lg w-full bg-white p-6 flex flex-col"
      ref={predictionsRef}
    >
      <div className="flex justify-between items-center">
        <p className="my-0 leading-none text-xl font-roboto font-bold text-backD2">
          {predictionsText[language].predictionsBy}
          <span className="my-0 text-secondaryD3">{` ${prediction.alias}`}</span>
        </p>
        <IconButton onClick={handleDownload}>
          <span className="material-icons text-backD2">download</span>
        </IconButton>
      </div>
      <p className="my-2 text-lg font-bold">{currentRace?.name}</p>
      <div className="flex justify-between">
        <p className="my-0 text-base font-roboto text-backD1">
          {raceTracks[RT]?.name}
        </p>
        {prediction?.weatherCode && (
          <WeatherIcon
            weatherCode={prediction.weatherCode}
            size={24}
            color="#C4C4C4"
            className="!m-0"
          />
        )}
      </div>
      <div className="flex justify-between">
        <p className="my-0 text-base font-roboto text-backD1">
          {currentRace?.date && format(new Date(currentRace?.date), "dd/MM/yy")}
        </p>
        {prediction?.tempC && (
          <p className="my-0 text-base font-roboto text-backD1">{`${prediction.tempC}°C`}</p>
        )}
      </div>

      <div className="flex h-[1px] bg-back mx-4 my-2" id="divider" />

      <div className="flex justify-between">
        <p className="my-0 text-xl font-roboto font-bold text-secondaryD3">
          {`${predictionsText[language].race} ${currentRace?.raceno || ""}`}
        </p>
        <p className="my-0 text-xl font-roboto text-secondaryD3">
          {currentRace?.date && format(new Date(currentRace?.date), "HH:mm")}
        </p>
      </div>
      <div className="flex justify-between">
        <p className="my-0 text-base font-roboto text-backD2">
          {currentRace?.distance && `${currentRace?.distance}m`}
        </p>

        <p
          className={`
            my-0 text-base font-roboto text-white px-1
            ${course === "sand" ? "bg-sand" : "bg-grass"}
          `}
        >
          {predictionsText[language][course]}
        </p>
      </div>
      <div className="flex flex-col gap-y-4 py-4 mt-4">
        {predictionsArray?.map((predictionLine, index) => (
          <div
            className="w-full min-h-[3rem] gap-x-4 px-4 rounded-3xl flex items-center py-2 transition-colors bg-secondary"
            key={`line-${index + 1}`}
          >
            <p className="text-lg my-0 font-bold leading-none">{index+1}</p>
            {predictionLine.map((item) => {
              const isAlter = new RegExp(/[a-zA-Z]/g).test(item.rail);
              return (
                <div
                  key={`rail-${item.rail}`}
                  className={`
                    h-8 w-8 rounded-full my-0 flex items-center justify-center
                    ${alterStyle[isAlter]}
                  `}
                >
                  {item.rail}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PredictionCard;
