import React from "react";

import CollapseMUI from "@mui/material/Collapse";
import Review from "components/ui/Review";
import { useMutation } from "@apollo/client";
import { UPDATE_HORSERATING } from "queries/score";

const Collapse = ({ open, competitor }) => {
  const [saveRating, { data: dataSaveRating, loading: loadingSaveRating, error: errorSaveRating }] =
    useMutation(UPDATE_HORSERATING, { errorPolicy: "all"});

  const ratingHorse = competitor.rating || {};

  const handleSaveRating = ({ score, comment }) => {
      if(competitor.horse_id) {
        saveRating({
          variables: {
            horseId: competitor.horse_id,
            score: parseFloat(score),
            comment: comment || '',
          },
        });
    }
  };  

  return (
    <CollapseMUI in={open} timeout="auto" unmountOnExit>
      <Review
        initialScore={ratingHorse.score || 0}
        initialComment={ratingHorse.comment || ""}
        onSave={handleSaveRating}
        dataSave={dataSaveRating}
        loadingSave={loadingSaveRating}
        errorSave={errorSaveRating}
      />
    </CollapseMUI>
  );
};

export default Collapse;
