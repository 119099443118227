import React, { useContext } from 'react';

import Chip from '@mui/material/Chip';
import WeatherIcon from 'components/ui/WeatherIcon';
import headText from 'texts/components/headText';
import { BaseContext } from 'contexts/BaseContext';
const StatusChips = ({currentRace}) => {
    const { language } = useContext(BaseContext);
    const grass = currentRace?.course?.includes("Cesped");
    return ( 
        <div className="flex gap-x-2 gap-y-1">
            <Chip
                size="small"
                color={grass?'grass':'sand'}
                label={ grass ? headText[language].grass : headText[language].sand }
            />
            <Chip
                size="small"
                color="primary"
                label={
                headText[language][
                    currentRace.status
                    ? currentRace.status.toLowerCase()
                    : "normal"
                ]
                }
            />
            {currentRace.weatherCode && (
                <Chip
                    avatar={
                        <WeatherIcon
                            weatherCode={currentRace.weatherCode}
                            size={18}
                            color="#1A7AC7"
                            className="mx-0 my-1 rounded-full !bg-white"
                        />
                    }
                    size="small"
                    color="primary"
                    label={`${currentRace.weatherTemp}°C`}
                />
            )}
            </div>

     );
}
 
export default StatusChips;