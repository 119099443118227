import React from "react";

import rails from "constants/Rails";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import getIncidentKey from "utilities/functions/getIncidentKey";
import distanceByKey from "utilities/objects/distanceByKey";

const TableRow = ({
  rowData,
  loadingSelects,
  jockeyOptions = [],
  distOptions = [],
  posOptions = [],
  studOptions = [],
  raceIndex = 0,
  competitorIndex = 0,
  setEditedRaces,
  currentRace = {},
}) => {
  const incidentKey = getIncidentKey(rowData);

  const handleWithdraw = () => {
    setEditedRaces((oldRaces) => {
      let auxPosOptions = structuredClone(posOptions);
      let newRaces = structuredClone(oldRaces);
      let newCompetitorData =
        newRaces[raceIndex]?.competitors?.[competitorIndex];
      newCompetitorData.withdrawn = !newCompetitorData.withdrawn;
      newCompetitorData.withdrawn_modified = true;
      newRaces[raceIndex].competitors[competitorIndex] = newCompetitorData;
      return newRaces;
    });
  };

  const handleWindDistChange = ({target}) => {
    const { value:new_wind_dist } = target;
    setEditedRaces((oldRaces) => {
      let newRaces = structuredClone(oldRaces);
      let currentComp = newRaces[raceIndex]?.competitors?.[competitorIndex];
      let newPos = currentComp?.pos;

      const new_distance = distanceByKey[new_wind_dist] || {};
      const prev_distance = distanceByKey[currentComp?.wind_dist] || {}; 

      if(new_distance.disablePos && !prev_distance.disablePos) {
        newPos = '-';
      } else if(!new_distance?.disablePos && prev_distance?.disablePos) {
        newPos = ' ';
      }
      
      
      currentComp = {
        ...currentComp,
        pos: newPos,
        wind_dist: new_wind_dist,
        cpos: new_wind_dist === '4cpm' ? '5' : '',
        wind_dist_modified: true,
        rodo: !!new_wind_dist === 'rodo',
        desmonta: !!new_wind_dist === 'desm.',
      };
      
      newRaces[raceIndex].competitors[competitorIndex] = currentComp;

      return newRaces;
    });
  }

  const handleChange = (newValue, field) => {
    setEditedRaces((oldRaces) => {
      let newRaces = [...oldRaces];
      let newCompetitorData = {
        ...newRaces[raceIndex].competitors[competitorIndex],
      };
      newCompetitorData[field] = newValue;
      newCompetitorData[`${field}_modified`] = true;
      newRaces[raceIndex].competitors[competitorIndex] = newCompetitorData;
      return newRaces;
    });
  };

  const handleAutocomplete = (
    newValue,
    key_field,
    value_field,
    key_value_object
  ) => {
    setEditedRaces((oldRaces) => {
      if (key_value_object && key_value_object[newValue]) {
        let newRaces = structuredClone(oldRaces);
        let newCompetitorData = {
          ...newRaces[raceIndex]?.competitors?.[competitorIndex],
        };
        newCompetitorData[key_field] = newValue;
        newCompetitorData[value_field] = key_value_object[newValue];
        newRaces[raceIndex].competitors[competitorIndex] = newCompetitorData;
        return newRaces;
      }
      return oldRaces;
    });
  };
  
  return (
    <tr
      className={`
                border-b-backD2 transition-colors 
                ${rowData.withdrawn ? "bg-back" : ""}
                ${(incidentKey && incidentKey !== 'withdrawn') ? 'bg-dangerL1':''}
                ${currentRace?.suspended ? '!bg-back':''}
            `}
    >
      <td className="text-xs md:text-sm text-center w-12">
        <button
          className={`
                        material-icons text-center align-middle select-none border-none h-9
                        cursor-pointer rounded-full hover:bg-backL2 transition-colors
                        ${rowData.withdrawn ? "text-backD1" : "text-warning"}
                        ${
                          rowData.withdrawn_modified
                            ? "bg-secondaryL1"
                            : "bg-transparent"
                        }
                    `}
          onClick={handleWithdraw}
        >
          lightbulb
        </button>
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-12 min-w-[3rem] max-w-[3rem]">
        <TextField
          select
          disabled={!!incidentKey}
          value={rowData?.pos || ""}
          onChange={({ target }) => handleChange(target.value, "pos")}
          size="small"
          color="secondary"
          variant="standard"
          className={`w-16 ${rowData?.pos_modified ? "bg-secondaryL1" : ""}`}
        >
          {posOptions.map((opt) => (
            <MenuItem 
              value={opt} 
              key={opt}
            >
              {opt || ""}
            </MenuItem>
          ))}
        </TextField>
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-12 min-w-[3rem] max-w-[3rem]">
        <TextField
          select
          disabled={rowData.withdrawn}
          value={rowData?.wind_dist || ""}
          onChange={handleWindDistChange}
          size="small"
          color="secondary"
          variant="standard"
          className={`
                        w-12 h-7
                        ${rowData.wind_dist_modified ? "bg-secondaryL1" : ""}
                    `}
        >
          {Object.keys(distOptions).map((opt) => (
            <MenuItem 
              value={opt} 
              key={opt}
            >
              {distOptions[opt]}
            </MenuItem>
          ))}
        </TextField>
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-28 max-w-[7rem] px-4">
        <div className="-mb-3.5 overflow-hidden">
          <TextField
            disabled={!!incidentKey || rowData.wind_dist !== '4cpom'}
            size="small"
            value={rowData.cpos || ""}
            variant="standard"
            onChange={({ target }) => handleChange(target.value, "cpos")}
            className={`w-full ${rowData.cpos_modified ? "bg-secondaryL1" : ""}`}
            InputProps={{ maxLength: 100 }}
          />
        </div>
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-16 min-w-[4rem]">
        <img
          alt={`rail${rowData.rail || 0}`}
          src={rails[rowData.rail || 1]}
          className="w-7 h-7 mx-auto align-middle"
        />
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-44 min-w-[11rem] px-2">
        {rowData.horse_name || "NOMBRE CABALLO 2000"}
      </td>

      <td className="py-2 text-xs md:text-sm text-center w-44 min-w-[14rem] px-2">
        {loadingSelects || !Object.keys(jockeyOptions)?.length ? (
          <div className="w-full h-6 bg-backL2 animate-pulse" />
        ) : (
          <Autocomplete
            options={Object.keys(jockeyOptions)}
            value={rowData.jockey_id}
            onChange={(event, newValue) =>
              handleAutocomplete(
                newValue,
                "jockey_id",
                "jockey_name",
                jockeyOptions
              )
            }
            getOptionLabel={(option) => jockeyOptions?.[option] || ""}
            renderOption={(props, option) => (
              <p {...props} className={`${props.className} my-0`}>
                {jockeyOptions?.[option] || ""}
              </p>
            )}
            selectOnFocus
            clearOnBlur
            disabled={rowData.withdrawn}
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-44 min-w-[14rem] px-2">
        {loadingSelects || !Object.keys(studOptions)?.length ? (
          <div className="w-full h-6 bg-backL2 animate-pulse" />
        ) : (
          <Autocomplete
            options={Object.keys(studOptions)}
            value={rowData.stable_id}
            onChange={(event, newValue) =>
              handleAutocomplete(
                newValue,
                "stable_id",
                "stable_name",
                studOptions
              )
            }
            getOptionLabel={(option) => studOptions?.[option] || ""}
            renderOption={(props, option) => (
              <p {...props} className={`${props.className} my-0`}>
                {studOptions?.[option] || ""}
              </p>
            )}
            disabled={rowData.withdrawn}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}
      </td>
      <td className="py-2 text-xs md:text-sm text-center w-28 max-w-[7rem] px-4">
        <div className="-mb-3.5 overflow-hidden">
          <TextField
            size="small"
            value={rowData.jKg || ""}
            variant="standard"
            onChange={({ target }) => handleChange(target.value, "jKg")}
            className={`w-full ${rowData.jKg_modified ? "bg-secondaryL1" : ""}`}
            InputProps={{ maxLength: 100 }}
            disabled={rowData.withdrawn}
          />
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
