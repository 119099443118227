import { BaseContext } from 'contexts/BaseContext';
import React, { useContext } from 'react';
import pricing_bg from 'assets/pricing_bg.webp';
import priceText from 'texts/pages/priceText';
import HorseFace from 'assets/icons/placa_lower.webp'
import { ReactComponent as MercadoPago } from 'assets/icons/svg/mercado-pago.svg';
const Pricing = () => {
    
    const {language} = useContext(BaseContext);

    return ( 
        <div 
            className='h-full w-full bg-cover bg-center'
            style={{
                backgroundImage: `url(${pricing_bg})`
            }}    
        >
            <div className=' max-w-3xl mx-auto px-8 w-full h-full flex flex-col items-center justify-start py-8 gap-y-20'>
                <div className='flex items-center justify-center flex-col gap-y-2'>
                    <h1 className='text-center text-lg md:text-4xl text-white font-monsterrat'>{priceText[language].title}</h1>
                    <h4 className='text-center text-xs md:text-xl text-white font-roboto'>
                        {priceText[language].desc1}
                        <span className='material-icons px-2 text-xl'>volunteer_activism</span>
                        {priceText[language].desc2}
                    </h4>
                </div>
                <div className='flex flex-col items-center justify-center gap-y-2'>
                    <img 
                        alt="horse_face"
                        src={HorseFace}
                        className="w-40 h-auto"
                    />
                    <h3 className='text-center font-roboto text-white md:text-2xl'>{priceText[language].priceTitle}</h3>
                    <div className='flex flex-wrap items-center gap-x-2'>
                        <h5 className='text-center font-roboto text-white text-xs md:text-sm'>{priceText[language].priceDesc}</h5>
                        <MercadoPago className='h-6 w-auto mx-auto my-2' />
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default Pricing;