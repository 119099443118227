
const solveFraction = fraction => {
    const parts = fraction.split('/');
    return parts[0]/parts[1]
}

const getDistanceKey = ( dist ) => {
    let icon = '';
    if (dist) {    
      if (new RegExp(['pzo', 'cza', 'vm', 'hco', 'em', 'emp', 'sa', '-', 'rodo', 'dist', 'desm.', 'dp', 'nl'].join("|")).test(dist.toLowerCase())){ //Si tiene letras 
        if (dist.includes('cza')){
          if(dist.includes('/'))
            icon = 'mcza';
          else
            icon = 'cza';
        }
        
        else if (dist.includes('vm'))
          icon = 'vm';
        
        else if (dist?.includes('pzo'))
        {
          if(dist.includes('/'))
            icon = 'mpzo';
          else
            icon = 'pzo';
        }
        else if (dist.includes('hco'))
          icon = 'hco';
        else if (['em', 'emp'].includes(dist)) 
          icon = 'emp';
        else if (dist.includes('sa'))
          icon = 'sa';
        else if (dist.includes('rodo'))
          icon = 'rodo';
        else if (dist.includes('dist'))
          icon = 'dist';
        else if (dist.includes('nl'))
          icon = 'nl';
        else if (dist.includes('desm.'))
          icon = 'desm.';
        else if (dist.includes('dp'))
          icon = 'dp';
        else if (dist.includes('-'))
          icon = 'last';
        else
          icon = ''
      } else {
        let auxValue = 0;
        if(dist === '0') {
            icon = '';
        } else if (dist.includes(' ')) {
          let auxDist = dist.split(' ');
          auxValue = parseInt(auxDist[0]) + solveFraction(auxDist[1]);
          if (auxValue > 4)
            icon = '4cpom';
          else if (auxValue === 3.5)
            icon = '3cpom';
          else if (auxValue === 2.5)
            icon = '2cpom';
          else if (auxValue === 1.5)
            icon = '1cpom';
          else
            icon = '';
        }
        else if(dist.includes('/'))
        {
          auxValue = solveFraction(dist);
          if (auxValue === 0.75)
            icon = 'tcpo';
          else if (auxValue === 0.5)
            icon = 'mcpo';
          else if (auxValue === 0.25)
            icon = 'pzo';
          else
            icon = 'hco';
        }
        else
        {
            auxValue = parseInt(dist);
            if (auxValue === 4)
              icon = '4cpo';
            else if (auxValue === 3)
              icon = '3cpo';
            else if (auxValue === 2)
              icon = '2cpo';
            else if (auxValue === 1)
              icon = '1cpo';
            else
              icon = '4cpom';
        }
      }
    }
    return icon;
}

export default getDistanceKey;