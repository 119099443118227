import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import cosineSimilarity from "simple-cosine-similarity";

import Breadcrumb from "components/ui/Breadcrumb";
import searchBg from "assets/search_bg.webp";
import TabsHeader from "./TabsHeader";

import { useLazyQuery } from "@apollo/client";
import { SEARCH } from "queries/search";
import { GET_HORSE_HISTORY_SEARCH } from "queries/horserace";
import { GET_WEATHER_BY_DATES } from "queries/weather";
import { addHistoryToResults } from "./functions";
import HorseTab from "./Tabs/HorseTab";
import JockeyTab from "./Tabs/JockeyTab";
import RaceTab from "./Tabs/RaceTab";
import searchText from "texts/pages/searchText";
import { BaseContext } from "contexts/BaseContext";

const Search = () => {
  const { language } = useContext(BaseContext);
  const { search_query } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [searchData, setSearchData] = useState();

  const [getSearch, { data: dataSearch, loading: loadingSearch }] =
    useLazyQuery(SEARCH);

  const [
    getHistoryHorse,
    { data: dataHistoryHorse, loading: loadingHistoryHorse },
  ] = useLazyQuery(GET_HORSE_HISTORY_SEARCH);

  const [getWeather, { data: dataWeather, loading: loadingWeather }] =
    useLazyQuery(GET_WEATHER_BY_DATES);

  const normalize = (action = () => {}, str1 = "", str2 = "") => {
    return action(str1.toUpperCase(), str2.toUpperCase());
  };

  useEffect(() => {
    let mounted = true;
    if (dataWeather && !loadingWeather) {
      let auxWeather = dataWeather.weatherByDates;
      let auxRaces = structuredClone(searchData.race);

      auxRaces.forEach((race) => {
        race.items.forEach((item) => {
          const index = auxWeather.findIndex(
            (w) => w.date.slice(0, 13) === item.date.slice(0, 13)
          );
          if (index >= 0) {
            item.weather = auxWeather[index];
          }
        });
      });

      if (mounted) {
        setSearchData(data=>({
          ...data,
          race: auxRaces,
        }));
      }
    }

    return () => mounted = false;
  }, [dataWeather]);

  useEffect(() => {
    let mounted = true;
    if (search_query?.length >= 3) {
      const splitQuery = search_query.split(" ");
      let longestWord = "";
      splitQuery.forEach((word) => {
        if (word.length > longestWord.length) {
          longestWord = word;
        }
      });

      if (mounted) {
        getSearch({ variables: { contains: longestWord } });
      }
    }
    return () => (mounted = false);
  }, [search_query]);

  useEffect(() => {
    let mounted = true;
    if (dataSearch && !loadingSearch && mounted) {
      let query = "^";
      let searchWords = search_query.split(" ");
      searchWords.forEach((word) => (query += `(?=.*${word})`));
      query += ".+";
      const regex = new RegExp(query, "mi");
      const dataSearchFiltered = dataSearch.search.filter((res) =>
        regex.test(res.name)
      );

      let auxJockey = [];
      let auxHorse = [];
      let groupedRaces = [];
      let auxRace = [];
      let auxHorseIds = [];
      let auxDates = [];

      dataSearchFiltered.forEach((item, index) => {
        if (
          item.__typename === "Horse" &&
          ((item.hasOwnProperty("main") && item.main) || item.main === null)
        ) {
          auxHorse.push(item);
          auxHorseIds.push(item.idHorse);
        } else if (item.__typename === "JockeySearch") {
          auxJockey.push(item);
        } else if (item.__typename === "HorseRace") {
          auxRace.push(item);
          auxDates.push(format(new Date(item.date), "yyyy-MM-dd'T'HH"));
        }
      });

      if (auxRace.length) {
        let j = 0;
        while (auxRace.length > 0) {
          let auxGroup = {
            name: auxRace[0].name,
            racetrack_id: auxRace[0].racetrack_id,
            items: [auxRace[0]],
            condition: auxRace[0].condition,
            weight: auxRace[0].weight,
            course: auxRace[0].course,
            type: auxRace[0].type,
            distance: auxRace[0].distance,
          };
          auxRace.splice(0, 1);
          j = 0;
          while (j < auxRace.length && auxRace.length > 0) {
            let cosIndex = normalize(
              cosineSimilarity,
              auxGroup.name.trim(),
              auxRace[j].name.trim()
            );
            if (
              cosIndex > 0.92 &&
              auxGroup.racetrack_id === auxRace[j].racetrack_id
            ) {
              auxGroup.items.push(auxRace[j]);
              auxRace.splice(j, 1);
            } else {
              j++;
            }
          }
          groupedRaces.push(auxGroup);
        }
      }

      if (mounted) {
        if (auxHorseIds.length) {
          getHistoryHorse({
            variables: {
              date: format(new Date(), "yyyy-MM-dd"),
              horseIdList: auxHorseIds,
            },
          });
        }
        if (auxDates.length) {
          getWeather({ variables: { dates: auxDates } });
        }
        if (auxHorse.length) {
          setCurrentTab(0);
        } else if (auxJockey.length) {
          setCurrentTab(1);
        } else if (auxRace.length) {
          setCurrentTab(2);
        }

        setSearchData((search) => ({
          ...search,
          horse: auxHorse ? auxHorse : null,
          jockey: auxJockey ? auxJockey : null,
          race: groupedRaces ? groupedRaces : null,
        }));
      }
    }
    return () => (mounted = false);
  }, [dataSearch, loadingSearch]);

  useEffect(() => {
    let mounted = true;

    if (dataHistoryHorse && !loadingHistoryHorse && searchData.horse) {
      const auxHorses = JSON.parse(JSON.stringify(searchData.horse));
      const auxHistory = [...dataHistoryHorse.horseRaceByHorsesAndDate];
      const finalHorses = addHistoryToResults(auxHistory, auxHorses);

      if (mounted) {
        setSearchData((data) => ({
          ...data,
          horse: finalHorses,
        }));
      }
    }
    return () => (mounted = false);
  }, [dataHistoryHorse]);

  return (
    <div
      className="flex-grow flex flex-col items-center bg-cover bg-center"
      style={{ backgroundImage: `url(${searchBg})` }}
    >
      <div className="flex flex-col max-w-7xl w-full flex-grow">
        <TabsHeader
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          loading={loadingSearch}
          searchData={searchData}
        />
        <div className="bg-white flex md:max-h-[84vh] overflow-auto">
          {!loadingSearch && (
            <>
              <HorseTab
                currentTab={currentTab}
                searchResult={searchData?.horse}
                loadingHistory={loadingHistoryHorse}
                search_query={search_query}
              />
              <JockeyTab
                currentTab={currentTab}
                searchResult={searchData?.jockey}
                search_query={search_query}
              />
              <RaceTab
                currentTab={currentTab}
                searchResult={searchData?.race}
              />
            </>
          )}
          {!loadingSearch &&
            !searchData?.horse?.length &&
            !searchData?.race?.length &&
            !searchData?.jockey?.length && <p className="text-2xl text-backD1 mt-28 mx-auto">{searchText[language].notFound}</p>}
        </div>
      </div>
    </div>
  );
};

export default Search;
