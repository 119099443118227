import React from 'react';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

const theme = createTheme({
    status: {

    },
    palette: {
        primary: {
            main: '#2196F3',
            dark:'#1A7AC7',
        },
        secondary: {
            main: '#D1C4E9',
            contrastText:'#FFF',
        },
        grass: {
            main: '#83C636',
            light: '#A8E660',
            dark: '#649E21',
            contrastText:'#FFF',
        },
        sand: {
            main: '#BDAD84',
            light: '#CFC19D',
            dark: '#998B67',
            contrastText:'#FFF',
        },
        secondaryDark:{
            main:'#672F8E',
            dark: '#4B2A78',
            contrastText: '#FFF',
        },
        inactive:{
            main:'#E1E1E1',
            dark:'#B8B8B8',
            light:'#FFF',
            contrastText:'#000',
        },
        inactiveDark:{
            main:'#C4C4C4',
            dark:'#444444',
            light:'#FFF',
            contrastText:'#000',
        },
        success:{
            main:"#15BD93",
            light:"#6EFFE8",
            contrastText:"#FFF",
        },
    }
})

const Theme = ({children}) => {
    return ( 
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider> 
    );
}
 
export default Theme;