export const validateEmail = (email) => {
    if (!email || email.trim() === "")
      return { ok: false, error: "Este campo es obligatorio." };
    
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) 
        return { ok: false, error: "Email invalido." };
    
    return { ok: true, error: "" };
};