import lcpo from 'assets/icons/distances/1cpo.svg';
import lcpom from 'assets/icons/distances/1cpom.svg';
import llcpo from 'assets/icons/distances/2cpo.svg';
import llcpom from 'assets/icons/distances/2cpom.svg';
import lllcpo from 'assets/icons/distances/3cpo.svg';
import lllcpom from 'assets/icons/distances/3cpom.svg';
import llllcpo from 'assets/icons/distances/4cpo.svg';
import llllcpom from 'assets/icons/distances/4cpom.svg';
import cza from 'assets/icons/distances/cza.svg';
import emp from 'assets/icons/distances/emp.svg';
import hco from 'assets/icons/distances/hco.svg';
import mpzo from 'assets/icons/distances/mpzo.svg';
import pzo from 'assets/icons/distances/pzo.svg';
import mcpo from 'assets/icons/distances/mcpo.svg';
import tcpo from 'assets/icons/distances/tcpo.svg';
import mcza from 'assets/icons/distances/mcza.svg';
import sa from 'assets/icons/distances/sa.svg';
import vm from 'assets/icons/distances/vm.svg';
import rodo from 'assets/icons/distances/rodo.svg';
import dist from 'assets/icons/distances/dist.svg';
import desm from 'assets/icons/distances/Dm.svg';
import dp from 'assets/icons/distances/DP.svg';
import nl from 'assets/icons/distances/nl.svg';
import last from 'assets/icons/distances/last.svg';


const distanceByKey = {
    "":{
        "icon": null,
        "distValue":"",
        "distance":0,
        "disablePos": false
    },
    "1cpo": {
        "icon": lcpo,
        "distValue":"1",
        "distance":1,
        "disablePos": false
    },
    "1cpom":{
        "icon": lcpom,
        "distValue":"1 1/2",
        "distance":1.5,
        "disablePos": false
    },
    "2cpo": {
        "icon": llcpo,
        "distValue":"2",
        "distance":2,
        "disablePos": false
    },
    "2cpom": {
        "icon": llcpom,
        "distValue":"2 1/2",
        "distance":2.5,
        "disablePos": false
    },
    "3cpo": {
        "icon": lllcpo,
        "distValue":"3",
        "distance":3,
        "disablePos": false
    },
    "3cpom": {
        "icon": lllcpom,
        "distValue":"3 1/2",
        "distance":3.5,
        "disablePos": false
    },
    "4cpo": {
        "icon": llllcpo,
        "distValue":"4",
        "distance":4,
        "disablePos": false
    },
    "4cpom": {
        "icon": llllcpom,
        "distValue":"5",
        "distance":5,
        "disablePos": false
    },
    "cza": {
        "icon": cza,
        "distValue":"cza",
        "distance":0.2,
        "disablePos": false
    },
    "emp": {
        "icon": emp,
        "distValue":"emp",
        "distance":0,
        "disablePos": false
    },
    "hco": {
        "icon": hco,
        "distValue":"hco",
        "distance":0.1,
        "disablePos": false
    },
    "mcpo": {
        "icon": mcpo,
        "distValue":"1/2",
        "distance":0.5,
        "disablePos": false
    },
    "mcza": {
        "icon": mcza,
        "distValue":"1/2 cza",
        "distance":0.15,
        "disablePos": false
    },
    "mpzo": {
        "icon": mpzo,
        "distValue":"1/2 pzo",
        "distance":0.25,
        "disablePos": false
    },
    "pzo": {
        "icon": pzo,
        "distValue":"pzo",
        "distance":0.3,
        "disablePos": false
    },
    "sa": {
        "icon": sa,
        "distValue":"sa",
        "distance":0,
        "disablePos": false
    },
    "tcpo": {
        "icon": tcpo,
        "distValue":"3/4",
        "distance":0.75,
        "disablePos": false
    },
    "vm": {
        "icon": vm,
        "distValue":"vm",
        "distance":0.05,
        "disablePos": false
    },
    "rodo": {
        "icon": rodo,
        "distValue":"rodo",
        "distance":0,
        "incident":true,
        "disablePos": '-',
        "setPos":true,
    },
    "dist": {
        "icon": dist,
        "distValue":"dist",
        "distance":0,
        "disablePos": false,
        "priority":true,
    },
    "desm.":{
        "icon": desm,
        "distValue":"desm.",
        "distance":0,
        "incident":true,
        "disablePos": '-',
        "setPos":true,
    },
    "nl": {
        "icon": nl,
        "distValue":"nl",
        "distance":0,
        "incident":true,
        "disablePos": 'NL',
        "setPos":true,
    },
    "dp": {
        "icon": dp,
        "distValue":"dp",
        "disablePos":'DP',
        "priority":true,
        "setPos":true,
    },
    "last": {
        "icon": last,
        "distValue":"",
        "distance":0,
        "disablePos": false
    },
}

export default distanceByKey;