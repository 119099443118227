import IconButton from "@mui/material/IconButton";
import React from "react";

const ArrowButton = ({ disabled, setVal, direction = "left", size="md" }) => {
  const arrowStyle={
    button:{
      sm:"h-6 w-6",
      md:"h-10 w-10 ",
    },
    icon:{
      sm:"text-sm",
      md:"text-base",
    },
  }

  const handleClick = () => {
    if (direction === "right") setVal(val => val + 1);
    if (direction === "left") setVal(val => val - 1);
  };

  return (
    <IconButton
      disabled={disabled}
      className={`
        bg-white hover:bg-back disabled:bg-backD1 rounded-full flex items-center justify-center border-backL2 shadow
        ${arrowStyle.button[size]}  
      `}
      onClick={handleClick}
    >
      <span className={`material-icons ${arrowStyle.icon[size]}`}>
        {direction === "right" ? "keyboard_arrow_right" : "keyboard_arrow_left"}
      </span>
    </IconButton>
  );
};

export default ArrowButton;
