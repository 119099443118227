const jockeyDetailText = { 
    en: {
        year:'Year',
        distance:'Distance',
        sand:'Sand',
        grass:'Grass',
        meters:'meters',
        noWeek: 'This jockey didn\'t run last week',
        groupTitle: 'Group Horse Races',
        weekTitle: 'Week History',
        weekDesc: 'Last week\'s horse races.',
        semesterTitle:'Semiannual History',
        semesterDesc:'Races of the last 6 months.',
        yearTitle: 'Year History',
        yearDesc: 'Last year\'s horse races.',
        distanceTitle:'Distances',
        distanceDesc:'Race statistics by distance and track type.',
        won:'Won',
        races:'Horse Races',
        biography:'Biography',
        to:'to',
        racesByDist:'Races by Distance',
        yearSelect:'Pick a year',
        totalRaces:'Total races contested:',
        studTitle:'Stable',
        studDesc:'',
        horseTitle:'Horses',
        horseDesc:'Horses',
        lastWeek:'Races last week',
        lastMonth:'Races last month',
        seeMonthly:'Month',
        total_races:'Registered races',
        earnings:'Earnings',
        bet_average:'Bet amount',
        noRaces:'No races in ',
        noRacesMonth:'We did not register any races in the last month.',
        noRacesWeek:'We did not register any races in the last week.',
        noRacesFiltered:'NO RACES, according to applied filters.',
        prizes:'Prizes',
        place:'Placé',
        ran:'Races',
        effectivity:'Effectiveness',
        mile_fourth:'1/4 Mile',
        sprinter:'Sprinter',
        mile:'Mile',
        middle_dist:'Middle Distance',
        long_dist:'Long Distance',
        pos:'Largadas (pos)',
        prizeEffectivity:'Effectiveness (prizes)',
        chartInfo:'Number of races during last week or month.',
        distanceInfoTitle:"Effectiveness by distance categories:",
        distanceInfoItems:[
            '1/4 mile: <800m',
            'Sprinter: >=800m <=1300m',
            'Mile: from >1300 <=1600m',
            'Middle distance: >1600m <=1999m',
            'Long distance runners: >2000m',
        ],
        distanceInfoStars:"Star: highlights highest values, both in effectiveness and in prizes",
        distanceInfoCoins:"Rising Coins: Marks an increase in recent effectiveness",
        distanceInfoChips:"Trophy chips show the number of wins in featured races",
        distances:"Distances",
        victories:"Victories",
        jockeyFilterInfo:"Filter by horse",
        meters:'meters',
        reviewChip:'Leave your opinion',
        reviewEdit:'Edit your opinion',
        jockey:'Professional Jockey',
        jocketa:'Professional Jockey',
        upcoming:'Next races',
        opinion:'Opinion',
        opinions:'Opinions',
        no:'No',
        today:'Today',
        tomorrow:'Tomorrow',
        race:'Race',
        grass:'Grass',
        sand:'Sand',
        age:'years old',
        noRace:'There are no registered races.',
        jockeyFilterInfo:"Filter by horse",
    },
    es: {
        year:'Año',
        distance:'Distancia',
        sand:'Arena',
        grass:'Cesped',
        meters:'metros',
        noWeek: 'Este jockey no corrió la última semana',
        groupTitle: 'Carreras de Grupo',
        weekTitle: 'Histórico Semanal',
        weekDesc: 'Carreras de la semana.',
        semesterTitle:'Histórico Semestral',
        semesterDesc:'Carreras de los últimos 6 meses.',
        yearTitle: 'Histórico Anual',
        yearDesc: 'Carreras del año.',
        distanceTitle:'Distancias',
        distanceDesc:'Estadísticas de carreras por distancia y tipo de pista.',
        won:'Ganadas',
        races:'Carreras',
        biography:'Biografía',
        to:'a',
        racesByDist:'Carreras por Distancia',
        yearSelect:'Elegí un año',
        totalRaces:'Total de carreras disputadas:',
        studTitle:'Stud/Haras',
        studDesc:'',
        horseTitle:'Caballos',
        horseDesc:'',
        lastWeek:'Carreras en la semana',
        lastMonth:'Carreras en el mes',
        seeMonthly:'Mes',
        total_races:'Carreras registradas',
        earnings:'Ganancias',
        bet_average:'Promedio en apuestas',
        noRaces:'Sin carreras en ',
        noRacesMonth:'No registramos carreras en el último mes.',
        noRacesWeek:'No registramos carreras en la última semana.',
        noRacesFiltered:'SIN CARRERAS, según filtros aplicados.',
        prizes:'Premios',
        place:'Placé',
        ran:'Corridas',
        effectivity:'Efectividad',
        mile_fourth:'Cuarto de Milla',
        sprinter:'Velocista',
        mile:'Milla',
        middle_dist:'Medio Fondo',
        long_dist:'Fondista',
        pos:'Largadas (pos)',
        prizeEffectivity:'Efectividad (premios)',
        chartInfo:'Cantidad de carreras durante la última semana o mes.',
        distanceInfoTitle:"Efectividad por categorías de distancia:",
        distanceInfoItems:[
            'Cuarto de Milla: <800m',
            'Velocista: >=800m <=1300m',
            'Milla: desde >1300 <=1600m',
            'Medio fondo: >1600m <=1999m',
            'Fondista: >2000m',
        ],
        distanceInfoStars:"Estrella: destaca valores más altos, tanto en efectividad como en premios",
        distanceInfoCoins:"Monedas en alza: marca un aumento de la efectividad reciente",
        distanceInfoChips:"Los chips con trofeo muestran la cantidad de victorias en carreras destacadas",
        distances:"Distancias",
        victories:"Victorias",
        jockeyFilterInfo:"Filtrar por ejemplar",
        meters: 'metros',
        reviewChip:'Dejá tu opinión',
        reviewEdit:'Editá tu opinión',
        jockey:'Jockey Profesional',
        jocketa:'Jocketa Profesional',
        upcoming:'Próximas montas',
        opinion:'Opinión',
        opinions:'Opiniones',
        no:'Sin',
        today:'Hoy',
        tomorrow:'Mañana',
        race:'Carrera',
        grass:'Césped',
        sand:'Arena',
        age:'años',
        noRace:'No se registran próximas montas.',
        jockeyFilterInfo:"Filtrar por ejemplar",
    },
    pt: {
        year:'Ano',
        dist:'Distância',
        sand:'Areia',
        grass:'Grama',
        meters:'metros',
        noWeek: 'Este jockey não correu na semana passada',
        groupTitle:'Corrida de grupo',
        weekTitle: 'Histórico Semanal',
        weekDesc: 'Corridas da semana passada.',
        semesterTitle:'História Semestral',
        semesterDesc:'Corridas dos últimos 6 meses.',
        yearTitle: 'Histórico Anual',
        yearDesc: 'Corridas do ano passado.',
        distanceTitle:'Distâncias',
        distanceDesc:'Estatísticas de corrida por distância e tipo de pista.',
        won:'Ganhou',
        races:'Corridas',
        biography:'Biografia',
        to:'a',
        racesByDist:'Corridas de Distância',
        yearSelect:'Escolha um ano',
        totalRaces: 'Total de corridas disputadas:',
        studTitle:'Stud/Haras',
        studDesc:'',
        horseTitle:'Cavalos',
        horseDesc:'',
        lastWeek:'Corridas na semana',
        lastMonth:'Corridas no mês',
        seeMonthly:'Mês',
        total_races:'Corridas registradas',
        earnings:'Ganhos',
        bet_average:'Valor da aposta',
        noRaces:'Nenhuma corrida em ',
        noRacesWeek:'Não registramos corridas na última semana.',
        noRacesMonth:'Não registramos corridas no último mês.',
        noRacesFiltered:'SEM CORRIDAS, de acordo com os filtros aplicados.',
        prizes:'Premios',
        place:'Placé',
        ran:'Corridas',
        effectivity:'Eficácia',
        mile_fourth:'1/4 de milha',
        sprinter:'Velocista',
        mile:'Milha',
        middle_dist:'Distância média',
        long_dist:'Longa distância',
        pos:'Largadas (pos)',
        prizeEffectivity:'Eficácia (premios)',
        chartInfo:'Número de corridas durante a última semana ou mês.',
        distanceInfoTitle:"Eficácia por categorias de distância:",
        distanceInfoItems:[
            '1/4 de milha: <800m',
            'Velocista: >=800m <=1300m',
            'Milha: desde >1300 <=1600m',
            'Distância média: >1600m <=1999m',
            'Longa distância: >2000m',
        ],
        distanceInfoStars:"Estrela: destaca valores mais altos, tanto em eficácia quanto em prêmios",
        distanceInfoCoins:"Moedas crescentes: Marca um aumento na eficácia recente",
        distanceInfoChips:"Fichas de troféus mostram o número de vitórias em corridas em destaque",
        distances:"Distancias",
        victories:"Vitorias",
        jockeyFilterInfo:"Filtrar dados",
        meters:'metros',
        reviewChip:'Deixe a sua opinião',
        reviewEdit:'Edite sua opinião',
        jockey:'Jóquei Profissional',
        jocketa:'Jocketa Profesional',
        upcoming:'Próximas corridas',
        opinion:'Opinião',
        opinions:'Opiniãos',
        no:'Sim',
        today:'Hoje',
        tomorrow:'Amanhã',
        race:'Corrida',
        sand:'Areia',
        grass:'Grama',
        age:'anos',
        noRace:'Não há corridas registradas',
        jockeyFilterInfo:"Filtrar dados",
    },
}

export default jockeyDetailText;