import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "contexts/BaseContext";
import { MainContext } from "contexts/MainContext";

import historyTableText from "texts/components/historyTableText";
import { DataGrid } from "@mui/x-data-grid";
import getDistanceKey from "utilities/functions/getDistanceKey";
import getIncidentKey from "utilities/functions/getIncidentKey";
import distanceByKey from "utilities/objects/distanceByKey";
import getSortedByDistance from "utilities/functions/getSortedByDistance";

const HistoryTable = ({ historyData = {}, currentComp = {} }) => {
  const { language } = useContext(BaseContext);
  const { dayRaces, currentIndex } = useContext(MainContext);

  const currentRace = dayRaces?.[currentIndex] || {};
  const [distanceData, setDistanceData] = useState({ total: 0, distances: [] });

  const sortedComps = getSortedByDistance(historyData?.competitors);

  const columns = [
    {
      field: "dist",
      headerAlign: "center",
      headerName: historyTableText[language].dist,
      flex: 30,
      minWidth: 80,
      renderCell: ({ value, id, row = {} }) => {
        if (value === undefined || row.withdrawn) return null;

        const rowIndex = parseInt(id.split("_")?.[0]);
        const thisRowData = distanceData.distances[rowIndex] || {};
        const totalDistance = distanceData.total;
        const accumulatedDistance = thisRowData.distAcc;
        const distanceLeft = totalDistance - accumulatedDistance;

        const percentage = ((distanceLeft * 100) / totalDistance).toFixed(2);
        const grass = historyData.course?.toLowerCase()?.includes("arena");
        const thisRowDistance = thisRowData.dist;

        let label = "";
        if (thisRowDistance > 1) {
          label = `${value} cpos`;
        } else if (thisRowDistance === 1) {
          label = `${value} cpo`;
        } else if (thisRowDistance !== 0) {
          label = value;
        }

        return (
          <div className="relative w-full h-3/5 group-hover:bg-back bg-backL2 flex justify-start rounded-lg overflow-hidden">
            <div
              className={`
                                h-full
                                ${
                                  grass
                                    ? "bg-grass group-hover:bg-grassD1"
                                    : "bg-sand group-hover:bg-sandD1"
                                }
                            `}
              style={{
                width: value === 0 ? "100%" : `${percentage}%`,
              }}
            >
              <p className="absolute top-1/2 -translate-y-1/2 my-0 text-sm leading-none left-4">{label}</p>
            </div>
          </div>
        );
      },
      editable: false,
    },
    {
      field: "icon",
      headerName: " ",
      flex: 10,
      minWidth: 44,
      editable: false,
      renderCell: (cellValues) => {
        const thisRowIndex = parseInt(cellValues.id.split("_")[0]);
        const thisHorseId = parseInt(cellValues.id.split("_")[1]);

        const thisRowDistanceData = distanceData.distances[thisRowIndex] || {};
        const nextRowDistanceData =
          distanceData.distances[thisRowIndex + 1] || {};

        const { icon: thisDistanceKey, disablePos: disablesPosition } =
          thisRowDistanceData;
        const { icon: nextDistanceKey, disablePos: disablesNextPosition } =
          nextRowDistanceData;

        const isHighlightedHorse = thisHorseId === currentComp.horse_id;

        if (nextRowDistanceData?.icon === 'withdrawn') return <div className="h-6 w-6" />;

        if (nextRowDistanceData?.icon && !disablesNextPosition)
          return (
            <img
              alt={`${nextRowDistanceData?.icon}A`}
              src={distanceByKey[nextDistanceKey]?.icon}
              className={`
                            h-6 w-6
                            ${isHighlightedHorse ? "filter invert" : ""}
                        `}
            />
          );

        if (disablesPosition && distanceByKey[thisDistanceKey]?.icon)
          return (
            <img
              alt={`${thisRowDistanceData?.icon}B`}
              src={distanceByKey[thisDistanceKey]?.icon}
              className={`
                            h-6 w-6
                            ${isHighlightedHorse ? "filter invert" : ""}
                        `}
            />
          );

        if (!disablesPosition) return <div className="h-6 w-6" />;
      },
    },
    {
      field: "rail",
      headerName: "Rail",
      flex: 10,
      minWidth: 50,
      type: "number",
      editable: false,
    },
    {
      field: "comp",
      headerAlign: "center",
      headerName: historyTableText[language].comp,
      flex: 50,
      minWidth: 160,
      editable: false,
    },
    {
      field: "jKg",
      headerAlign: "center",
      headerName: "jKg",
      type: "number",
      flex: 20,
      minWidth: 65,
      editable: false,
    },
    {
      field: "jockey",
      headerAlign: "center",
      headerName: historyTableText[language].jockey,
      flex: 50,
      minWidth: 160,
      editable: false,
    },
    {
      field: "bet",
      valueFormatter: ({ value }) =>
        `$${value ? value.toFixed(2).replace(".", ",") : "0"}`,
      type: "string",
      headerAlign: "center",
      headerName: historyTableText[language].bet,
      align: "right",
      flex: historyData.racetrack_id === "ARLP" ? 40 : 30,
      minWidth: historyData.racetrack_id === "ARLP" ? 125 : 70,
      editable: false,
    },
    {
      field: "hKg",
      headerAlign: "center",
      headerName: "hKg",
      type: "number",
      flex: 20,
      minWidth: 65,
      editable: false,
      hide: historyData.racetrack_id === "ARLP",
    },
  ];

  const getRows = () => {
    let rows = [];
    // sortByPos(dataRace.competitors).forEach((competitor, index) => {
    sortedComps?.forEach((competitor, index) => {
      let pos = competitor.pos;
      if (
        competitor.withdrawn ||
        competitor.pos === 0 ||
        competitor.pos === "0" ||
        competitor.rodo ||
        competitor.desmonta
      ) {
        pos = historyData?.competitors?.length + 2;
      }

      rows.push({
        id: `${index}_${competitor.horse_id}`,
        pos: pos,
        comp: competitor.horse_name,
        jockey: competitor.jockey_name,
        icon: competitor.wind_dist,
        dist: competitor.wind_dist,
        bet: competitor.bet_amt,
        hKg: isNaN(competitor.hKg) ? 0 : competitor.hKg,
        jKg: isNaN(competitor.jKg) ? 0 : competitor.jKg,
        withdrawn: competitor.withdrawn,
        desmonta: competitor.desmonta,
        rodo: competitor.rodo,
        rail: competitor.rail,
      });
    });
    return rows;
  };

  const isInRace = (horse_id) => {
    let inRace = false;
    currentRace.competitors?.forEach((comp) => {
      if (comp.horse_id === horse_id) {
        inRace = true;
      }
    });
    // if(inRace && !tags.repeatComp) {
    //     setTags({
    //         ...tags,
    //         repeatComp: true
    //     });
    // }
    return inRace;
  };

  useEffect(() => {
    let mounted = true;
    if (historyData && historyData.competitors) {
      let auxState = {
        total: 0,
        distances: [],
      };
      // sortByPos(dataRace.competitors).forEach((comp, index) => {
      sortedComps?.forEach((comp, index) => {
        let icon = getIncidentKey(comp);
        if (icon) {
          auxState.distances[index] = {
            dist: 0,
            distAcc: auxState.total,
            icon: icon || false,
          };
        } else {
          icon = getDistanceKey(comp.wind_dist);
          auxState.total += distanceByKey[icon]?.distance || 0;
          auxState.distances[index] = {
            dist: distanceByKey[icon]?.distance || 0,
            distAcc: auxState.total,
            icon: icon || false,
          };
        }
      });
      if (mounted) {
        setDistanceData(auxState);
      }
    }

    return () => {
      mounted = false;
    };
  }, [historyData]);

  return (
    <div className="flex h-full w-full">
      <DataGrid
        hideFooter
        rows={getRows()}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        getRowClassName={({ row }) => {
          const rowHorseId = row.id.split("_")[1];
          const isIncident = getIncidentKey(row);

          if (rowHorseId === currentComp.horse_id)
            return "group bg-secondary font-bold hover:bg-secondaryD2 hover:text-white text-black";

          if (isInRace(rowHorseId))
            return "group bg-primaryL1 hover:bg-primary text-white text-backD2";

          if (row.withdrawn)
            return "group bg-backD1 text-white hover:bg-backD2";

          if (isIncident) return "group bg-dangerL1";

          return "group hover:bg-red-400";
        }}
      />
    </div>
  );
};

export default HistoryTable;
