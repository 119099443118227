import { ReactComponent as CoinsIcon } from "assets/icons/svg/coin-stack-increase.svg";
import { BaseContext } from "contexts/BaseContext";
import Expand from "components/ui/Expand";
import Tooltip from "@mui/material/Tooltip";
import jockeyDetailText from "texts/pages/jockeyText";
import { useContext, useState } from "react";

import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";

const DistanceCard = ({ distances }) => {
  const [victoriesOnly, setVictoriesOnly] = useState(false);
  const { language } = useContext(BaseContext);

  const formatEarnings = (earnings) => {
    let formattedEarnings = "";
    if (!isNaN(earnings)) {
      if (earnings >= 1000000) {
        formattedEarnings = `${(earnings / 1000000).toFixed(1)}M`;
      } else if (earnings >= 1000) {
        formattedEarnings = `${(earnings / 1000).toFixed(1)}k`;
      } else {
        formattedEarnings = earnings.toFixed(0);
      }
    }
    return formattedEarnings;
  };

  const formatEff = (value, total) => {
    let formattedEffectivity = value / (total || 1);
    if (isNaN(formattedEffectivity)) {
      return 0;
    } else if (formattedEffectivity === 1) {
      return 1;
    } else {
      return formattedEffectivity.toFixed(3).toString().substring(1, 5);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between px-4">
        <p className="text-md font-bold text-left text-backD2 my-0">
          {jockeyDetailText[language].distances}
        </p>
        <div className="flex gap-3 items-center">
          <p
            className={`text-md text-backD1 my-0 ${
              victoriesOnly ? "text-primary" : "text-backD2"
            }`}
          >
            {jockeyDetailText[language].victories}
          </p>
          <label className="switch">
            <input
              type="checkbox"
              value={victoriesOnly}
              onChange={() => {
                setVictoriesOnly(!victoriesOnly);
              }}
            />
            <span className="slider round"></span>
          </label>
          <Tooltip
            placement="left"
            title={
              <div className="flex flex-col gap-y-3 text-white">
                <h5 className="text-sm  my-0">
                  {jockeyDetailText[language].distanceInfoTitle}
                </h5>
                <div className="ml-8 block">
                  {jockeyDetailText[language].distanceInfoItems.map(
                    (distanceInfo) => (
                      <h5 className="text-sm my-0" key={`dist-${distanceInfo}`}>
                        ● {distanceInfo}
                      </h5>
                    )
                  )}
                </div>
                <div className="flex gap-x-2">
                  <div className="w-10 flex-shrink-0 flex items-center justify-center">
                    <span className="material-icons text-xl text-primaryD1 px-1">
                      star
                    </span>
                  </div>
                  <h5 className="text-sm my-0">
                    {jockeyDetailText[language].distanceInfoStars}
                  </h5>
                </div>

                <div className="flex gap-x-2">
                  <div className="flex-shrink-0 w-10 flex items-center justify-center">
                    <CoinsIcon className="fill-grass h-6 w-6"/>
                  </div>
                  <h5 className="text-sm my-0">
                    {jockeyDetailText[language].distanceInfoCoins}
                  </h5>
                </div>

                <div className="flex items-center gap-x-2">
                  <div className="bg-secondaryD1 rounded-full relative flex items-center px-1 py-0.5 w-10">
                    <span className="text-xs material-icons leading-none mr-1">
                      emoji_events
                    </span>
                    <p className="text-backD2 text-xs my-0">G1</p>
                  </div>
                  <h5 className="text-sm my-0">
                    {jockeyDetailText[language].distanceInfoChips}
                  </h5>
                </div>
              </div>
            }
          >
            <div className="material-icons text-backD1 select-none">info</div>
          </Tooltip>
        </div>
      </div>
      <div className="h-auto overflow-x-auto">
        <div className="flex items-center mt-4 mb-2">
          <div className="w-60 flex-shrink-0 text-center md:w-5/12"> </div>
          <div className="md:w-7/12 flex justify-evenly">
            <div className="w-28 md:w-1/6 text-xs text-backD2 font-bold text-left">
              {" "}
              {jockeyDetailText[language].effectivity}{" "}
            </div>
            <div className="w-28 md:w-1/6 text-xs text-backD2 font-bold text-center">
              {" "}
              {jockeyDetailText[language].ran}{" "}
            </div>
            <div className="w-28 md:w-1/6 text-xs text-backD2 font-bold text-center">
              {" "}
              {victoriesOnly
                ? jockeyDetailText[language].victories
                : jockeyDetailText[language].place}{" "}
            </div>
            <div className="w-28 md:w-1/6 text-xs text-backD2 font-bold text-left">
              {" "}
              {jockeyDetailText[language].prizes}{" "}
            </div>
          </div>
        </div>
        {distances &&
          distances.map((distance, index) => (
            <div className="w-full flex" key={distance.name}>
              <Expand
                disabled={distance.disabled || distance.total === 0}
                key={`distance-expand-${index}`}
                shadow={true}
                clickableClassName={distance.disabled || distance.total === 0 ? "":'hover:bg-backL2 transition-colors'}
                clickable={
                  <div className="flex items-center">
                    <div className="w-60 md:w-5/12 flex items-center py-2">
                      <div className="flex-grow flex flex-col justify-between">
                        <h4 className="text-sm text-left mx-4 my-0">
                          {jockeyDetailText[language][distance.name]}
                        </h4>
                        {distance.sand || distance.grass ? (
                          <div className="h-2 my-1 px-4 flex justify-center w-full">
                            <div
                              className="bg-sand h-1.5 transition-all duration-500"
                              style={{
                                width: `${
                                  victoriesOnly
                                    ? (distance.sandFirst * 100) /
                                      (distance.first || 1)
                                    : (distance.sandPlace * 100) /
                                      (distance.first || 1)
                                }%`,
                              }}
                            />
                            <div
                              className="bg-grass h-1.5 transition-all duration-500"
                              style={{
                                width: `${
                                  victoriesOnly
                                    ? (distance.grassFirst * 100) /
                                      (distance.first || 1)
                                    : (distance.grassPlace * 100) /
                                      (distance.first || 1)
                                }%`,
                              }}
                            />
                          </div>
                        ) : null}
                        <div className="flex justify-start mx-4 gap-x-4 gap-y-2 mt-0.5 flex-wrap">
                          {distance.prizes_objects &&
                            Object.keys(distance.prizes_objects).map(
                              (prize, index) => (
                                <Badge
                                  badgeContent={distance.prizes_objects[prize]}
                                  color="primary"
                                  classes={{anchorOriginTopRight:"top-[4px] -right-0.5"}}
                                  key={`prize-${prize}`}
                                >
                                  <Chip
                                    color="secondary"
                                    key={`chip-win-${index}`}
                                    variant="outlined"
                                    label={prize}
                                    // className="text-backD2"
                                    size="small"
                                    icon={
                                      <span className="text-sm material-icons leading-none mr-1 text-secondary">
                                        emoji_events
                                      </span>
                                    }
                                  />
                                </Badge>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="w-auto md:w-7/12 flex justify-evenly items-center">
                      <div className="w-28 md:w-1/6 flex-shrink-0 px-1 flex items-center justify-start">
                        <p className="text-sm text-backD2 leading-none w-8 my-0">
                          {(distance.first || distance.place) &&
                            `
                              ${
                                victoriesOnly
                                  ? formatEff(distance.first, distance.total)
                                  : formatEff(distance.place, distance.total)
                              }
                          `}
                        </p>
                        {((victoriesOnly && distance.highest_effic_vict) ||
                          (!victoriesOnly && distance.highest_effic)) && (
                          <p className="material-icons text-lg leading-none text-primaryD1 my-0">
                            star
                          </p>
                        )}
                        {distance.rising &&
                          ((victoriesOnly &&
                            formatEff(distance.first, distance.total) !==
                              ".000") ||
                            (!victoriesOnly &&
                              formatEff(distance.place, distance.total) !==
                                ".000")) && (
                            <div className="px-1">
                              <CoinsIcon className="fill-grass h-4 w-4" />
                            </div>
                          )}
                      </div>
                      <div className="w-28 md:w-1/6 text-sm text-backD2 flex-shrink-0 px-1 flex items-center justify-center">
                        {distance.total}
                      </div>
                      <div className="w-28 md:w-1/6 text-sm text-backD2 flex-shrink-0 px-1 flex items-center justify-center">
                        {victoriesOnly ? distance.first : distance.place}
                      </div>
                      <div className="w-28 md:w-1/6 text-sm text-backD2 flex-shrink-0 px-1 flex items-center justify-start">
                        {victoriesOnly
                          ? formatEarnings(distance.prizes_first)
                          : formatEarnings(distance.prizes)}
                        {(victoriesOnly && distance.highest_prizes_vict) ||
                        (!victoriesOnly && distance.highest_prizes) ? (
                          <span className="material-icons text-lg text-primaryD1 px-1">
                            star
                          </span>
                        ) : (
                          <span className="w-4 mx-1 h-2 px-1" />
                        )}
                      </div>
                    </div>
                  </div>
                }
                icon="expand_more"
              >
                <table className="w-full px-4 my-4">
                  <thead>
                    <tr>
                      <th className="text-sm font-bold text-backD2">
                        {jockeyDetailText[language].distance}
                      </th>
                      <th className="text-sm font-bold text-backD2">
                        {jockeyDetailText[language].effectivity}
                      </th>
                      <th className="text-sm font-bold text-backD2">
                        {jockeyDetailText[language].ran}
                      </th>
                      <th className="text-sm font-bold text-backD2">
                        {victoriesOnly
                          ? jockeyDetailText[language].victories
                          : jockeyDetailText[language].place}{" "}
                      </th>
                      <th className="text-sm font-bold text-backD2">
                        {jockeyDetailText[language].prizes}
                      </th>
                      <th className="text-sm font-bold text-backD2">
                        {jockeyDetailText[language].prizeEffectivity}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {distance.detail &&
                      Object.keys(distance.detail).map(
                        (specificDistance) =>
                          specificDistance && (
                            <tr
                              key={`row${index}-${specificDistance}`}
                              className="appearance-none border-backL1 border-t border-b"
                            >
                              <td className="text-center py-2 text-backD2 text-sm">
                                {specificDistance}
                              </td>
                              <td className="text-center py-2 text-backD2 text-sm justify-center flex items-center">
                                {victoriesOnly
                                  ? formatEff(
                                      distance.detail[specificDistance].first,
                                      distance.detail[specificDistance].total
                                    )
                                  : formatEff(
                                      distance.detail[specificDistance].place,
                                      distance.detail[specificDistance].total
                                    )}
                                {(!victoriesOnly &&
                                  distance.detail[specificDistance]
                                    .highest_effic) ||
                                (victoriesOnly &&
                                  distance.detail[specificDistance]
                                    .highest_effic_vict) ? (
                                  <span className="material-icons text-base text-primaryD1 px-1 !leading-tight">
                                    star
                                  </span>
                                ) : (
                                  <span className="w-4 mx-1 h-2 px-1" />
                                )}
                              </td>
                              <td className="text-center py-2 text-backD2 text-sm">
                                {distance.detail[specificDistance].total}
                              </td>
                              <td className="text-center py-2 text-backD2 text-sm">
                                {victoriesOnly
                                  ? distance.detail[specificDistance].first
                                  : distance.detail[specificDistance].place}
                              </td>
                              <td className="text-center py-2 text-backD2 text-sm">
                                <div className="flex justify-center items-center">
                                  {victoriesOnly
                                    ? formatEarnings(
                                        distance.detail[specificDistance]
                                          .prizes_first
                                      )
                                    : formatEarnings(
                                        distance.detail[specificDistance].prizes
                                      )}
                                  {(!victoriesOnly &&
                                    distance.detail[specificDistance]
                                      .highest_prizes) ||
                                  (victoriesOnly &&
                                    distance.detail[specificDistance]
                                      .highest_prizes_vict) ? (
                                    <span className="material-icons text-base text-primaryD1 px-1 !leading-tight">
                                      star
                                    </span>
                                  ) : (
                                    <span className="w-4 mx-1 h-2 px-1" />
                                  )}
                                </div>
                              </td>
                              <td className="text-center py-2 text-backD2 text-sm flex items-center justify-center">
                                {victoriesOnly
                                  ? formatEff(
                                      distance.detail[specificDistance]
                                        .prizes_times_first,
                                      distance.detail[specificDistance].total
                                    )
                                  : formatEff(
                                      distance.detail[specificDistance]
                                        .prizes_times,
                                      distance.detail[specificDistance].total
                                    )}

                                {(!victoriesOnly &&
                                  distance.detail[specificDistance]
                                    .effective_prizes) ||
                                (victoriesOnly &&
                                  distance.detail[specificDistance]
                                    .effective_prizes_vict) ? (
                                  <span className="material-icons text-base text-primaryD1 px-1 !leading-tight">
                                    star
                                  </span>
                                ) : (
                                  <span className="w-4 mx-1 h-2 px-1" />
                                )}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </Expand>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DistanceCard;
