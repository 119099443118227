import { gql } from "@apollo/client";

export const CREATE_PREFERENCE = gql`
  mutation CreatePreference($input: PreferenceInput) {
    createPreference(input: $input) {
      collector_id
      items {
        category_id
        description
        picture_url
        unit_price
      }
      init_point
      sandbox_init_point
    }
  }
`;
