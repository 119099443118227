import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from 'contexts/UserContext';
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({children}) => {
    const { session, dataMe, calledMe, loadingMe, loadingSignIn } = useContext(UserContext);
    const location = useLocation();
    
    if((loadingMe || loadingSignIn) && (!dataMe?.me || !session.email)) {
        return (
            <div className='h-screen w-screen flex items-center justify-center'>
                <CircularProgress size={150} color='secondary'/>
            </div>
        )
    }

    if (!session?.email && !dataMe?.me && !loadingMe && calledMe) {
        return <Navigate to="/auth/signin" state={{ from: location }} replace />;
    }


    return children;
}
 
export default RequireAuth;