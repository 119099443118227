const confirmText = { 
    en: {  
        confirmText:'Your e-mail has been successfully verified 🎉',
        confirmDesc:'Redirecting to sign in...',
        confirmFailText:'We couldn\'t verify your e-mail ⚠️',
        confirmFailDesc: 'Verification link could be expired .'
    },
    es: {  
        confirmText:'Tu e-mail se ha verificado correctamente 🎉',
        confirmDesc:'Redirigiendo para iniciar sesión...',
        confirmFailText: 'No hemos podido verificar tu correo electrónico ⚠️',
        confirmFailDesc: 'Es probable que el enlace de verificación haya expirado.'
    },
    pt: {  
        confirmText:'Seu e-mail foi verificado 🎉',
        confirmDesc:'Redirecionando para login...',
        confirmFailText: 'Não conseguimos verificar o seu e-mail ⚠️',
        confirmFailDesc: 'O link de verificação provavelmente expirou .'
    },
}

export default confirmText;