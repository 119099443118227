import { createContext, useEffect, useState } from "react";
import { SIGN_IN, GET_ME } from "queries/auth";
import { useMutation, useQuery } from "@apollo/client/react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [session, setSession] = useState({});
  const loggedIn = Boolean(session && session.name);
  const [
    signIn,
    { loading: loadingSignIn, error: errorSignIn, data: dataSignIn },
  ] = useMutation(SIGN_IN, { errorPolicy: "all" });
  const {
    refetch: refetchMe,
    loading: loadingMe,
    error: errorMe,
    data: dataMe,
    called: calledMe,
  } = useQuery(GET_ME, {
    // skip: session?.email,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const handleSignIn = async ({ signIn }) => {
    await localStorage.setItem("token", signIn.token);
    refetchMe();
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && dataSignIn && !loadingSignIn) handleSignIn(dataSignIn);

    return () => (mounted = false);
  }, [dataSignIn, loadingSignIn]);

  useEffect(() => {
    let mounted = true;
    if (mounted && dataMe && !loadingMe) {
      setSession(dataMe.me);
    }

    return () => (mounted = false);
  }, [dataMe, loadingMe]);

  const value = {
    session,
    loggedIn,
    signIn,
    loadingSignIn,
    errorSignIn,
    dataMe,
    loadingMe,
    errorMe,
    calledMe,
    dataSignIn,
    setSession,
    refetchMe,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
