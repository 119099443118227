import { BaseContext } from "contexts/BaseContext";

import ArrowButton from "components/ui/ArrowButton";
import TagsChips from "components/ui/Head/Chips/TagsChips";
import React, { useContext } from "react";
import sideContentText from "texts/components/sideContentText";
import raceNumberText from "texts/general/raceNumberText";
import Chip from "@mui/material/Chip";
import { MainContext } from "contexts/MainContext";

const Head = ({ currentRace }) => {
  const { language } = useContext(BaseContext);
  const { currentIndex, setCurrentIndex, dayRaces } = useContext(MainContext);

  const isSand = currentRace?.course?.toLowerCase()?.includes("arena");

  return (
    <div className="flex w-full flex-col p-4 gap-y-4">
      <div className="min-h-[52px]">
        <TagsChips 
          currentRace={currentRace} 
          className="justify-center" 
        />
      </div>
      <div className="flex items-center">
        <ArrowButton
          disabled={currentIndex === 0}
          setVal={setCurrentIndex}
          direction="left"
        />
        <div className="w-full">
          <div className="w-full flex items-end justify-center gap-x-3">
            <div className="flex flex-col">
              <div className="flex items-start justify-center">
                <h1 className="text-6xl text-secondaryD3 !leading-none my-0">
                  {currentRace.raceno}
                </h1>
                <h4 className="text-secondaryD3 text-xl md:text-2xl !leading-none my-0">
                  {raceNumberText[language]?.[currentRace.raceno]}
                </h4>
              </div>
              <Chip
                size="small"
                color="primary"
                label={
                  sideContentText[language]?.[currentRace.status?.toLowerCase() || "normal"]
                }
              />
            </div>
            <div className="flex flex-col">
              <span className="py-1">
                <h5 className="text-lg md:text-2xl font-normal text-backD2 text-center !leading-none my-0">
                  {currentRace.distance}
                </h5>
                <h5 className="text-lg md:text-2xl font-normal text-backD2 text-center !leading-none my-0">
                  {sideContentText[language].meters}
                </h5>
              </span>
              <Chip
                size="small"
                color={isSand ? "sand" : "grass"}
                label={
                  isSand
                    ? sideContentText[language].sand
                    : sideContentText[language].grass
                }
              />
            </div>
          </div>
        </div>
        <ArrowButton
          disabled={currentIndex === dayRaces?.length - 1}
          setVal={setCurrentIndex}
          direction="right"
        />
      </div>
    </div>
  );
};

export default Head;
