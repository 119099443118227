import React from 'react';
import LanguagePicker from 'components/ui/LanguagePicker';
import Notifications from './Notifications';
import ProfileMenu from './ProfileMenu';
import Collaborate from './Collaborate';

const Toolbar = () => {
    return ( 
        <div className='flex items-center gap-x-1'>
            <LanguagePicker />
            <Collaborate />
            <Notifications />
            <ProfileMenu />
        </div> 
    );
}
 
export default Toolbar;