import Card from "components/ui/Card";
import { BaseContext } from "contexts/BaseContext";
import jockeyDetailText from "texts/pages/jockeyText";
import { useContext } from "react";

const StatCards = ({ data, loading }) => {
  const { language } = useContext(BaseContext);

  const formatEarnings = (earnings) => {
    let formattedEarnings = "";
    if (!isNaN(earnings)) {
      if (earnings >= 1000000) {
        formattedEarnings = `${(earnings / 1000000).toFixed(1)}M`;
      } else if (earnings >= 1000) {
        formattedEarnings = `${(earnings / 1000).toFixed(1)}k`;
      } else {
        formattedEarnings = earnings.toFixed(0);
      }
    }
    return formattedEarnings;
  };

  return (
    <div className="block lg:flex gap-x-2">
      <Card
        type="icon"
        className="flex flex-col flex-grow"
        containerClassName="flex-grow flex flex-col justify-between"
        iconBg="gradient1"
        icon={
          <span className="material-icons m-3 text-white">
            equalizer
          </span>
        }
        footer={
          <div>
            <div className="h-2 px-4 flex justify-center">
              <div
                className="bg-sand h-1.5 transition-all duration-500"
                style={{
                  width:
                    data && !loading
                      ? `${(data.sand * 100) / data.total}%`
                      : "50%",
                }}
              />
              <div
                className="bg-grass h-1.5 transition-all duration-500"
                style={{
                  width:
                    data && !loading
                      ? `${(data.grass * 100) / data.total}%`
                      : "50%",
                }}
              />
            </div>
            <div className="flex items-center justify-between px-4 flex-wrap">
              <div className="flex gap-1 items-center ">
                <p className="text-sand text-xs font-bold my-0">
                  {jockeyDetailText[language].sand}
                </p>
                {data && !loading ? (
                  <p className="text-backD1 text-xs  my-0">{data.sand}</p>
                ) : (
                  <div className="h-4 w-16 my-2 bg-gray-300 animate-pulse self-end" />
                )}
              </div>
              <div className="flex gap-1 items-center">
                <p className="text-grass text-xs font-bold my-0">
                  {jockeyDetailText[language].grass}
                </p>
                {data && !loading ? (
                  <p className="text-backD1 text-xs my-0">{data.grass}</p>
                ) : (
                  <div className="h-4 w-16 my-2 bg-gray-300 animate-pulse self-end" />
                )}
              </div>
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-end">
          <p className="text-xs text-backD1 text-right my-0">
            {jockeyDetailText[language].total_races}
          </p>
          {data && data.total !== undefined && !loading ? (
            <p className="text-xl text-backD2 font-bold text-right my-0">
              {data.total}
            </p>
          ) : (
            <div className="h-8 w-16 mt-1 bg-gray-300 animate-pulse self-end" />
          )}
        </div>
      </Card>
      <Card
        type="icon"
        className="flex flex-col flex-grow"
        containerClassName="flex flex-grow flex-col justify-between"
        iconBg="gradient1"
        icon={
          <span className="material-icons m-3 text-white">monetization_on</span>
        }
        footer={
          data && data.earnings_total !== undefined && !loading ? (
            <p className="text-backD1 text-xs my-0">{`${data.earnings_total?.toLocaleString(
              "es-AR"
            )} (${data.betamt_uom?.toUpperCase() || "?"})`}</p>
          ) : (
            <div className="h-4 w-16 my-2 bg-gray-300 animate-pulse self-end" />
          )
        }
      >
        <div className="flex flex-col justify-end">
          <p className="text-xs text-backD1 text-right my-0">
            {jockeyDetailText[language].earnings}
          </p>
          {data && data.earnings_total !== undefined && !loading ? (
            <p className="text-xl text-backD2 font-bold text-right my-0">
              {formatEarnings(data.earnings_total)}
            </p>
          ) : (
            <div className="h-8 w-16 mt-1 bg-gray-300 animate-pulse self-end" />
          )}
        </div>
      </Card>
      <Card
        type="icon"
        className="flex flex-col flex-grow"
        containerClassName="h-full flex flex-col justify-between"
        iconBg="gradient1"
        icon={<span className="material-icons m-3 text-white">receipt</span>}
        footer={
          <div className="flex items-center justify-between">
            <div className="flex gap-1 items-center">
              <p className="text-success text-xs font-bold xl:hidden 2xl:flex my-0">Min</p>
              {data && !loading ? (
                <p className="text-backD1 xl:text-success 2xl:text-backD1 text-xs my-0">{`${data.bets_min} (${
                  data.betamt_uom?.toUpperCase() || "?"
                })`}</p>
              ) : (
                <div className="h-4 w-16 my-2 bg-gray-300 animate-pulse self-end" />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <p className="text-danger text-xs font-bold md:flex xl:hidden 2xl:flex my-0">Max</p>
              {data && !loading ? (
                <p className="text-backD1 xl:text-danger 2xl:text-backD1 text-xs my-0">{`${data.bets_max} (${
                  data.betamt_uom?.toUpperCase() || "?"
                })`}</p>
              ) : (
                <div className="h-4 w-16 my-2 bg-gray-300 animate-pulse self-end" />
              )}
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-end">
          <p className="text-xs text-backD1 text-right my-0">
            {jockeyDetailText[language].bet_average}
          </p>
          {data && data.bets_average !== undefined && !loading ? (
            <div className="flex items-baseline gap-x-1 justify-end">
              <p className="text-xl text-backD2 font-bold text-right my-0">
                {`${data.bets_average.toFixed(2)}`}
              </p>
              <p className="text-sm text-backD2 font-bold my-0">{`(${data.betamt_uom?.toUpperCase() || "?"})`}</p>
            </div>
          ) : (
            <div className="h-8 w-16 mt-1 bg-gray-300 animate-pulse self-end" />
          )}
        </div>
      </Card>
    </div>
  );
};

export default StatCards;
