const forgotPasswordText = { 
    en: {  
        noAccount:'Do not have an account?',
        signUp:'Sign Up',
        signIn:'Sign In',
        password:'Password',
        email:'E-mail',
        signInFail:'The password or email entered are incorrect.',
        forgot:'Forgot your password?',
        recover:'Get it back',
        send:'Send',
        successTitle:'¡Updated password!',
        successDesc: 'The password was updated successfully 🎉',
        redirecting:'Redirecting to our home screen...',
        failureTitle:'There was an error trying to update the password ⚠️',
        failureDesc:'Please try again later', // disparar correo en caso de error? (!)
		errorLength: 'The character limit is',
        errorGeneral: 'An error has occurred, please try again later',
        passwordConfirm: 'Confirm password',
		
    },
    es: {  
        noAccount:'¿No tenés una cuenta?',
        signUp:'Registrate',
        signIn: 'Ingresar',
        password:'Contraseña',
        email:'E-mail',
        signInFail:'La contraseña o mail ingresados son incorrectos.',
        forgot:'¿Olvidaste tu contraseña?',
        recover:'Recuperala',
        send:'Enviar',
        successTitle:'¡Contraseña actualizada!',
        successDesc: 'La contraseña se cambió con éxito 🎉',
        redirecting:'Redirigiendo a la pantalla de inicio...',
        failureTitle:'Hubo un error al intenntar actualizar la contraseña ⚠️',
        failureDesc:'Por favor, intente nuevamente más tarde', // disparar correo en caso de error? (!)
        errorLength:'El límite de caracteres es de ', 
        errorGeneral:'Ha ocurrido un error, vuelva a intentarlo más tarde',
        passwordConfirm:'Confirmar contraseña',
    },
    pt: {  
        noAccount:'Não tem uma conta? ',
        signUp:'Inscrever-se',
        signIn:'Ingresar',
        password:'Senha',
        email:'E-mail',
        signInFail:'A senha ou e-mail inserido está incorreto.',
        forgot:'Esqueceu a sua senha?',
        recover:'Recuperá-la',
        send:'Enviar',
        successTitle:'¡Senha atualizada!',
        successDesc: 'A senha foi atualizada com sucesso 🎉',
        redirecting:'Redirecionando para nossa tela inicial...',
        failureTitle:'Ocorreu um erro ao tentar atualizar a senha ⚠️',
        failureDesc:'Por favor, tente novamente mais tarde', // disparar correo en caso de error? (!)
        errorLength:'O limite de caracteres é ',
        errorGeneral:'Ocorreu um erro, tente novamente mais tarde',
        passwordConfirm:'Confirmar senha',
    },
}

export default forgotPasswordText;