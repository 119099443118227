import { gql } from "@apollo/client";

export const GET_JOCKEY_PROFILE = gql`
query JockeyProfile($jockeyId: ID!) {
  jockeyProfile(id: $jockeyId) {
    rating {
      totalCount
      average
    }
    totalWon {
      name
      won
    },
    racetracks
    next_mounts {
      horse_id
      racetrack_id
      distance
      raceno
      date
      course
      type
      stable_name
      horse_name
      race_name
      age
      color
      stats {
        first
        no_place
        place
      }
    }
    id
    country
    name
    chdate
    crdate
    isactive
    ismale
  }
}`;