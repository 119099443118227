import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { format } from "date-fns";

import "chart.js/auto";
import { useLazyQuery } from "@apollo/client";
//Styles
import Breadcrumb from "components/ui/Breadcrumb";
import { BaseContext } from "contexts/BaseContext";
import MainContent from "./MainContent";
import { GET_HORSE_PROFILE } from "queries/horse";
import SideContent from "./SideContent";
import { breadCrumbText } from "texts/components/breadCrumbText";

const HorseDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [horseToAdd, setHorseToAdd] = useState("");
  const [horseProfile, setHorseProfile] = useState();
  const [previousPath, setPreviousPath] = useState([]);

  const { horse_id } = useParams();

  if (!horse_id) {
    navigate("/app/main");
  }

  const { language } = useContext(BaseContext);
  //graphql
  const [getHorseData, { data: dataHorse, loading: loadingHorse }] =
    useLazyQuery(GET_HORSE_PROFILE);

  //States

  //useEffect
  useEffect(() => {
    var mounted = true;
    let breadCrumbPaths = [];
    if (location && location.state) {
      breadCrumbPaths.push({
        text: breadCrumbText[language].main,
        href: "/app/main",
      });

      if (location.state.prev === "search") {
        breadCrumbPaths.push({
          text: breadCrumbText[language].search,
          href: `/app/search/${location.state.param}`,
        });
      }

      if (location.state.prev === "detail") {
        breadCrumbPaths.push({
          text: breadCrumbText[language].detail,
          href: `/app/detail`,
        });
      }
    }

    if (mounted) {
      setPreviousPath(breadCrumbPaths);
      let oneMonthAgo = new Date();
      let currentYear = format(new Date(), "yyyy");
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      oneMonthAgo = format(new Date(oneMonthAgo), `yyyy-MM-dd`);
      if (horse_id) {
        getHorseData({ variables: { horseId: horse_id } });
      }
      // }
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (dataHorse && !loadingHorse && mounted) {
      const auxHorseData = { ...dataHorse.horseProfile };
      setHorseProfile(auxHorseData);
    }

    return () => {
      mounted = false;
    };
  }, [dataHorse]);

  return (
    <section className="flex flex-col flex-grow">
      <Breadcrumb path={previousPath} />
      <div className="block md:flex gap-x-2 flex-grow">
        <div className="w-full lg:w-[368px] shadow-xl flex-shrink-0">
          <SideContent
            horseData={horseProfile}
            horse_id={horse_id}
            setHorseToAdd={setHorseToAdd}
          />
        </div>
        <MainContent horse_id={horse_id} />
      </div>
    </section>
  );
};

export default HorseDetail;
