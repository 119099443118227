import { gql } from "@apollo/client";

export const GET_HORSE_YEAR = gql`
  query horseByYear($horse_id: ID!, $year: String!) {
    horseByYear(id: $horse_id, year: $year) {
      racetrack_id
      weatherCode
      date
      distance
      course
      betamt_uom
      status
      type
      prizes {
        prize1
        prize2
        prize3
        prize4
        prize5
        prize6
        prize7
        prize8
      }
      competitor {
        pos
        rail
        bet_amt
        jockey_id
        jockey_name
      }
    }
  }
`;
