import { gql } from "@apollo/client";

export const GET_JOCKEYRATING_BY_USER = gql`
  query JockeyRatingByUser($jockeyId: String!) {
    jockeyRatingByUser(jockeyId: $jockeyId) {
      score
      comment
    }
  }
`;
