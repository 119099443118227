import React, { useRef, useState, useEffect, useContext } from "react";
import { RaceContext } from "contexts/RaceContext";
import { BaseContext } from "contexts/BaseContext";

import YouTubePlayer from "react-player/youtube";
import Slider from "@mui/material/Slider";
import gallopGif from "assets/icons/gallop.gif";
import gallop from "assets/icons/gallop.png";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const VideoCard = ({ videoData = {}, historyData = {} }) => {
  const { raceTracks } = useContext(RaceContext);
  const { language, locales } = useContext(BaseContext);
  const [videoState, setVideoState] = useState({
    seeking: false,
    played: 0,
    playing: false,
    playbackRate: 1,
    muted: false,
    volume: 0.5,
  });
  const videoRef = useRef(null);
  const playbackSpeeds = [0.75, 1.0, 1.5, 2, 0.5];
  const grass = historyData.course?.toLowerCase()?.includes('cesped');

  useEffect(() => {
    let mounted = true;
    if (videoData && videoData.url && mounted) {
      setVideoState((prevState) => ({
        ...prevState,
        playing: false,
        played: 0,
      }));
    }
    return () => (mounted = false);
  }, [videoData]);

  const handlePlayPause = () => {
    setVideoState((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  };

  const handleEnded = () => {
    setVideoState((prevState) => ({
      ...prevState,
      playing: false,
      played: 0,
    }));
  };

  const handleProgress = (state) => {
    if (!videoState.seeking && videoState.playing) {
      setVideoState((prevState) => ({
        ...prevState,
        played: state.played,
      }));
    }
  };

  const handleSeekMouseDown = (e, value) => {
    setVideoState((prevState) => ({
      ...prevState,
      seeking: true,
      played: parseFloat(value),
    }));
  };

  const handleSeekMouseUp = (e, value) => {
    if (value.toFixed(2) === 1.0 && !videoState.playing) {
      setVideoState((prevState) => ({
        ...prevState,
        seeking: false,
        played: 0,
      }));
    } else {
      setVideoState((prevState) => ({
        ...prevState,
        seeking: false,
        played: parseFloat(value),
      }));
      videoRef.current.seekTo(parseFloat(value));
    }
  };

  const handleSpeed = () => {
    const index = playbackSpeeds.findIndex(
      (speed) => speed === videoState.playbackRate
    );
    var newValue = 1;
    if (index >= 0 && index < playbackSpeeds.length - 1) {
      newValue = playbackSpeeds[index + 1];
    } else if (index >= 0) {
      newValue = playbackSpeeds[0];
    }
    setVideoState((prevState) => ({
      ...prevState,
      playbackRate: parseFloat(newValue),
    }));
  };

  return (
    <div className="bg-white rounded-lg h-full w-full flex flex-col overflow-hidden">
      <div className="flex flex-row px-4 py-2 w-full justify-between">
        <div className="flex flex-col justify-between">
          <p className="text-sm uppercase text-backD2 font-bold my-0 !leading-none">
            {raceTracks[historyData.racetrack_id]?.name}
          </p>
          <div className="flex gap-x-2 items-center">
            <p className="text-sm text-secondaryD1 my-0 !leading-none">
              {`Carrera ${historyData.raceno}`}
            </p>
            <p className="text-sm text-backD1.5 my-0 !leading-none">
              { historyData.date && format(new Date(historyData.date), 'MMM d, yyyy - HH:mm:ss',{locale: locales[language]})}
            </p>
            <button 
                className="
                    bg-transparent border-none cursor-pointer hover:bg-backL2 transition-colors material-icons text-lg !leading-none
                "
                // onClick={()=>console.log('WIP')}
            >
                visibility
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base text-backD2 my-0 !leading-none">
            {`${historyData.distance} metros`}
          </p>
          <p 
            className={`
                text-white w-full my-0 text-center
                ${grass ? 'bg-grass' : 'bg-sand'}
            `}
            
          >
            {grass? "Césped":"Arena"}
          </p>
        </div>
      </div>
      <div className="w-full h-full">
        {videoData?.url ? (
          <YouTubePlayer
            ref={videoRef}
            playing={videoState.playing}
            playbackRate={videoState.playbackRate}
            url={videoData?.url}
            width="100%"
            height="100%"
            volume={videoState.volume}
            muted={videoState.muted}
            onEnded={handleEnded}
            onProgress={handleProgress}
            controls={false}
            onPlay={() => setVideoState({ ...videoState, playing: true })}
            onPause={() => setVideoState({ ...videoState, playing: false })}
            config={{
                youtube: { playerVars: { origin: 'https://www.youtube.com' } }
            }}
          />
        ) : (
          <div className="h-full w-full flex items-center justify-center bg-gradient-to-b from-primary to-secondary text-white font-bold">
            NO HAY
          </div>
        )}
      </div>
      <div className="w-full flex flex-col">
        <div className="flex w-full h-full items-center gap-x-6">
          <button
            className="bg-transparent border-none flex-shrink-0 cursor-pointer px-4 hover:bg-backL2 transition-colors"
            onClick={handlePlayPause}
          >
            {videoState.playing ? (
              <img src={gallopGif} alt="playing" className="w-7 h-7" />
            ) : (
              <img src={gallop} alt="stopped" className="w-7 h-7" />
            )}
          </button>
          <Slider
            onChange={handleSeekMouseDown}
            value={videoState.played}
            min={0}
            step={0.01}
            max={0.999999}
            onChangeCommitted={handleSeekMouseUp}
            color="secondary"
            size="small"
          />
          <button
            className="bg-transparent border-none w-14 h-full text-center flex-shrink-0 cursor-pointer px-4 hover:bg-backL2 transition-colors"
            onClick={handleSpeed}
          >
            {`${videoState.playbackRate}x`}
          </button>
        </div>
        <div className="text-[9px] my-0 flex justify-end px-4 items-center pb-0.5">
            {`Fuente: `}
            <a
                target="_blank"
                rel="noreferrer"
                href={raceTracks?.[historyData.racetrack_id]?.name} 
            >
                {raceTracks?.[historyData.racetrack_id]?.name}
            </a>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
