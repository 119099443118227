import './App.css';

import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client'
import { ApolloLink } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

import Routes from 'AppRoutes';
import signOut from 'utilities/functions/signOut';

//http://localhost:4000/graphql
//https://backdev.invasor.ar/graphql
//https://backend-dev-316712.rj.r.appspot.com/graphql

function App() {

  const uploadLink = createUploadLink({
    uri:'https://backdev.invasor.ar/graphql',
  })
  
  const hashLink = createPersistedQueryLink({ sha256 }).concat(uploadLink);
  
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers = { ...headers, 'x-token': token };
      }
      else {
        headers = { ...headers, 'x-token': ''}
      }
  
      return { headers };
    });
  
    return forward(operation);
  });
  
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // console.log('GraphQL error', message);
        if (message.includes('session expired') ){ 
          if (window.location.href.toLowerCase().includes('app')) {
            signOut(client);
          } else {
            localStorage.removeItem('token');
          }
        }
      });
    }
  
    if (networkError) {
      // console.log('Network error', networkError);
      
      if (networkError.statusCode === 401) {
        signOut(client);
      }
    }
  });
  
  const link = ApolloLink.from([authLink, errorLink, hashLink]);
  
  const cache = new InMemoryCache();
  
  const client = new ApolloClient({
    link,
    cache,
  });
  
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
}

export default App;
