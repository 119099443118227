import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import domains from 'constants/Domains'
import signUpText from 'texts/pages/signUpText';

const filter = createFilterOptions();

export default function FreeSoloCreateOption({userData, setUserData, language, errors, setErrors}) {

  return (
    <Autocomplete
      value={userData.domain}
      onChange={(event, newValue, reason) => {
        if (typeof newValue === 'string') {
          setUserData({
            ...userData,
            domain: newValue,
          });
          setErrors({
            ...errors,
            domain:{
                ok:true,
                error:""
            }
          })
          return;
        } 
        
        if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setUserData({
            ...userData,
            domain: newValue.inputValue,
          });
          setErrors({
            ...errors,
            domain:{
                ok:true,
                error:`${signUpText[language].domainWarning}`
            }
          })
        }

        setUserData({
            ...userData,
            domain:newValue
        });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
            filtered.push( {
              label:`${signUpText[language].otherDomain1} "${params.inputValue}" ${signUpText[language].otherDomain2}`,
              inputValue:params.inputValue
            });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={domains}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.label || option}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField 
            {...params} 
            label={signUpText[language].domain}
            variant="standard"
            error={errors.domain && !errors.domain.ok }
            helperText={errors.domain && errors.domain.error}
            inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
            }}
        />
      )}
    />
  );
}

