import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { BaseContext } from "contexts/BaseContext";
import confirmText from "texts/pages/confirmText";
import confirmBg from "assets/confirm_bg.webp";
import { VALIDATE_EMAIL } from "queries/auth";
import { useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";

const ValidateEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { language } = useContext(BaseContext);
  const [status, setStatus] = useState();

  const [
    confirmEmail, 
    {
      data:dataValidate,
      loading:loadingValidate,
      error:errorValidate
    }
  ] = useMutation(VALIDATE_EMAIL, { onError(err) {} });
  
  useEffect(() => {
    let mounted = true;

    if(dataValidate && !loadingValidate && mounted) {
      setStatus('validated');
      setTimeout(() => {
        navigate('/auth/signin');
      }, 3000);
    }
  
    return () => mounted = false;
  }, [dataValidate, loadingValidate])

  useEffect(() => {
    let mounted = true;

    if(errorValidate && !loadingValidate && mounted) {
      setStatus('failed');
    }
  
    return () => mounted = false;
  }, [errorValidate, loadingValidate])
  
  useEffect(() => {
    let mounted = true;
    if(token && mounted){
      confirmEmail({ variables:{ token:token } });
    }
    return () => mounted = false;
  }, [])
  

  return (
    <div
      className="flex-grow flex flex-col items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${confirmBg})` }}
    >
      <div className="absolute h-full w-full left-0 top-0 bg-black bg-opacity-70 z-0" />
      <div className="mx-4 z-10">
        {
          loadingValidate && (
            <CircularProgress 
              color="primary" 
              size={120}
            />
          )
        }
        {
          (status === "validated" && !loadingValidate)&& (
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-white my-0 text-5xl">
                {confirmText[language].confirmText}
              </h1>
              <h4 className="text-white text-2xl">
                {confirmText[language].confirmDesc}
              </h4>
            </div>
          )
        }
        {
          (status === "failed" && !loadingValidate) && (
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-white my-0 text-5xl">
                {confirmText[language].confirmFailText}
              </h1>
              <h4 className="text-white text-2xl">
                {confirmText[language].confirmFailDesc}
              </h4>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ValidateEmail;
