//1
import r1 from 'assets/icons/numbers/1.webp';
import r2 from 'assets/icons/numbers/2.webp'; 
import r3 from 'assets/icons/numbers/3.webp';
import r4 from 'assets/icons/numbers/4.webp';
import r5 from 'assets/icons/numbers/5.webp';
import r6 from 'assets/icons/numbers/6.webp'; 
import r7 from 'assets/icons/numbers/7.webp';
import r8 from 'assets/icons/numbers/8.webp';
import r9 from 'assets/icons/numbers/9.webp';
//1A
import r1A from 'assets/icons/numbers/1A.webp';
import r2A from 'assets/icons/numbers/2A.webp'; 
import r3A from 'assets/icons/numbers/3A.webp';
import r4A from 'assets/icons/numbers/4A.webp';
import r5A from 'assets/icons/numbers/5A.webp';
import r6A from 'assets/icons/numbers/6A.webp'; 
import r7A from 'assets/icons/numbers/7A.webp';
import r8A from 'assets/icons/numbers/8A.webp';
import r9A from 'assets/icons/numbers/9A.webp';
//1B
import r1B from 'assets/icons/numbers/1B.webp';
import r2B from 'assets/icons/numbers/2B.webp'; 
import r3B from 'assets/icons/numbers/3B.webp';
import r4B from 'assets/icons/numbers/4B.webp';
import r5B from 'assets/icons/numbers/5B.webp';
import r6B from 'assets/icons/numbers/6B.webp'; 
import r7B from 'assets/icons/numbers/7B.webp';
import r8B from 'assets/icons/numbers/8B.webp';
import r9B from 'assets/icons/numbers/9B.webp';
//1C
import r1C from 'assets/icons/numbers/1C.webp';
import r2C from 'assets/icons/numbers/2C.webp'; 
import r3C from 'assets/icons/numbers/3C.webp';
import r4C from 'assets/icons/numbers/4C.webp';
import r5C from 'assets/icons/numbers/5C.webp';
import r6C from 'assets/icons/numbers/6C.webp'; 
import r7C from 'assets/icons/numbers/7C.webp';
import r8C from 'assets/icons/numbers/8C.webp';
import r9C from 'assets/icons/numbers/9C.webp';
//10
import r10 from 'assets/icons/numbers/10.webp'; 
import r11 from 'assets/icons/numbers/11.webp';
import r12 from 'assets/icons/numbers/12.webp';
import r13 from 'assets/icons/numbers/13.webp';
import r14 from 'assets/icons/numbers/14.webp'; 
import r15 from 'assets/icons/numbers/15.webp';
import r16 from 'assets/icons/numbers/16.webp';
import r17 from 'assets/icons/numbers/17.webp';
import r18 from 'assets/icons/numbers/18.webp'; 
import r19 from 'assets/icons/numbers/19.webp';
import r20 from 'assets/icons/numbers/20.webp';
//10A
import r10A from 'assets/icons/numbers/10A.webp'; 
import r11A from 'assets/icons/numbers/11A.webp';
import r12A from 'assets/icons/numbers/12A.webp';
import r13A from 'assets/icons/numbers/13A.webp';
import r14A from 'assets/icons/numbers/14A.webp'; 
import r15A from 'assets/icons/numbers/15A.webp';
import r16A from 'assets/icons/numbers/16A.webp';
import r17A from 'assets/icons/numbers/17A.webp';
import r18A from 'assets/icons/numbers/18A.webp'; 
import r19A from 'assets/icons/numbers/19A.webp';
import r20A from 'assets/icons/numbers/20A.webp';
//10B
import r10B from 'assets/icons/numbers/10B.webp'; 
import r11B from 'assets/icons/numbers/11B.webp';
import r12B from 'assets/icons/numbers/12B.webp';
import r13B from 'assets/icons/numbers/13B.webp';
import r14B from 'assets/icons/numbers/14B.webp'; 
import r15B from 'assets/icons/numbers/15B.webp';
import r16B from 'assets/icons/numbers/16B.webp';
import r17B from 'assets/icons/numbers/17B.webp';
import r18B from 'assets/icons/numbers/18B.webp'; 
import r19B from 'assets/icons/numbers/19B.webp';
import r20B from 'assets/icons/numbers/20B.webp';
//10C
import r10C from 'assets/icons/numbers/10C.webp'; 
import r11C from 'assets/icons/numbers/11C.webp';
import r12C from 'assets/icons/numbers/12C.webp';
import r13C from 'assets/icons/numbers/13C.webp';
import r14C from 'assets/icons/numbers/14C.webp'; 
import r15C from 'assets/icons/numbers/15C.webp';
import r16C from 'assets/icons/numbers/16C.webp';
import r17C from 'assets/icons/numbers/17C.webp';
import r18C from 'assets/icons/numbers/18C.webp'; 
import r19C from 'assets/icons/numbers/19C.webp';
import r20C from 'assets/icons/numbers/20C.webp';
//20
import r21 from 'assets/icons/numbers/21.webp';
import r22 from 'assets/icons/numbers/22.webp'; 
import r23 from 'assets/icons/numbers/23.webp';
import r24 from 'assets/icons/numbers/24.webp';
import r25 from 'assets/icons/numbers/25.webp';
//20A
import r21A from 'assets/icons/numbers/21A.webp';
import r22A from 'assets/icons/numbers/22A.webp'; 
import r23A from 'assets/icons/numbers/23A.webp';
import r24A from 'assets/icons/numbers/24A.webp';
import r25A from 'assets/icons/numbers/25A.webp';
//20B
import r21B from 'assets/icons/numbers/21B.webp';
import r22B from 'assets/icons/numbers/22B.webp'; 
import r23B from 'assets/icons/numbers/23B.webp';
import r24B from 'assets/icons/numbers/24B.webp';
import r25B from 'assets/icons/numbers/25B.webp';
//20C
import r21C from 'assets/icons/numbers/21C.webp';
import r22C from 'assets/icons/numbers/22C.webp'; 
import r23C from 'assets/icons/numbers/23C.webp';
import r24C from 'assets/icons/numbers/24C.webp';
import r25C from 'assets/icons/numbers/25C.webp';

const rails = {
    '1':r1,
    '2':r2,
    '3':r3,
    '4':r4,
    '5':r5,
    '6':r6,
    '7':r7,
    '8':r8,
    '9':r9,
    '10':r10,
    '1A':r1A,
    '2A':r2A,
    '3A':r3A,
    '4A':r4A,
    '5A':r5A,
    '6A':r6A,
    '7A':r7A,
    '8A':r8A,
    '9A':r9A,
    '10A':r10A,
    '1B':r1B,
    '2B':r2B,
    '3B':r3B,
    '4B':r4B,
    '5B':r5B,
    '6B':r6B,
    '7B':r7B,
    '8B':r8B,
    '9B':r9B,
    '10B':r10B,
    '1C':r1C,
    '2C':r2C,
    '3C':r3C,
    '4C':r4C,
    '5C':r5C,
    '6C':r6C,
    '7C':r7C,
    '8C':r8C,
    '9C':r9C,
    '10C':r10C,
    '11':r11,
    '12':r12,
    '13':r13,
    '14':r14,
    '15':r15,
    '16':r16,
    '17':r17,
    '18':r18,
    '19':r19,
    '20':r20,
    '21':r21,
    '22':r22,
    '23':r23,
    '24':r24,
    '25':r25,
    '11A':r11A,
    '12A':r12A,
    '13A':r13A,
    '14A':r14A,
    '15A':r15A,
    '16A':r16A,
    '17A':r17A,
    '18A':r18A,
    '19A':r19A,
    '20A':r20A,
    '21A':r21A,
    '22A':r22A,
    '23A':r23A,
    '24A':r24A,
    '25A':r25A,
    '11B':r11B,
    '12B':r12B,
    '13B':r13B,
    '14B':r14B,
    '15B':r15B,
    '16B':r16B,
    '17B':r17B,
    '18B':r18B,
    '19B':r19B,
    '20B':r20B,
    '21B':r21B,
    '22B':r22B,
    '23B':r23B,
    '24B':r24B,
    '25B':r25B,
    '11C':r11C,
    '12C':r12C,
    '13C':r13C,
    '14C':r14C,
    '15C':r15C,
    '16C':r16C,
    '17C':r17C,
    '18C':r18C,
    '19C':r19C,
    '20C':r20C,
    '21C':r21C,
    '22C':r22C,
    '23C':r23C,
    '24C':r24C,
    '25C':r25C,
}

export default rails