import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from 'contexts/UserContext';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const RequireAdmin = ({children}) => {
    const { session, dataMe, calledMe, loadingMe, loadingSignIn } = useContext(UserContext);
    
    if((loadingMe || loadingSignIn) && (!dataMe?.me || !session.email)) {
        return (
            <div className='h-screen w-screen flex items-center justify-center'>
                <CircularProgress size={150} color='secondary'/>
            </div>
        )
    }

    if (!session?.email && !dataMe?.me && !loadingMe && calledMe) {
        return <Navigate to="/auth/signin" state={{ from: location }} replace />;
    }

    if(dataMe?.me?.role !== 'ADMIN' && session?.role !== 'ADMIN') {
        return <Navigate to="/app/main" replace />;
    }

    return children;
}
 
export default RequireAdmin;