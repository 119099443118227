import React from "react";

const ClassicStepIcon = ({ icon, dayRaces, currentIndex }) => {
  const sand = dayRaces?.[icon]?.course?.toLowerCase()?.includes("arena");

  return (
    <div>
      {currentIndex === icon ? (
        <span className="text-secondaryD2 material-icons text-lg md:text-2xl leading-none group-hover:scale-125 transition-all">
          emoji_events
        </span>
      ) : currentIndex > icon || currentIndex === icon ? (
        <span
          className={`
            material-icons text-lg md:text-2xl leading-none transition-all
            ${ sand ? "text-sand group-hover:text-sandD1 group-hover:scale-125" : "text-grass group-hover:text-grassD1"}
          `}
        >
          emoji_events
        </span>
      ) : (
        <span
          className={`
            material-icons text-lg md:text-2xl leading-none transition-all
            ${ sand ? "text-sandL1 group-hover:text-sand group-hover:scale-125" : "text-grassL1 group-hover:text-grass"}
          `}
        >
          emoji_events
        </span>
      )}
    </div>
  );
};

export default ClassicStepIcon;
