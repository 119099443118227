import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SplideSlide } from '@splidejs/react-splide';
import { BaseContext } from 'contexts/BaseContext';
import horse_bg01 from 'assets/horse_bg01_lower.webp';
import homeText from 'texts/pages/homeText';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Slide1 = () => {
    const { language } = useContext(BaseContext);
    const [mail, setMail] = useState("");
    const navigate = useNavigate();
    const handleRegisterRedirect = ()=>{
        if(mail) {
            navigate(`/auth/signup/${mail}`)
        } else {
            navigate('/auth/signup');
        }
    };
    return ( 
    <SplideSlide className="h-full w-full">
        <div
            className='h-full w-full bg-cover bg-center flex flex-row items-center justify-center'
            style={{backgroundImage:`url(${horse_bg01})`}}
        >
            <div className='h-full w-full absolute z-0 bg-black bg-opacity-50'/>
            <div className='z-20 relative max-w-4xl px-12 py-10 flex flex-col gap-y-4 md:gap-y-8'>
                <div>
                    <h1 className="font-monsterrat text-2xl md:text-4xl text-white">
                        {homeText[language].title1}
                    </h1>
                    <h1 className="font-monsterrat text-2xl md:text-4xl text-white font-bold">
                        {homeText[language].title2}
                    </h1>
                </div>
                <h3 className="text-base md:text-xl text-white">
                {homeText[language].sub1}
                </h3>
                <form 
                    className="bg-white rounded-lg p-4 gap-x-4 md:gap-x-0 flex items-center"
                    onSubmit={handleRegisterRedirect}    
                >
                    <TextField 
                        id="sign-up-email"
                        value={mail}
                        onChange={({target})=>setMail(target.value)}
                        color="secondary"
                        variant="standard"
                        placeholder={homeText[language].mail}
                        InputProps={{
                            maxLength: 100,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <span className='material-icons text-lg text-black'>email</span>
                                </InputAdornment>
                            )
                        }}
                        className="w-full"
                    />
                    <Button 
                        className='rounded-full flex-shrink-0 px-5 py-2' 
                        color="secondaryDark" 
                        variant='contained'
                        type="submit"
                    >
                        {homeText[language].register}
                    </Button>
                </form>
                <h5 className='text-base text-white'>
                    {homeText[language].signIn1}{" "}
                    <Link to="/auth/signin" className='text-white font-bold underline'>
                        {homeText[language].signIn2}
                    </Link>
                </h5>
            </div>
        </div>
    </SplideSlide>
 );
}
 
export default Slide1;