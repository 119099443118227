import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { BaseContext } from "contexts/BaseContext";
import signInText from "texts/pages/signInText";
import signInBg from "assets/signin_bg.webp";
import LoginForm from "./LoginForm";
import RecoverForm from "./RecoverForm";
import { UserContext } from "contexts/UserContext";

const SignIn = () => {
  const { language } = useContext(BaseContext);
  const { session, loadingSignIn } = useContext(UserContext);
  const [formToDisplay, setFormToDisplay] = useState("sign_in");

  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (mounted && session?.email && !loadingSignIn) {
      if (["ADMIN", "EDIT"].includes(session.role)) navigate("/app/edit");
      navigate("/app/main");
    }
    return () => {
      mounted = false;
    };
  }, [session]);

  return (
    <div
      className="flex-grow flex flex-col items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${signInBg})` }}
    >
      <div className="absolute h-full w-full left-0 top-0 bg-black bg-opacity-50 z-0" />
      <div className="mx-4 md:min-w-[30.74rem] min-h-[25rem] z-10">
        <h1 className="text-3xl text-white font-roboto font-bold py-2">
          {formToDisplay === "sign_in"
            ? signInText[language].signInTitle
            : signInText[language].recoverPasswordTitle}
        </h1>
        <div className="bg-white shadow-lg rounded-md p-8 pt-16 flex flex-col gap-y-3">
          {formToDisplay === "sign_in" ? (
            <LoginForm setFormToDisplay={setFormToDisplay} />
          ) : (
            <RecoverForm setFormToDisplay={setFormToDisplay} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
