import React from 'react';

import { MainProvider } from 'contexts/MainContext';
import { Outlet } from 'react-router-dom';
import Predictions from 'components/ui/Predictions';


const MainLayout = () => {
    return ( 
        <MainProvider>
            <Outlet/>
            <Predictions/>
        </MainProvider>
    );
}
 
export default MainLayout;