import React, { useContext, useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { RaceContext } from "contexts/RaceContext";
import detailText from "texts/pages/detailText";
import { BaseContext } from "contexts/BaseContext";
import { format, parse } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineDistance = ({ historyData, setHistoryIndex }) => {
  const { raceTracks } = useContext(RaceContext);
  const { language, locales } = useContext(BaseContext);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: detailText[language].pos,
        data: [],
        borderColor: "#D1C4E9",
        fill: false,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 5,
        pointHitRadius: 15,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, element, { tooltip = {} }) => {
      if (tooltip.title) {
        const splitDate = tooltip.title?.[0]?.split(",") || [];
        const clickedDate = `${splitDate[0]} ${splitDate[1]}`;
        let formattedDate = "";
        if (language === "en") {
          formattedDate = format(
            parse(clickedDate, "MMM dd yy", new Date(), {
              locale: locales[language],
            }),
            "yyyy-MM-dd"
          );
        } else {
          formattedDate = format(
            parse(clickedDate, "dd MMM yy", new Date(), {
              locale: locales[language],
            }),
            "yyyy-MM-dd"
          );
        }
        const index = historyData.findIndex(
          (race) => race?.date?.substring(0, 10) === formattedDate
        );
        if (index >= 0) setHistoryIndex(index);
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = "pointer";
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function ({ raw }, data) {
            return `${detailText[language].dist}: ${raw}m`;
          },
        },
      },
    },
    elements: { width: 5, borderWidth: 5 },
    scales: {
      y: {
        ticks: {
          beginAtZero: false,
          callback: function (label, index) {
            return `${label}m`;
          },
          reverse: false,
          precision: 0,
          stepSize: 100,
        },
        position: "right",
      },
    },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && historyData && historyData[0] && raceTracks) {
      let data = {
        labels: [],
        datasets: [
          {
            label: detailText[language].dist,
            data: [],
            fill: false,
            borderColor: "rgb(0,0,0,0)",
            tension: 1,
            backgroundColor: "#D1C4E9",
            borderWidth: 0,
            pointRadius: 7,
            pointHitRadius: 15,
          },
        ],
      };

      historyData.forEach((record) => {
        if (record.date) {
          data.labels.push([
            format(
              new Date(record.date),
              language === "en" ? "MMM d" : "d MMM",
              {
                locale: locales[language],
              }
            ),
            format(new Date(record.date), "yy", { locale: locales[language] }),
          ]);
          data.datasets[0].data.push(record.distance);
        }
      });

      setChartData(data);
    } else if (historyData && historyData.length === 0) {
      let data = {
        labels: [],
        datasets: [
          {
            label: detailText[language].dist,
            data: [],
            fill: false,
            borderColor: "rgb(0,0,0,0)",
            tension: 1,
            backgroundColor: "#D1C4E9",
            borderWidth: 0,
            pointRadius: 7,
            pointHitRadius: 15,
          },
        ],
      };
      setChartData(data);
    }

    return () => (mounted = false);
  }, [historyData]);

  return (
    <div className="h-[95%] max-h-[95%] relative">
      <Line options={options} data={chartData} />
    </div>
  );
};

export default LineDistance;
