import React, { useContext } from "react";

import { BaseContext } from "contexts/BaseContext";
import { getFinalDistanceIcon } from "utilities/functions/getFinalDistanceIcon";
import getIncidentKey from "utilities/functions/getIncidentKey";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import rails from "constants/Rails";

const Row = ({ rowData, competitorIndex, currentRace, prediction }) => {
  const { language } = useContext(BaseContext);

  const horse_details = rowData.horse_details || {};
  const incidentKey = getIncidentKey(rowData);
  const nextCompetitor = currentRace?.competitors?.[competitorIndex + 1];
  const totalCompetitors = currentRace?.competitors?.length;

  const finalDistanceIcon = getFinalDistanceIcon(
    rowData,
    nextCompetitor,
    competitorIndex,
    totalCompetitors,
    language
  );

  const headerStyle = {
    cell: "p-2 text-center",
    small_cell: "p-0 text-center",
  };

  const mergedPredictions = [
    ...prediction.first?.split(","),
    ...prediction.second?.split(","),
    ...prediction.third?.split(","),
    ...prediction.fourth?.split(","),
  ].filter((p) => p);

  const includedInPrediction = mergedPredictions.includes(rowData.rail);

  return (
    <TableRow
      className={`
            border-b-backD2 transition-colors
            ${rowData.withdrawn ? "bg-back" : ""}
            ${incidentKey && incidentKey !== "withdrawn" ? "bg-dangerL1" : ""}
            ${currentRace?.suspended ? "!bg-back" : ""}
            ${includedInPrediction ? "!bg-secondary" : ""}
        `}
    >
      <TableCell className={headerStyle.small_cell}></TableCell>
      <TableCell className={headerStyle.small_cell}>
        <div className="flex h-full items-center">
          {finalDistanceIcon || <div className="h-6 w-6 bg-blue-400" />}
        </div>
      </TableCell>
      <TableCell className={headerStyle.small_cell}>
        <div className="flex h-full items-center">
          <img
            alt={`numbers/${rowData.rail}`}
            src={rails[rowData.rail || 1]}
            className="h-6 w-6"
          />
        </div>
      </TableCell>
      <TableCell className={headerStyle.cell}>{rowData.horse_name}</TableCell>
      <TableCell className={headerStyle.small_cell}>{rowData.age}</TableCell>
      <TableCell className={headerStyle.small_cell}>
        {horse_details.gender}
      </TableCell>
      <TableCell className={headerStyle.small_cell}>
        {horse_details.color}
      </TableCell>
      <TableCell className={headerStyle.cell}>{rowData.jockey_name}</TableCell>
      <TableCell className={headerStyle.cell}>{rowData.jKg}</TableCell>
      <TableCell className={headerStyle.cell}>{rowData.stable_name}</TableCell>
      <TableCell className={headerStyle.cell}>{rowData.carer_name}</TableCell>
    </TableRow>
  );
};

export default Row;
