import React from 'react'
import { Draggable } from 'react-beautiful-dnd';

const RailsItem = ({content = {}, index = 0, isDisabled, copy}) => {
    const isAlter = new RegExp(/[a-zA-Z]/g).test(content.rail)
    return (
        <Draggable
            draggableId={content.id}
            index={index}
            isDragDisabled={isDisabled}
        >
            {
                (provided, snapshot) => (
                    <>
                        <div 
                            className={`
                                h-8 w-8 rounded-full my-0 flex items-center justify-center
                                ${isAlter ? 'bg-yellow-300 text-black' : 'bg-backD2 text-white'}
                                ${isDisabled ? '!bg-backD3 !text-black select-none' : ''}
                            `}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            {content.rail}
                        </div>
                        {
                            (snapshot.isDragging && copy) && 
                            <div
                                className={`
                                    h-8 w-8 rounded-full my-0 flex items-center justify-center
                                    ${isAlter ? 'bg-yellow-300 text-black' : 'bg-backD2 text-white'}
                                `}
                            >
                                {content.rail}
                            </div>
                        }
                    </>
                )
            }
        </Draggable>
    );
}
 
export default RailsItem;