import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "contexts/MainContext";

import { useQuery } from "@apollo/client";
import { GET_DETAIL_HISTORY } from "queries/horserace";

import LowerSection from "./LowerSection";
import UpperSection from "./UpperSection";
import FiltersComponent from "components/ui/Filters";
import detailTemplate from "./FilterFunctions/filterTemplate";
import { RaceContext } from "contexts/RaceContext";
import getSortedByDistance from "utilities/functions/getSortedByDistance";
import detailText from "texts/pages/detailText";
import { BaseContext } from "contexts/BaseContext";

const MainContent = ({ compIndex, currentCompetitor }) => {
  const {
    dayRaces,
    currentIndex,
    dayHistory,
    lastCalled,
    dayRTS,
    loadingRTS,
    loadingBasicRace,
  } = useContext(MainContext);

  const { raceTracks } = useContext(RaceContext);
  const { language } = useContext(BaseContext);

  const sortedCompetitors =
    getSortedByDistance(dayHistory?.[currentIndex]?.competitors) || [];
  const currentCompHistory = sortedCompetitors[compIndex]?.history || [];

  const [historyIndex, setHistoryIndex] = useState(
    currentCompHistory.length - 1 || 0
  );
  const [historyDetail, setHistoryDetail] = useState([]);
  const [videosDetail, setVideosDetail] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const dates = currentCompHistory.map((r) => r?.date) || [];
  const noRaces =
    (!dayRTS?.list?.length && !loadingRTS && lastCalled?.rts) ||
    (!dayRaces?.length && !loadingBasicRace && lastCalled?.basicRace);

  const { data: dataFullHistory, loading: loadingFullHistory } = useQuery(
    GET_DETAIL_HISTORY,
    {
      variables: { dates: dates || [], horse_id: currentCompetitor?.horse_id },
      skip:
        dates === undefined ||
        dates === null ||
        !dates?.length ||
        noRaces ||
        !currentCompetitor?.horse_id,
    }
  );

  useEffect(() => {
    let mounted = true;
    if (dataFullHistory && !loadingFullHistory && true) {
      setHistoryDetail(dataFullHistory.horseRaceByDates);
      setFiltered(dataFullHistory.horseRaceByDates);
      setVideosDetail(dataFullHistory.videoByDates);
    }

    return () => (mounted = false);
  }, [dataFullHistory]);

  useEffect(() => {
    let mounted = true;
    if (mounted && filtered) {
      setHistoryIndex(filtered.length - 1 || 0);
    }
    return () => (mounted = false);
  }, [filtered]);

  const detailFilterTemplate = detailTemplate(raceTracks);
  return (
    <div className="h-full flex flex-col flex-grow gap-y-2">
      <div className="bg-white rounded-lg w-full h-auto py-4 md:py-0 md:px-4">
        {loadingFullHistory ? (
          <div className="h-16 w-full rounded-lg bg-backL2 animate-pulse" />
        ) : (
          <FiltersComponent
            filtered={filtered}
            setFiltered={setFiltered}
            originalData={historyDetail}
            template={detailFilterTemplate || {}}
          />
        )}
      </div>
      {
        filtered?.length ? (

          <>
            <UpperSection
              historyData={filtered}
              currentCompetitor={currentCompetitor}
              setHistoryIndex={setHistoryIndex}
              loading={loadingFullHistory}
            />
            <LowerSection
              videosDetail={videosDetail}
              historyDetail={filtered}
              historyIndex={historyIndex}
              setHistoryIndex={setHistoryIndex}
              currentComp={dayRaces?.[currentIndex]?.competitors?.[compIndex]}
              loading={loadingFullHistory}
            />
          </>
        ) : (
            <div className="bg-white rounded-lg h-full w-full flex items-center justify-center">
              <p className="text-lg md:text-2xl text-center">
                {detailText[language].noChartData}
              </p>
            </div>
        )
      }
    </div>
  );
};

export default MainContent;
