import React, { useRef, useState } from "react";

const MobileNavigation = ({ children }) => {
  const [displayNav, setDisplayNav] = useState({ right: false, left: false });
  const containerRef = useRef(null);

  const moreLeft = () => {
    const scrollValue = containerRef?.current?.scrollLeft;
    if (scrollValue - 150 <= 0) {
      containerRef?.current?.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    } else {
      containerRef?.current?.scrollTo({
        left: containerRef?.current?.scrollLeft - 150,
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const moreRight = () => {
    const scrollValue = containerRef?.current?.scrollLeft;
    const maxScroll =
      containerRef?.current?.scrollWidth - containerRef?.current?.clientWidth;
    if (scrollValue + 150 >= maxScroll) {
      containerRef?.current?.scrollTo({
        left: maxScroll,
        top: 0,
        behavior: "smooth",
      });
    } else {
      containerRef?.current?.scrollTo({
        left: containerRef?.current?.scrollLeft + 150,
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = (e) => {
    e.preventDefault();
    const overflow =
      containerRef?.current?.scrollWidth > containerRef?.current?.clientWidth;
    if (overflow) {
      const maxScroll =
        containerRef?.current?.scrollWidth - containerRef?.current?.clientWidth;
      const scrollValue = containerRef?.current?.scrollLeft;
      var auxDisplay = { ...displayNav };
      if (scrollValue >= maxScroll - 10) {
        auxDisplay.right = false;
      } else if (scrollValue < maxScroll - 10) {
        auxDisplay.right = true;
      }

      if (scrollValue <= 10) {
        auxDisplay.left = false;
      } else if (scrollValue > 10) {
        auxDisplay.left = true;
      }
      setDisplayNav(auxDisplay);
    }
  };

  return (
    <div className="relative h-full w-full">
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="overflow-x-auto md:overflow-x-hidden h-full"
      >
        {children}
      </div>
      <div
        onClick={moreLeft}
        className={`
            flex h-full bg-gradient-to-r from-backD2 left-0 top-0 items-center justify-center w-12 z-40 cursor-pointer
            ${displayNav.left ? "absolute md:hidden" : "hidden"}
        `}
      >
        <span className="material-icons">chevron_left</span>
      </div>
      <div
        onClick={moreRight}
        className={`
            flex h-full bg-gradient-to-l from-backD2 right-0 top-0 items-center justify-center w-12 z-40 cursor-pointer
            ${displayNav.right ? "absolute md:hidden" : "hidden"}
        `}
      >
        <span className="material-icons">chevron_right</span>
      </div>
    </div>
  );
};

export default MobileNavigation;
