import { Avatar, Chip } from "@mui/material";
import LinkIf from "components/ui/LinkIf";
import Pedigree from "components/ui/Pedigree";
import countryList from "constants/CountryList";
import horseColor from "constants/HorseColor";
import { BaseContext } from "contexts/BaseContext";
import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import { CircleFlag } from "react-circle-flags";
import searchText from "texts/pages/searchText";

const HorseTab = ({ currentTab, searchResult = [], loadingHistory, search_query }) => {
  const { language } = useContext(BaseContext);
  const historyOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "transparent",
        },
      },
      y: {
        display: false,
        reverse: true,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className={`flex flex-col w-full ${currentTab === 0 ? "flex" : "hidden"}`}>
      {searchResult.map((item) => (
        <LinkIf
          to={{
            pathname: `/app/horse/${item.idHorse}`,
          }}
          state={{ prev: "search", param: search_query}}
          key={item.name}
          className="no-underline text-black"
          condition={item?.name?.toLowerCase() !== "sin asignar"}
        >
          <div className="w-full bg-white border-solid border-back border-0 border-b flex justify-between px-12 py-2 items-center gap-x-4">
            <div className="flex gap-x-4 items-center">
              <Avatar
                src={`https://storage.googleapis.com/invasor-assets/horses/${item.idHorse}.webp`}
                className="h-20 w-20 bg-secondaryD1 text-4xl"
              >
                <Avatar
                  src={horseColor[item?.color || "Z"]?.img}
                  className="w-20 h-20 self-center"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </Avatar>
              <div className="flex flex-col justify-center">
                <p className="font-bold my-0 text-lg"> {item?.name} </p>
                <p className="my-0"> {item?.stable_name} </p>
                <div className="flex gap-x-2 gap-y-3 flex-wrap">
                  <Chip
                    size="small"
                    label={`${
                      horseColor[item.color]?.name || searchText[language].generic
                    }`}
                  />
                  <Chip
                    size="small"
                    label={`${searchText[language].horseGender[item.gender]}`}
                  />
                  {item.age && (
                    <Chip
                      size="small"
                      label={`${item.age} ${searchText[language].age}`}
                    />
                  )}
                  {item?.debut && !item.chartData?.data?.length && (
                    <Chip size="small" label={searchText[language].debutant} />
                  )}
                  <Chip
                    size="small"
                    avatar={
                      <CircleFlag
                        countryCode={item.country?.toLowerCase()}
                        height="12"
                      />
                    }
                    label={countryList[language][item.country]}
                  />
                </div>
              </div>
            </div>
            <div className="h-[6.25rem] w-[18.75rem] flex items-center md:mx-8">
              {loadingHistory ? (
                <div width="15.75rem h-[80px] bg-back animate-pulse" />
              ) : item.chartData?.data?.length ? (
                <Line
                  data={{
                    labels: item.chartData?.labels,
                    datasets: [
                      {
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "rgba(230,230,250,75)",
                        borderColor: "rgba(230,230,250,100)",
                        borderCapStyle: "butt",
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#1A7AC7",
                        pointBackgroundColor: "#1A7AC7",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBackgroundColor: "#1A7AC7",
                        pointHoverBorderColor: "#1A7AC7",
                        pointHoverBorderWidth: 1,
                        pointRadius: 1,
                        pointHitRadius: 7,
                        data: item?.chartData?.data,
                      },
                    ],
                  }}
                  options={historyOptions}
                  height="100"
                />
              ) : (
                !item?.debut && (
                  <p className="text-center"> {searchText[language].noData} </p>
                )
              )}
            </div>
            {item?.sire && item?.dam && (
              <div>
                <Pedigree details={item} />{" "}
              </div>
            )}
          </div>
        </LinkIf>
      ))}
    </div>
  );
};

export default HorseTab;
