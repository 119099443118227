// import Predictions from 'components/ui/Predictions';
import { RaceProvider } from 'contexts/RaceContext';
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';

const App = () => {
    return ( 
        <RaceProvider>
            <div className='flex flex-col h-auto md:min-h-screen bg-back'>
                <NavBar/>
                <Outlet/>
                {/* <Predictions/> */}
            </div>
        </RaceProvider>
    );
}
 
export default App;