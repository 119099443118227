import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { RaceContext } from "contexts/RaceContext";
import { UPDATE_LIVE_URL } from "queries/racetrack";

const VideoUrlUpdater = ({ racetrack_id = "" }) => {
  const { raceTracks } = useContext(RaceContext);
  const [videoUrl, setVideoUrl] = useState();
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (raceTracks[racetrack_id] && videoUrl === undefined && mounted) {
      setVideoUrl(raceTracks[racetrack_id]?.url_live);
    }
    return () => (mounted = false);
  }, [racetrack_id, raceTracks]);

  const [
    updateLiveUrl,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_LIVE_URL, { onError(err) {} });

  const handleChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const handleSave = () => {
    updateLiveUrl({
      variables: {
        racetrack_id: racetrack_id,
        url: videoUrl,
      },
    });
  };

  useEffect(() => {
    let mounted = true;
    if (dataUpdate && !loadingUpdate && mounted) {
      setOpenSuccess(true);
    }
    return () => (mounted = false);
  }, [dataUpdate, loadingUpdate]);

  useEffect(() => {
    let mounted = true;
    if (errorUpdate && !loadingUpdate && mounted) {
      setOpenFailure(true);
    }
    return () => (mounted = false);
  }, [errorUpdate, loadingUpdate]);

  return (
    <div className="flex items-center py-6 px-4 bg-white shadow">
      <div className="flex flex-grow">
        {videoUrl === undefined? (
          <div className="w-full h-12 bg-back animate-pulse" />
        ) : (
          <TextField
            value={videoUrl || ''}
            onChange={handleChange}
            size="medium"
            color="secondary"
            label="Link del video en vivo"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            className="w-full"
          />
        )}
      </div>
      {loadingUpdate && <CircularProgress />}
      {(videoUrl !== undefined && !loadingUpdate) && (
        <button
          className="
                            bg-primaryD1 hover:bg-primaryD2 transition-colors text-white border-none cursor-pointer
                            flex flex-shrink-0 items-center p-3 rounded-full gap-x-2 md:ml-2"
          type="button"
          onClick={handleSave}
        >
          <span className="material-icons text-white">save</span>
        </button>
      )}
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setOpenSuccess(false)}
        message="¡El url del video en vivo se guardó con éxito!"
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenSuccess(false)}
          >
            close
          </span>
        }
      />
      <Snackbar
        open={openFailure}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" } }}
        onClose={() => setOpenFailure(false)}
        message="Ocurrió un error al guardar el url del video en vivo."
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenFailure(false)}
          >
            close
          </span>
        }
      />
    </div>
  );
};

export default VideoUrlUpdater;
