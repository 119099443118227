import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ path = [] }) => {
  return (
    <div className="flex w-full py-2 px-8 bg-secondary">
      <Breadcrumbs classes={{ separator: "text-white" }}>
        {path.map((segment) => (
          <div key={segment.text}>
            {segment.href === '' ? (
              <p className="text-white my-0 select-none">{segment.text}</p>
            ) : (
              <Link
                to={segment.href}
                className="no-underline text-white hover:text-backL2 transition-all hover:underline"
              >
                {segment.text}
              </Link>
            )}
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
