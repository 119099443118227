import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseContext } from "contexts/BaseContext";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import MobileNavigation from "./MobileNavigation";
import CheckSelect from "./CheckSelect";
import generateFilters from "./generateFilters";
import applyFilters from "./applyFilters";

/*
    Filter: {
      value: "value",
      text: DisplayedText,
      active: boolean,
      important: boolean,
    }

    Category: {
        icon: React Component,
        content: ArrayOfFilter,
        text: language object,
        field: name of field to be obtained from,
        active: counter of active items,
        initTotal: counter of inital total,
        type: type of input (select or autocomplete),
        total: counter of after-filter total,
    }
*/
const FiltersComponent = ({ filtered, setFiltered, template, originalData}) => {
  const { language } = useContext(BaseContext);
  const [filters, setFilters] = useState(template);
  const containerRef = useRef(null);

  const onChangeSelect = (category, filter) => {
    let auxCategory = { ...filters?.[category] };
    let auxFilter = { ...auxCategory?.content?.[filter] };

    auxFilter.active = !auxFilter.active;
    auxFilter.important = !auxFilter.important;
    auxCategory.content[filter] = auxFilter;

    if (auxFilter.active) {
      auxCategory.active++;
    } else {
      auxCategory.active--;
    }
    const auxAllFilters = {...filters};
    auxAllFilters[category] = auxCategory;
    setFilters(auxAllFilters);
    const newData = applyFilters(originalData, auxAllFilters);
    setFiltered(newData || []);
    // applyFilters(originalData, setData, auxFilters, setFilterItems, raceTracks);
  };

  const onChangeAutocomplete = (value, category, reason) => {
    const auxCategory = { ...category };
    const auxAllFilters = {...filters};
    if(reason === 'selectOption') {
      auxCategory.selected = value;
      auxCategory.active = true;      
    } else if(reason === 'clear') {
      auxCategory.selected = {};
      auxCategory.active = false;
    }

    auxAllFilters[category.name] = auxCategory;
    setFilters(auxAllFilters);
    const newData = applyFilters(originalData, auxAllFilters);
    setFiltered(newData || []);
    // let auxCategory = { ...category };

    // if (auxFilter.active) {
    //   auxCategory.active++;
    // } else {
    //   auxCategory.active--;
    // }
    // const auxAllFilters = {...filters};
    // auxAllFilters[category] = auxCategory;
    // setFilters(auxAllFilters);
    // const newData = applyFilters(originalData, auxAllFilters);
    // setFiltered(newData || []);
  }

  const onChangeToggle = (category) => {
    if (category.name) {
      const auxFilters = {...filters};
      auxFilters[category.name].active = !auxFilters[category.name].active;
      setFilters(auxFilters);
      const newData = applyFilters(originalData, auxFilters);
      setFiltered(newData || []);
    }
  };

  const handleReset = () => {
    const newFilters = generateFilters(originalData, template);
    setFilters(newFilters);
    setFiltered(originalData);
  };

  useEffect(() => {
    let mounted = true;

    if (originalData && originalData.length && mounted) {
      const newFilters = generateFilters(originalData, template);
      setFilters(newFilters);
    }

    return () => {
      mounted = false;
    };
  }, [originalData]);

  return (
    <div className="h-full w-full flex items-center flex-col md:flex-row">
      <MobileNavigation>
        <div className="w-full flex gap-x-4 items-center h-full py-4" ref={containerRef}>
          {filters && Object.keys(filters).map((categ, index) => {
            const category = filters[categ];
            return category?.type === "select" ? (
              <CheckSelect key={categ} category={category}>
                {Object.keys(category?.content)?.map((fil) => {
                  const filter = category?.content?.[fil];
                  return (
                    <MenuItem
                      key={filter.value}
                      onClick={() => onChangeSelect(categ, fil)}
                      value={fil}
                    >
                      <Checkbox checked={filter.active} />
                      { category.render_label(filter.value, language) }
                    </MenuItem>
                  );
                })}
              </CheckSelect>
            ) : category?.type === "autocomplete" ? (
              <Autocomplete
                disablePortal
                options={Object.keys(category?.content).map((filter) => ({
                  label: category?.render_label(category?.content?.[filter]?.value, language),
                  value: category?.content?.[filter]?.value,
                }))}
                onChange={(e, value, reason) => onChangeAutocomplete(value, category, reason)}
                isOptionEqualToValue={(data)=>data.value}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="secondary"
                    placeholder={category.text?.[language]}
                    size="small"
                    variant="standard"
                    className="mx-2"
                    sx={{ width: 140 }}
                  />
                )}
              />
            ) : category?.type === "toggle" ? (
              <Chip
                label={category.text?.[language]}
                icon={category.icon || null}
                clickable
                onClick={()=>onChangeToggle(category)}
                color={category.active ? category.color : 'inactive'}
                variant='filled'
                disabled={!category.exists}
              />
            ) : null;
          })}
        </div>
      </MobileNavigation>
      <IconButton
        onClick={handleReset}
        className="p-3"
      >
        <span className="material-icons">delete_sweep</span>
      </IconButton>
    </div>
  );
};

export default FiltersComponent;
