const { useRef } = require("react");

const Card = ({
  children,
  footer,
  iconBg = "white",
  type = "default",
  containerClassName = "",
  iconClassName= "",
  icon,
  className = "",
  startAfterIcon,
}) => {
  const iconRef = useRef(null);
  const containerClasses = {
    default: "",
    icon: "",
  };
  const cardContainer = {
    default: "",
    icon: "pt-4 relative",
  };
  const colors = {
    white: "bg-white",
    primary: "bg-primaryD1",
    secondary: "bg-secondaryD1",
    gradient1:'bg-gradient-to-r from-primaryD1 to-secondaryD1 bg-gradient-195',
    gradient2:'bg-gradient-to-r from-success to-primaryD1 bg-gradient-195',
  };

  return (
    <div className={`${cardContainer[type]} ${type === 'icon'? className : ''} w-full md:w-auto`}>
      {type === "icon" && (
        <div
          className={`absolute top-0 left-4 w-aut z-20 shadow-md rounded-lg flex items-center justify-center
          ${colors[iconBg] || colors.white}
          ${iconClassName? iconClassName : ''}
          `}
          style={{ minWidth: "2rem", minHeight: "2rem" }}
          ref={iconRef}
        >
          {icon}
        </div>
      )}
      <div 
        className={`bg-white shadow-lg w-auto rounded-xl p-4 ${containerClasses[type]} ${containerClassName} ${type !== 'icon'? className : ''} w-full md:w-auto`}
        style={{
          minWidth: "10rem",
        }}
      >
        <div
          className="h-full"
          style={{
            paddingLeft:
              (type === "icon" && iconRef.current && !startAfterIcon)
                ? `${iconRef.current.clientWidth/16 + 1}rem`
                : "",
            paddingTop: 
              (type === "icon" && startAfterIcon && iconRef.current)
                ? `${iconRef.current.clientHeight/16 - 0.75}rem`
                : "",
          }}
        >
          {children}
        </div>
        {footer && (
          <>
            <div className="mx-4 bg-backL2 h-0.5 my-2" />
            {footer}
          </>
        )}
      </div>

    </div>
  );
};

export default Card;
