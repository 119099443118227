import { gql } from "@apollo/client";

export const GET_HISTORY_BY_DATES = gql`
  query ($dates: [String!]) {
    horseRaceByDates(dates: $dates) {
      distance
      course
      raceno
      category
      type
      minutes
      seconds
      milliseconds
      name
      condition
      racetrack_id
      date
      status
      competitors {
        horse_id
        hKg
        jKg
        jockey_name
        jockey_id
        bet_amt
        age
        rail
        pos
        horse_name
        wind_dist
        withdrawn
        rodo
        desmonta
      }
    }
    videoByDates(dates: $dates) {
      url
      racetrack_id
      date
    }
  }
`;
