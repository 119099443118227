import React, { useContext, useEffect, useState } from "react";

import { BaseContext } from "contexts/BaseContext";
import { RaceContext } from "contexts/RaceContext";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "queries/auth";
import { UPLOAD_PICTURE } from "queries/auth";
import { UserContext } from "contexts/UserContext";

import profileText from "texts/pages/profileText";

const UserForm = ({ userData, setUserData, setOpenDelete }) => {
  const { language } = useContext(BaseContext);
  const { refetchMe, session } = useContext(UserContext);
  const { raceTracks } = useContext(RaceContext);

  const [
    updateUser,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UPDATE_USER, { onError(e) {} });

  const [
    uploadAvatar,
    { data: dataAvatar, loading: loadingAvatar, error: errorAvatar },
  ] = useMutation(UPLOAD_PICTURE, { onError(e) {} });

  const [successOpen, setSuccessOpen] = useState(false);
  const [failureOpen, setFailureOpen] = useState(false);

  const [errors, setErrors] = useState({
    name: { ok: true, error: "" },
    lastname: { ok: true, error: "" },
    alias: { ok: true, error: "" },
  });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let finalGender = userData.gender;
    if (finalGender === "O")
      finalGender = `${userData.gender}:${userData.genderOther}`;

    let finalSurvey = userData.survey;
    if (finalSurvey === "Hipódromo")
      finalSurvey = `${userData.survey}:${userData.surveyRacetrack}`;
    if (finalSurvey === "O")
      finalSurvey = `${userData.survey}:${userData.surveyOther}`;

    const input = {
      alias: userData.alias,
      name: userData.name,
      lastname: userData.lastname,
      gender: finalGender,
      survey: finalSurvey,
      documentNumber: userData.documentNumber,
      documentType: userData.documentType,
      about: userData.about,
    };
    if (userData.image !== session?.image) {
      uploadAvatar({
        variables: {
          uploadAvatarImage: dataURLtoFile(
            userData.image,
            `${new Date().getTime()}.jpg`
          ),
        },
      });
    }
    updateUser({ variables: { userInput: input } });
  };

  useEffect(() => {
    let mounted = true;
    if (dataUser && !loadingUser && mounted) {
      setSuccessOpen(true);
      refetchMe();
    }
    return () => (mounted = false);
  }, [dataUser, loadingUser]);

  useEffect(() => {
    let mounted = true;
    if (dataAvatar && !loadingAvatar && mounted) {
      setSuccessOpen(true);
      refetchMe();
    }
    return () => (mounted = false);
  }, [dataAvatar, loadingAvatar]);

  useEffect(() => {
    let mounted = true;
    if (errorAvatar && !loadingAvatar && mounted) {
      setFailureOpen(true);
    }
    return () => (mounted = false);
  }, [errorAvatar, loadingAvatar]);

  useEffect(() => {
    let mounted = true;
    if (errorUser && !loadingUser && mounted) {
      setFailureOpen(true);
    }
    return () => (mounted = false);
  }, [errorUser, loadingUser]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setErrors({
      ...errors,
      name: { ok: true, error: "" },
      lastname: { ok: true, error: "" },
      alias: { ok: true, error: "" },
    });
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleDelete = () => {
    setOpenDelete(true);
  };

  return (
    <div className="bg-white rounded-lg w-full mt-[40px] flex flex-col px-4 pb-8">
      <div className="h-32 w-32 rounded-lg shadow-lg flex items-center justify-center flex-shrink-0 bg-secondaryD1 transform -translate-y-10">
        <span className="material-icons text-white text-7xl">person</span>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-y-6 px-2 md:px-9"
      >
        <TextField
          name="alias"
          value={userData.alias}
          onChange={handleChange}
          size="small"
          color="secondary"
          error={errors.alias && !errors.alias.ok}
          helperText={errors.alias && errors.alias.error}
          label={profileText[language].alias}
          variant="standard"
          inputProps={{ maxLength: 30 }}
          className="w-full"
        />
        <div className="w-full flex flex-col md:flex-row gap-x-4 gap-y-12">
          <div className="md:w-1/2">
            <TextField
              name="name"
              value={userData.name}
              onChange={handleChange}
              size="small"
              color="secondary"
              error={errors.name && !errors.name.ok}
              helperText={errors.name && errors.name.error}
              label={profileText[language].firstName}
              variant="standard"
              inputProps={{ maxLength: 30 }}
              className="w-full"
            />
          </div>
          <div className="md:w-1/2">
            <TextField
              name="lastname"
              value={userData.lastname}
              onChange={handleChange}
              size="small"
              color="secondary"
              error={errors.lastname && !errors.lastname.ok}
              helperText={errors.lastname && errors.lastname.error}
              label={profileText[language].lastName}
              variant="standard"
              inputProps={{ maxLength: 30 }}
              className="w-full"
            />
          </div>
        </div>
        <div className="w-full flex gap-x-4">
          <div className="w-24">
            <TextField
              name="documentType"
              select
              value={userData.documentType}
              onChange={handleChange}
              size="small"
              color="secondary"
              label={profileText[language].idType}
              variant="standard"
              className="w-full"
            >
              <MenuItem value={"DNI"}>DNI</MenuItem>
              <MenuItem value={"CI"}>Cédula</MenuItem>
              <MenuItem value={"LC"}>L.C.</MenuItem>
              <MenuItem value={"LE"}>L.E.</MenuItem>
              <MenuItem value={"Otro"}>Otro</MenuItem>
            </TextField>
          </div>
          <div className="w-full">
            <TextField
              name="documentNumber"
              value={userData.documentNumber}
              onChange={handleChange}
              size="small"
              color="secondary"
              label={profileText[language].document}
              variant="standard"
              inputProps={{ maxLength: 10 }}
              className="w-full"
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row gap-x-4 gap-y-12">
          <div className="w-full">
            <TextField
              name="gender"
              select
              value={userData.gender || ""}
              onChange={handleChange}
              size="small"
              color="secondary"
              label={profileText[language].gender}
              variant="standard"
              className="w-full"
            >
              <MenuItem value={"M"}>{profileText[language].male}</MenuItem>
              <MenuItem value={"F"}>{profileText[language].female}</MenuItem>
              <MenuItem value={"O"}>{profileText[language].other}</MenuItem>
            </TextField>
          </div>
          {userData.gender === "O" && (
            <div className="w-full">
              <TextField
                name="genderOther"
                value={userData.genderOther}
                onChange={handleChange}
                size="small"
                color="secondary"
                label={profileText[language].other}
                variant="standard"
                inputProps={{ maxLength: 20 }}
                className="w-full"
              />
            </div>
          )}
        </div>
        <div className="w-full md:flex gap-x-4">
          <div className="w-full">
            <TextField
              name="survey"
              select
              value={userData.survey || ""}
              onChange={handleChange}
              size="small"
              color="secondary"
              label={profileText[language].survey}
              variant="standard"
              className="w-full"
            >
              <MenuItem value={"Instagram"}>Instagram</MenuItem>
              <MenuItem value={"Facebook"}>Facebook</MenuItem>
              <MenuItem value={"Agencia"}>
                {profileText[language].agency}
              </MenuItem>
              <MenuItem value={"Conocido"}>
                {profileText[language].friend}
              </MenuItem>
              <MenuItem value={"Hipódromo"}>
                {profileText[language].horsetrack}
              </MenuItem>
              <MenuItem value={"O"}>{profileText[language].other}</MenuItem>
            </TextField>
          </div>
          {userData.survey && userData.survey.includes("Hipódromo") && (
            <div className="w-full">
              {/* TODO: implement Autoselect instead of select */}
              <TextField
                name="surveyRacetrack"
                select
                value={userData.surveyRacetrack || ""}
                onChange={handleChange}
                size="small"
                color="secondary"
                label={profileText[language].raceTrack}
                variant="standard"
                className="w-full"
              >
                {raceTracks &&
                  Object.keys(raceTracks).map((key) => (
                    <MenuItem
                      value={raceTracks[key].id}
                      key={raceTracks[key].id}
                    >
                      {raceTracks[key].name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          )}
          {userData.survey === "O" && (
            <div className="w-full">
              <TextField
                name="surveyOther"
                value={userData.surveyOther}
                onChange={handleChange}
                size="small"
                color="secondary"
                label={profileText[language].other}
                variant="standard"
                inputProps={{ maxLength: 20 }}
                className="w-full"
              />
            </div>
          )}
        </div>
        <TextField
          name="about"
          label={profileText[language].about}
          multiline
          color="secondary"
          inputProps={{ maxLength: 500 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <p
                  className={`${
                    userData && userData.about.length === 500
                      ? "text-danger"
                      : "text-secondaryD1"
                  }`}
                >
                  {`${(userData.about && userData.about.length) || 0}/500`}
                </p>
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="standard"
          rows={5}
          value={userData.about}
          onChange={handleChange}
        />
        <div className="flex justify-between">
          <Button variant="text" className="text-backD2" onClick={handleDelete}>
            {profileText[language].delete}
          </Button>
          {loadingUser ? (
            <CircularProgress size={72} />
          ) : (
            <IconButton className="bg-primaryD1 rounded-full" type="submit">
              <span className="material-icons text-5xl p-1 text-white">
                save
              </span>
            </IconButton>
          )}
        </div>
      </form>
      <Snackbar
        open={successOpen}
        autoHideDuration={3500}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setSuccessOpen(false)}
        message={profileText[language].success}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setSuccessOpen(false)}
          >
            close
          </span>
        }
      />

      <Snackbar
        open={failureOpen}
        autoHideDuration={3500}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" } }}
        onClose={() => setFailureOpen(false)}
        message={profileText[language].failure}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setFailureOpen(false)}
          >
            close
          </span>
        }
      />
    </div>
  );
};

export default UserForm;
