import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "contexts/BaseContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";

import { useMutation } from "@apollo/client";
import { RESEND_CONFIRM } from "queries/auth";

import signInText from "texts/pages/signInText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResendDialog = ({ open, setOpen, email }) => {
  const { language } = useContext(BaseContext);
  const [resendTimeout, setResendTimeout] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const [failureOpen, setFailureOpen] = useState(false);

  const [
    resendConfirm,
    { data: dataResend, loading: loadingResend, error: errorResend },
  ] = useMutation(RESEND_CONFIRM, { onError(err) {} });

  const formatSeconds = (seconds) => {
    let mins = 0;
    let auxSeconds = seconds;
    while (auxSeconds >= 60) {
      mins += 1;
      auxSeconds -= 60;
    }
    return `${mins > 9 ? mins : `0${mins}`}:${
      auxSeconds > 9 ? auxSeconds : `0${auxSeconds}`
    }`;
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleResend = () => {
    try {
      const cleanEmail = email?.trim()?.toLowerCase();
      resendConfirm({ variables: { email: cleanEmail } });
    } catch (e) {}
  };

  useEffect(() => {
    let mounted = true;
    if (dataResend && !loadingResend && mounted) {
      setSuccessOpen(true);
      setResendTimeout(180);
    }
    return () => (mounted = false);
  }, [dataResend, loadingResend]);

  useEffect(() => {
    var timer;
    if (resendTimeout > 0) {
      timer = setTimeout(() => {
        setResendTimeout(resendTimeout - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    let mounted = true;
    if (errorResend && !loadingResend && mounted) {
      setFailureOpen(true);
    }
    return () => (mounted = false);
  }, [errorResend, loadingResend]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogContent className="flex flex-col gap-y-4 pb-8 pt-12 justify-center items-center">
        <p className="text-backD1 my-0">{signInText[language].checkMail}</p>
        <p className="text-backD2 my-0">{signInText[language].checkSpam}</p>
        {resendTimeout > 0 && (
          <p className="text-backD1">
            {` ${signInText[language].timeout1} 
                    ${formatSeconds(resendTimeout)} 
                    ${signInText[language].timeout2}
                `}
          </p>
        )}
        {loadingResend ? (
          <CircularProgress />
        ) : (
          <Button
            className="rounded-full mx-auto"
            color="secondary"
            variant="contained"
            size="large"
            disabled={resendTimeout > 0}
            onClick={handleResend}
          >
            {signInText[language].resend}
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" className="text-backD2">
          {signInText[language].cancel}
        </Button>
      </DialogActions>

      <Snackbar
        open={successOpen}
        autoHideDuration={3500}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setSuccessOpen(false)}
        message={signInText[language].resendSuccess}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setSuccessOpen(false)}
          >
            close
          </span>
        }
      />

      <Snackbar
        open={failureOpen}
        autoHideDuration={3500}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" } }}
        onClose={() => setFailureOpen(false)}
        message={signInText[language].resendFailure}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setFailureOpen(false)}
          >
            close
          </span>
        }
      />
    </Dialog>
  );
};

export default ResendDialog;
