import React, { useContext } from "react";
import { BaseContext } from "contexts/BaseContext";

import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Rating from "@mui/material/Rating";
import horseDetailText from "texts/pages/horseText";
import { CircleFlag } from "react-circle-flags";

const MainInfo = ({ horseData = {}, horse_id = "" }) => {
  const { language } = useContext(BaseContext);
  const short_name =
    horseData.name?.split(" ")?.legth > 0
      ? `${horseData.name?.split(" ")?.[0]?.[0]}${
          horseData.name?.split(" ")?.[1]?.[0]
        }`
      : `${horseData.name?.[0]}`;
  return (
    <div className="flex flex-col">
      <div className="flex w-full">
        <div className="relative h-24 w-24">
          <Avatar
            alt={
              horseData.name?.split(" ")?.legth > 0
                ? `${horseData.name?.split(" ")?.[0]?.[0] || ""}${
                    horseData.name?.split(" ")?.[1]?.[0] || ""
                  }` ||
                  horseData.name?.[0] ||
                  ""
                : `${horseData.name?.[0] || ""}`
            }
            src={`https://storage.googleapis.com/invasor-assets/jockeys/${horse_id}.webp`}
            onContextMenu={(e) => e.preventDefault()}
            className="h-24 w-24 bg-secondary font-medium text-5xl"
          >
            {short_name}
          </Avatar>
        </div>
        <div className="px-4">
          <h3 className="text-2xl font-medium my-0">{horseData.name}</h3>
          <p className="text-gray-400 my-1">
            {horseData.ismale
              ? horseDetailText[language].jockey
              : horseDetailText[language].jocketa}
          </p>
          <div className="flex gap-2 mb-1 items-center">
            <p className="text-gray-400 leading-none my-0">
              {horseData.rating?.average || "-"}
            </p>
            <Rating
              size="small"
              precision={0.1}
              value={horseData.rating?.average || 0}
              readOnly
            />
            <p className="text-blue-500 leading-none text-sm my-0">
              {`${horseData.rating?.totalCount || horseDetailText[language].no} 
                      ${
                        horseData.rating?.totalCount === 1
                          ? horseDetailText[language].opinion
                          : horseDetailText[language].opinions
                      }`}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-start gap-x-4 gap-y-2 mt-6 mb-3 flex-wrap">
        {horseData.totalWon?.map((item, index) => (
          <Badge
            badgeContent={item.won}
            color="primary"
            key={`chip-${item.name}`}
            classes={{ anchorOriginTopRight: "top-[4px] -right-0.5" }}
          >
            <Chip
              label={item.name}
              color="secondary"
              key={`chip-win-${index}`}
              variant="filled"
              size="small"
              icon={
                <span className="text-sm material-icons leading-none mr-1 text-secondary">
                  emoji_events
                </span>
              }
            />
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default MainInfo;
