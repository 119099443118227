const priceText = { 
    en: {  
        titleOld:'Join our Invasor community',
        title:'Collaborating is the best way to support our project',
        desc1:'Inside our app you will find a',
        desc2:'button to support us.',
        priceTitle:'YOU CHOOSE THE PRICE',
        priceDesc:'For our payments, we trust in',
        invasor:'Invasor',
        descOld:'We designed a single plan for you to start enjoying your experience, in a different way.',
        free:`First ${process.env.REACT_APP_TRIAL_TIME} days`,
        paid:'Single Plan',
        register:'Sign up',
        start:'Start',
        monthly:'Monthly plan',
        free2:`FIRST ${process.env.REACT_APP_TRIAL_TIME} DAYS FREE TRIAL`,
        freeText1:'Then you would pay the price of the single plan through',
        freeText2:'We bring you the link to pay.',
        firstMonth:`the first ${process.env.REACT_APP_TRIAL_TIME} days`,
        currency:'AR$',
        
    },
    es: {
        titleOld:'Sumate a nuestra comunidad',
        title:'Colaborar es la mejor forma de apoyar nuestro proyecto',
        desc1:'Dentro de la app web, encontrarás un botón',
        desc2:'para poder hacerlo.',
        priceTitle:'EL PRECIO LO PONES VOS',
        priceDesc:'Para nuestros pagos, confiamos en la interfaz de',
        invasor:'Invasor',
        descOld:'Diseñamos un único plan para que comiences a vivir tu experiencia, de una manera diferente.',
        free:`Primeros ${process.env.REACT_APP_TRIAL_TIME} días`,
        benefit:'Beneficio',
        paid:'Plan Único',
        register:'Registrate',
        start:'Comenzar',
        monthly:'Plan mensual',
        free2:`PRIMEROS ${process.env.REACT_APP_TRIAL_TIME} DÍAS GRATIS`,
        freeText1:'Luego, abonarías el precio del plan único por',
        freeText2:'Te acercamos el enlace para abonar.',
        firstMonth:`los primeros ${process.env.REACT_APP_TRIAL_TIME} días`,
        currency:'AR$',

    },
    pt: {
        titleOld:'Conheça nossa comunidade',
        title:'Colaborar é a melhor forma de apoiar nosso projeto',
        desc1:'Dentro do aplicativo, você encontrará um botão',
        desc2:'para apoiar-nos.',
        priceTitle:'VOCÊ ESCOLHE O PREÇO',
        priceDesc:'Para nossos pagamentos, nós confiamos na interface de',
        invasor:'Invasor',
        descOld:'Desenhamos um único plano por assinatura para começar a desfrutar a sua experiência, de forma diferente.',
        free:`Os primeiros ${process.env.REACT_APP_TRIAL_TIME} dias`,
        paid:'Plano Único',
        register:'Cadastre-se',
        start:'Assinar',
        monthly:'Valor /mês',
        free2:`SEUS PRIMEIROS ${process.env.REACT_APP_TRIAL_TIME} DIAS GRÁTIS `,
        freeText1:'Após, você pagaria o preço do plano único no',
        freeText2:'Trazemos para você o link para pagar.',
        firstMonth:'o primeiro mês',
        currency:'AR$',
          
    },
}

export default priceText;