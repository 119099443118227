const racestatsText = {
    en:{
        noRacesFiltered:"NO RACES, according to applied filters.",  
    },
    es:{
        noRacesFiltered:"SIN CARRERAS, según filtros aplicados.",
    },
    pt:{
        noRacesFiltered:'SEM CORRIDAS, de acordo com os filtros aplicados.',
    },
}

export default racestatsText;