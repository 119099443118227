import { gql } from "@apollo/client/core";

export const UPDATE_HORSERATING = gql`
mutation ( $horseId:String!, $score:Float!, $comment: String)
{
  updateHorseRating(horseId:$horseId, score:$score, comment:$comment)
  {
    horse_id
    comment
    score
  }
}
`