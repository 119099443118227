import React, { useContext, useEffect, useState } from "react";
import { RaceContext } from "contexts/RaceContext";
import { BaseContext } from "contexts/BaseContext";

import { isSameDay } from "date-fns";
import RaceBanner from "components/ui/RaceBanner";
import Animations from "./Animations";
import Head from "components/ui/Head";
import { mainText } from "texts/pages/mainText";
import Table from "./Table";
import Prizes from "./Prizes";
import { MainContext } from "contexts/MainContext";
import { UserContext } from "contexts/UserContext";
import FirstLogin from "./FirstLogin";

const Main = () => {
  const { raceDate } = useContext(RaceContext);
  const { language } = useContext(BaseContext);
  const { 
    lastCalled, dayRTS, dayRaces, currentIndex, setCurrentIndex, calledRTS, loadingRTS, loadingBasicRace, RT
  } = useContext(MainContext);
  const { session } = useContext(UserContext);
  const [firstOpen, setFirstOpen] = useState(false);

  const noRaces = (!dayRTS?.list?.length && !loadingRTS && lastCalled?.rts) || (!dayRaces?.length && !loadingBasicRace && lastCalled?.basicRace);
  const time = isSameDay(new Date(), raceDate) ? 'present' : new Date() > raceDate ? 'past' : 'future';

  useEffect(() => {
    let mounted = true;
    if(session?.firstLogin && mounted) {
      setFirstOpen(true);
    }

    return () => mounted = false
  }, [session])
  
  
  return (
    <>
      <RaceBanner
        RT={RT}
        calledRT={calledRTS}
        loadingRTS={loadingRTS}
        currentRace={dayRaces?.[currentIndex]}
        dayRaces={dayRaces}
      />
      {
        noRaces ? (
          <div className="w-full bg-white flex items-center justify-center text-center h-24 px-8">
            { mainText[language][time]}
          </div>
        ):(<div className="flex flex-col 2xl:flex-row w-full h-full">
          <div className="w-full">
            {(dayRaces && dayRaces[currentIndex] && (isSameDay(lastCalled.basicRace, raceDate))) && (!loadingBasicRace && !loadingRTS) ? (
              <Head
                currentRace={dayRaces?.[currentIndex]}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                dayRaces={dayRaces}
              />
            ) : (
              <div className="flex w-full h-44 shadow-lg bg-backL2 animate-pulse rounded-lg" />
            )}
            {
              (dayRaces && dayRaces[currentIndex]) && (isSameDay(lastCalled.basicRace, raceDate)) && (!loadingBasicRace && !loadingRTS) ? (
                <Prizes currentRace={dayRaces[currentIndex]} RT={RT}/>
                ):(
                <div className="w-full bg-backL2 shadow-lg animate-pulse my-4 h-[4.75rem] md:h-10 rounded-lg"/>
              )
            }

            {(dayRaces && dayRaces[currentIndex]) && (isSameDay(lastCalled.basicRace, raceDate)) && (!loadingBasicRace && !loadingRTS) ? (
              <Table currentRace={dayRaces?.[currentIndex]}/>
            ): (
              <div className="flex w-full h-80 shadow-lg bg-backL2 animate-pulse rounded-lg"/>
            )}

          </div>
          <div className="w-full 2xl:w-[22.22rem] h-full p-4 flex-shrink-0">
            <Animations
              dayRaces={dayRaces}
              currentRace={dayRaces?.[currentIndex]}
              fullWidth
            />
          </div>
        </div>)
      }
      
      <FirstLogin
        open={firstOpen}
        setOpen={setFirstOpen}
      />
    </>
  );
};

export default Main;
