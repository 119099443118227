import React from 'react'

const ContentSkeleton = () => {
    return ( 
        <>
            <div className="w-full h-10 rounded-3xl bg-back animate-pulse"/>
            <div className="w-full h-10 rounded-3xl bg-back animate-pulse"/>
            <div className="w-full h-10 rounded-3xl bg-back animate-pulse"/>
            <div className="w-full h-10 rounded-3xl bg-back animate-pulse"/>
        </> 
    );
}
 
export default ContentSkeleton;