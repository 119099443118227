import React from 'react';
import { Outlet } from 'react-router-dom';

const Auth = () => {
    return ( <div>
        <div className='flex flex-col h-screen max-h-screen bg-gradient-to-r from-primaryD1 to-secondary'>
            <Outlet/>
        </div>
    </div> );
}
 
export default Auth;