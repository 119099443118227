import React, { useContext } from "react";
import { RaceContext } from "contexts/RaceContext";
import { BaseContext } from "contexts/BaseContext";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Expand from "components/ui/Expand";
import { add, format } from "date-fns";
import jockeyDetailText from "texts/pages/jockeyText";
import HorseColor from "constants/HorseColor";

const UpcomingMounts = ({ jockeyData, setHorsefilter }) => {
  const { language, locales } = useContext(BaseContext);
  const { raceTracks } = useContext(RaceContext);

  return (
    <>
      {Object.keys(jockeyData.next_mounts).map((mountDate, index1) => (
        <Expand
          key={`distance-expand-${index1}`}
          clickable={
            <div className="flex items-center">
              <p className="text-sm text-gray-300 my-0">
                {format(new Date(), "yyyy-MM-dd") === mountDate
                  ? jockeyDetailText[language].today
                  : format(add(new Date(), { days: 1 }), "yyyy-MM-dd") ===
                    mountDate
                  ? jockeyDetailText[language].tomorrow
                  : ""}
                &nbsp;
              </p>
              <p className="text-sm text-blue-300 my-0">
                {" "}
                {`${format(new Date(mountDate), `d 'de' MMMM yyy`, {
                  locale: locales[language],
                })}`}
                &nbsp;
              </p>
              <p className="text-sm text-blue-300 my-0">{`@${
                raceTracks[jockeyData.next_mounts[mountDate]?.[0]?.racetrack_id]
                  ?.name_s
              }`}</p>
            </div>
          }
          className="gap-y-4 flex flex-col"
          defaultOpen={index1 === 0}
          iconPosition="right"
          icon="expand_more"
        >
          {jockeyData.next_mounts[mountDate].map((horse, index2) => (
            <div key={`mount-item-${index2}`}>
              <div className="bg-gray-100 h-0.5 w-full my-1" />
              <div className="flex ml-12">
                <p className="text-xs text-blue-500 my-0">
                  {`${format(new Date(horse.date), `HH:mm`, {
                    locale: locales[language],
                  })}, ${jockeyDetailText[language].race} ${horse.raceno}`}{" "}
                  &nbsp;
                </p>
                <p className="text-xs text-gray-400 my-0">{` | ${
                  horse.distance
                }m ${
                  horse.course?.toLowerCase()?.includes("arena")
                    ? jockeyDetailText[language].sand
                    : jockeyDetailText[language].grass
                }`}</p>
              </div>
              <div className="flex items-center ml-12 mb-1 gap-2">
                <p className="text-xs text-gray-400 my-0">
                  {" "}
                  {horse.race_name}{" "}
                </p>
                {["clasico", "g1", "g2", "g3", "handicap", "especial"].includes(
                  horse.type?.toLowerCase()
                ) && (
                  <Chip
                    label={horse.type}
                    color={"secondary"}
                    className="p-0 mt-1"
                    size="small"
                    icon={
                      <span className="!text-xs material-icons leading-none">
                        emoji_events
                      </span>
                    }
                  />
                )}
              </div>
              <div className="flex w-full">
                <div className="flex w-2/3 px-2">
                  <Avatar
                    alt={
                      `${horse?.horse_name.split(" ")[0][0]}${
                        horse?.horse_name.split(" ")[1]?.[0] || ""
                      }` || ""
                    }
                    src={`https://storage.googleapis.com/invasor-assets/horses/${horse?.horse_id}.webp`}
                    className="h-10 w-10 bg-primaryL1"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <Avatar
                      alt={
                        `${horse?.horse_name.split(" ")[0][0]}${
                          horse?.horse_name.split(" ")[1]?.[0] || ""
                        }` || ""
                      }
                      src={HorseColor[horse?.color || "GENERIC"].img}
                      className="h-10 w-10 bg-primaryL1"
                    />
                  </Avatar>
                  <div className="ml-2">
                    <h4 className="text-sm font-medium my-0">
                      {horse?.horse_name}
                    </h4>
                    <h5 className="text-xs text-gray-400 my-0">{`${
                      HorseColor[horse?.color]?.name
                    }, ${horse?.age} ${jockeyDetailText[language].age}`}</h5>
                    <h5 className="text-xs text-primaryD2 my-0">
                      {horse?.stable_name}
                    </h5>
                  </div>
                </div>
                <div className="w-1/3 border-l border-gray-200 flex items-center justify-center cursor-pointer">
                  <Tooltip
                    title={jockeyDetailText[language].jockeyFilterInfo}
                    position="left"
                  >
                    <div
                      className="flex items-center justify-center my-0"
                      onClick={() => setHorsefilter(horse)}
                    >
                      <span className="material-icons text-backD1">
                        filter_alt
                      </span>
                      <span className="material-icons text-secondaryD3">
                        arrow_forward
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
        </Expand>
      ))}
    </>
  );
};

export default UpcomingMounts;
