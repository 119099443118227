import React from 'react'
import { Droppable } from 'react-beautiful-dnd';
import RailsItem from '../RailsItem';


const RailsLine = ({posText, droppableId = "", posItems = [], isDisabled}) => {
    return (
        <Droppable
            droppableId={droppableId}
            direction="horizontal"
        >
            {
                (provided, snapshot) => ( 
                <div 
                    className={`
                        w-full min-h-[2.5rem] gap-x-4 px-4 rounded-3xl flex items-center py-2 transition-colors
                        ${snapshot.isDraggingOver ? 'bg-primaryL2' : 'bg-secondary'}
                        ${isDisabled ? '!bg-backD1' : ''}
                    `}
                    ref={provided.innerRef}
                    {...provided.droppableProps}    
                >
                    {posText}
                    <div className='flex gap-x-4 gap-y-1 flex-wrap'>
                        {
                            posItems.map((item, index)=>(
                                <RailsItem 
                                    content={item}
                                    key={item.id}
                                    index={index}
                                    isDisabled={isDisabled}
                                    copy={droppableId === "rails"}
                                />
                            ))
                        }
                    </div>
                    {provided.placeholder}
                </div>
                )
            }
        </Droppable>
    );
}
 
export default RailsLine;