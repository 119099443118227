import StatusChips from "components/ui/Head/Chips/StatusChips";
import TagsChips from "components/ui/Head/Chips/TagsChips";
import { BaseContext } from "contexts/BaseContext";
import { format } from "date-fns";
import React, { useContext } from "react";
import raceNumberText from "texts/general/raceNumberText";
import Steps from "./Steps";

const Head = ({ currentRace }) => {
  const { language } = useContext(BaseContext);
  return (
    <>
      <div
        className="flex min-h-[10rem] rounded-b-lg shadow bg-white p-4 md:px-8"
      >
        <div className="flex flex-row flex-grow">
          <div className=" flex-grow flex flex-col">
            <div className="flex h-auto justify-between">
              <Steps
                total_races={currentRace.total_races}
                currentRace={currentRace}
              />
            </div>
            <div className=" flex flex-grow h-full">
            <div className="flex flex-col md:flex-row">
                <div className="flex items-start">
                <h1 className="text-secondaryD3 text-5xl font-bold md:text-6xl leading-none my-0">
                    {currentRace.raceno}
                </h1>
                <h4 className="text-secondaryD3 text-xl md:text-2xl leading-none my-0">
                    {raceNumberText[language]?.[currentRace.raceno]}
                </h4>
                </div>
                <p className="my-0 text-xl md:hidden">
                {currentRace.date &&
                    format(new Date(currentRace.date), "HH:mm")}
                </p>
            </div>
            <div className="px-4 py-2 flex flex-col md:flex-row-reverse flex-grow justify-start items-center md:items-end md:justify-between">
                <div className="flex flex-col items-center gap-y-2">
                <p className="my-0 text-lg py-0">{`${currentRace.distance}m`}</p>
                <div className="hidden md:flex">
                    {" "}
                    <StatusChips currentRace={currentRace} />{" "}
                </div>
                </div>
                <div className="flex flex-col gap-y-2 flex-grow">
                <p className="my-0 text-xl py-0">{currentRace.name}</p>
                <div className="hidden md:block">
                    {" "}
                    <TagsChips currentRace={currentRace} />{" "}
                </div>
                </div>
                <div className="md:hidden">
                {" "}
                <StatusChips currentRace={currentRace} />{" "}
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 md:hidden">
        {" "}
        <TagsChips currentRace={currentRace} />{" "}
      </div>
    </>
  );
};

export default Head;
