import { gql } from "@apollo/client";

export const GET_JOCKEY_RACES_SINCE = gql`
query HorseRacesByJockeySince($date: String!, $jockeyId: String!) {
  horseRacesByJockeySince(date: $date, jockeyId: $jockeyId) {
    date
    competitor{
      pos
    }
  }
}`;