import React, { useContext } from "react";

import { BaseContext } from "contexts/BaseContext";
import { CircleFlag } from "react-circle-flags";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import countryList from "constants/CountryList";
import { useNavigate } from "react-router-dom";

const JockeyData = ({ competitor = {}, details = {} }) => {
  const { language } = useContext(BaseContext);
  const navigate = useNavigate();
  const short_name = `${competitor.jockey_name?.split(" ")?.[0]?.[0]} ${
    competitor.jockey_name?.split(" ")?.[1]?.[0]
  }`;

  const handleRedirect = () => {
    if (
      competitor.jockey_id &&
      competitor.jockey_name?.toLowerCase() !== "sin asignar"
    ) {
      navigate(`/app/jockey/${competitor.jockey_id}`, {
        state: { prev: "detail" },
      });
    }
  };

  return (
    <div className="w-full flex gap-x-2">
      <Avatar
        alt={competitor.jockey_name}
        src={`https://storage.googleapis.com/invasor-assets/jockeys/${competitor?.jockey_id}.webp`}
        className={`h-16 w-16 bg-primaryL1 ${
          competitor.jockey_name !== "Sin Asignar"
            ? "cursor-pointer"
            : "cursor-default"
        }`}
        onContextMenu={(e) => e.preventDefault()}
        onClick={handleRedirect}
      >
        {short_name}
      </Avatar>
      <div className="flex flex-col gap-y-2 w-full">
        <div className="flex flex-col relative w-full">
          <Chip
            color="inactiveDark"
            variant="outlined"
            label={countryList[language]?.[details.country?.toUpperCase()]}
            className="text-backD2"
            size="small"
          />
          <CircleFlag
            countryCode={competitor.horse_details?.country?.toLowerCase()}
            height="12"
            className="absolute right-1.5 transform top-1/2 -translate-y-1/2"
          />
        </div>
        <Chip
          color="inactiveDark"
          variant="outlined"
          label={`${competitor.jKg}kg`}
          className="text-backD2"
          size="small"
        />
      </div>
    </div>
  );
};

export default JockeyData;
