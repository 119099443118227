import { gql } from "@apollo/client";
export const GET_HORSE_RACES_SINCE = gql`
  query HorseRacesByHorseSince($date: String!, $horseId: String!) {
    horseRacesByHorseSince(date: $date, horseId: $horseId) {
      date
      competitor {
        pos
      }
    }
  }
`;
