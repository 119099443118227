export const breadCrumbText = { 
    en: {
        main:'Home',
        detail:'Detail',
        search:'Search',
        profile:'User profile',
        jockey:"Jockey profile",
    },
    es: {
        main:'Home',
        detail:'Detalle',
        search:'Búsqueda',
        profile:'Perfil de usuario',
        jockey:"Perfil de jockey",
    },
    pt: {
        main:'Home',
        detail:'Detalhe',
        search:'Procurar',
        profile:'Perfil de usuário',
        jockey:"Perfil de jockey",
    },
}
