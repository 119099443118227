import React, { useContext } from "react";
import { BaseContext } from "contexts/BaseContext";
import { RaceContext } from "contexts/RaceContext";

import conditionTags from "utilities/json/conditionTags.json";
import weightTags from "utilities/json/weightTags.json";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Chip from "@mui/material/Chip";
import AccordionDetails from "@mui/material/AccordionDetails";
import WeatherIcon from "components/ui/WeatherIcon";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import searchText from "texts/pages/searchText";
import { format } from "date-fns";

// import EyeIcon from "assets/icons/png/eye-arrow-left.png";
import { ReactComponent as EyeIcon } from "assets/icons/svg/eye-arrow-left.svg";
import { useNavigate } from "react-router-dom";

const RaceTab = ({ currentTab, searchResult = [] }) => {
  const navigate = useNavigate();
  const { language, locales, setRedirect } = useContext(BaseContext);
  const { raceTracks, setRaceDate } = useContext(RaceContext);

  const handleRedirect = (redirDate, raceno, racetrack_id) => {
    // updateDayRaces();
    if (redirDate) {
      setRedirect({ raceno: raceno, racetrack_id: racetrack_id });
      setRaceDate(new Date(redirDate));
      navigate('/app/main');
    }
  };
  return (
    <div
      className={`flex w-full flex-col ${currentTab === 2 ? "flex" : "hidden"}`}
    >
      {searchResult.map((race, i) => (
        <div className="w-full bg-white border-solid border-back border-0 border-b flex justify-between items-center gap-x-4">
          <Accordion key={`acc-${i}`} className="w-full shadow-none">
            <AccordionSummary
              expandIcon={<span className="material-icons">expand_more</span>}
              className="px-4 pr-4 py-2"
            >
              <div className="w-full">
                <div className="w-full relative px-2 py-0 block md:flex items-center justify-between">
                  <div className="flex items-center gap-x-3">
                    <img
                      src={`https://storage.googleapis.com/invasor-assets/racetrack/${race?.racetrack_id}/logo.png`}
                      alt="img"
                      onContextMenu={(e) => e.preventDefault()}
                      className="block w-12 md:w-20 h-auto filter invert"
                    />
                    <div className="flex flex-col items-start min-w-[10rem]">
                      <p className="my-0">
                        {" "}
                        {raceTracks[race.racetrack_id]?.name}{" "}
                      </p>
                      <p className="text-lg my-0 font-bold"> {race.name} </p>
                      <div className="flex gap-x-2 items-center justify-start">
                        <p className="my-0 font-bold">
                          {" "}
                          {`${race?.distance}m`}{" "}
                        </p>
                        <Chip
                          label={
                            race?.course?.includes("Cesped")
                              ? searchText[language].grass
                              : searchText[language].sand
                          }
                          size="small"
                          color={
                            race?.course?.includes("Cesped") ? "grass" : "sand"
                          }
                        />
                        {((race.type && race.type !== "L") ||
                          race.destacada) && (
                          <Chip
                            label={
                              race.destacada
                                ? ""
                                : searchText[language][race.type.toLowerCase()]
                            }
                            color="secondary"
                            size="small"
                            avatar={
                              <span className="material-icons !text-base !bg-transparent rounded-full flex items-center justify-center">
                                emoji_events
                              </span>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-x-2 gapy-y-2 pr-4">
                    {race?.condition &&
                      conditionTags["es"][race?.condition?.toLowerCase()] &&
                      Object.values(
                        conditionTags["es"][race?.condition?.toLowerCase()]
                      ).map(
                        (tag, index) =>
                          tag &&
                          index > 0 && (
                            <Chip
                              label={tag}
                              color={
                                race?.course?.includes("Cesped")
                                  ? "grass"
                                  : "sand"
                              }
                              size="small"
                            />
                          )
                      )}
                    {race?.weight &&
                      weightTags[language][race?.weight] &&
                      Object.values(weightTags[language][race?.weight]).map(
                        (tag, index) =>
                          tag &&
                          index > 0 && (
                            <Chip
                              label={tag}
                              color={
                                race?.course?.includes("Cesped")
                                  ? "grass"
                                  : "sand"
                              }
                              key={`wgh-${index}`}
                              size="small"
                            />
                          )
                      )}
                  </div>
                </div>
                <p className="my-0 absolute top-1/2 right-[2.5rem] -translate-y-1/2 text-backD1">
                  {race.items?.length || "0"}
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full">
                <Timeline align="alternate">
                  {race.items.map((item, j) => (
                    <TimelineItem
                      key={`race-${j}`}
                      className="cursor-pointer"
                      onClick={() => {
                        handleRedirect(
                          item.date,
                          item.raceno,
                          item.racetrack_id
                        );
                      }}
                    >
                      <TimelineOppositeContent>
                        <div className="inline-flex gap-x-2 items-start">
                          <p className="my-0 text-backD1">
                            {format(
                              new Date(item.date),
                              language === "en"
                                ? "MMM dd yyyy HH:mm"
                                : "dd MMM yyyy HH:mm",
                              { locale: locales[language] }
                            )}
                          </p>
                          {item.date &&
                            new Date(item.date) > new Date("2019-08-01") && (
                              <EyeIcon className="h-6 w-6 fill-secondaryD1 hover:fill-secondaryD2 transition-colors" />
                            )}
                        </div>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          variant={
                            j + 1 < race.items.length ? "default" : "outlined"
                          }
                          color={
                            item.course?.includes("Arena")
                              ? "primary"
                              : "secondary"
                          }
                          // className="border-solid"
                          classes={{
                            defaultPrimary: "bg-sand border-sand",
                            outlinedPrimary:
                              "border-solid border-2 border-sand",
                            defaultSecondary: "bg-grass border-grass",
                            outlinedSecondary:
                              "border-solid border-2 border-grass",
                          }}
                        />
                        {j + 1 < race.items.length && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <div className="inline-flex items-start">
                          {item.weather && item.weather.weatherCode && (
                            <WeatherIcon
                              weatherCode={item.weather.weatherCode}
                              size={24}
                              color="#1A7AC7"
                              className="m-0 p-0 leading-none"
                            />
                          )}
                          {item.weather && item.weather.tempC && (
                            <p className="my-0 text-back-d2">
                              {item.weather.tempC}°C
                            </p>
                          )}
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default RaceTab;
