
import A from 'assets/horses/A.webp';
import AT from 'assets/horses/AT.webp';
import M from 'assets/horses/M.webp';
import R from 'assets/horses/R.webp';
import T from 'assets/horses/T.webp';
import Z from 'assets/horses/Z.webp';
import ZC from 'assets/horses/ZC.webp';
import ZD from 'assets/horses/ZD.webp';
import ZN from 'assets/horses/ZN.webp';

const horseColor = {
  A:{
    img:A,
    name:"Alazan",
  },
  AT:{
    img:AT,
    name:"Alazan tostado",
  },
  TA:{
    img:AT,
    name:"Alazan tostado",
  },
  M:{
    img:M,
    name:"Moro",
  },
  R:{
    img:R,
    name:"Ruano rosillo",
  },
  T:{
    img:T,
    name:"Tordillo",
  },
  Z:{
    img:Z,
    name:"Zaino",
  },
  ZC:{
    img:ZC,
    name:"Zaino colorado",
  },
  ZD:{
    img:ZD,
    name:"Zaino doradillo",
  },
  ZN:{
    img:ZN,
    name:"Zaino negro",
  },
  GENERIC:{
    name:"Genérico",
    img:Z,
  }
}; 


export default horseColor;