const homeText = {
    en: {  
        transformTitle:'Digital transformation ',
        transformTitle2:'has arrived to stay',
        squareTitle1:'An elegant tool ',
        squareTitle2:'Continuously improving ',
        squareTitle3:'That connects passions ',
        squareTitle4:'Ensuring quality',
        squareTitle5:'Innovating and leading ',
        squareTitle6:'From home',
        squareDesc1:'To enjoy analyzing the data, online or offline.',
        squareDesc2:'Thanks to each feedback received, considering opinions, listening and evaluating.',
        squareDesc3:'Taking care of your most valuable assets such as your time and your experience. ',
        squareDesc4:'In the most efficient way thanks to our talents with expertise in data science and digital transformation. ',
        squareDesc5:'Adding value to the community and the industry, permanently, relying on the latest technological trends and best practices.',
        squareDesc6:'A product designed with talent, pride and passion.',
        weAre:'We are a company focused on designing innovative products for the horseracing industry, enhancing experiences, turning them into unique and exciting moments.',
        title1:'Welcome to a unique experience.',
        title2: <> <b>Invasor</b>, where <b>you choose the price</b></>,
        sub1:'We designed an innovative platform that will allow you to analyze horseracing data in an efficient and fun way, increasing your chances of winning.',
        from:'From Argentina,',
        to:'to your home.',
        mail:'Write your email here',
        discover:'Discover us ',
        trial:'By entering your e-mail you will be able to access',
        freeMonth:`${process.env.REACT_APP_TRIAL_TIME} days of FREE trial`,
        transform:'La transformación digital de la industria del turf está en camino [EN]',
        soon:'COMING SOON IN: ',
        findUs:'Also find us',
        signIn1:'If you already have an account,',
        signIn2:'login',
        register:'FREE SIGN UP',
        
        //navbar
        language:'Language',
        spanish: 'Spanish',
        english: 'English',
        portuguese: 'Portuguese',
        logout: 'Log out',
        date: 'Date',
        notifications: 'Notifications',
        price:'Pricing',
        collaborate:'Collaborate',
        contact:'Contact us',
        login:'Log-in',
        //navbar end
    },
    es: {  
        transformTitle:'La transformación digital',
        transformTitle2:'llegó para quedarse',
        squareTitle1:'Una herramienta elegante',
        squareTitle2:'Mejorando continuamente',
        squareTitle3:'Que conecta pasiones',
        squareTitle4:'Asegurando calidad',
        squareTitle5:'Innovando y liderando',
        squareTitle6:'Desde casa',
        squareDesc1:'Para disfrutar analizando los datos, con o sin conexión.',
        squareDesc2:'Gracias a cada feedback recibido, considerando opiniones, escuchando y valorando.',
        squareDesc3:'Cuidando de sus activos más valiosos como su tiempo y su experiencia.',
        squareDesc4:'De la manera más eficiente gracias a nuestros talentos con expertise en ciencia de datos y transformación digital.',
        squareDesc5:'Agregando valor a la comunidad y a la industria, permanentemente, apoyándonos en las últimas tendencias tecnológicas y mejores prácticas.',
        squareDesc6:'Un producto diseñado en Argentina con talento, orgullo y pasión.',
        weAre:'Somos una empresa focalizada en diseñar productos innovadores para la industria del turf, potenciando experiencias, convirtiéndolas en momentos únicos y emocionantes.',
        title1:'Bienvenido a una experiencia única.',
        title2: <> <b>Invasor</b>, donde <b>el precio lo ponés vos</b></>,
        sub1:'Diseñamos una plataforma innovadora que te permitirá analizar los datos de las carreras de una manera eficiente y divertida, aumentando tus chances de ganar.',
        from:'De Argentina,',
        to:'al mundo.',
        mail:'Ingresa tu correo electrónico',
        discover:'Descubrinos',
        trial:'Ingresando tu email podrás acceder a',
        freeMonth:`${process.env.REACT_APP_TRIAL_TIME} días de prueba GRATIS`,
        transform:'La transformación digital de la industria del turf está en camino',
        soon:'PRÓXIMAMENTE EN:',
        findUs:'Encontranos también por',
		signIn1:'Si ya tenés cuenta,',
        signIn2:'iniciá sesión',      
        register:'REGISTRATE GRATIS', 

        //navbar
        language:'Idioma',
        spanish: 'Español',
        english: 'Inglés',
        portuguese: 'Portugués',
        profile:'Perfil',
        logout: 'Cerrar Sesión',
        date: 'Fecha',
        notifications: 'Notificaciones',
        price:'Precios',
        collaborate:'Colaborar',
        contact:'Contactanos',
        login:'Ingresar', 
        //navbar end

    },
    pt: {  
        transformTitle:'Transformação digital ',
        transformTitle2:'chegou para ficar ',
        squareTitle1:'Uma ferramenta elegante',
        squareTitle2:'Melhorando continuamente',
        squareTitle3:'Conectando paixões ',
        squareTitle4:'Garantindo qualidade ',
        squareTitle5:'Inovando e liderando ',
        squareTitle6:'Da nossa casa',
        squareDesc1:'Desfrutar da análise de dados, online ou offline.',
        squareDesc2:'Considerando seu feedback, suas opiniões, ouvindo e avaliando.',
        squareDesc3:'Nós cuidamos de seus ativos mais valiosos, como seu tempo e sua experiência.',
        squareDesc4:'Eficientes, com experiência sólida em ciência de dados e transformação digital.',
        squareDesc5:'Agregamos o que gera valor para nossa comunidade e para a indústria, trabalhando com as melhores tecnologias, focados nas boas práticas.',
        squareDesc6:'Um produto desenhado com talento, orgulho e paixão.',
        weAre:'Somos uma empresa focada em desenvolver produtos inovadores para a indústria do Turfe, realçando experiências, transformando-as em momentos únicos e emocionantes. ',
        title1:'Bem-vindo a uma experiência única.',
        title2: <> <b>Invasor</b>, onde <b>você coloca o preço</b></>,
        sub1:'Desenhamos uma plataforma inovadora que lhe permitirá analisar os dados da corrida de forma eficiente e divertida, aumentando as suas chances de ganhar.',
        from:'Da Argentina,',
        to:'para onde você esteja.',
        mail:'Digite seu e-mail',
        discover:'Descubra-nos',
        trial:'Digite seu e-mail, e acesse a',
        freeMonth:`${process.env.REACT_APP_TRIAL_TIME} dias de teste GRATUITO`,
        transform:'A transformação digital da indústria do Turfe está em andamento', 
        soon:'EM BREVE:',
        findUs:'Também pode nos encontrar em',
        signIn1:'Se já possui uma conta,',
        signIn2:'faça login',
        register:'CADASTRE-SE GRÁTIS',

        //navbar
        language:'Idioma',
        spanish: 'Espanhol',
        english: 'Inglês',
        portuguese: 'Português',
        logout: 'Fechar Sessão',
        date: 'Data',
        notifications: 'notificações',
        collaborate:'Colaborar',
        price:'Preços',
        contact:'Entre em contato',
        login:'Acessar',
        //navbar end

    },
}

export default homeText