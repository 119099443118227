import React from "react";

const UnsavedClassicStep = ({ icon, dayRaces, currentIndex }) => {
  const sand = dayRaces?.[icon]?.course?.toLowerCase()?.includes("arena");

  return (
    <div>
      {currentIndex === icon ? (
        <span className="text-danger material-icons text-lg md:text-2xl leading-none group-hover:scale-125 transition-all">
          emoji_events
        </span>
      ) : currentIndex > icon || currentIndex === icon ? (
        <span className="material-icons text-lg md:text-2xl text-red-400 leading-none group-hover:scale-125 transition-all">
          emoji_events
        </span>
      ) : (
        <span className="material-icons text-lg md:text-2xl text-dangerL1 leading-none group-hover:scale-125 transition-all">
          emoji_events
        </span>
      )}
    </div>
  );
};

export default UnsavedClassicStep;