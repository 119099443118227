import { Splide, SplideTrack } from '@splidejs/react-splide';
import React from 'react';
import Slide1 from './Slides/Slide1';
import Slide2 from './Slides/Slide2';
import Slide3 from './Slides/Slide3';
import Slide4 from './Slides/Slide4';
import '@splidejs/react-splide/css';
import Footer from './Footer';

const Home = () => {
    const options = {
        type:"loop"
    };

    return ( 
        <div className='flex-grow relative'>
            <Footer/>
            <Splide 
                options={options}
                hasTrack={ false } 
                className="h-full"
            >
                <SplideTrack className="h-full">
                    <Slide1 />
                    <Slide2 />
                    <Slide3 />
                    <Slide4 />
                </SplideTrack>
            </Splide>
        </div> 
    );
}
 
export default Home;