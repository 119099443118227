import React, { useContext } from "react";
import { UserContext } from "contexts/UserContext";
import { MainContext } from "contexts/MainContext";
import { RaceContext } from "contexts/RaceContext";
import { BaseContext } from "contexts/BaseContext";

import { format } from "date-fns";
import html2canvas from "html2canvas";
import IconButton from "@mui/material/IconButton";

import { ReactComponent as Whatsapp } from "assets/icons/svg/whatsapp.svg";
import { getShortUrl } from "../functions";
import { predictionsText } from "texts/components/predictionsText";
import WeatherIcon from "components/ui/WeatherIcon";

const TopInfo = ({
  setInfoSnack,
  handleSave,
  dataPrediction,
  predictionsRef,
  isEmpty,
}) => {
  const { language } = useContext(BaseContext);
  const { RT, currentRace } = useContext(MainContext);
  const { raceTracks } = useContext(RaceContext);
  const { session } = useContext(UserContext);

  const course = currentRace?.course?.toLowerCase()?.includes("arena")
    ? "sand"
    : "grass";

  const handleShare = () => {
    handleSave();
    const short_url = getShortUrl(dataPrediction?.predictionByRace);
    let message = `${predictionsText[language].share1} ${currentRace?.raceno} `;
    message += `${predictionsText[language].share2} ${raceTracks?.[RT]?.name}: `;
    message += `https://dev.invasor.ar/landing/${short_url}`;
    window.open(`https://wa.me/send?text=${message}`);
  };

  const handleDownload = () => {
    handleSave();
    const short_url = getShortUrl(dataPrediction?.predictionByRace);
    html2canvas(predictionsRef.current, {
      onclone: (document) => {
        const element = document.getElementById("button-container");
        element.parentNode.removeChild(element);
      },
    })?.then((canvas) => {
      const a = document.createElement("a");
      a.href = canvas.toDataURL();
      a.download = `${format(
        new Date(currentRace?.date),
        "yyyyMMdd_HHmm"
      )}_${RT}_${session?.alias}_${short_url}.png`;
      a.click();
    });
  };

  const intermediaryHandler = (callback) => {
    if (isEmpty) {
      setInfoSnack((infoSnack) => ({
        ...infoSnack,
        text: predictionsText[language].emptyPred,
        display: true,
      }));
    } else {
      callback();
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <p className="my-0 leading-none text-xl font-roboto font-bold text-backD2">
          Mis predicciones
        </p>
        <div className="flex items-center" id="button-container">
          <IconButton onClick={() => intermediaryHandler(handleDownload)}>
            <span className="material-icons text-backD2">download</span>
          </IconButton>
          <IconButton onClick={() => intermediaryHandler(handleShare)}>
            <Whatsapp className="h-5 w-5 m-1 fill-backD2" />
          </IconButton>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="my-0 text-base font-roboto text-backD1">
          {raceTracks[RT]?.name}
        </p>
        {currentRace?.weatherCode && (
          <WeatherIcon
            weatherCode={currentRace.weatherCode}
            size={24}
            color="#C4C4C4"
            className="!m-0"
          />
        )}
      </div>
      <div className="flex justify-between">
        <p className="my-0 text-base font-roboto text-backD1">
          {currentRace?.date && format(new Date(currentRace?.date), "dd/MM/yy")}
        </p>
        {currentRace?.weatherTemp && (
          <p className="my-0 text-base font-roboto text-backD1">{`${currentRace.weatherTemp}°C`}</p>
        )}
      </div>

      <div className="flex h-[1px] bg-back mx-4 my-2" id="divider" />

      <div className="flex justify-between">
        <p className="my-0 text-xl font-roboto font-bold text-secondaryD3">
          {`${predictionsText[language].race} ${currentRace?.raceno || ""}`}
        </p>
        <p className="my-0 text-xl font-roboto text-secondaryD3">
          {currentRace?.date && format(new Date(currentRace?.date), "HH:mm")}
        </p>
      </div>
      <div className="flex justify-between">
        <p className="my-0 text-base font-roboto text-backD2">
          {currentRace?.distance && `${currentRace?.distance}m`}
        </p>

        <p
          className={`
                my-0 text-base font-roboto text-white px-1
                ${course === "sand" ? "bg-sand" : "bg-grass"}
            `}
        >
          {predictionsText[language][course]}
        </p>
      </div>
    </div>
  );
};

export default TopInfo;
