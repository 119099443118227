import { gql } from "@apollo/client";

const VERIFY_CHANGE_PASSWORD = gql`
mutation VerifyChangeMutation($token: String!) {
  verifyForgotPassword(token: $token) {
    email
    id
  }
}
`;

export default VERIFY_CHANGE_PASSWORD;