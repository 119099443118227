import Slider from '@mui/material/Slider';
import React, { useState } from 'react';

const CompSlider = ({competitors = [], compIndex, setCompIndex}) => {
    const [currentSlider, setCurrentSlider] = useState(compIndex);

    const handleChange = (event, value) => {
        setCurrentSlider(value);
    }

    const handleCommited = (event, value) => {
        setCompIndex(value);
        // setCurrentSlider(value);
    }

    return ( 
        <div className='w-full px-8'>
            <Slider
                defaultValue={0}
                color="secondary"
                valueLabelDisplay="auto"
                valueLabelFormat={(val) => `${competitors[val]?.rail}`}
                step={1}
                value={currentSlider}
                marks
                min={0}
                max={competitors.length - 1}
                onChange={handleChange}
                onChangeCommitted={handleCommited}
                classes={{
                    track:"h-3",
                    rail:'h-3'
                }}
            />    
        </div> 
    );
}
 
export default CompSlider;