import { Avatar } from "@mui/material";
import LinkIf from "components/ui/LinkIf";
import React from "react";
import { CircleFlag } from "react-circle-flags";

const JockeyTab = ({ currentTab, searchResult = [], search_query }) => {
  return (
    <div className={`flex flex-col ${currentTab === 1 ? "flex" : "hidden"}`}>
      {searchResult.map((item, index) => (
        <LinkIf
          to={{
            pathname: `/app/jockey/${item.idJockey}`,
          }}
          state= {{ prev: "search", param: search_query }}
          key={item?.name}
          className="no-underline text-black"
          condition={item?.name?.toLowerCase() !== "sin asignar"}
        >
          <div className="w-full bg-white border-solid border-back border-0 border-b flex px-12 py-2 justify-between items-center gap-x-4">
            <div className="flex items-center gap-x-4">
              <Avatar
                src={`https://storage.googleapis.com/invasor-assets/jockeys/${item?.idJockey}.webp`}
                className="h-20 w-20 bg-secondaryD1 text-4xl my-0"
              >
                {`${item?.name?.split(" ")?.[0]?.[0]}${
                  item?.name?.split(" ")?.[1]?.[0]
                }`}
              </Avatar>
              <div className="flex flex-col items-start gap-y-1">
                <p className="font-bold text-lg my-0"> {item?.name} </p>
                <p className="my-0"> {item?.stable_name} </p>
                <CircleFlag
                  countryCode={item?.country?.toLowerCase()}
                  height="30"
                />
              </div>
            </div>
            <div className="flex justify-start items-center mt-8 md:mt-0 w-auto md:w-[35.7rem]">
              {item.racetracks?.map((rt) => (
                <div className="mx-4">
                  <img
                    key={`rt-${rt}`}
                    src={`https://storage.googleapis.com/invasor-assets/racetrack/${rt}/logo.png`}
                    className="block w-12 md:w-20 h-auto filter invert"
                  />
                </div>
              ))}
            </div>
          </div>
        </LinkIf>
      ))}
    </div>
  );
};

export default JockeyTab;
