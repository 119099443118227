import React, { useContext, useState } from 'react';

import IconButton from "@mui/material/IconButton"
import logo_mobile from "assets/icons/logo_mobile.webp";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { BaseContext } from 'contexts/BaseContext';
import { Link, useNavigate } from "react-router-dom";
import navBarText from 'texts/components/navbarText';

const SearchBarMobile = () => {
    const { language } = useContext(BaseContext);
    const navigate = useNavigate();

    const [displaySearch, setDisplaySearch] = useState(false);
    const [search, setSearch] = useState("");
    const [error, setError] = useState({ok:true, error:""});
    
    const submitSearch = (e) => {
        e.preventDefault();
        let clean = search.trim();
        clean = clean.replace(/\s+/g, ' ');
        if(search.length < 3) {
            setError({
                ok:false,
                error: navBarText[language].searchError
            });
        } else {
            navigate(`/app/search/${clean}`)
        }
    }

    const openSearch = () => {
        setDisplaySearch(true);
    };

    const closeSearch = () => {
        setSearch('');
        setError({
            ok:true,
            error:''
        });
        setDisplaySearch(false);
    };

    const onChangeSearch = ({target}) => {
        if(!error.ok) {
            setError({ ok:true, error: ""});
        }
        setSearch(target.value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ0-9@\s]/g,''))
    }

    return ( 
    <>
    {
        !displaySearch &&
        <Link
          to="/app/main"
          className="absolute left-1/2 transform -translate-x-1/2"
        >
          <img
            src={logo_mobile}
            alt="invasor_logo"
            className="h-14 block"
          />
        </Link>
    }
        <form
            onSubmit={submitSearch} 
            className={`
                flex items-center justify-end transition-[width] duration-1000
                ${displaySearch ? 'w-full relative' : 'absolute left-0 w-0'}    
            `}
        >
            <input
                id="search-input"
                placeholder={displaySearch? navBarText[language].searchPlaceholder : ''}
                value={search}
                onChange={onChangeSearch}
                className={`
                    border-backD1 rounded-md min-w-0 text-backD2 transition-[width] duration-1000 bg-transparent
                    ${error && !error.ok ? 'border-danger text-danger' : '' }
                    ${displaySearch ? 'flex-grow py-2 pr-12 pl-4 border' : 'flex-grow-0 p-0 border-0'}
                `}
                maxLength="70"
            />
            {
                (error && !error.ok && displaySearch) &&
                <span className='text-danger'>{navBarText[language].searchError}</span>
            }
            {
                displaySearch &&
                <IconButton 
                    className="absolute right-9 cursor-pointer p-0 z-40 text-backD2 material-icons"
                    onClick={closeSearch}
                >
                    close
                </IconButton>
            }
            {
                displaySearch &&
                <IconButton 
                    className="absolute right-2 p-0 text-backD2 material-icons" 
                    type='submit'
                >
                    search
                </IconButton>
            }
        </form>
        {
            !displaySearch &&
            <IconButton
                onClick={openSearch}
                className="material-icons p-2"
            >
                search
            </IconButton>
        }
    </>
    );
}
 
export default SearchBarMobile;