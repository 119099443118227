import React, { useContext } from "react";

import { Tab, Tabs } from "@mui/material";
import { BaseContext } from "contexts/BaseContext";
import searchText from "texts/pages/searchText";

const TabsHeader = ({
  currentTab,
  setCurrentTab,
  loading,
  searchData = {},
}) => {
  const { language } = useContext(BaseContext);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className="bg-secondary flex">
      {loading ? (
        <Tabs value={0} onChange={handleChange}>
          <Tab label={<div className="w-24 h-6 bg-back animate-pulse" />} />
          <Tab label={<div className="w-24 h-6 bg-back animate-pulse" />} />
          <Tab label={<div className="w-24 h-6 bg-back animate-pulse" />} />
        </Tabs>
      ) : (
        <Tabs value={currentTab} onChange={handleChange}>
          <Tab
            label={`${searchText[language].horses} (${searchData.horse?.length || 0})`}
            className={`text-white px-8 ${searchData.horse?.length ? 'flex' : 'hidden'}`}
          />

          <Tab
            label={`${searchText[language].jockeys} (${searchData.jockey?.length || 0})`}
            className={`text-white px-8 ${searchData.jockey?.length ? 'flex' : 'hidden'}`}
          />

          <Tab
            label={`${searchText[language].race} (${searchData.race?.length || 0})`}
            className={`text-white px-8 ${searchData.race?.length ? 'flex' : 'hidden'}`}s
          />
        </Tabs>
      )}
    </div>
  );
};

export default TabsHeader;
