import { gql } from "@apollo/client";

export const UPDATE_JOCKEYRATING = gql`
  mutation ($jockeyId: String!, $score: Float!, $comment: String) {
    updateJockeyRating(jockeyId: $jockeyId, score: $score, comment: $comment) {
      jockey_id
      comment
      score
    }
  }
`;
