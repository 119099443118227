import React from 'react';

import Rails from 'constants/Rails';
import Uniform from './Uniform';
import Pedigree from 'components/ui/Pedigree';
import Chip from '@mui/material/Chip';
import HorseData from './HorseData';
import JockeyData from './JockeyData';

const CompetitorSection = ({competitor = {}, details = {}}) => {

    return ( 
        <div className='flex w-full'>
            <div className='flex-shrink-0 relative flex items-center'>
                <Uniform
                    id={competitor.stable_id} 
                    gender={details.ismale?'male':'female'}
                />
            </div>
            <div className='w-full flex flex-col gap-y-4 items-end p-6'>
                <div className='flex gap-x-2 items-center'>
                    <p className='my-0 text-sm text-backD2 font-bold'>{competitor.horse_name}</p>
                    <img
                        alt={`numbers/${competitor.rail}`}
                        src={Rails[competitor.rail || 1]}
                        className="h-6 w-6 flex-shrink-0"
                    />
                </div>
                <div className='text-xs'>
                    <Pedigree details={competitor.horse_details} />
                </div>
                <Chip 
                    color="primary" 
                    label={competitor.stable_name} 
                    className='w-full'
                />

                <div className='w-full flex flex-col gap-y-8'>
                    <HorseData competitor={competitor}/>
                    <p className='my-0 text-sm font-bold text-end'>{competitor.jockey_name}</p>
                    <JockeyData 
                        competitor={competitor} 
                        details={details}
                    />
                </div>
            </div>
        </div>
     );
}
 
export default CompetitorSection;