import { gql } from "@apollo/client/core";

export const GET_HISTORY_BY_DATE_RT = gql`
  query HorseRaceDateRT($date: String!, $racetrack_id: String!) {
    horseRaceDateRT(date: $date, racetrack_id: $racetrack_id) {
      id
      competitors {
        horse_id
        pos
        desmonta
        rodo
        withdrawn
        history(date:$date){
          racetrack_id
          date
          distance
          competitor{
            pos
            rodo
            desmonta
            withdrawn
          }
        }
      }
    }
  }
`;
