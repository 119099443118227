const getVideoId = (url) => {
    let videoId = '';
    if (url) {
        if (url.includes('v=')) {
            videoId = url.split('v=')[1]
        }
        else if (url.includes('be/')) {
            videoId = url.split('be/')[1]
        }
    }
    return videoId;
}

export default getVideoId;