import ArrowButton from "components/ui/ArrowButton";
import React from "react";
import HistoryTable from "./HistoryTable";
import VideoCard from "./VideoCard";

const LowerSection = ({
  historyIndex,
  setHistoryIndex,
  historyDetail = [],
  videosDetail = [],
  currentComp = {},
  loading,
}) => {
  return (
    <div className="h-[60%] w-full flex flex-col gap-y-2">
      <div className="w-full h-10 rounded-lg bg-secondary flex items-center justify-between px-4">
        <ArrowButton
          disabled={historyIndex === 0}
          setVal={setHistoryIndex}
          direction="left"
          size="sm"
        />
        <div className="flex items-center gap-x-2">
          {historyDetail?.map((item, index) => (
            <div
              className={`rounded-full h-2 w-2 ${
                index === historyIndex ? "bg-secondaryD1" : "bg-backL2"
              }`}
              key={`step-${index}`}
            />
          ))}
        </div>
        <ArrowButton
          disabled={historyIndex === historyDetail?.length - 1}
          setVal={setHistoryIndex}
          direction="right"
          size="sm"
        />
      </div>
      <div className="h-full w-full flex flex-col lg:flex-row items-center gap-x-4 gap-y-2">
        <div className="w-full lg:w-1/3 h-80 lg:h-full">
          {
            (!historyDetail?.[historyIndex] || loading )? (
              <div className="h-full w-full rounded-lg bg-backL2 animate-pulse"/>
            ) : (
            <VideoCard
              videoData={videosDetail?.[historyIndex]}
              historyData={historyDetail?.[historyIndex]}
            />)
          }
        </div>
        <div className="w-full lg:w-2/3 h-96 lg:h-full bg-white rounded-lg">
          {
            (!historyDetail?.[historyIndex] || loading) ? (
              <div className="h-full w-full rounded-lg bg-backL2 animate-pulse"/>
            ) : (
              <HistoryTable
                historyData={historyDetail?.[historyIndex]}
                currentComp={currentComp}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default LowerSection;
