import { ReactComponent as HorseIcon } from "assets/icons/svg/horse-riding.svg";
import { ReactComponent as DistanceIcon } from "assets/icons/svg/road-map.svg";
import { ReactComponent as SearchIcon } from "assets/icons/svg/search-location.svg";
import { ReactComponent as WeatherIcon } from "assets/icons/svg/weatherIcon.svg";
import { filtersTextAlt } from "texts/components/filtersText";
import { weatherCodesAlter } from "utilities/objects/weatherByCode";

const detailTemplate = (raceTracks = {}) => {
  return {
    grass: {
      text: filtersTextAlt.grass,
      field: "course",
      color: "grass",
      name: "grass",
      targetFunction: (field = "") => !String(field)?.toLowerCase()?.includes("cesped"),
      active: true,
      type: "toggle",
      exists: false,
    },
    sand: {
      text: filtersTextAlt.sand,
      field: "course",
      color: "sand",
      name: "sand",
      targetFunction: (field = "") => !String(field)?.toLowerCase()?.includes("arena"),
      active: true,
      type: "toggle",
      exists: false,
    },
    jockey: {
      icon: <HorseIcon className="h-4 w-4" />,
      content: {},
      selected: {},
      text: filtersTextAlt.jockey,
      render_label: (value) => value,
      field: "competitor|jockey_name",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    weather: {
      icon: <WeatherIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.weather,
      render_label: (value, language) =>
        weatherCodesAlter?.[value]?.[language]?.desc || value,
      field: "weatherCode",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    status: {
      icon: <WeatherIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.status,
      render_label: (value) => value,
      field: "status",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    distance: {
      icon: <DistanceIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.distance,
      render_label: (value) => `${value}m`,
      field: "distance",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    racetrack: {
      icon: <SearchIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.racetrack,
      render_label: (value) => raceTracks?.[value]?.name || value,
      field: "racetrack_id",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
  };
};

export default detailTemplate;
