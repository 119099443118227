import { gql } from "@apollo/client";

const UPDATE_USER = gql`
mutation($userInput: UserInput) {
  updateUser(input: $userInput) {
    dateOfBirth
    documentNumber
    email
    lastname
    name
  }
}`;

export default UPDATE_USER;