import { gql } from "@apollo/client";

export const GET_HORSERATING_BY_USER = gql`
  query HorseRatingByUser($horseId: String!) {
    horseRatingByUser(horseId: $horseId) {
      score
      comment
    }
  }
`;
