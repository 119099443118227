import React, { useContext, useEffect, useRef, useState } from "react";

import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import TopInfo from "./TopInfo";
import { MainContext } from "contexts/MainContext";
import { DragDropContext } from "react-beautiful-dnd";
import {
  getAllRailsInRace,
  getPredictionFromString,
  getShortUrl,
  onDragEnd,
} from "./functions";
import RailsLine from "./RailsLine";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { predictionsText } from "texts/components/predictionsText";
import { BaseContext } from "contexts/BaseContext";
import { GET_PREDICTION, UPDATE_PREDICTION } from "queries/predictions";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from "contexts/UserContext";
import ContentSkeleton from "./ContentSkeleton";

const Predictions = () => {
  const { language } = useContext(BaseContext);
  const { currentRace, loadingBasicRace } = useContext(MainContext);
  const { session } = useContext(UserContext);

  const [successSnack, setSuccessSnack] = useState({
    display: false,
    text: "",
  });
  const [failureSnack, setFailureSnack] = useState({
    display: false,
    text: "",
  });
  const [infoSnack, setInfoSnack] = useState({ display: false, text: "" });
  const [predictionPos, setPredictionPos] = useState([[], [], [], []]);
  const [predictionRails, setPredictionRails] = useState([]);
  const [anchor, setAnchor] = useState(null);

  const predictionsRef = useRef(null);
  const menuOpen = Boolean(anchor);
  const isDisabled = currentRace?.minutes || new Date(currentRace?.date) < new Date();
  const isEmpty = !predictionPos[0].length && !predictionPos[1].length && !predictionPos[2].length && !predictionPos[3].length;

  const { data: dataPrediction, loading: loadingPrediction } = useQuery(
    GET_PREDICTION,
    {
      variables: {
        raceId: currentRace?.id,
        userId: session?.id,
        alias: session?.alias,
      },
      fetchPolicy: "cache-and-network",
      skip: !session?.id || !currentRace?.id,
    }
  );

  const [
    updatePrediction,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_PREDICTION, {
    errorPolicy: "all",
    onError() {},
  });

  const clearPredictions = () => {
    setPredictionPos([[], [], [], []]);
  };

  const handleSave = () => {
    let predictionPositions = [];
    predictionPos.forEach((rails, index) => {
      predictionPositions[index] = "";

      rails.forEach(
        (railItem) => (predictionPositions[index] += `${railItem.rail},`)
      );

      if (predictionPositions[index] === ",") predictionPositions[index] = " ";

      predictionPositions[index] = predictionPositions[index]?.slice(0, -1);
    });

    const input = {
      alias: session?.alias,
      user_id: session?.id,
      race_id: currentRace?.id,
      first: predictionPositions[0],
      second: predictionPositions[1],
      third: predictionPositions[2],
      fourth: predictionPositions[3],
      short_url: getShortUrl(dataPrediction?.predictionByRace),
      weatherCode: currentRace?.weatherCode || '',
      tempC: currentRace?.weatherTemp || '',
    };

    updatePrediction({ variables: { input } });
  };

  useEffect(() => {
    let mounted = true;
    if (dataUpdate && !loadingUpdate && mounted) {
      setSuccessSnack({
        display: true,
        text: predictionsText[language].saveSuccess,
      });
    }
    return () => (mounted = false);
  }, [dataUpdate, loadingUpdate]);

  useEffect(() => {
    let mounted = true;
    if (errorUpdate && !loadingUpdate && mounted) {
      setFailureSnack({
        display: true,
        text: predictionsText[language].saveFailure,
      });
    }
    return () => (mounted = false);
  }, [errorUpdate, loadingUpdate]);

  useEffect(() => {
    let mounted = true;
    if (currentRace && mounted) {
      const allRails = getAllRailsInRace(currentRace?.competitors);
      const auxPredictionRails = getPredictionFromString(allRails, true);
      setPredictionRails(auxPredictionRails);
    }
    return () => (mounted = false);
  }, [currentRace]);

  useEffect(() => {
    let mounted = true;
    if (dataPrediction && !loadingPrediction && mounted) {
      const prediction = dataPrediction.predictionByRace;
      let auxPredictionPos = [
        getPredictionFromString(prediction?.first, false),
        getPredictionFromString(prediction?.second, false),
        getPredictionFromString(prediction?.third, false),
        getPredictionFromString(prediction?.fourth, false),
      ];

      if (mounted) {
        setPredictionPos(auxPredictionPos);
      }
    }
    return () => (mounted = false);
  }, [dataPrediction, loadingPrediction]);

  return (
    <div>
      <IconButton
        onClick={(e) => setAnchor(e.currentTarget)}
        className="material-icons fixed right-4 bottom-8 p-6 shadow-md shadow-backD2 bg-secondary hover:bg-secondaryD1"
      >
        workspaces
      </IconButton>
      <Popover
        open={menuOpen}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div
          className={`w-full md:w-96 flex flex-col px-8 pt-12 pb-4 ${
            isDisabled ? "bg-backL2" : "bg-white"
          }`}
          ref={predictionsRef}
        >
          <TopInfo
            setInfoSnack={setInfoSnack}
            handleSave={handleSave}
            dataPrediction={dataPrediction}
            predictionsRef={predictionsRef}
            isEmpty={isEmpty}
          />
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(
                result,
                predictionPos,
                setPredictionPos,
                predictionRails
              )
            }
          >
            <div className="flex flex-col gap-y-4 py-4">
              {loadingPrediction || !dataPrediction?.predictionByRace ? (
                <ContentSkeleton />
              ) : (
                predictionPos.map((positionRail, index) => (
                  <RailsLine
                    posText={
                      <span className="my-0 select-none">{index + 1}</span>
                    }
                    key={`line-${index+1}`}
                    droppableId={`rail-line-${index}`}
                    posItems={positionRail}
                    isDisabled={isDisabled}
                  />
                ))
              )}
              {loadingBasicRace || !currentRace ? (
                <div className="w-full h-24 rounded-3xl bg-back animate-pulse" />
              ) : (
                <RailsLine
                  posText={
                    <span className="material-icons text-black select-none">
                      delete
                    </span>
                  }
                  droppableId="rails"
                  posItems={predictionRails}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </DragDropContext>

          <div className="flex h-[1px] bg-back mx-4 my-2" id="divider" />

          <div className="flex items-center justify-end">
            {loadingUpdate ? (
              <CircularProgress size={32} />
            ) : (
              <>
                <Button
                  variant="text"
                  className="text-black"
                  onClick={clearPredictions}
                  disabled={isDisabled}
                >
                  {predictionsText[language].delete}
                </Button>
                <Button
                  variant="text"
                  className="text-black"
                  onClick={handleSave}
                  disabled={isDisabled}
                >
                  {predictionsText[language].save}
                </Button>
              </>
            )}
          </div>
        </div>
      </Popover>

      <Snackbar
        open={successSnack.display}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setSuccessSnack((old) => ({ ...old, display: false }))}
        message={successSnack.text}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() =>
              setSuccessSnack((old) => ({ ...old, display: false }))
            }
          >
            close
          </span>
        }
      />

      <Snackbar
        open={failureSnack.display}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" } }}
        onClose={() => setFailureSnack((old) => ({ ...old, display: false }))}
        message={failureSnack.text}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() =>
              setFailureSnack((old) => ({ ...old, display: false }))
            }
          >
            close
          </span>
        }
      />

      <Snackbar
        open={infoSnack.display}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#000" } }}
        onClose={() => setInfoSnack((old) => ({ ...old, display: false }))}
        message={infoSnack.text}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setInfoSnack((old) => ({ ...old, display: false }))}
          >
            close
          </span>
        }
      />
    </div>
  );
};

export default Predictions;
