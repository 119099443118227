const firstLoginText = { 
    en: {  
        welcome:'Welcome! 🎉',
        alias:'Your nickname*',
        update:'Update',
        country:'Country*',
        info1:'We need some data before start discovering our app.',
        info2:'You can edit this information later, from your profile.',
        surveyQuestion:'Where did you hear about us?*',
        dateOfBirth:'Date of birth*',
        minors:'This application is exclusively for adults. Did you enter the correct date?',
        no:'No, I will fix it',
        yes:'Yes, I did',
        agency:'Horse racing agency',
        friend:'A friend',
        horsetrack:'At the racetrack',
        other:'Other',
        raceTrack:'Racetrack',
        success:'Data saved successfully.',
        failure:'The data could not be saved.',
        cancel:'No, I will correct it',
        confirm:'Yes, it\'s correct',

    },
    es: {  
        welcome:'¡Bienvenid@! 🎉',
        alias:'Tu apodo*',
        update:'Actualizar',
        country:'País*',
        info1:'Necesitamos algunos datos antes de comenzar a descubrir nuestra app.',
        info2:'Podés editar esta info después, desde tu perfil.',
        surveyQuestion:'¿Dónde nos conociste?*',
        dateOfBirth:'Fecha de nacimiento*',
        minors:'Esta aplicación es exclusiva para mayores de edad. ¿Estás seguro que la fecha es correcta?',
        no:'No, voy a corregirla',
        yes:'Sí, es correcta',
        agency:'Agencia hípica',
        friend:'Un conocido',
        horsetrack:'En el hipódromo',
        other:'Otro',
        raceTrack:'Hipódromo',
        success:'Datos guardados con éxito.',
        failure:'Los datos no pudieron ser guardados.',
        cancel:'No, voy a corregirla',
        confirm:'Sí, es correcta',
    },
    pt: {  
        welcome:'Bem-vind@! 🎉',
        alias:'Seu Apodo*',
        update:'Atualizar ',
        country:'País*',
        info1:'Precisamos de algumas informações antes de começar a descobrir nossa app.',
        info2:'Você pode editar essas informações mais tarde em seu perfil.',
        surveyQuestion:'Onde nos conheceu?*',
        dateOfBirth:'Data de nascimento*',
        minors:'Este aplicativo é exclusivamente para adultos. Tem certeza que a data é correta?',
        no:'Não, vou corrigi-la',
        yes:'Sim, é correta',
        agency:'Agência de apostas',
        friend:'Uma pessoa de contato',
        horsetrack:'No hipódromo',
        other:'Outro',
        raceTrack:'Hipódromo',
        success:'Dados salvos com sucesso.',
        failure:'Os dados não puderam ser salvos.',
        cancel:'Não, vou corrigir',
        confirm:'Sim, esta correto',

    },
}

export default firstLoginText;