const getIncidentKey = ( row ) => {
    if (!row) return false;

    if (row.wind_dist === 'dp') return 'dp';

    if(row.desmonta || row.wind_dist === 'nl' || row.wind_dist === 'desm.') {
        if(row.pos && String(row.pos).toUpperCase() === 'NL') return 'nl';
        return 'desm.';
    }
    
    if (row.rodo || row.wind_dist === "rodo") return 'rodo';
    
    if (row.withdrawn) return 'withdrawn';

    return false;
}

export default getIncidentKey;