import React, { useContext, useEffect, useState } from 'react';
import { BaseContext } from 'contexts/BaseContext';
import reviewText from 'texts/components/reviewText';

import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import RatingDialog from './RatingDialog';

const Review = ({onSave, initialScore = 0, initialComment = "", dataSave, loadingSave, errorSave, mode = "input"}) => {

    const { language } = useContext(BaseContext);
    const [hover, setHover] = useState(-1);
    const [rating, setRating] = useState({ score:initialScore, comment:initialComment});
    const [openDialog, setOpenDialog] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFailure, setOpenFailure] = useState(false);

    useEffect(() => {
      let mounted = true;
      if(!isNaN(initialScore) && (initialComment || initialComment === "") && mounted) {
        setRating({
            comment:initialComment,
            score:initialScore
        })
      }
      return () => mounted = false;
    }, [initialScore, initialComment])
    

    const handleSave = () => {
        onSave(rating);
    }

    const ratingLabels = {
        0.5: reviewText[language][0.5],
        1: reviewText[language][1],
        1.5: reviewText[language][1.5],
        2: reviewText[language][2],
        2.5: reviewText[language][2.5],
        3: reviewText[language][3],
        3.5: reviewText[language][3.5],
        4: reviewText[language][4],
        4.5: reviewText[language][4.5],
        5: reviewText[language][5],
    };

    useEffect(() => {
        let mounted = true;

        if(mounted && dataSave && !loadingSave) {
            setOpenSuccess(true);
            setOpenDialog(false);
        }

        if(mounted && errorSave && !loadingSave) {
            setOpenFailure(true);
            setOpenDialog(false);
        }

        return () => mounted = false;
    }, [dataSave, loadingSave, errorSave])

    const onChangePrev = event => {
        const {name, value} = event.target
        setRating({
            ...rating,
            [name]: value
        })
        setOpenDialog(true);
    };

    const onChange = event => {
        const {name, value} = event.target
        setRating({
            ...rating,
            [name]: value
        })
    };
    
    const onChangeHover = (event, newHover) => setHover(newHover);

    return ( 
        <div>
            {
                mode === "input" ? (
                    <div className='flex items-center gap-x-4 py-2'>
                        <Rating
                            name="score"
                            size='large'
                            precision={0.5}
                            value={parseFloat(rating.score)}
                            onChange={onChangePrev}
                            onChangeActive={onChangeHover}
                        />
                        <p className="md:w-24 my-0">
                            { rating !== null &&
                                (ratingLabels[hover !== -1 ?
                                hover
                                : rating.score]
                                )
                            }
                        </p>
                        <p className='my-0'>
                        {rating.comment}  
                        </p>
                    </div>
                ) : (
                    <div className='flex items-center'>
                        <Button
                            className="rounded-full text-black normal-case"
                            color="inactive"
                            variant="outlined"
                            size="large"
                            onClick={()=>setOpenDialog(true)}
                            startIcon={<span className='py-0 leading-none material-icons text-secondary'>rate_review</span>}
                        >
                            {reviewText[language].share}
                        </Button>
                    </div>
                )
            }
            <RatingDialog 
                open={openDialog}
                onClose={()=>setOpenDialog(false)}
                score={rating.score}
                comment={rating.comment}
                onChange={onChange}
                onChangeHover={onChangeHover}
                onSave={handleSave}
                hover={hover}
                loading={loadingSave}
            />
            <Snackbar
                open={openSuccess}
                autoHideDuration={2000}
                sx={{"& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" }}}
                onClose={()=>setOpenSuccess(false)}
                message={reviewText[language].saveSuccess}
                action={<span 
                    className='material-icons cursor-pointer'
                    onClick={()=>setOpenSuccess(false)}
                >
                    close
                </span>}
            />
            <Snackbar
                open={openFailure}
                autoHideDuration={2000}
                sx={{"& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" }}}
                onClose={()=>setOpenFailure(false)}
                message={reviewText[language].saveFailure}
                action={<span 
                    className='material-icons cursor-pointer'
                    onClick={()=>setOpenFailure(false)}
                >
                    close
                </span>}
            />
        </div>
    );
}
 
export default Review;