import React, { useState, useContext, useEffect } from "react";

import Chip from "@mui/material/Chip";

import conditionTags from "utilities/json/conditionTags.json";
import weightTags from "utilities/json/weightTags.json";
import { BaseContext } from "contexts/BaseContext";
const TagsChips = ({ currentRace, currentWeather, className = "" }) => {
  const { language } = useContext(BaseContext);

  const [tags, setTags] = useState({});

  const grass = currentRace?.course?.includes("Cesped");
  const inactive = !!currentRace?.minutes;

  useEffect(() => {
    let mounted = true;
    if (currentRace) {
      const conditions = conditionTags[language];
      const weights = weightTags[language];
      const cleanCurrentCond = currentRace.condition?.trim()?.toLowerCase();
      const cleanCurrentWeigh = currentRace.weight?.trim()?.toLowerCase();

      let conditionObject,
        weightObject = {};

      if (currentRace.condition) {
        conditionObject = conditions[cleanCurrentCond];
      }

      if (currentRace.weight) {
        weightObject = weights[cleanCurrentWeigh];
      }

      if (mounted) {
        setTags({ ...conditionObject, ...weightObject });
      }
    }

    return () => {
      mounted = false;
    };
  }, [currentRace]);
  return (
    <div className={`flex gap-x-2 gap-y-1 flex-wrap ${className}`}>
      {tags?.condition_tag && (
        <Chip
          size="small"
          color={inactive ? "inactive" : grass ? "grass" : "sand"}
          label={tags?.condition_tag}
        />
      )}
      {tags?.edad_tag && (
        <Chip
          size="small"
          color={inactive ? "inactive" : grass ? "grass" : "sand"}
          label={tags?.edad_tag}
        />
      )}
      {tags?.win_tag && (
        <Chip
          size="small"
          color={inactive ? "inactive" : grass ? "grass" : "sand"}
          label={tags?.win_tag}
        />
      )}
      {tags?.weight_tag && (
        <Chip
          size="small"
          color={inactive ? "inactive" : grass ? "grass" : "sand"}
          label={tags?.weight_tag}
        />
      )}
      {currentRace?.minutes && (
        <Chip
          size="small"
          color="primary"
          label={`${currentRace?.minutes}' ${
            currentRace?.seconds ? currentRace?.seconds : "0"
          }.${currentRace?.milliseconds ? currentRace?.milliseconds : "0"}" `}
          icon={<span className="material-icons">timer</span>}
        />
      )}
      {currentRace?.type !== "L" && (
        <Chip
          size="small"
          color="primary"
          label={currentRace?.type}
          icon={<span className="material-icons">emoji_events</span>}
        />
      )}
    </div>
  );
};

export default TagsChips;
