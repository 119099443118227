import { 
    WiDaySunny,
    WiCloud,
    WiCloudy,
    WiFog,
    WiSprinkle,
    WiSnow,
    WiSleet,
    WiStormShowers,
    WiSnowWind,
    WiShowers,
    WiRain,
    WiHail,
    WiThunderstorm,
    WiNightAltStormShowers,
  
  } from "weather-icons-react";
  
  export const weatherCodesAlter = {
    "113":{
       "en":{
          "desc":"Clear/Sunny",
          "icon": ()=>WiDaySunny
       },
       "es":{
          "desc":"Claro / soleado",
          "icon": ()=>WiDaySunny
       },
       "pt":{
          "desc":"Claro / Ensolarado",
          "icon": ()=>WiDaySunny
       }
    },
    "116":{
       "en":{
          "desc":"Partly Cloudy",
          "icon": ()=>WiCloud
       },
       "es":{
          "desc":"Parcialmente nublado",
          "icon": ()=>WiCloud
       },
       "pt":{
          "desc":"Parcialmente Nublado",
          "icon": ()=>WiCloud
       }
    },
    "119":{
       "en":{
          "desc":"Cloudy",
          "icon": ()=>WiCloudy
       },
       "es":{
          "desc":"Nublado",
          "icon": ()=>WiCloudy
       },
       "pt":{
          "desc":"Nublado",
          "icon": ()=>WiCloudy
       }
    },
    "122":{
       "en":{
          "desc":"Overcast",
          "icon": ()=>WiFog
       },
       "es":{
          "desc":"Nublado",
          "icon": ()=>WiFog
       },
       "pt":{
          "desc":"Encoberto",
          "icon": ()=>WiFog
       }
    },
    "143":{
       "en":{
          "desc":"Mist",
          "icon": ()=>WiSprinkle
       },
       "es":{
          "desc":"Niebla",
          "icon": ()=>WiSprinkle
       },
       "pt":{
          "desc":"Mist",
          "icon": ()=>WiSprinkle
       }
    },
    "176":{
       "en":{
          "desc":"Patchy rain nearby",
          "icon": ()=> WiSprinkle
       },
       "es":{
          "desc":"Lluvia irregular cerca",
          "icon": ()=> WiSprinkle
       },
       "pt":{
          "desc":"Patchy rain próximo",
          "icon": ()=> WiSprinkle
       }
    },
    "179":{
       "en":{
          "desc":"Patchy snow nearby",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Parches de nieve cerca",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Neve fragmentada nas proximidades",
          "icon": ()=> WiSnow
       }
    },
    "182":{
       "en":{
          "desc":"Patchy sleet nearby",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Aguanieve disperso cerca",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Granizo irregular nas proximidades",
          "icon": ()=> WiSleet
       }
    },
    "185":{
       "en":{
          "desc":"Patchy freezing drizzle nearby",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Llovizna helada irregular cerca",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Chuviscos gelados nas proximidades",
          "icon": ()=> WiSleet
       }
    },
    "200":{
       "en":{
          "desc":"Thundery outbreaks nearby",
          "icon": ()=> WiStormShowers
       },
       "es":{
          "desc":"Brotes de truenos cercanos",
          "icon": ()=> WiStormShowers
       },
       "pt":{
          "desc":"Surtos tempestuosos próximos",
          "icon": ()=> WiStormShowers
       }
    },
    "227":{
       "en":{
          "desc":"Blowing snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Nieve que sopla",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Soprando neve",
          "icon": ()=> WiSnow
       }
    },
    "230":{
       "en":{
          "desc":"Blizzard",
          "icon": ()=> WiSnowWind
       },
       "es":{
          "desc":"Ventisca",
          "icon": ()=> WiSnowWind
       },
       "pt":{
          "desc":"Blizzard",
          "icon": ()=> WiSnowWind
       }
    },
    "248":{
       "en":{
          "desc":"Fog",
          "icon": ()=> WiFog
       },
       "es":{
          "desc":"Niebla",
          "icon": ()=> WiFog
       },
       "pt":{
          "desc":"Fog",
          "icon": ()=> WiFog
       }
    },
    "260":{
       "en":{
          "desc":"Freezing fog",
          "icon": ()=> WiFog
       },
       "es":{
          "desc":"Niebla helada",
          "icon": ()=> WiFog
       },
       "pt":{
          "desc":"Névoa congelante",
          "icon": ()=> WiFog
       }
    },
    "263":{
       "en":{
          "desc":"Patchy light drizzle",
          "icon": ()=> WiSprinkle
       },
       "es":{
          "desc":"Llovizna ligera irregular",
          "icon": ()=> WiSprinkle
       },
       "pt":{
          "desc":"Chuviscos fracos",
          "icon": ()=> WiSprinkle
       }
    },
    "266":{
       "en":{
          "desc":"Light drizzle",
          "icon": ()=> WiSprinkle
       },
       "es":{
          "desc":"Llovizna ligera",
          "icon": ()=> WiSprinkle
       },
       "pt":{
          "desc":"garoa leve",
          "icon": ()=> WiSprinkle
       }
    },
    "281":{
       "en":{
          "desc":"Freezing drizzle",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Llovizna helada",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"garoa congelante",
          "icon": ()=> WiSleet
       }
    },
    "284":{
       "en":{
          "desc":"Heavy freezing drizzle",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Fuerte llovizna helada",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Chuviscos congelantes fortes",
          "icon": ()=> WiSleet
       }
    },
    "293":{
       "en":{
          "desc":"Patchy light rain",
          "icon": ()=> WiSprinkle
       },
       "es":{
          "desc":"Lluvia ligera dispersa",
          "icon": ()=> WiSprinkle
       },
       "pt":{
          "desc":"Patchy light rain",
          "icon": ()=> WiSprinkle
       }
    },
    "296":{
       "en":{
          "desc":"Light rain",
          "icon": ()=> WiSprinkle
       },
       "es":{
          "desc":"Lluvia ligera",
          "icon": ()=> WiSprinkle
       },
       "pt":{
          "desc":"Chuva fraca",
          "icon": ()=> WiSprinkle
       }
    },
    "299":{
       "en":{
          "desc":"Moderate rain at times",
          "icon": ()=> WiShowers
       },
       "es":{
          "desc":"Lluvia moderada a veces",
          "icon": ()=> WiShowers
       },
       "pt":{
          "desc":"Às vezes, chuva moderada",
          "icon": ()=> WiShowers
       }
    },
    "302":{
       "en":{
          "desc":"Moderate rain",
          "icon": ()=> WiShowers
       },
       "es":{
          "desc":"Lluvia moderada",
          "icon": ()=> WiShowers
       },
       "pt":{
          "desc":"Chuva moderada",
          "icon": ()=> WiShowers
       }
    },
    "305":{
       "en":{
          "desc":"Heavy rain at times",
          "icon": ()=> WiRain
       },
       "es":{
          "desc":"Lluvia intensa a veces",
          "icon": ()=> WiRain
       },
       "pt":{
          "desc":"Às vezes, chuva forte",
          "icon": ()=> WiRain
       }
    },
    "308":{
       "en":{
          "desc":"Heavy rain",
          "icon": ()=> WiRain
       },
       "es":{
          "desc":"Lluvia intensa",
          "icon": ()=> WiRain
       },
       "pt":{
          "desc":"Chuva forte",
          "icon": ()=> WiRain
       }
    },
    "311":{
       "en":{
          "desc":"Light freezing rain",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Lluvia helada leve",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Chuva congelante leve",
          "icon": ()=> WiSleet
       }
    },
    "314":{
       "en":{
          "desc":"Moderate or heavy freezing rain",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Lluvia helada moderada o intensa",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Chuva congelante moderada ou forte",
          "icon": ()=> WiSleet
       }
    },
    "317":{
       "en":{
          "desc":"Light sleet",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Aguanieve ligero",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"granizo leve",
          "icon": ()=> WiSleet
       }
    },
    "320":{
       "en":{
          "desc":"Moderate or heavy sleet",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Aguanieve moderado o fuerte",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"granizo moderado ou forte",
          "icon": ()=> WiSleet
       }
    },
    "323":{
       "en":{
          "desc":"Patchy light snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Nieve ligera irregular",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Patchy light snow",
          "icon": ()=> WiSnow
       }
    },
    "326":{
       "en":{
          "desc":"Light snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Nieve ligera",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Neve fraca",
          "icon": ()=> WiSnow
       }
    },
    "329":{
       "en":{
          "desc":"Patchy moderate snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Nieve irregular moderada",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Neve moderada irregular",
          "icon": ()=> WiSnow
       }
    },
    "332":{
       "en":{
          "desc":"Moderate snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Nieve moderada",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Neve moderada",
          "icon": ()=> WiSnow
       }
    },
    "335":{
       "en":{
          "desc":"Patchy heavy snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Nieve espesa en parches",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Patchy heavy snow",
          "icon": ()=> WiSnow
       }
    },
    "338":{
       "en":{
          "desc":"Heavy snow",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Fuertes nevadas",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Neve forte",
          "icon": ()=> WiSnow
       }
    },
    "350":{
       "en":{
          "desc":"Ice pellets",
          "icon": ()=> WiHail
       },
       "es":{
          "desc":"Gránulos de hielo",
          "icon": ()=> WiHail
       },
       "pt":{
          "desc":"Pellets de gelo",
          "icon": ()=> WiHail
       }
    },
    "353":{
       "en":{
          "desc":"Light rain shower",
          "icon": <WiSprinkle size={24} />
       },
       "es":{
          "desc":"Chubascos ligeros",
          "icon": <WiSprinkle size={24} />
       },
       "pt":{
          "desc":"Chuva leve",
          "icon": <WiSprinkle size={24} />
       }
    },
    "356":{
       "en":{
          "desc":"Moderate or heavy rain showers",
          "icon": ()=> WiRain
       },
       "es":{
          "desc":"Lluvias moderadas o intensas",
          "icon": ()=> WiRain
       },
       "pt":{
          "desc":"Pancadas de chuva moderadas ou fortes",
          "icon": ()=> WiRain
       }
    },
    "359":{
       "en":{
          "desc":"Torrential rain shower",
          "icon": ()=> WiRain
       },
       "es":{
          "desc":"Lluvia torrencial",
          "icon": ()=> WiRain
       },
       "pt":{
          "desc":"Chuva torrencial",
          "icon": ()=> WiRain
       }
    },
    "362":{
       "en":{
          "desc":"Light sleet showers",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Ligeros chubascos de aguanieve",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Pancadas leves de granizo",
          "icon": ()=> WiSleet
       }
    },
    "365":{
       "en":{
          "desc":"Moderate or heavy sleet showers",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Lluvias de aguanieve moderadas o intensas",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"aguaceiros de granizo moderados ou fortes",
          "icon": ()=> WiSleet
       }
    },
    "368":{
       "en":{
          "desc":"Light snow showers",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Chubascos de nieve ligera",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Pancadas leves de neve",
          "icon": ()=> WiSnow
       }
    },
    "371":{
       "en":{
          "desc":"Moderate or heavy snow showers",
          "icon": ()=> WiSnow
       },
       "es":{
          "desc":"Chubascos de nieve moderados o intensos",
          "icon": ()=> WiSnow
       },
       "pt":{
          "desc":"Pancadas de neve moderadas ou fortes",
          "icon": ()=> WiSnow
       }
    },
    "374":{
       "en":{
          "desc":"Light showers of ice pellets",
          "icon": ()=> WiSleet
       },
       "es":{
          "desc":"Ligeros chubascos de hielo granulado",
          "icon": ()=> WiSleet
       },
       "pt":{
          "desc":"Chuvas leves de pelotas de gelo",
          "icon": ()=> WiSleet
       }
    },
    "377":{
       "en":{
          "desc":"Moderate or heavy showers of ice pellets",
          "icon": ()=> WiHail
       },
       "es":{
          "desc":"Lluvias moderadas o intensas de gránulos de hielo",
          "icon": ()=> WiHail
       },
       "pt":{
          "desc":"Chuvas moderadas ou intensas de pellets de gelo",
          "icon": ()=> WiHail
       }
    },
    "386":{
       "en":{
          "desc":"Patchy light rain in area ()=> With thunder",
          "icon": ()=> WiStormShowers
       },
       "es":{
          "desc":"Lluvia ligera dispersa en un área con truenos",
          "icon": ()=> WiStormShowers
       },
       "pt":{
          "desc":"Chuva fraca em área com trovões",
          "icon": ()=> WiStormShowers
       }
    },
    "389":{
       "en":{
          "desc":"Moderate or heavy rain in area ()=> With thunder",
          "icon": ()=> WiThunderstorm
       },
       "es":{
          "desc":"Lluvia moderada o intensa en una zona con truenos",
          "icon": ()=> WiThunderstorm
       },
       "pt":{
          "desc":"Chuva moderada ou forte em área com trovão",
          "icon": ()=> WiThunderstorm
       }
    },
    "392":{
       "en":{
          "desc":"Patchy light snow in area ()=> With thunder",
          "icon": ()=> WiStormShowers
       },
       "es":{
          "desc":"Parches de nieve ligera en una zona con truenos",
          "icon": ()=> WiStormShowers
       },
       "pt":{
          "desc":"Neve fraca em áreas com trovões",
          "icon": ()=> WiStormShowers
       }
    },
    "395":{
       "en":{
          "desc":"Moderate or heavy snow in area ()=> With thunder",
          "icon": ()=> WiStormShowers
       },
       "es":{
          "desc":"Nieve moderada o intensa en una zona con truenos",
          "icon": ()=> WiStormShowers
       },
       "pt":{
          "desc":"Neve moderada ou forte em área com trovões",
          "icon": ()=> WiStormShowers
       }
    }
  }
  
  export const weatherCodes = {
    en:{
      113: {
        "desc": "Clear/Sunny",
        "icon": ()=>WiDaySunny
      },
      116: {
        "desc": "Partly Cloudy",
        "icon": ()=>WiCloud
      },
      119: {
        "desc": "Cloudy",
        "icon": ()=>WiCloudy
      },
      122: {
        "desc": "Overcast",
        "icon": ()=>WiFog
      },
      143: {
        "desc": "Mist",
        "icon": ()=>WiSprinkle
      },
      176: {
        "desc": "Patchy rain nearby",
        "icon": ()=> WiSprinkle
      },
      179: {
        "desc": "Patchy snow nearby",
        "icon": ()=> WiSnow
      },
      182: {
        "desc": "Patchy sleet nearby",
        "icon": ()=> WiSleet
      },
      185: {
        "desc": "Patchy freezing drizzle nearby",
        "icon": ()=> WiSleet
      },
      200: {
        "desc": "Thundery outbreaks nearby",
        "icon": ()=> WiStormShowers
      },
      227: {
        "desc": "Blowing snow",
        "icon": ()=> WiSnow
      },
      230: {
        "desc": "Blizzard",
        "icon": ()=> WiSnowWind
      },
      248: {
        "desc": "Fog",
        "icon": ()=> WiFog
      },
      260: {
        "desc": "Freezing fog",
        "icon": ()=> WiFog
      },
      263: {
        "desc": "Patchy light drizzle",
        "icon": ()=> WiSprinkle
      },
      266: {
        "desc": "Light drizzle",
        "icon": ()=> WiSprinkle
      },
      281: {
        "desc": "Freezing drizzle",
        "icon": ()=> WiSleet
      },
      284: {
        "desc": "Heavy freezing drizzle",
        "icon": ()=> WiSleet
      },
      293: {
        "desc": "Patchy light rain",
        "icon": ()=> WiSprinkle
      },
      296: {
        "desc": "Light rain",
        "icon": ()=> WiSprinkle
      },
      299: {
        "desc": "Moderate rain at times",
        "icon": ()=> WiShowers
      },
      302: {
        "desc": "Moderate rain",
        "icon": ()=> WiShowers
      },
      305: {
        "desc": "Heavy rain at times",
        "icon": ()=> WiRain
      },
      308: {
        "desc": "Heavy rain",
        "icon": ()=> WiRain
      },
      311: {
        "desc": "Light freezing rain",
        "icon": ()=> WiSleet
      },
      314: {
        "desc": "Moderate or heavy freezing rain",
        "icon": ()=> WiSleet
      },
      317: {
        "desc": "Light sleet",
        "icon": ()=> WiSleet
      },
      320: {
        "desc": "Moderate or heavy sleet",
        "icon": ()=> WiSleet
      },
      323: {
        "desc": "Patchy light snow",
        "icon": ()=> WiSnow
      },
      326: {
        "desc": "Light snow",
        "icon": ()=> WiSnow
      },
      329: {
        "desc": "Patchy moderate snow",
        "icon": ()=> WiSnow
      },
      332: {
        "desc": "Moderate snow",
        "icon": ()=> WiSnow
      },
      335: {
        "desc": "Patchy heavy snow",
        "icon": ()=> WiSnow
      },
      338: {
        "desc": "Heavy snow",
        "icon": ()=> WiSnow
      },
      350: {
        "desc": "Ice pellets",
        "icon": ()=> WiHail
      },
      353: {
        "desc": "Light rain shower",
        "icon": <WiSprinkle size={24} />
      },
      356: {
        "desc": "Moderate or heavy rain showers",
        "icon": ()=> WiRain
      },
      359: {
        "desc": "Torrential rain shower",
        "icon": ()=> WiRain
      },
      362: {
        "desc": "Light sleet showers",
        "icon": ()=> WiSleet
      },
      365: {
        "desc": "Moderate or heavy sleet showers",
        "icon": ()=> WiSleet
      },
      368: {
        "desc": "Light snow showers",
        "icon": ()=> WiSnow
      },
      371: {
        "desc": "Moderate or heavy snow showers",
        "icon": ()=> WiSnow
      },
      374: {
        "desc": "Light showers of ice pellets",
        "icon": ()=> WiSleet
      },
      377: {
        "desc": "Moderate or heavy showers of ice pellets",
        "icon": ()=> WiHail
      },
      386: {
        "desc": "Patchy light rain in area ()=> With thunder",
        "icon": ()=> WiStormShowers
      },
      389: {
        "desc": "Moderate or heavy rain in area ()=> With thunder",
        "icon": ()=> WiThunderstorm
      },
      392: {
        "desc": "Patchy light snow in area ()=> With thunder",
        "icon": ()=> WiStormShowers
      },
      395: {
        "desc": "Moderate or heavy snow in area ()=> With thunder",
        "icon": ()=> WiStormShowers
      }
    },
    es:{
      113: {
        "desc": "Claro / soleado",
        "icon": WiDaySunny
      },
      116: {
        "desc": "Parcialmente nublado",
        "icon": WiCloud
      },
      119: {
        "desc": "Nublado",
        "icon": WiCloudy
      },
      122: {
        "desc": "Nublado",
        "icon": WiFog
      },
      143: {
        "desc": "Niebla",
        "icon": WiSprinkle
      },
      176: {
        "desc": "Lluvia irregular cerca",
        "icon": WiSprinkle
      },
      179: {
        "desc": "Parches de nieve cerca",
        "icon": WiSnow
      },
      182: {
        "desc": "Aguanieve disperso cerca",
        "icon": WiSleet
      },
      185: {
        "desc": "Llovizna helada irregular cerca",
        "icon": WiSleet
      },
      200: {
        "desc": "Brotes de truenos cercanos",
        "icon": WiStormShowers
      },
      227: {
        "desc": "Nieve que sopla",
        "icon": WiSnow
      },
      230: {
        "desc": "Ventisca",
        "icon": WiSnowWind
      },
      248: {
        "desc": "Niebla",
        "icon": WiFog
      },
      260: {
        "desc": "Niebla helada",
        "icon": WiFog
      },
      263: {
        "desc": "Llovizna ligera irregular",
        "icon": WiSprinkle
      },
      266: {
        "desc": "Llovizna ligera",
        "icon": WiSprinkle
      },
      281: {
        "desc": "Llovizna helada",
        "icon": WiSleet
      },
      284: {
        "desc": "Fuerte llovizna helada",
        "icon": WiSleet
      },
      293: {
        "desc": "Lluvia ligera dispersa",
        "icon": WiSprinkle
      },
      296: {
        "desc": "Lluvia ligera",
        "icon": WiSprinkle
      },
      299: {
        "desc": "Lluvia moderada a veces",
        "icon": WiShowers
      },
      302: {
        "desc": "Lluvia moderada",
        "icon": WiShowers
      },
      305: {
        "desc": "Lluvia intensa a veces",
        "icon": WiRain
      },
      308: {
        "desc": "Lluvia intensa",
        "icon": WiRain
      },
      311: {
        "desc": "Lluvia helada leve",
        "icon": WiSleet
      },
      314: {
        "desc": "Lluvia helada moderada o intensa",
        "icon": WiSleet
      },
      317: {
        "desc": "Aguanieve ligero",
        "icon": WiSleet
      },
      320: {
        "desc": "Aguanieve moderado o fuerte",
        "icon": WiSleet
      },
      323: {
        "desc": "Nieve ligera irregular",
        "icon": WiSnow
      },
      326: {
        "desc": "Nieve ligera",
        "icon": WiSnow
      },
      329: {
        "desc": "Nieve irregular moderada",
        "icon": WiSnow
      },
      332: {
        "desc": "Nieve moderada",
        "icon": WiSnow
      },
      335: {
        "desc": "Nieve espesa en parches",
        "icon": WiSnow
      },
      338: {
        "desc": "Fuertes nevadas",
        "icon": WiSnow
      },
      350: {
        "desc": "Gránulos de hielo",
        "icon": WiHail
      },
      353: {
        "desc": "Chubascos ligeros",
        "icon": WiSprinkle
      },
      356: {
        "desc": "Lluvias moderadas o intensas",
        "icon": WiRain
      },
      359: {
        "desc": "Lluvia torrencial",
        "icon": WiRain
      },
      362: {
        "desc": "Ligeros chubascos de aguanieve",
        "icon": WiSleet
      },
      365: {
        "desc": "Lluvias de aguanieve moderadas o intensas",
        "icon": WiSleet
      },
      368: {
        "desc": "Chubascos de nieve ligera",
        "icon": WiSnow
      },
      371: {
        "desc": "Chubascos de nieve moderados o intensos",
        "icon": WiSnow
      },
      374: {
        "desc": "Ligeros chubascos de hielo granulado",
        "icon": WiSleet
      },
      377: {
        "desc": "Lluvias moderadas o intensas de gránulos de hielo",
        "icon": WiHail
      },
      386: {
        "desc": "Lluvia ligera dispersa en un área con truenos",
        "icon": WiNightAltStormShowers
      },
      389: {
        "desc": "Lluvia moderada o intensa en una zona con truenos",
        "icon": WiThunderstorm
      },
      392: {
        "desc": "Parches de nieve ligera en una zona con truenos",
        "icon": WiStormShowers
      },
      395: {
        "desc": "Nieve moderada o intensa en una zona con truenos",
        "icon": WiStormShowers
      } 
    },
    pt:{
      113: {
        "desc": "Claro / Ensolarado",
        "icon": WiDaySunny
      },
      116: {
        "desc": "Parcialmente Nublado",
        "icon": WiCloud
      },
      119: {
        "desc": "Nublado",
        "icon": WiCloudy
      },
      122: {
        "desc": "Encoberto",
        "icon": WiFog
      },
      143: {
        "desc": "Mist",
        "icon": WiSprinkle
      },
      176: {
        "desc": "Patchy rain próximo",
        "icon": WiSprinkle
      },
      179: {
        "desc": "Neve fragmentada nas proximidades",
        "icon": WiSnow
      },
      182: {
        "desc": "Granizo irregular nas proximidades",
        "icon": WiHail
      },
      185: {
        "desc": "Chuviscos gelados nas proximidades",
        "icon": WiHail
      },
      200: {
        "desc": "Surtos tempestuosos próximos",
        "icon": WiStormShowers
      },
      227: {
        "desc": "Soprando neve",
        "icon": WiSnow
      },
      230: {
        "desc": "Blizzard",
        "icon": WiSnowWind
      },
      248: {
        "desc": "Fog",
        "icon": WiFog
      },
      260: {
        "desc": "Névoa congelante",
        "icon": WiFog
      },
      263: {
        "desc": "Chuviscos fracos",
        "icon": WiSprinkle
      },
      266: {
        "desc": "garoa leve",
        "icon": WiSprinkle
      },
      281: {
        "desc": "garoa congelante",
        "icon": WiHail
      },
      284: {
        "desc": "Chuviscos congelantes fortes",
        "icon": WiHail
      },
      293: {
        "desc": "Patchy light rain",
        "icon": WiSprinkle
      },
      296: {
        "desc": "Chuva fraca",
        "icon": WiSprinkle
      },
      299: {
        "desc": "Às vezes, chuva moderada",
        "icon": "wi-chuveiros"
      },
      302: {
        "desc": "Chuva moderada",
        "icon": "wi-chuveiros"
      },
      305: {
        "desc": "Às vezes, chuva forte",
        "icon": WiRain
      },
      308: {
        "desc": "Chuva forte",
        "icon": WiRain
      },
      311: {
        "desc": "Chuva congelante leve",
        "icon": WiHail
      },
      314: {
        "desc": "Chuva congelante moderada ou forte",
        "icon": WiHail
      },
      317: {
        "desc": "granizo leve",
        "icon": WiHail
      },
      320: {
        "desc": "granizo moderado ou forte",
        "icon": WiHail
      },
      323: {
        "desc": "Patchy light snow",
        "icon": WiSnow
      },
      326: {
        "desc": "Neve fraca",
        "icon": WiSnow
      },
      329: {
        "desc": "Neve moderada irregular",
        "icon": WiSnow
      },
      332: {
        "desc": "Neve moderada",
        "icon": WiSnow
      },
      335: {
        "desc": "Patchy heavy snow",
        "icon": WiSnow
      },
      338: {
        "desc": "Neve forte",
        "icon": WiSnow
      },
      350: {
        "desc": "Pellets de gelo",
        "icon": WiHail
      },
      353: {
        "desc": "Chuva leve",
        "icon": WiSprinkle
      },
      356: {
        "desc": "Pancadas de chuva moderadas ou fortes",
        "icon": WiRain
      },
      359: {
        "desc": "Chuva torrencial",
        "icon": WiRain
      },
      362: {
        "desc": "Pancadas leves de granizo",
        "icon": WiHail
      },
      365: {
        "desc": "aguaceiros de granizo moderados ou fortes",
        "icon": WiHail
      },
      368: {
        "desc": "Pancadas leves de neve",
        "icon": WiSnow
      },
      371: {
        "desc": "Pancadas de neve moderadas ou fortes",
        "icon": WiSnow
      },
      374: {
        "desc": "Chuvas leves de pelotas de gelo",
        "icon": WiHail
      },
      377: {
        "desc": "Chuvas moderadas ou intensas de pellets de gelo",
        "icon": WiHail
      },
      386: {
        "desc": "Chuva fraca em área com trovões",
        "icon": WiStormShowers
      },
      389: {
        "desc": "Chuva moderada ou forte em área com trovão",
        "icon": "wi-tempestade"
      },
      392: {
        "desc": "Neve fraca em áreas com trovões",
        "icon": WiStormShowers
      },
      395: {
        "desc": "Neve moderada ou forte em área com trovões",
        "icon": WiStormShowers
      } 
    }
  }