const signUpText = { 
    en: {  
        password: 'Password',
        confirmPassword: 'Confirm password',
        email: 'E-mail',
        signUpTitle: 'Register account',
        signUp: 'Register',
        adultLabel: 'I\'m over 18 y/o ',
        termsLabel1: 'I have read and accept the',
        termsLabel2: 'terms and conditions (spanish)',
        signIn1: 'Do you already have an account?',
        signIn2: 'Log in',
        confirmText1: 'Please check ',
        confirmText2: '\'s e-mail box to confirm the account and have access to the site.',
        confirmSpam:'If you can\'t find your email, remember to check the SPAM folder.',
        confirm: 'Ok',
        accept: 'Accept',
        reject: 'Reject',
        errorConfirmPass: 'Passwords do not match.',
        errorTerms: 'You must to accept the terms and conditions.',
        errorAdult: 'You must be of legal age',
        errorMail1: 'There is already an account associated with that e-mail.',
        errorLength: 'The character limit is',
        welcome: 'Welcome!',
        domain:'Domain',
        otherDomain1: 'Select',
        otherDomain2: 'as domain.',
        domainWarning: '⚠️You have entered a private domain',
    },
    es: {  
        password:'Contraseña',
        confirmPassword:'Confirmar contraseña',
        email:'E-mail',
        signUpTitle:'Registrar cuenta',
        signUp:'Registrarse',
        adultLabel:'Soy mayor de 18 años',
        termsLabel1:'He leido y acepto los ',
        termsLabel2:'términos y condiciones',
        errorConfirmPass:'Las contraseñas no coinciden.',
        errorTerms: 'Debes aceptar los términos y condiciones.',
        errorAdult: 'Debes ser mayor de edad',
        errorMail1: 'Ya existe una cuenta asociada a ese mail.',
        signIn1: '¿Ya tenés cuenta?',
        signIn2: 'Iniciá sesión',
        confirmText1: 'Por favor revisá la casilla de',
        confirmText2: 'para confirmarla y poder acceder al sitio.',
        confirmSpam:'De no encontrar tu correo,  recuerda revisar la carpeta de correo no deseado.',
        confirm: 'De acuerdo',
        accept: 'Acepto',
        reject: 'No acepto',
        errorLength: 'El límite de caracteres es de ',
        welcome: 'Bienvenido!',
        domain:'Dominio',
        otherDomain1: 'Seleccionar',
        otherDomain2: 'como dominio',
        domainWarning: '⚠️Has ingresado un dominio privado',
    },
    pt: {  
		password: 'Senha',
        confirmPassword: 'Confirmar senha',
        email: 'E-mail',
        signUpTitle: 'Registrar conta',
        signUp: 'Registre-se',
        adultLabel: 'Tenho mais de 18 anos',
		termsLabel1: 'Eu li e aceito os ',
        termsLabel2:'termos e condições (em espanhol)',
        signIn1: 'Você já tem uma conta?',
        signIn2: 'Log in',
        confirmText1: 'Por favor, verifique a caixa de e-mail',
        confirmText2: 'para confirmá-la e poder acessar o site.',
        confirmSpam:'Se você não consegue encontrar seu e-mail, lembre-se de verificar a pasta de SPAM.',
        confirm: 'Ok',
        accept: 'Aceitar',
        reject: 'Rejeitar',
        errorConfirmPass: 'As senhas não correspondem.',
        errorTerms: 'Você deve aceitar os termos e condições.',
        errorAdult: 'Você deve ser maior de idade',
        errorMail1: 'Já existe uma conta associada a esse e-mail.',
        errorLength: 'O limite de caracteres é',
		welcome: 'Bem vindo!',
        domain:'Domínio',
        otherDomain1: 'Seleccione',
        otherDomain2: 'como domínio',
        domainWarning: '⚠️Você inseriu um domínio privado',
    },
}

export default signUpText;