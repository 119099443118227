import React, { useContext, useState } from 'react';

import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import { BaseContext } from 'contexts/BaseContext';


const CheckSelect = ({ category, children}) => {
    const { language } = useContext(BaseContext);
    const [anchor, setAnchor] = useState(null);
    const menuOpen = Boolean(anchor);

    const badgeContent = category.active !== category.total ? `${category.active}/${category.total}` : `${category.active}`;

    return (
        <div className='w-auto'>
            <Badge
                badgeContent={badgeContent}
                color={category.active === category.total ? "primary" : 'secondary'}
                classes={{anchorOriginTopRight:"top-[8px]"}}
            >
                <Chip
                    label={category.text?.[language]}
                    icon={category.icon}
                    clickable
                    onClick={(e)=>setAnchor(e.currentTarget)}
                    color={category.active === category.total ? "secondary" : 'primary'}
                    variant={category.active === category.total ? "outlined" : 'filled'}
                    className='p-2 text-backD2 text-sm items-center'
                />
            </Badge>
            <Menu
                id="check-select-menu"
                anchorEl={anchor}
                open={menuOpen}
                onClose={()=>setAnchor(null)}
            >
                {children}
            </Menu>
        </div>
     );
}
 
export default CheckSelect;