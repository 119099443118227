import React, { useContext, useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { MainContext } from "contexts/MainContext";
import { RaceContext } from "contexts/RaceContext";
import { BaseContext } from "contexts/BaseContext";
import tableText from "texts/components/tableText";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineHistory = ({compIndex, sortedCompetitors}) => {
  const { language } = useContext(BaseContext);
  const { dayHistory, loadingHistory } = useContext(MainContext);
  const { raceTracks } = useContext(RaceContext);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "data",
        data: [],
        borderColor: "#D1C4E9",
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        reverse: true,
        grid: {
          display: false,
          borderColor: "transparent",
        },
        // ticks:{ font:{ size:12 } },
      },
      y: {
        display: false,
        reverse: true,
        ticks:{
            beginAtZero: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    layout: {
      padding: {
        left: 15,
        right: 15,
      }
    }  
  };

  useEffect(() => {
    let mounted = true;
  
    if(mounted && sortedCompetitors && !loadingHistory && raceTracks) {
        let auxChartData = {
            labels: [],
            datasets: [
              {
                label: "data",
                data: [],
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(230,230,250,75)',
                borderColor: 'rgba(230,230,250,100)',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: "#1A7AC7",
                pointBackgroundColor: "#1A7AC7",
                pointBorderWidth: 1,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "#1A7AC7",
                pointHoverBorderColor: "#1A7AC7",
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                pointHitRadius: 7,
              },
            ],
        };
        sortedCompetitors?.[compIndex]?.history.forEach(history=>{
          let incidentPos = "";
          if(history?.competitor?.withdrawn) incidentPos = "RET";
          if(history?.competitor?.rodo) incidentPos = "RD";
          if(history?.competitor?.desmonta) incidentPos = "DM";
          
          if(incidentPos) {
            auxChartData.labels.push(incidentPos);
            auxChartData.datasets[0].data.push(null);
          } else {
            auxChartData.labels.push(`${history?.competitor?.pos}${raceTracks[history?.racetrack_id]?.alpha2?.[0] || "-"}`);
            auxChartData.datasets[0].data.push(history?.competitor?.pos || null);
          }
          

        })

        setChartData(auxChartData);
    }

    return () => mounted = false
  }, [dayHistory])
  

  return (
    <div className="relative h-11 w-80 my-2 flex items-center justify-center">
      {
        !chartData?.datasets?.[0]?.data?.length ? ( 
          <p>{tableText[language].noData}</p>
        ): (
          <Line
            options={options}
            data={chartData}
          />
        )
      }
    </div>
  );
};

export default LineHistory;
