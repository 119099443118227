export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const copy = (sourceItem, destinationArray, droppableDestination) => {
    if(!destinationArray.some(pos => pos.rail === sourceItem.rail)) {
        const sourceClone = {...sourceItem};
        sourceClone.id = generateUID();
        sourceClone.clone = true;
        destinationArray.splice(droppableDestination.index, 0, sourceClone);
    }
};

export const remove = (sourceArray, droppableSource) => {
    sourceArray.splice(droppableSource.index, 1);
};

export const move = (sourceArray, destinationArray, droppableSource, droppableDestination) => {
    const [removed] = sourceArray.splice(droppableSource.index, 1);
    destinationArray.splice(droppableDestination.index, 0, removed);
};

export const onDragEnd = (result, predictionPos, setPredictionPos, railsList) => {
    const { source, destination } = result;
    const destinationIndex = destination.droppableId.slice(-1);
    const sourceIndex = source.droppableId.slice(-1);
    const auxPosList = structuredClone(predictionPos);

    
    if (!destination) return;

    if(source.droppableId === destination.droppableId && source.droppableId === "rails") return;

    if (source.droppableId === destination.droppableId) {
      const sorted = reorder(
        auxPosList[sourceIndex],
        source.index,
        destination.index
      );
      auxPosList[sourceIndex] = sorted;
      setPredictionPos(auxPosList);
      return;
    }

    if (source.droppableId === "rails") {
        copy(
            railsList[source.index],
            auxPosList[destinationIndex],
            destination
        );
        setPredictionPos(auxPosList);
        return;
    } 
    
    if (destination.droppableId === "rails") {
        remove(
            auxPosList[sourceIndex],
            source,
        );
        setPredictionPos(auxPosList);
        return;
    } 
    
    move(
        auxPosList[sourceIndex],
        auxPosList[destinationIndex],
        source,
        destination
    );
    setPredictionPos(auxPosList);
    return;
};

export const getPredictionFromString = (posString) => {
    let resultArray = [];
    if (posString) {
      let itemsArray = posString.split(",");
      Object.keys(itemsArray).forEach((item, index) => {
        resultArray.push({
          id: generateUID(),
          rail: itemsArray[item],
        });
      });
    }
    return resultArray;
  };

export const getAllRailsInRace = (competitors) =>{
    if(competitors) {
        let allRails = '';
        competitors.forEach(comp=>{
            allRails += `${comp.rail},`
        })
        allRails = allRails.slice(0,-1).split(',');
    
        allRails = allRails.sort((a,b)=> {
            let newA = a.replace(/[a-zA-Z]/g, '');
            let newB = b.replace(/[a-zA-Z]/g, '');
    
            return (newA - newB)
        });
    
        allRails = allRails.join(',');
    
        return allRails;
    }
    return "";
}

export const getShortUrl = (predictionByRace) => {
    let auxUrl= '';
    if(predictionByRace) {
        if(predictionByRace.short_url) {
            auxUrl = predictionByRace.short_url;
        } else {
            auxUrl = `${predictionByRace._id?.slice(-3)}${predictionByRace.user_id?.slice(-4)}`;
        }
    }
    return auxUrl;
}

export const generateUID = () =>
  String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ""
);