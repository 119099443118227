const historyTableText = { 
    en: {  
        pos:'Pos',
        comp:'Competitor',
        jockey:'Jockey',
        dist:'Distance',
        bet:'$',
        total:'Total competitors',
        by:'By',
        weight:'Weight',
        repeatComp:'Rivals',
        track:'Track',
        normal:'Fast Track',
        pesada:'Good Track',
        fangosa:'Muddy Track',
        humeda:'Wet Track',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Classic',
        l:'L',
        especial:'Special',
        horse:'Horse',
    },
    es: {  
        pos:'Pos',
        comp:'Competidor',
        jockey:'Jockey',
        dist:'Distancia',
        bet:'$',
        total:'Total de competidores',
        by:'Por',
        weight:'Peso',
        repeatComp:'Rivales',
        track:'Pista',
        normal:'Pista Normal',
        pesada:'Pista Pesada',
        fangosa:'Pista Fangosa',
        humeda:'Pista Húmeda',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Clásico',
        l:'L',
        especial:'Especial',
        horse:'Caballo',
    },
    pt: {  
        pos:'Pos',
        comp:'Concorrente',
        jockey:'Jóquei',
        dist:'Distancia',
        bet:'$',
        total:'Total de concorrentes',
        by:'Por',
        weight:'Peso',
        repeatComp:'Rivais',
        track:'Track',
        normal:'Pista Rápida',
        pesada:'Pista Boa',
        fangosa:'Pista Encharcada',
        humeda:'Pista Molhada',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Clássico',
        l:'L',
        especial:'Especial',
        horse:'Cavalo',
    },
}

export default historyTableText;