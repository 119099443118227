const headText = { 
    en: {  
        prize:'Prize',
        grass:'Grass',
        sand:'Sand',
        meters:'meters',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Classic',
        l:'L',
        especial:'Special',
        normal:'Fast Track',
        pesada:'Good Track',
        fangosa:'Muddy Track',
        humeda:'Wet Track',
    },
    es: {  
        prize:'Premio',
        grass:'Césped',
        sand:'Arena',
        meters:'metros',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Clásico',
        l:'L',
        especial:'Especial',
        normal:'Pista Normal',
        pesada:'Pista Pesada',
        fangosa:'Pista Fangosa',
        humeda:'Pista Húmeda',
    },
    pt: {  
        prize:'Prêmio',
        grass:'Grama',
        sand:'Areia',
        meters:'metros',
        g1:'G1',
        g2:'G2',
        g3:'G3',
        handicap:'Handicap',
        clasico:'Clássico',
        l:'L',
        especial:'Especial',
        normal:'Pista Rápida',
        pesada:'Pista Boa',
        fangosa:'Pista Encharcada',
        humeda:'Pista Molhada',
    },
}

export default headText;