import React, { useContext, useEffect, useState } from 'react';
import { MainContext } from 'contexts/MainContext';

import { Navigate, useLocation } from 'react-router-dom';

import Breadcrumb from 'components/ui/Breadcrumb';
import SideContent from './SideContent';
import MainContent from './MainContent';
import getSortedByDistance from 'utilities/functions/getSortedByDistance';

import detailText from 'texts/pages/detailText';
import { BaseContext } from 'contexts/BaseContext';

const Detail = () => {
    const { dayRaces, currentIndex } = useContext(MainContext);
    const { language } = useContext(BaseContext);

    if(!dayRaces?.length) return <Navigate to="/app/main" />
    const location = useLocation();

    const [compIndex, setCompIndex] = useState(location?.state?.compIndex || 0);
    
    const currentRace = dayRaces[currentIndex] || {};
    const competitors = getSortedByDistance(dayRaces?.[currentIndex]?.competitors) || [];
    const debutIsRaceDate = competitors[compIndex].horse_details?.crdate?.substring(0, 10) === currentRace.crdate?.substring(0, 10);
    const isDebutant = competitors[compIndex].horse_details?.debut && debutIsRaceDate;
    return ( 
        <>
            <Breadcrumb path={[ { href:'/app/main', text: 'Main' }, { href:'', text: 'Detail' } ]}/>
            <div className='flex flex-col lg:flex-row flex-grow w-full h-full'>
                <div className='w-full lg:w-[368px] shadow-xl flex-shrink-0'>
                    <SideContent 
                        currentCompetitor={competitors?.[compIndex]}
                        competitors={competitors}
                        compIndex={compIndex}
                        setCompIndex={setCompIndex}    
                    />
                </div>
                <div className='flex-grow flex flex-col p-4 gap-y-4'>
                    {
                        isDebutant ? (
                            <div className='h-96 md:h-full w-full bg-white rounded-lg flex items-center justify-center'>
                                <p className='text-xl md:text-3xl'>{detailText[language].debutDetail}</p>
                            </div>
                        ) : (
                            <MainContent
                                compIndex={compIndex}
                                currentCompetitor={competitors?.[compIndex]}
                            />
                        )
                    }
                </div>
            </div>
        </> 
    );
}
 
export default Detail;