const SkeletonJockey = () => {
    return (
        <div className='flex'>
            <div name='avatar' className="h-28 w-28 rounded-full bg-gray-300 animate-pulse flex-shrink-0"/>
            <div className='px-4'>
                <div className='h-8 w-48 mb-2 bg-gray-300 animate-pulse'/>
                <div className='flex gap-2 mb-1 items-center'>
                    <div className='w-2 h-4 bg-gray-300 animate-pulse'/>
                    <div className="w-20 h-4 bg-gray-300 animate-pulse" />
                    <div className='w-16 h-4 bg-gray-300 animate-pulse'/>
                </div>
                <div className='h-6 w-48 bg-gray-300 animate-pulse'/>
                <div className='flex justify-start gap-x-1 my-2 h-5 flex-wrap'>
                    <div className='rounded-full h-5 w-11 bg-gray-300 animate-pulse'/>
                    <div className='rounded-full h-5 w-11 bg-gray-300 animate-pulse'/>
                </div>
            </div>
        </div> );
}
 
export default SkeletonJockey;