import React, { useContext, useEffect, useState } from "react";

import { RaceContext } from "contexts/RaceContext";
import { BaseContext } from "contexts/BaseContext";

import { useQuery } from "@apollo/client";
import { GET_HORSE_YEAR } from "queries/horse";
import { GET_HORSE_RACES_SINCE } from "queries/horserace";

import noRacesImg from "assets/jockey_bg0.webp";
import StatCards from "./StatCards";
import { format } from "date-fns";
import { getHorseStats } from "../getHorseStats";
import RaceStats from "components/ui/RaceStats";
import DistanceCard from "./DistanceCard";
import FiltersComponent from "components/ui/Filters";
import horseTemplate from "./FilterFunctions/filterTemplate";
import { MenuItem, TextField } from "@mui/material";
import HorseChart from "./HorseChart";
import horseDetailText from "texts/pages/horseText";

const MainContent = ({ horse_id }) => {
  const { language } = useContext(BaseContext);
  const { raceTracks } = useContext(RaceContext);
  const [yearStats, setYearStats] = useState({});
  const [yearRaces, setYearRaces] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [monthRaces, setMonthRaces] = useState([]);
  const [year, setYear] = useState(parseInt(format(new Date(), "yyyy")));

  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const { data: dataYear, loading: loadingYear, refetch:fetchYear } = useQuery(GET_HORSE_YEAR, {
    skip: !horse_id || !year,
    variables: { horse_id, year: `${year}` },
    fetchPolicy: "cache-first",
  });

  const { data: dataMonth, loading: loadingMonth } = useQuery(
    GET_HORSE_RACES_SINCE,
    {
      skip: !horse_id,
      variables: {
        horseId: horse_id,
        date: `${format(new Date(oneMonthAgo), `yyyy-MM-dd`)}`,
      },
      fetchPolicy: "cache-first",
    }
  );


  const getYears = () => {
    let currentYear = parseInt(format(new Date(), "yyyy"));
    let years = [];
    if (currentYear > 2019) {
      for (let i = currentYear; i >= parseInt(currentYear) - 2; i--) {
        years.push({ text: i, value: i });
      }
    }
    return years;
  };

  useEffect(() => {
    let mounted = true;
    if (dataYear && !loadingYear && mounted) {
      setFiltered(dataYear?.horseByYear);
      setYearRaces(dataYear?.horseByYear);
      // const generatedStats = getJockeyStats(dataYear?.horseByYear);
      // setYearStats(generatedStats);
    }
    return () => (mounted = false);
  }, [dataYear]);

  useEffect(()=>{
    let mounted = true;
    if (filtered) {
      const generatedStats = getHorseStats(filtered);
      setYearStats(generatedStats);
    }

    return () => (mounted = false);
  }, [filtered]);

  useEffect(() => {
    let mounted = true;
    if (dataMonth && !loadingMonth && mounted) {
      let filteredRaces = dataMonth.horseRacesByHorseSince.filter(
        (race) => !race.withdrawn
      );
      setMonthRaces(filteredRaces);
    }
    return () => mounted = false;
  }, [dataMonth]);

  const horseFilterTemplate = horseTemplate(raceTracks);
  const yearList = getYears();

  const handleYearChange = (event,{props}) => {
    setYear(props.value);
  }

  return (
    <div className="flex-grow flex flex-col items-start gap-y-2 py-4 w-full">
      <div className="h-14 w-full bg-white rounded-lg flex items-center gap-x-2 px-4 py-2">
        <TextField
          select
          label={horseDetailText[language].year}
          value={year}
          size="small"
          className="flex-shrink-0"
          onChange={handleYearChange}
        >
          {yearList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
        <FiltersComponent
          filtered={filtered}
          setFiltered={setFiltered}
          originalData={yearRaces}
          template={horseFilterTemplate}
        />
      </div>
      {dataYear && dataYear.horseByYear.length === 0 && !loadingYear ? (
        <div
          className="w-full h-64 md:h-auto flex flex-col flex-grow bg-cover items-center justify-center rounded-lg relative p-4 my-4 px-10 overflow-hidden shadow-2xl bg-bottom"
          style={{ backgroundImage: `url(${noRacesImg})` }}
        >
          <div className="absolute w-full h-full bg-backD3" />
          <h1 className="text-xl md:text-4xl text-center text-white font-bold z-20">{`${horseDetailText[language].noRaces} ${year}`}</h1>
        </div>
      ) : (
        <div className="h-full w-full flex flex-col md:flex-row gap-x-2">
          <div className="w-full md:w-7/12 flex flex-col gap-y-2">
            <StatCards data={yearStats} loading={loadingYear} />
            <div className="w-full p-4 bg-white rounded-lg shadow-lg">
              <RaceStats
                size="xs"
                raceStats={{
                  first: yearStats.won,
                  place: yearStats.place,
                  noPlace: yearStats.no_place,
                }}
                label
                loading={loadingYear}
              />
            </div>
            <div className="bg-white p-3 rounded-lg">
              <DistanceCard
                distances={Object.values(yearStats.distance || {})}
              />
            </div>
          </div>
          <div className="w-full md:w-5/12 mt-4">
            <div className="relative h-56 bg-white rounded-lg p-3">
              <HorseChart data={monthRaces} loading={loadingMonth} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainContent;
