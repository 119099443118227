export const predictionsText = { 
    en: {  
        predictions:'My predictions',
        sand:'Sand',
        grass:'Grass',
        share1:'Im sharing my prediction for race',
        share2:'at',
        prize:'Prize',
        horse:'Horse',
        esp: 'ASF', // age, sex, fur
        age:'Age',
        years: 'Years Old',
        H: 'Female',
        M: 'Male',
		sex:'Sex',
		fur:'Fur',
        predictionsBy:'Prediction by ',
        jockey:'Jockey',
        race:'Race',
        save:'Save',
        delete:'Clean',
        rail: 'Rail',
        kg:'Kg',
        stud:'Stable',
        caretaker:'Trainer',
        performances:'Last Perfomances',
        saveSuccess: 'Your prediction was successfully saved',
        saveFailure: 'An error occurred while trying to save your prediction',
        ARSI:'Hipódromo de San Isidro',
        ARPA:'Hipódromo de Palermo',
        ARLP:'Hipódromo de La Plata',
        emptyPred:'Your prediction is empty. Make at least one change to download or share.',
        blurText:'To access this function, ',
    },
    es: {  
        predictions:'Mis predicciones',
        sand:'Arena',
        grass:'Césped',
        prize:'Premio',
        race:'Carrera',
        predictionsBy:'Predicción de ',
        horse:'Ejemplar',
        esp: 'ESP',  //edad, sexo, pelaje
        age:'Edad',
        years: 'Años',
        H: 'Hembra',
        M: 'Macho',
		sex:'Sexo',
		fur:'Pelaje',
        jockey:'Jockey',
        share1:'Te comparto mis predicciones para la carrera',
        share2:'en el',
        rail: 'Rail',
        kg:'Kg',
        save:'Guardar',
        delete:'Limpiar',
        stud:'Stud/ Haras',
        caretaker:'Entrenador',
        performances:'Últimas Perfomances',
        saveSuccess: 'Tu predicción se guardó con éxito',
        saveFailure: 'Ocurrió un error al guardar tu predicción',
        ARSI:'Hipódromo de San Isidro',
        ARPA:'Hipódromo de Palermo',
        ARLP:'Hipódromo de La Plata',
        emptyPred:'Tu predicción está vacía. Realiza al menos un cambio para descargar o compartir.',
        blurText:'Para acceder a esta función, ',
    },
    pt: {  
        predictions:'Minha previsão',
        sand:'Areia',
        predictionsBy:'Previsão de ',
        grass:'Grama',
        prize:'prêmio',
        race:'Corrida',
        share1:'Essa é a minha previsão para a corrida',
        share2:'no',
        save:'Salvar',
        delete:'Limpar',
        horse:'Anotado',
        esp: 'ISP', // idade, sexo, pele
        age:'Idade',
        years: 'Anos',
        H: 'Fêmea',
        M: 'Macho',
		sex:'Sexo',
		fur:'Pelo',
        jockey:'Jóquei',
        rail: 'Rail',
        kg:'Kg',
        stud:'Stud/Haras',
        caretaker:'Treinador',
        performances:'Últimas Perfomances',
        saveSuccess: 'Suas previsões foram salvas', 
        saveFailure: 'Ocorreu um erro ao salvar as suas previsões',
        ARSI:'Hipódromo de San Isidro',
        ARPA:'Hipódromo de Palermo',
        ARLP:'Hipódromo de La Plata',
        emptyPred:'Sua previsão está vazia. Faça pelo menos uma alteração para baixar ou compartilhar.',
        blurText:'Para acessar esta funcionalidade, ',
    },
}
