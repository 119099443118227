import { Link } from "react-router-dom";

const LinkIf = ({ children, to, state, className = "", condition }) =>
  !!condition && to ? (
    <Link to={to} state={state} className={className}>
      {children}
    </Link>
  ) : (
    <>{children} </>
  );

export default LinkIf;
