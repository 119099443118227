import { Navigate } from "react-router-dom";

const signOut = async (client, setSession) => {
    if(client && client.cache) {
      if(setSession) await setSession({});
      await client.cache.reset();
      await localStorage.removeItem("token");
      return (<Navigate  to="/auth/signin"/>); //navigate("/auth/signin");
    }
};

export default signOut;