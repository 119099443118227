import React, { useContext, useEffect, useState } from "react";
import { RaceContext } from "contexts/RaceContext";
import { UserContext } from "contexts/UserContext";
import { BaseContext } from "contexts/BaseContext";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import countryList from "constants/CountryList";
import { DatePicker } from "@mui/x-date-pickers";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_USER } from "queries/auth";
import { differenceInYears, format } from "date-fns";
import signOut from "utilities/functions/signOut";
import { useNavigate } from "react-router-dom";
import { WELCOME_MAIL } from "queries/auth";

import firstLoginText from "texts/components/firstLoginText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FirstLogin = ({ open, setOpen }) => {
  const { language } = useContext(BaseContext);
  const { raceTracks } = useContext(RaceContext);
  const { setSession, refetchMe } = useContext(UserContext);

  const client = useApolloClient();
  const [welcomeMail] = useMutation(WELCOME_MAIL, { onError(err) {} });
  const navigate = useNavigate();

  const [warningOpen, setWarningOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [failureOpen, setFailureOpen] = useState(false);
  const [userData, setUserData] = useState({
    alias: "",
    country: "",
    birthdate: "",
    survey: "",
    surveyRacetrack: "",
    surveyOther: "",
  });

  const [
    updateUser,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UPDATE_USER, { onError(e) {} });

  const onClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const onCloseWarning = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setWarningOpen(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //validate stuff
    if (differenceInYears(new Date(), userData.birthdate) < 18) {
      setWarningOpen(true);
    } else {
      let finalSurvey = userData.survey;
      if (finalSurvey === "O")
        finalSurvey = `${userData.survey}:${userData.surveyOther}`;
      if (finalSurvey === "Hipóromo")
        finalSurvey = `${userData.survey}:${userData.surveyHippodrome}`;

      const input = {
        firstLogin: false,
        alias: userData.alias,
        country: userData.country,
        dateOfBirth: format(userData.birthdate, "dd-MM-yyyy"),
        survey: finalSurvey,
      };
      updateUser({ variables: { userInput: input } });
    }
  };

  const handleSignOut = async () => {
    await signOut(client, setSession);
    navigate("/");
  };

  useEffect(() => {
    let mounted = true;
    if (dataUser && !loadingUser && mounted) {
      setSuccessOpen(true);
      refetchMe();
      welcomeMail({ variables: { name: userData.alias } });
      setOpen(false);
    }
    return () => (mounted = false);
  }, [dataUser, loadingUser]);

  useEffect(() => {
    let mounted = true;
    if (errorUser && !loadingUser && mounted) {
      setFailureOpen(true);
    }
    return () => (mounted = false);
  }, [errorUser, loadingUser]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={onClose}
        maxWidth="sm"
      >
        <DialogTitle>{firstLoginText[language].welcome}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {firstLoginText[language].info1}
            <br />
            {firstLoginText[language].info2}
          </DialogContentText>
          <form
            className="flex flex-col items-center justify-center gap-y-6 py-4"
            onSubmit={handleSubmit}
          >
            <TextField
              name="alias"
              disabled={loadingUser}
              value={userData.alias}
              onChange={handleChange}
              size="small"
              color="secondary"
              label={firstLoginText[language].alias}
              variant="standard"
              inputProps={{ maxLength: 50 }}
              className="w-full"
            />

            <Autocomplete
              options={Object.values(countryList[language])}
              color="secondary"
              disabled={loadingUser}
              value={userData.country}
              onChange={(e, newValue) =>
                setUserData({
                  ...userData,
                  country: newValue,
                })
              }
              autoHighlight
              className="w-full"
              getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (
                <p {...props} className={`${props.className} my-0`}>
                  {`${option}`}
                </p>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={firstLoginText[language].country}
                  variant="standard"
                  className="w-full"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                autoOk
                openTo="year"
                disabled={loadingUser}
                disableFuture={true}
                label={firstLoginText[language].dateOfBirth}
                inputFormat={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                value={userData.birthdate}
                name="birthdate"
                onChange={(newValue) =>
                  setUserData({ ...userData, birthdate: newValue })
                }
                className="w-full"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    className="w-full"
                    error={false}
                  />
                )}
                color="secondary"
                views={["year", "month", "day"]}
              />
            </LocalizationProvider>

            <TextField
              name="survey"
              select
              value={userData.survey || ""}
              onChange={handleChange}
              size="small"
              color="secondary"
              disabled={loadingUser}
              label={firstLoginText[language].surveyQuestion}
              variant="standard"
              className="w-full"
            >
              <MenuItem value={"Instagram"}>Instagram</MenuItem>
              <MenuItem value={"Facebook"}>Facebook</MenuItem>
              <MenuItem value={"Agencia"}>
                {firstLoginText[language].agency}
              </MenuItem>
              <MenuItem value={"Conocido"}>
                {firstLoginText[language].friend}
              </MenuItem>
              <MenuItem value={"Hipódromo"}>
                {firstLoginText[language].horsetrack}
              </MenuItem>
              <MenuItem value={"O"}>{firstLoginText[language].other}</MenuItem>
            </TextField>
            {userData.survey?.includes("Hipódromo") && (
              <TextField
                name="surveyRacetrack"
                select
                value={userData.surveyRacetrack || ""}
                onChange={handleChange}
                size="small"
                color="secondary"
                label={firstLoginText[language].raceTrack}
                variant="standard"
                className="w-full"
              >
                {raceTracks &&
                  Object.keys(raceTracks).map((key) => (
                    <MenuItem
                      value={raceTracks[key].id}
                      key={raceTracks[key].id}
                    >
                      {raceTracks[key].name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            {userData.survey === "O" && (
              <TextField
                disabled={loadingUser}
                name="surveyOther"
                value={userData.surveyOther}
                onChange={handleChange}
                size="small"
                color="secondary"
                label={firstLoginText[language].other}
                variant="standard"
                inputProps={{ maxLength: 20 }}
                className="w-full"
              />
            )}
            {loadingUser ? (
              <CircularProgress />
            ) : (
              <Button
                className="rounded-full"
                color="secondary"
                variant="contained"
                size="large"
                type="submit"
              >
                {firstLoginText[language].update}
              </Button>
            )}
          </form>
        </DialogContent>

        <Dialog
          open={warningOpen}
          TransitionComponent={Transition}
          onClose={onCloseWarning}
          maxWidth="sm"
        >
          <DialogContent className="bg-danger">
            <DialogContentText className="text-black font-bold">
              {firstLoginText[language].minors}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="bg-danger">
            <Button onClick={onCloseWarning} className="text-black">
              {firstLoginText[language].cancel}
            </Button>
            <Button onClick={handleSignOut} className="text-black font-bold">
              {firstLoginText[language].confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <Snackbar
        open={successOpen}
        autoHideDuration={3500}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setSuccessOpen(false)}
        message={firstLoginText[language].success}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setSuccessOpen(false)}
          >
            close
          </span>
        }
      />
      <Snackbar
        open={failureOpen}
        autoHideDuration={3500}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" } }}
        onClose={() => setFailureOpen(false)}
        message={firstLoginText[language].failure}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setFailureOpen(false)}
          >
            close
          </span>
        }
      />
    </>
  );
};

export default FirstLogin;
