import { useState } from "react";

const Expand = ({
  children,
  className="",
  clickableClassName="",
  clickable,
  disabled,
  shadow,
  defaultOpen,
  icon = "expand_more",
  iconPosition = "left",
}) => {
  const [display, setDisplay] = useState(defaultOpen);

  const handleClick = () => {
    if (!disabled) {
      setDisplay(!display);
    }
  };
  return (
    <div
      className={`flex flex-col w-auto md:w-full h-auto ${
        disabled ? "bg-backD1" : "bg-white"
      }`}
    >
      {
        <div
          className={` border-b border-t border-backL1 flex items-center px-4 
          ${shadow && "shadow-lg"} 
          ${!disabled ? "cursor-pointer" : "cursor-default"}
          ${clickableClassName}
          `}
          onClick={handleClick}
        >
          {icon && iconPosition === "left" && (
            <span
              className={`material-icons text-backD2 transition-all ${
                disabled ? "invisible" : "visible"
              } ${display ? "transform rotate-180" : ""}`}
            >
              expand_more
            </span>
          )}
          <div className="flex-grow">{clickable}</div>
          {icon && iconPosition === "right" && (
            <span
              className={`material-icons text-backD2 transition-all ${
                disabled ? "invisible" : "visible"
              } ${display ? "transform rotate-180" : ""}`}
            >
              expand_more
            </span>
          )}
        </div>
      }
      <div
        className={`${
          display ? "h-auto py-4" : "h-0 p-0"
        } transition-all overflow-x-hidden duration-150 bg-backL2
        ${className}
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default Expand;
