const distanceLocaleByKey = { 
    en: {
        "":'',
        "RET":"Retired",
        "1cpo": '1 body',
        "1cpom": '1 body and a half', 
        "2cpo":  '2 bodies',
        "2cpom": '2 bodies and a half',
        "3cpo":  '3 bodies',
        "3cpom": '3 bodies and a half',
        "4cpo": '4 bodies',
        "4cpom": 'More than 4 bodies',
        "cza": '1 head',
        "emp": 'Tie',
        "hco": '1 snout',
        "mcpo": 'Half a body',
        "mcza": 'Half a head',
        "mpzo": 'half a neck',
        "pzo": '1 neck',
        "sa": 'No Appreciation',
        "tcpo": '3/4 of a body',
        "vm": 'Minimum advantage',
        "rodo": 'Fall',
        "dist": 'Distanciated',
        "desm.": "Unmounted",
        "dp": "Positive doping",
        "last": 'Last',
        "nl":"Refuse",
    },
    es: {
        "":'',
        "RET":"Retirado",
        "1cpo": '1 cuerpo',
        "1cpom": '1 cuerpo y medio', 
        "2cpo":  '2 cuerpos',
        "2cpom": '2 cuerpos y medio',
        "3cpo":  '3 cuerpos',
        "3cpom": '3 cuerpos y medio',
        "4cpo": '4 cuerpos',
        "4cpom": 'Más de 4 cuerpos',
        "cza": '1 cabeza',
        "emp": 'Empate',
        "hco": '1 hocico',
        "mcpo": 'Medio cuerpo',
        "mcza": 'Media cabeza',
        "mpzo": 'Medio pescuezo',
        "pzo": '1 pescuezo',
        "sa": 'Sin apreciación',
        "tcpo": '3/4 de Cuerpo',
        "vm": 'Ventaja mínima',
        "rodo": 'Rodó',
        "dist": 'Distanciado',
        "desm.": "Desmonta",
        "dp": "Doping positivo",
        "last": 'Último',
        "nl":"No largó",
    },
    pt: {  
        "":'',
        "RET":"Retirado",
        "1cpo": '1 corpo',
        "1cpom": '1 corpo e meio', 
        "2cpo":  '2 corpos',
        "2cpom": '2 corpos e meio',
        "3cpo":  '3 corpos',
        "3cpom": '3 corpos e meio',
        "4cpo": '4 corpos',
        "4cpom": 'Mais de 4 corpos',
        "cza": '1 cabeça',
        "emp": 'Empate',
        "hco": '1 focinho',
        "mcpo": 'Meio corpo',
        "mcza": 'Meia cabeça',
        "mpzo": 'Medio pescoço',
        "pzo": '1 pescoço',
        "sa": 'Sem apreciação ',
        "tcpo": '3/4 do corpo',
        "vm": 'Ventagem mínima',
        "rodo": 'Enrolado',
        "dist": 'Distanciado',
        "desm.": "Desmonta",
        "dp": "Doping positivo",
        "last": 'Último',
        "nl":"Recusa",
    },
}

export default distanceLocaleByKey;