import React from "react";

import male from "assets/ui/man.webp";
import female from "assets/ui/woman.webp";
import generic from "assets/ui/generic.webp";

const Uniform = ({ id = "", gender = "male" }) => {
  const isRealImage = (url) => {
    const image = new Image();
    image.src = url;
    if (image.complete) {
      return true;
    } else {
      image.onload = () => {
        return true;
      };
      image.onerror = () => {
        return false;
      };
    }
  };

  const getUniformFromSrc = (src) => {
    const url = new URL(src);
    if (isRealImage(url)) {
      return (
        <img
          src={src}
          alt="avatar"
          className="w-[120px] mt-[28%]"
          onError={(e) => {
            altImg
              ? (e.target.src = altImg)
              : (e.target.style.display = "none");
          }}
        />
      );
    }
    return (
      <img
        src={generic}
        alt={`stable-uniform`}
        className="w-[120px] mt-[28%]"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    );
  };

  return (
    <div className="w-full relative flex justify-center">
      {getUniformFromSrc(
        `https://storage.googleapis.com/invasor-assets/studv2/${id}.webp`
      )}
      <img
        src={gender === "male" ? male : female}
        onContextMenu={(e) => e.preventDefault()}
        alt={`uniform-${gender}`}
        className="absolute top-0 w-[120px]"
      />
    </div>
  );
};

export default Uniform;
