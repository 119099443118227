import updateFilters from "./updateFilters";

const applyFilters = (data, categories, setFilterItems, raceTracks) => {
  let filteredData = structuredClone(data);
  if (data && data.length > 0) {
    Object.keys(categories).forEach((categ) => {
      const category = categories[categ];
      if (filteredData && filteredData.length > 0 && category) {
        if (category.type === "autocomplete") {
          if (category.active) {
            if (category.field.split("|").length > 1) {
              const fields = category.field.split("|");
              filteredData = filteredData.filter(
                (race) =>
                  race[fields[0]]?.[fields[1]] === category.selected.value
              );
            } else {
              filteredData = filteredData.filter(
                (race) => race[category.field] === category.selected.value
              );
            }
          }
        } else if (category.type === "toggle") {
          if (!category.active) {
            if (category.field.split("|").length > 1) {
              const fields = category.field.split("|");
              filteredData = filteredData.filter((race) =>
                category.targetFunction(race?.[fields[0]]?.[fields[1]])
              );
            } else {
              filteredData = filteredData.filter((race) =>
                category.targetFunction(race?.[category.field])
              );
            }
          }
        } else {
          const filters = category.content;
          Object.keys(filters).forEach((filt) => {
            const filter = filters[filt];
            if (!filter.active && category.type === "select") {
              if (category.field.split("|").length > 1) {
                const fields = category.field.split("|");
                filteredData = filteredData.filter(
                  (race) =>
                    race[fields[0]][fields[1]] !== filter.value &&
                    race[fields[0]][fields[1]] !== null
                );
              } else {
                filteredData = filteredData.filter(
                  (race) =>
                    race[category.field] !== filter.value &&
                    race[category.field] !== null
                );
              }
            }
          });
        }
      }
    });
    return filteredData;
    // setData(filteredData)
    // updateFilters(filteredData, filters, setFilterItems, raceTracks);
  }
};

export default applyFilters;
