import React, { useContext, useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { RaceContext } from "contexts/RaceContext";
import detailText from "texts/pages/detailText";
import { BaseContext } from "contexts/BaseContext";
import { format, parse } from "date-fns";
import distanceLocaleByKey from "utilities/objects/distanceLocaleByKey";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LinePosition = ({ historyData, setHistoryIndex, currentCompetitor = {}}) => {
  const [worstPos, setWorstPos] = useState(0); 
  const { raceTracks } = useContext(RaceContext); 
  const { language, locales } = useContext(BaseContext);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: detailText[language].pos,
        data: [],
        borderColor: "#D1C4E9",
        fill: false,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 5,
        pointHitRadius: 15,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, element, { tooltip = {} }) => {
      if (tooltip.title) {
        const splitDate = tooltip.title?.[0]?.split(",") || [];
        const clickedDate = `${splitDate[0]} ${splitDate[1]}`;
        let formattedDate = "";
        if (language === "en") {
          formattedDate = format(
            parse(clickedDate, "MMM dd yy", new Date(), {
              locale: locales[language],
            }),
            "yyyy-MM-dd"
          );
        } else {
          formattedDate = format(
            parse(clickedDate, "dd MMM yy", new Date(), {
              locale: locales[language],
            }),
            "yyyy-MM-dd"
          );
        }
        const index = historyData.findIndex(
          (race) => race.date?.substring(0, 10) === formattedDate
        );
        if (index >= 0) setHistoryIndex(index);
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = "pointer";
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: ({ raw }) => {
            let pos = raw;
            if (pos === worstPos) {
              pos = distanceLocaleByKey[language].RET;
            } else if (pos === parseInt(worstPos + 1)) {
              pos = distanceLocaleByKey[language].DP;
            } else if (pos === parseInt(worstPos + 2)) {
              pos = distanceLocaleByKey[language].rodo;
            } else if (pos === parseInt(worstPos + 3)) {
              pos = distanceLocaleByKey[language].nl;
            } else if (pos === parseInt(worstPos + 4)) {
              pos = distanceLocaleByKey[language].desm;
            }
            return `Pos: ${pos}`;
          },
        },
      },
    },
    elements: { width: 5, borderWidth: 5 },
    scales: {
      y: {
        reverse: true,
        ticks: {
          beginAtZero: false,
          callback: function (label, index) {
            if (label === worstPos) {
              return "RET";
            } else if (label === worstPos + 1) {
              return "DP";
            } else if (label === worstPos + 2) {
              return "RODO";
            } else if (label === worstPos + 3) {
              return "NL";
            } else if (label === worstPos + 4) {
              return "DESM";
            } else {
              return `${label}.°`;
            }
          },
          min: 1,
          precision: 0,
          stepSize: 1,
        },
        gridLines: {
          display: true,
        },
      },
    },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && historyData && historyData[0] && raceTracks) {
      let data = {
        labels: [],
        datasets: [
          {
          label: detailText[language].pos,
          data: [],
          borderColor: "#D1C4E9",
          fill: false,
          tension: 0.4,
          borderWidth: 3,
          pointRadius: 5,
          pointHitRadius: 15,
          },
        ],
      };
      let auxWorstPos = 0;
      historyData.forEach((record) => {
        let pos = parseInt(record.competitor?.pos);
        if ((!isNaN(pos) && pos >= auxWorstPos) || (pos === 0)) {
          auxWorstPos = pos + 1;
        }
      });
      setWorstPos(auxWorstPos);
      
      historyData.forEach((record, index) => {
        const comp = record.competitors?.find(c=>c.horse_id===currentCompetitor.horse_id);
        if (record.date && comp) {
          let pos = comp?.pos;
          if (comp?.withdrawn || pos === 0) {
            pos = parseInt(worstPos);
          } else if (pos?.toString() === "DP") {
            pos = parseInt(worstPos + 1);
          } else if (pos?.toString() === "rodo" || comp?.rodo) {
            pos = parseInt(worstPos + 2);
          } else if (pos?.toString() === "desm" || comp?.desmonta) {
            if (pos === "nl") {
              pos = parseInt(worstPos + 3);
            } else {
              pos = parseInt(worstPos + 4);
            }
          } else if(isNaN(parseInt(pos))) {
            pos = parseInt(pos);
          }
          data.labels.push([
            format(new Date(record.date), language === "en" ? "MMM d" : "d MMM", {
              locale: locales[language],
            }),
            format(new Date(record.date), "yy", { locale: locales[language] }),
          ]);
          data.datasets[0].data.push(pos);
        }
      })
      setChartData(data);
    } else if (historyData && historyData.length === 0) {
      let data = {
        labels: [],
        datasets: [
          {
          label: detailText[language].pos,
          data: [],
          borderColor: "#D1C4E9",
          fill: false,
          tension: 0.4,
          borderWidth: 3,
          pointRadius: 5,
          pointHitRadius: 15,
          },
        ],
      };
      setChartData(data);
    }

    return () => (mounted = false);
  }, [historyData]);

  return (
    <div className="h-[95%] max-h-[95%] relative">
      <Line options={options} data={chartData} />
    </div>
  );
};

export default LinePosition;
