import React, { useContext, useEffect, useState } from 'react';

//components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

//graphql
// import { useMutation } from '@apollo/client';
// import { CREATE_PREFERENCE } from 'queries/payment';

//Locale
import collaborateText from 'texts/components/collaborateText';

//style
import { ReactComponent as MercadoPago } from 'assets/icons/svg/mercado-pago.svg';
import { ReactComponent as HeartComp } from 'assets/icons/svg/hand-holding-heart-solid.svg';

import { BaseContext } from 'contexts/BaseContext';
import Chip from '@mui/material/Chip';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const defaultAmounts = [250,350,500]

const Collaborate = ({text}) => {

    const [amount, setAmount] = useState(250);
    const [other, setOther] = useState(20);
    const [step, setStep] = useState(0);

    //graphql
    // const [createPreference, {loading:loadingPreference, data:dataPreference}] = useMutation(CREATE_PREFERENCE);

    const { language } = useContext(BaseContext)
    const [dialogOpen, setDialogOpen] = useState(false);
    
    const handleDialogClose = (event, reason) => {
        setDialogOpen(false);
    };

    const handleDialogOpen = (event, reason) => {
        setDialogOpen(true);
    };    

    const handleAmount = (value) => {
        setAmount(value);
    }

    const handleOther = ({target}) => {
        const value = target.value.replace(/[^0-9]/g,'').slice(0,8);
        setOther(parseInt(value) || 0);
    }

    const goBack = () => {
        setStep(0);
    }

    const handleSubmit = ()=> {
        var price = 0;
        if(amount === 'other') {
            price = other;
        } else {
            price = amount;
        }
        // createPreference({variables:{input:{price:price}}});
    }

    const handleRedirect = ()=>{
        // if(dataPreference.createPreference?.init_point) {
        //     window.location.href = dataPreference.createPreference?.init_point;
        // }
    }

    // useEffect(() => {
    //     var mounted = true;
    
    //     if(dataPreference && !loadingPreference && mounted) {
    //         setStep(1);
    //     }

    //     return () => {
    //         mounted = false;
    //     }
    // }, [dataPreference, loadingPreference])
    

    return ( 
        <>
            <Button onClick={handleDialogOpen}>
                <HeartComp className='h-5 w-5 fill-secondaryD2'/>
                { text && <p>{text}</p> }
            </Button>
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                onClose={handleDialogClose}
            >
                <DialogContent>
                    <div className='flex justify-between'>
                        <h2 className="">{collaborateText[language].title}</h2>
                        <b> { amount === 'other' ? `$${other}` : `$${amount}` } </b>
                    </div>
                    <h3 className="">{collaborateText[language].desc}</h3>
                    <div className="h-0.5 w-full bg-backL2"/>
                    <div className='flex items-center'>
                        <p className="">{collaborateText[language].desc2}</p>
                        <MercadoPago className='h-6 w-auto mx-auto my-2' />
                    </div>
                    <Stepper activeStep={step} alternativeLabel className='py-6 px-0'>
                        <Step key={0}>
                            <StepLabel StepIconComponent={StepIcon}>
                                {collaborateText[language].step1}
                            </StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel StepIconComponent={StepIcon}>
                                {collaborateText[language].step2}
                                <p align='center' className="">{collaborateText[language].continueMP}</p>
                            </StepLabel>
                        </Step>
                    </Stepper>
                    {
                        step === 0 &&
                        <div className='min-h-[90px]'>
                            <p className="">{collaborateText[language].ammTitle}</p>
                            <p className="">$ ARS</p>
                            <div className="">
                                <div className='flex'>
                                    {
                                        defaultAmounts.map(item=>(
                                            <Chip 
                                                key={`ammount-${item}`}
                                                onClick={()=>handleAmount(item)}
                                                color={amount === item?'secondaryDark':'secondary'}
                                                label={item}
                                                clickable
                                            />
                                        ))
                                    }
                                    <Chip 
                                        onClick={()=>handleAmount("other")}
                                        color={amount === "other"?'secondaryDark':'secondary'}
                                        label={collaborateText[language].other}
                                        clickable
                                    />
                                </div>
                                {
                                    amount === 'other' &&
                                    <div className="{classes.otherContainer}">
                                        <TextField 
                                            size='small' 
                                            value={other} 
                                            onChange={handleOther} 
                                            className=""
                                            error={amount==='other' && other < 20}
                                        />
                                        { 
                                            amount==='other' && other < 20 && 
                                            <p className="text-danger">{collaborateText[language].minimum}</p>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    
                    <div className="h-0.5 w-full bg-backL2"/>
                </DialogContent>
                    <DialogActions className="text-danger" >
                        <Button onClick={goBack} disabled={step===0}>
                            {collaborateText[language].goBack}
                        </Button>
                        {/* {
                            (dataPreference && step === 1)
                                ? <Button onClick={handleRedirect} >
                                    {collaborateText[language].goToPay}
                                </Button>
                                : loadingPreference 
                                    ? <CircularProgress/>
                                    : <Button onClick={handleSubmit} disabled={amount==='other' && other < 20}>
                                        {collaborateText[language].continue}
                                    </Button>
                            
                        } */}
                    </DialogActions>
            </Dialog>
        </>
    );
}

const StepIcon = ({ active, completed }) => {
    // const classes = useStyle();
    return (
      <div
        // className={classes.root}
      >
        {completed ? <span className="material-icons">done</span> : <div className=''> aAAA</div>}
      </div>
    );
  }
 
export default Collaborate;