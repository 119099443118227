import { ReactComponent as HorseIcon } from "assets/icons/svg/horse-riding.svg";
import { ReactComponent as RacetrackIcon } from "assets/icons/svg/road-map.svg";
import { ReactComponent as WeatherIcon } from "assets/icons/svg/weatherIcon.svg";
import { filtersTextAlt } from "texts/components/filtersText";
import { weatherCodesAlter } from "utilities/objects/weatherByCode";

const horseTemplate = (raceTracks = {}) => {
  return {
    grass: {
      text: filtersTextAlt.grass,
      field: "course",
      color: "grass",
      name: "grass",
      targetFunction: (field = "") => {
        return !String(field)?.toLowerCase()?.includes("cesped");
      },
      active: true,
      type: "toggle",
      exists: false,
    },
    sand: {
      text: filtersTextAlt.sand,
      field: "course",
      color: "sand",
      name: "sand",
      targetFunction: (field = "") => {
        return !String(field)?.toLowerCase()?.includes("arena");
      },
      active: true,
      type: "toggle",
      exists: false,
    },
    racetrack: {
      icon: <RacetrackIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.racetrack,
      render_label: (value) => raceTracks?.[value]?.name || value,
      field: "racetrack_id",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    status: {
      icon: <WeatherIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.status,
      render_label: (value) => value,
      field: "status",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    weather: {
      icon: <WeatherIcon className="h-4 w-4" />,
      content: {},
      text: filtersTextAlt.weather,
      render_label: (value, language) =>
        weatherCodesAlter?.[value]?.[language]?.desc || value,
      field: "weatherCode",
      active: 0,
      initTotal: 0,
      type: "select",
      total: 0,
    },
    jockey: {
      icon: <HorseIcon className="h-4 w-4" />,
      content: {},
      selected: {},
      text: filtersTextAlt.jockey,
      render_label: (value) => value,
      field: "competitor|jockey_name",
      active: false,
      type: "autocomplete",
      name: 'jockey',
    },
  };
};

export default horseTemplate;
