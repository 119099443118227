import React from "react";

import weatherCodes from "utilities/json/weatherCodes";

import {
  WiDaySunny,
  WiCloud,
  WiCloudy,
  WiFog,
  WiSprinkle,
  WiSnow,
  WiSleet,
  WiStormShowers,
  WiSnowWind,
  WiShowers,
  WiRain,
  WiHail,
  WiThunderstorm,
} from "weather-icons-react";

const WeatherIcon = ({ className, weatherCode, size, color }) => {
  switch (weatherCodes[weatherCode]?.icon) {
    case "WiDaySunny":
      return (
        <WiDaySunny
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiCloud":
      return (
        <WiCloud
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiCloudy":
      return (
        <WiCloudy
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiFog":
      return (
        <WiFog
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiSprinkle":
      return (
        <WiSprinkle
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiSnow":
      return (
        <WiSnow
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiSleet":
      return (
        <WiSleet
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiStormShowers":
      return (
        <WiStormShowers
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiSnowWind":
      return (
        <WiSnowWind
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiShowers":
      return (
        <WiShowers
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiRain":
      return (
        <WiRain
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiHail":
      return (
        <WiHail
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    case "WiThunderstorm":
      return (
        <WiThunderstorm
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
    default:
      return (
        <WiCloud
          size={size}
          color={color || "#FFF"}
          className={`block m-0 md:mx-auto ${className || ""}`}
        />
      );
  }
};

export default WeatherIcon;
