import React, { useState, useEffect, useContext } from "react";
import { BaseContext } from "contexts/BaseContext";

import ReviewButton from "components/ui/Review";
import MainInfo from "./MainInfo";

import horseDetailText from "texts/pages/horseText";

import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { UPDATE_HORSERATING } from "queries/score";
import { GET_HORSERATING_BY_USER } from "queries/score";

const SideContent = ({ horseData, horse_id, setHorseToAdd }) => {
  const { language } = useContext(BaseContext);

  const [userRating, setUserRating] = useState({ score: 0, comment: "" });

  const [
    updateRating,
    {
      data: dataSaveRating,
      loading: loadingSaveRating,
      error: errorSaveRating,
    },
  ] = useMutation(UPDATE_HORSERATING, { onError(err) {} });

  const { data: dataRating, loading: loadingRating } = useQuery(
    GET_HORSERATING_BY_USER,
    {
      fetchPolicy: "cache-and-network",
      skip: !horseData?.id,
      variables: {
        horseId: horseData?.id,
      },
    }
  );

  const setHorsefilter = (horse) => {
    setHorseToAdd(horse.horse_name);
  };

  const handleReviewSave = (review) =>
    new Promise((resolve, reject) => {
      try {
        updateRating({
          variables: {
            horseId: horseData.id,
            score: parseFloat(review.score),
            comment: review.comment || "",
          },
        }).then((data) => {
          if (!data.errors) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      } catch (e) {
        reject(false);
      }
    });

  useEffect(() => {
    if (dataRating && !loadingRating) {
      setUserRating({
        score: dataRating.horseRatingByUser[0]?.score,
        comment: dataRating.horseRatingByUser[0]?.comment,
      });
    }
  }, [dataRating]);

  // useEffect(() => {
  //   if (horseData) {
  //     getHorseRating({ variables: { horseId: horseData?.id } });
  //   }
  // }, [horseData]);

  return (
    <div className="h-full bg-white p-4">
      {horseData ? (
        <MainInfo horseData={horseData} horse_id={horse_id} />
      ) : (
        <div className="w-full h-40 bg-back animate-pulse" />
      )}

      <div className="bg-gray-100 h-0.5 w-full" />

      {horseData ? (
        <div className="flex w-full items-center justify-center p-4">
          <ReviewButton
            initialScore={userRating.score || 0}
            initialComment={userRating.comment || ""}
            onSave={handleReviewSave}
            dataSave={dataSaveRating}
            loadingSave={loadingSaveRating}
            errorSave={errorSaveRating}
            mode="button"
          />
        </div>
      ) : (
        <div className="my-4 h-11 bg-gray-300 animate-pulse w-52 rounded-full mx-auto" />
      )}

      <div className="bg-gray-100 h-0.5 w-full" />

      <div className="flex justify-center items-center py-4">
        {horseData ? (
          horseData.racetracks?.map((rt, index) => (
            <div className="mx-2" key={`rt-${index}`}>
              <img
                key={`rt-${rt}`}
                src={`https://storage.googleapis.com/invasor-assets/racetrack/${rt}/logo.png`}
                className="block h-8 w-auto"
                style={{ filter: "invert(0.8)" }}
                alt=""
              />
            </div>
          ))
        ) : (
          <>
            <div className="bg-gray-300 w-14 animate-pulse mx-2 h-8 rounded" />
            <div className="bg-gray-300 w-14 animate-pulse mx-2 h-8 rounded" />
            <div className="bg-gray-300 w-14 animate-pulse mx-2 h-8 rounded" />
          </>
        )}
      </div>
    </div>
  );
};

export default SideContent;
