const signInText = { 
    en: {  
        noAccount:'Don\'t have an account?',
        signUp:'Sign Up',
        signIn:'Sign In',
        password:'Password',
        email:'E-mail',
        signInFail:'The password or email entered are incorrect.',
        forgot:'Forgot your password?',
        recover:'Get it back',
        noEmail:'The email entered does not exist',
        send:'Send',
        emailSent:'You will receive an email shortly to update your password.',
        resend:'Re-send e-mail',
        resendSuccess: 'The e-mail was resent.',
        resendFailure: 'There was an error resending the e-mail.',
        errorLength: 'The character limit is ',
        errorMailNoAccount: 'There is no account associated with that e-mail',
        errorMailForbidden: 'E-mail not allowed',
        errorMailOther:'There was an error sending the email, please try again later.',
        errorMailWrong:'The password or email is incorrect',
        recoverPasswordTitle:'Recover password',
        signInTitle:'Sign in',
        checkMail:'Please check your e-mail box to confirm the account and have access to the site.',
        checkSpam:'If you can\'t find your email, remember to check the SPAM folder.',
        understood:'Ok',
        cancel:'Cancel',
        resendDesc: 'Click on the button to send the e-mail.',
        timeout1:'Wait ',
        timeout2:' to request a verification e-mail again.',
        timeout3:' to request a new recover link by e-mail.',
        remembered:'Did you remember your password?',
    },
    es: {  
        noAccount:'¿No tenés una cuenta?',
        signUp:'Registrate',
        signIn: 'Ingresar',
        password:'Contraseña',
        email:'E-mail',
        signInFail:'La contraseña o mail ingresados son incorrectos',
        forgot:'¿Olvidaste tu contraseña?',
        recover:'Recuperala',
        send:'Enviar',
        cancel:'Cancelar',
        emailSent:'En breve te llegará un correo para actualizar tu contraseña',
        noEmail:'El correo ingresado no existe',
        resend:'Re-enviar correo',
        resendSuccess: 'E-mail de confirmación reenviado',
        resendFailure: 'E-mail de confirmación no pudo ser reenviado',
        errorLength: 'El límite de caracteres es de ',
        errorMailNoAccount: 'No existe ninguna cuenta asociada al e-mail ingresado',
        errorMailForbidden: 'Correo no permitido',
        errorMailOther:'Hubo un error al enviar el correo, por favor intente nuevamente más tarde.', // salvar alerta?
        errorMailWrong:'La contraseña o e-mail ingresados son incorrectos',
        recoverPasswordTitle:'Recuperar contraseña',
        signInTitle:'Iniciar sesión',
        checkMail:'Por favor revisá la casilla del correo con el que creaste tu cuenta para confirmarla y poder acceder al sitio',
        checkSpam:'De no encontrar tu correo,  recuerda revisar la carpeta de correo no deseado.',
        understood:'De acuerdo',
        resendDesc: 'Click en el botón para reenviar el e-mail.',
        timeout1:'Esperar ',
        timeout2:' para poder volver a enviar el e-mail de verificación.',
        timeout3:' para solicitar un nuevo enlace de recuperación por e-mail.',
        remembered:'¿Recordaste tu contraseña?',
    },
    pt: {  
        noAccount:'Não tem uma conta? ',
        signUp:'Inscrever-se',
        signIn:'Ingresar',
        password:'Senha',
        email:'E-mail',
        signInFail:'A senha ou e-mail inserido está incorreto.',
        forgot:'Esqueceu a sua senha?',
        recover:'Recuperá-la',
        noEmail:'O e-mail inserido não existe',
        resendSuccess: 'E-mail de confirmação reenviado.',
        resendFailure: 'Não foi possível reenviar o e-mail de confirmação.',
        send:'Enviar',
        cancel:'Fechar',
        emailSent:'Você receberá um e-mail em breve para atualizar sua senha.', 
        resend:'Reenviar E-mail',
        errorLength: 'O limite de caracteres é ',
        errorMailNoAccount: 'Não há conta associada ao e-mail inserido',
        errorMailForbidden: 'Correio não permitido',
        errorMailOther:'Ocorreu um erro ao enviar o e-mail, tente novamente mais tarde.',
        errorMailWrong:'A senha ou e-mail inserido est',
        recoverPasswordTitle:'Recuperar senha',
        signInTitle:'Sign in',
        checkMail:'Por favor, verifique a caixa de e-mail com a qual você criou sua conta para confirmá-la e poder acessar o site.',
        checkSpam:'Se você não consegue encontrar seu e-mail, lembre-se de verificar a pasta de SPAM.',
        understood:'Ok',
        resendDesc: 'Clique no botão para enviar o e-mail.',
        timeout1:'Esperar ',
        timeout2:' para solicitar um novo e-mail de verificação.',
        timeout3:' para solicitar um novo link de recuperação por e-mail.',
        remembered:'Você se lembrou da sua senha?',
    },
}

export default signInText;