import { gql } from "@apollo/client";

export const UPDATE_PREDICTION = gql`
  mutation updatePrediction($input: PredictionInput) {
    updatePrediction(input: $input) {
      chdate
      crdate
    }
  }
`;
