import Tooltip from "@mui/material/Tooltip";
import distanceByKey from "utilities/objects/distanceByKey";
import distanceLocaleByKey from "utilities/objects/distanceLocaleByKey";
import getDistanceKey from "./getDistanceKey";
import getIncidentKey from "./getIncidentKey";

export const getFinalDistanceIcon = (competitor, nextCompetitor, compIndex, totalCompetitors, language) => {
    const incidentKey = getIncidentKey(competitor);
    const nextIncidentKey = getIncidentKey(nextCompetitor);
    const distanceKey = getDistanceKey(competitor.wind_dist);
    const nextDistanceKey =
      nextCompetitor?.wind_dist && getDistanceKey(nextCompetitor.wind_dist);
  
    const disablePos = incidentKey && distanceByKey[incidentKey]?.disablePos;
    const nextDisablePos =
      nextIncidentKey && distanceByKey[nextIncidentKey]?.disablePos;
  
    const isValidIncident = incidentKey && incidentKey !== "withdrawn";
  
    const nextDistanceLabel = distanceLocaleByKey[language][nextDistanceKey];
    const incidentLabel = distanceLocaleByKey[language][incidentKey];
  
    const finalDistanceIcon = isValidIncident ? ( //current es incidente?
      <Tooltip title={incidentLabel}>
        <img
          alt={`${competitor.wind_dist}-${incidentKey}`}
          src={distanceByKey[incidentKey]?.icon}
          className="h-6 w-6"
        />
      </Tooltip>
    ) : nextDistanceKey ? ( //Próximo tiene wind distance?
      nextDisablePos ? ( //Próximo desactiva?
        <Tooltip title={distanceLocaleByKey[language].last}>
          <img alt="last" src={distanceByKey.last.icon} className="h-6 w-6" />
        </Tooltip>
      ) : (
        <Tooltip title={nextDistanceLabel}>
          <img
            alt={nextDistanceKey}
            src={distanceByKey[nextDistanceKey]?.icon}
            className="h-6 w-6"
          />
        </Tooltip>
      )
    ) : (compIndex + 1 === totalCompetitors &&
        distanceKey &&
        !incidentKey &&
        !disablePos) ||
      (distanceKey && !incidentKey && (nextIncidentKey || nextDisablePos)) ? (
      <Tooltip title={nextDistanceLabel || distanceLocaleByKey[language].last}>
        <img alt="last" src={distanceByKey.last.icon} className="h-6 w-6" />
      </Tooltip>
    ) : (
      <div className="h-6 w-6" />
    );
  
    return finalDistanceIcon;
  };