import React, { useContext, useState } from "react";
import { BaseContext } from "contexts/BaseContext";
import { MainContext } from "contexts/MainContext";
import { useNavigate } from "react-router-dom";

import TableRow from "@mui/material/TableRow";
import Collapse from "./Collapse";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import tableText from "texts/components/tableText";
import Rails from "constants/Rails";
import HorseColor from "constants/HorseColor";
import Pedigree from "components/ui/Pedigree";
import LineHistory from "./LineHistory";

//distance related imports
import getIncidentKey from "utilities/functions/getIncidentKey";
import getDistanceKey from "utilities/functions/getDistanceKey";
import distanceByKey from "utilities/objects/distanceByKey";
import distanceLocaleByKey from "utilities/objects/distanceLocaleByKey";
import getSortedByDistance from "utilities/functions/getSortedByDistance";

const Row = ({
  competitor = {},
  nextCompetitor = {},
  compIndex = 0,
  totalCompetitors = 0,
  currentRace = {},
}) => {
  const { clientWidth, language } = useContext(BaseContext);
  const { loadingHistory, dayHistory, currentIndex } = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const rowStyle = {
    cell: "py-0 text-center cursor-pointer",
    small_cell: "p-0 text-center cursor-pointer",
  };
  const redirect = () =>
    navigate("/app/main/detail", { state: { compIndex: compIndex } });

  const sortedCompetitors = getSortedByDistance(
    dayHistory[currentIndex]?.competitors
  );

  const horse_details = competitor.horse_details || {};

  const incidentKey = getIncidentKey(competitor);
  const nextIncidentKey = getIncidentKey(nextCompetitor);
  const distanceKey = getDistanceKey(competitor.wind_dist);
  const nextDistanceKey =
    nextCompetitor.wind_dist && getDistanceKey(nextCompetitor.wind_dist);

  const disablePos = incidentKey && distanceByKey[incidentKey]?.disablePos;
  const nextDisablePos =
    nextIncidentKey && distanceByKey[nextIncidentKey]?.disablePos;

  const isValidIncident = incidentKey && incidentKey !== "withdrawn";
  const nextDistanceLabel = distanceLocaleByKey[language][nextDistanceKey];
  const incidentLabel = distanceLocaleByKey[language][incidentKey];

  const finalDistanceIcon = isValidIncident ? ( //current es incidente?
    <Tooltip title={incidentLabel}>
      <img
        alt={`${competitor.wind_dist}-${incidentKey}`}
        src={distanceByKey[incidentKey]?.icon}
        className="h-6 w-6"
      />
    </Tooltip>
  ) : nextDistanceKey ? ( //Próximo tiene wind distance?
    nextDisablePos ? ( //Próximo desactiva?
      <Tooltip title={distanceLocaleByKey[language].last}>
        <img alt="last" src={distanceByKey.last.icon} className="h-6 w-6" />
      </Tooltip>
    ) : (
      <Tooltip title={nextDistanceLabel}>
        <img
          alt={nextDistanceKey}
          src={distanceByKey[nextDistanceKey]?.icon}
          className="h-6 w-6"
        />
      </Tooltip>
    )
  ) : (compIndex + 1 === totalCompetitors &&
      distanceKey &&
      !incidentKey &&
      !disablePos) ||
    (distanceKey && !incidentKey && (nextIncidentKey || nextDisablePos)) ? (
    <Tooltip title={nextDistanceLabel}>
      <img alt="last" src={distanceByKey.last.icon} className="h-6 w-6" />
    </Tooltip>
  ) : (
    <div className="h-6 w-6" />
  );

  const debutIsRaceDate = competitor.horse_details?.crdate?.substring(0, 10) === currentRace?.crdate?.substring(0, 10);
  const isDebutant = competitor.horse_details?.debut && debutIsRaceDate;

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`
          ${incidentKey && incidentKey !== "withdrawn" ? "bg-dangerL1" : ""}
          ${incidentKey === "withdrawn" ? "!bg-back" : ""}
        `}
      >
        <TableCell className={rowStyle.small_cell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <span className="material-icons">expand_less</span>
            ) : (
              <span className="material-icons">expand_more</span>
            )}
          </IconButton>
        </TableCell>

        <TableCell className={rowStyle.small_cell} onClick={redirect}>
          <div className="flex h-full items-center">
            {finalDistanceIcon || <div className="h-6 w-6 bg-blue-400" />}
          </div>
        </TableCell>

        <TableCell className={rowStyle.small_cell} onClick={redirect}>
          <div className="flex h-full items-center">
            <img
              alt={`numbers/${competitor.rail}`}
              src={Rails[competitor.rail || 1]}
              className="h-6 w-6"
            />
          </div>
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          {competitor.horse_name}
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          <Pedigree
            details={horse_details}
            incident={incidentKey && incidentKey !== "withdrawn"}
          />
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          {loadingHistory ? (
            <div className="h-10 my-2.5 w-80 bg-backL2 animate-pulse rounded-lg" />
          ) : (
            isDebutant ? ( 
              <p>{tableText[language].debut}</p>
            ) : (
              <LineHistory
                compIndex={compIndex}
                sortedCompetitors={sortedCompetitors}
              />
            )
          )}
        </TableCell>

        <TableCell className={rowStyle.small_cell} onClick={redirect}>
          <p className="my-0">{competitor.age}</p>
        </TableCell>
        <TableCell className={rowStyle.small_cell} onClick={redirect}>
          <Tooltip title={tableText[language][horse_details.gender]}>
            <p className="my-0">
              {`${tableText[language][`${horse_details.gender}short`] || "—"}`}
            </p>
          </Tooltip>
        </TableCell>
        <TableCell className={rowStyle.small_cell} onClick={redirect}>
          <Tooltip
            title={
              HorseColor[horse_details.color?.toUpperCase() || "GENERIC"]?.name
            }
          >
            <p className="my-0">{`${horse_details.color || "—"}`}</p>
          </Tooltip>
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          {competitor.jockey_name}
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          {competitor.jKg}
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          {competitor.stable_name}
        </TableCell>

        <TableCell className={rowStyle.cell} onClick={redirect}>
          {competitor.carer_name}
        </TableCell>
      </TableRow>
      <TableRow className="bg-sky-50 shadow-inner">
        <TableCell className="py-0" colSpan={clientWidth > 768 ? 13 : 8}>
          <Collapse open={open} competitor={competitor} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
