import React, { useContext, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { BaseContext } from "contexts/BaseContext";
import signUpText from "texts/pages/signUpText";
import { validateEmail } from "utilities/validators";
import signUpBg from "assets/signup_bg.webp";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DomainSelect from "./DomainSelect";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@apollo/client";
import { SIGN_UP } from "queries/auth";
import SuccessDialog from "./SuccessDialog";
import { validatePassword } from "utilities/validators";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import domains from 'constants/Domains'

const SignUp = () => {
  const { language } = useContext(BaseContext);
  const { landing_email } = useParams();
  const [errors, setErrors] = useState({
    email: { ok: true, error: "" },
    domain: { ok: true, error: "" },
    password: { ok: true, error: "" },
    passwordConfirm: { ok: true, error: "" },
    adult: { ok: true, error: "" },
    terms: { ok: true, error: "" },
    general: { ok: true, error: "" },
    noErrors: false,
  });
  const [userData, setUserData] = useState({
    email: "",
    domain: "",
    password: "",
    passwordConfirm: "",
    adult: false,
    terms: false,
    general: "",
  });
  const [successOpen, setSuccessOpen] = useState(false);

  const [
    signUp,
    { data: dataSignUp, loading: loadingSignUp, error: errorSignUp },
  ] = useMutation(SIGN_UP, { onError(e) {} });

  const resetErrors = () => {
    setErrors({
      ...errors,
      email: { ok: true, error: "" },
      domain: { ok: true, error: "" },
      password: { ok: true, error: "" },
      passwordConfirm: { ok: true, error: "" },
      adult: { ok: true, error: "" },
      terms: { ok: true, error: "" },
      general: { ok: true, error: "" },
    });
  };

  const handleChange = (event) => {
    const { id, checked, type, value } = event.target;
    if (errors && !errors.noErrors) resetErrors();

    if (type === "checkbox") {
      setUserData({
        ...userData,
        [id]: checked,
      });
      return;
    }

    setUserData({
      ...userData,
      [id]: value,
    });
  };

  const validateAll = (data) => {
    const finalEmail = `${data.email?.trim()?.toLowerCase()}@${data.domain
      ?.trim()
      ?.toLocaleLowerCase()}`;

    const generatedErrors = {
      ...errors,
      email: validateEmail(finalEmail),
      password: validatePassword(data.password),
      passwordConfirm:
        data.password === data.passwordConfirm
          ? { ok: true, error: "" }
          : { ok: false, error: signUpText[language].errorConfirmPass },
      terms: data.terms
        ? { ok: true, error: "" }
        : { ok: false, error: signUpText[language].errorTerms },
      adult: data.adult
        ? { ok: true, error: "" }
        : { ok: false, error: signUpText[language].errorAdult },
    };

    generatedErrors.noErrors =
      generatedErrors.email?.ok &&
      generatedErrors.password?.ok &&
      generatedErrors.passwordConfirm?.ok &&
      generatedErrors.terms?.ok &&
      generatedErrors.adult?.ok;

    return generatedErrors;
  };

  const handleSubmit = () => {
    const generatedErrors = validateAll(userData);
    if (generatedErrors.noErrors) {
      const finalEmail = `${userData.email?.trim()?.toLowerCase()}@${userData.domain
        ?.trim()
        ?.toLocaleLowerCase()}`;
      signUp({
        variables: {
          email: finalEmail,
          password: userData?.password,
        },
      });
      resetErrors();
    }
  };

  useEffect(() => {
    let mounted = true;
    if (dataSignUp && !loadingSignUp && mounted) {
      setSuccessOpen(true);
    }
    return () => (mounted = false);
  }, [dataSignUp, loadingSignUp]);

  useEffect(() => {
    let mounted = true;
    if (errorSignUp?.graphQLErrors?.[0]?.message && !loadingSignUp) {
      const errorMessage = errorSignUp.graphQLErrors[0].message || '';
      if(errorMessage.includes('duplicate key') && mounted) {
        setErrors({
          ...errors,
          general: {
            ok:false,
            error:signUpText[language].errorMail1
          }
        })
      }
    }
    return () => (mounted = false);
  }, [errorSignUp, loadingSignUp]);

  useEffect(() => {
    let mounted = true;
    if(landing_email && mounted) {
      const split_email = landing_email.split('@');
      if(split_email.length > 0) {
        setUserData(user=> ({
          ...user,
          email:split_email[0],
          domain:split_email[1],
        }));
        if(!domains.includes(split_email[1])) {
          setErrors({
            ...errors,
            domain:{
              ok:true,
              error:`${signUpText[language].domainWarning}`
            }
          })
        }
      } else {
        setUserData(user=> ({
          ...user,
          email:split_email[0],
        }))
      }
    }
    return () => mounted = false;
  }, []);

  return (
    <div
      className="flex-grow flex flex-col items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${signUpBg})` }}
    >
      <div className="absolute h-full w-full left-0 top-0 bg-black bg-opacity-50 z-0" />
      <div className="min-w-[30.74rem] min-h-[25rem] z-10">
        <h1 className="text-3xl text-white font-roboto font-bold">
          {signUpText[language].signUpTitle}
        </h1>
        <div className="bg-white shadow-lg rounded-md p-8 pt-16 flex flex-col gap-y-8">
          <div className="w-full flex items-start gap-x-2">
            <div className="w-1/2 items-center">
              <TextField
                id="email"
                value={userData.email}
                onChange={handleChange}
                size="small"
                color="secondary"
                error={errors.email && !errors.email.ok}
                helperText={errors.email && errors.email.error}
                label={signUpText[language].email}
                variant="standard"
                className="w-full"
                inputProps={{ autoComplete: "new-password" }}
              />
            </div>
            <p className="hidden md:block">@</p>
            <div className="w-1/2 flex items-end">
              <DomainSelect
                userData={userData}
                setUserData={setUserData}
                language={language}
                setErrors={setErrors}
                errors={errors}
              />
            </div>
          </div>
          <TextField
            id="password"
            value={userData.password}
            onChange={handleChange}
            size="small"
            color="secondary"
            error={errors.password && !errors.password.ok}
            helperText={errors.password && errors.password.error}
            label={signUpText[language].password}
            type={userData.showPassword ? "text" : "password"}
            variant="standard"
            InputProps={{
              maxLength: 22,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setUserData((currentData) => ({
                        ...currentData,
                        showPassword: !currentData.showPassword,
                      }))
                    }
                    className="py-1"
                  >
                    <span className="material-icons text-lg text-black">
                      {userData.showPassword ? "visibility_off" : "visibility"}
                    </span>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="w-full"
          />

          <TextField
            id="passwordConfirm"
            value={userData.passwordConfirm}
            onChange={handleChange}
            size="small"
            color="secondary"
            error={errors.passwordConfirm && !errors.passwordConfirm.ok}
            helperText={errors.passwordConfirm && errors.passwordConfirm.error}
            label={signUpText[language].confirmPassword}
            type={userData.showPasswordConfirm ? "text" : "password"}
            variant="standard"
            InputProps={{
              maxLength: 22,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setUserData((currentData) => ({
                        ...currentData,
                        showPasswordConfirm: !currentData.showPasswordConfirm,
                      }))
                    }
                    className="py-1"
                  >
                    <span className="material-icons text-lg text-black">
                      {userData.showPasswordConfirm
                        ? "visibility_off"
                        : "visibility"}
                    </span>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="w-full"
          />
          <div className="flex flex-col">
            <FormControl error={!errors.adult?.ok}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userData.adult}
                    name="adult"
                    id="adult"
                    onChange={handleChange}
                  />
                }
                label={signUpText[language].adultLabel}
              />
              <FormHelperText className="-mt-1 mx-0">
                {errors.adult?.error}
              </FormHelperText>
            </FormControl>

            <FormControl error={!errors.terms?.ok}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userData.terms}
                    name="terms"
                    id="terms"
                    onChange={handleChange}
                  />
                }
                label={
                  <div>
                    <span>{signUpText[language].termsLabel1}</span>
                    <span>{signUpText[language].termsLabel2}</span>
                  </div>
                }
              />
              <FormHelperText className="-mt-1 mx-0">
                {errors.terms?.error}
              </FormHelperText>
            </FormControl>
          </div>
          {
            errors?.general?.error && (
              <p className="text-danger my-0">
                {errors.general.error}    
              </p>
            )
          }
          <div className="flex justify-center">
            {
              loadingSignUp ? (
                <CircularProgress 
                  size={42}
                />
              ) : (
                <Button
                  className="rounded-full"
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  {signUpText[language].signUp}
                </Button>
              )
            }
          </div>
          <div className="flex items-center justify-center gap-x-2">
            <p className="text-base font-roboto text-dark">
              {signUpText[language].signIn1}
            </p>
            <Link
              to="/auth/signin"
              className="text-base font-roboto text-blue-400 underline"
            >
              {signUpText[language].signIn2}
            </Link>
          </div>
        </div>
      </div>
      <SuccessDialog
        open={successOpen}
        setOpen={setSuccessOpen}
        email={userData?.email}
        domain={userData?.domain}
      />
    </div>
  );
};

export default SignUp;
