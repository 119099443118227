import React, { useContext, useEffect } from 'react'

import ARPA from "assets/banner/ARPA.webp";
import ARSI from "assets/banner/ARSI.webp";
import ARLP from "assets/banner/ARLP.webp";
import GENERIC from "assets/banner/GENERIC.webp";
import NONE from "assets/banner/NONE.webp";
import { format } from 'date-fns';
import { BaseContext } from 'contexts/BaseContext';

const Banner = ({RT = "", raceTracks = {}, raceDate, loadingRace}) => {
    const { language, locales } = useContext(BaseContext);

    const racetrack_bg = {
        ARPA:ARPA, ARSI:ARSI, ARLP:ARLP, GENERIC:GENERIC, NONE:NONE
    };
    
    
    return ( 
        <div 
            className='h-36 md:h-[12.5rem] w-full relative bg-cover bg-center flex-shrink-0'
            onContextMenu={(e)=>e.preventDefault()}
            style={{ backgroundImage: `url(${racetrack_bg[RT || 'NONE'] || racetrack_bg.GENERIC })`}}
        >
            <div className='absolute h-full w-full bg-black bg-opacity-40 z-0'/>
            <div className='relative z-10 flex justify-between items-center w-full h-full'>
                <div className='flex flex-col md:px-8 px-4'>
                    <div className='flex items-start gap-x-4'>
                        {
                            RT ? (
                                <img
                                    src={RT ? `https://storage.googleapis.com/invasor-assets/racetrack/${RT}/logo.png` : null}
                                    alt={RT}
                                    className="hidden md:block w-28 h-auto"
                                />  
                            ): loadingRace ? (
                                <div className='hidden md:block w-20 h-16  md:w-28 md:h-24 rounded-xl bg-white animate-pulse'/>
                            ) : null
                        }
                        {
                            RT ? (
                                <h1 className='max-w-[10rem] text-lg md:text-2xl font-normal my-0 flex-wrap text-white'>
                                    {raceTracks[RT].name}
                                </h1>
                            ) : loadingRace ? (
                                <div className='flex flex-col gap-y-4'>
                                    <div className='w-14 md:w-28 h-6 md:h-8 bg-white animate-pulse'/>
                                    <div className='w-14 md:w-28 h-6 md:h-8 bg-white animate-pulse'/>
                                </div>
                            ) : null
                        }
                    </div>
                    {
                        raceDate ? (
                            <h1 className='text-white text-lg md:text-2xl font-normal'> { format(new Date(raceDate), 'EEE d, MMM yyyy', { locale: locales[language] }) } </h1>
                        ) : (
                            <div className='w-14 md:w-28 h-6 md:h-8 bg-white animate-pulse'/>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
 
export default Banner;