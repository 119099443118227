const navBarText = { 
    en: {  
        search: "Search",
        language:'Language',
        spanish: 'Spanish',
        english: 'English',
        portuguese: 'Portuguese',
        profile: 'Profile',
        logout: 'Log out',
        noNotif:'There are no notifications',
        date: 'Date',
        notifications: 'Notifications',
        retired:'Retired',
        subscribeText1:'To continue enjoying all the content,',
        subscribeText2:'subscribe',
        subscribeText3:'To report a payment,',
        subscribeText4:'contact us',
        freeNotif1:'Hello ',
        freeNotif2:'! The trial period is over, keep accompanying us with your',
        freeNotif3:'subscription',
        fullNotif1:'Thank you for subscribing and for trusting in our project 😊📊✨',
        renovateNotif1:'! It\'s time to renew your ',
        renovateNotif2:' for the month of ',
        discard:'Discard',
        subscribedNotif1:'Welcome!',
        subscribedNotif2:'Gracias por apoyarnos con tu suscripción.',
        incident:'Incident',
        R:'Fall',
        N:"Refuse",
        D:'Unmounted',
        searchPlaceholder:"Search by horse, jockey or race",
        searchError:'At least 3 characters',
        collaborate:'Collaborate',
    },
    es: {  
        search: "Buscar",
        language:'Lenguaje',
        spanish: 'Español',
        english: 'Inglés',
        portuguese: 'Portugués',
        noNotif:'No hay notificaciones',
        profile:'Perfil',
        logout: 'Cerrar Sesión',
        date: 'Fecha',
        notifications: 'Notificaciones',
        retired:'Retirado',
        subscribeText1:'Para seguir disfrutando de todo el contenido,',
        subscribeText2:'suscribite',
        subscribeText3:'Para reportar un pago realizado,',
        subscribeText4:'contactanos',
        freeNotif1:'Hola ',
        freeNotif2:'! Se agotó el período de prueba, seguí acompañándonos con tu',
        freeNotif3:'suscripción',
        fullNotif1:'Gracias por subscribirte y por confiar en nuestro proyecto 😊📊✨',
        renovateNotif1:'! Llegó el momento de renovar tu ',
        renovateNotif2:' para el mes de ',
        discard:'Descartar',
        subscribedNotif1:'¡Bienvenido!',
        subscribedNotif2:'Gracias por apoyarnos con tu subscripción.',
        incident:'Incidente',
        R:'Rodó',
        N:"No largó",
        D:'Desmonta',
        searchPlaceholder:"Buscar por ejemplar, jockey/ jocketa o carrera",
        searchError:'Mínimo 3 caracteres',
        collaborate:'Colaborar',
    },
    pt: {
        search: "Pesquisar",
        language:'Língua',
        spanish: 'Espanhol',
        english: 'Inglês',
        portuguese: 'Português',
        noNotif:'Sem notificações',
        profile:'Perfil',
        logout: 'Sair',
        date: 'Data',
        notifications: 'Notificações',
        retired:'Retirado',
        subscribeText1:'Para continuar aproveitando todo o conteúdo,',
        subscribeText2:'se inscrever',
        subscribeText3:'Para relatar um pagamento feito,',
        subscribeText4:'contate-nos',
        freeNotif1:'Olá ',
        freeNotif2:'! O período de teste acabou, continue nos acompanhando com sua',
        freeNotif3:'assinatura',
        fullNotif1:'Obrigado por se inscrever e por confiar em nosso projeto 😊📊✨',
        renovateNotif1:'! Chegou a hora de renovar seu ',
        renovateNotif2:' do mês de ',
        discard:'Descartar',
        subscribedNotif1:'Receber!',
        subscribedNotif2:'Obrigado por nos apoiar com sua assinatura.',
        incident:'Incidente',
        R:'Queda',
        N:"Recusa",
        D:'Desmonta',
        searchPlaceholder:"Pesquisa por cavalo, jóquei ou nome da corrida",
        searchError:'Mínimo de três caracteres',
        collaborate:'Colaborar',
    }
}

export default navBarText;