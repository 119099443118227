import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { BaseContext } from 'contexts/BaseContext';
import { ReactComponent as HandHeart } from 'assets/icons/svg/hand-holding-heart-solid.svg';
import LanguagePicker from 'components/ui/LanguagePicker';
import Dropdown from 'components/ui/Dropdown';
import homeText from 'texts/pages/homeText';

const MenuItems = () => {
    const { language } = useContext(BaseContext);
    return ( 
    <div className='h-full'>
        <div className='hidden md:flex items-center h-full'>
            <LanguagePicker />
            <Link to="pricing" className='flex gap-x-2 items-center bg-white hover:bg-backL2 transition-colors h-full px-3 flex-shrink-0 no-underline'>
                <HandHeart className='h-5 w-5 fill-secondaryD2'/>
                <p className='uppercase text-xs font-roboto font-medium'>
                    {homeText[language].collaborate}
                </p>
            </Link>
            <Link to="/auth/signin" className='flex gap-x-2 items-center bg-white hover:bg-backL2 transition-colors h-full px-3 flex-shrink-0 no-underline'>
                <span className='material-icons text-xl leading-none text-secondaryD2'>fingerprint</span>
                <p className='uppercase text-xs font-roboto font-medium'>
                    {homeText[language].login}
                </p>
            </Link>
        </div>
        <div className='block md:hidden h-full'>
            <Dropdown
                position="top-14 right-0"
                buttonIcon={( 
                    <div className="material-icons"> more_vert </div>
                )}
                classes={{
                    container:'h-full',
                    button:'h-full',
                    menu:'p-0',
                }}
            >
                <Link to="pricing" className='flex gap-x-2 items-center bg-white hover:bg-backL2 transition-colors h-full py-4 px-7'>
                    <HandHeart className='h-6 w-6 fill-secondaryD2'/>
                    <p className='uppercase text-xs font-roboto font-medium'>
                    {homeText[language].collaborate}
                    </p>
                </Link>
                <Link to="/auth/signin" className='flex gap-x-2 items-center bg-white hover:bg-backL2 transition-colors h-full py-4 px-7'>
                    <HandHeart color='' className='h-6 w-6 fill-secondaryD2'/>
                    <p className='uppercase text-xs font-roboto font-medium'>
                        {homeText[language].login}
                    </p>
                </Link>
                <LanguagePicker 
                    displayPickerLabel 
                    labelPosition="right" 
                    className="px-3"
                />
            </Dropdown>
        </div>
    </div>
    );
}
 
export default MenuItems;