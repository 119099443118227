import { createContext, useContext, useEffect, useState } from "react";

import { RACE_TRACKS } from "queries/racetrack";
import { useLazyQuery } from "@apollo/client/react";
import { UserContext } from "./UserContext";

export const RaceContext = createContext();

export const RaceProvider = ({ children }) => {
    const { session } = useContext(UserContext);

    const [raceDate, setRaceDate] = useState(new Date);
    const [raceTracks, setRaceTracks] = useState({});

    const [getRacetracks, {data:dataRaceTracks, error:errorRaceTracks, loading:loadingRaceTracks}] = useLazyQuery(RACE_TRACKS);


    useEffect(() => {
        let mounted = true;

        if(mounted && session && session.email) {
            getRacetracks();
        }
        
        return () => {
            mounted = false;
        }
    }, [session]);

    useEffect(() => {
        var mounted = true;
        if(dataRaceTracks && !loadingRaceTracks){
            let auxRaceTracks = {};
            dataRaceTracks.raceTracks?.forEach(RT => {
               auxRaceTracks[RT.id] = RT 
            });

            if(mounted) {
                setRaceTracks(auxRaceTracks);
            }
        }
        return () => {
            mounted = false;
        }
    }, [dataRaceTracks, loadingRaceTracks])


    const value = {
        raceDate,
        setRaceDate,
        raceTracks
    };
    return <RaceContext.Provider value={value}>{children}</RaceContext.Provider>;
}