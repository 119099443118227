import { gql } from "@apollo/client";

export const RACE_TRACKS = gql`
  query RaceTrack {
    raceTracks {
      id
      alpha2
      bet_ref {
        bet_2
        bet_1
        bet_3
        bet_imperfecta
        bet_exacta
        bet_trifecta
        bet_cuatrifecta
      }
      chdate
      city
      coord_dd
      country
      crdate
      currency
      isactive
      name
      province
      tz_utc
      url
      url_live
      url_logo
      url_map
      name_s
    }
  }
`;
