import { gql } from "@apollo/client"

export const GET_DETAIL_HISTORY = gql`
query($dates:[String!], $horse_id:String){
  horseRaceByDates(dates:$dates, horse_id:$horse_id)
  {
   	distance
    course
    raceno
    category
    type
    minutes
    seconds
    milliseconds
    name
    condition
   	racetrack_id
   	date
    status
    competitor{
      jockey_name
      pos
    }
    weatherCode
   	competitors
   	{
      horse_id
     	hKg
      jKg
      jockey_name
      jockey_id
      bet_amt
     	age
      rail
      pos
     	horse_name
     	wind_dist
      withdrawn,
      rodo,
      desmonta
   	}
  }
  videoByDates(dates: $dates)
  {
    url
    racetrack_id
    date
  }

}
`