import React, { useContext } from 'react';
import { SplideSlide } from '@splidejs/react-splide';
import horse_bg02 from 'assets/horse_bg02_lower.webp';
import homeText from 'texts/pages/homeText';
import { ReactComponent as FacebookIcon } from 'assets/icons/svg/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/svg/instagram.svg';
import { BaseContext } from 'contexts/BaseContext';

const Slide1 = () => {
    const { language } = useContext(BaseContext);
    return ( 
    <SplideSlide className="h-full">
        <div
            className='h-full w-full bg-cover bg-center'
            style={{backgroundImage:`url(${horse_bg02})`}}
        >
            <div className='h-full w-full absolute z-0 bg-black bg-opacity-50'/>
            <div className='h-full w-full max-w-[1400px] mx-auto relative flex items-center justify-center '>
                <div className='flex flex-col items-center justify-center gap-y-10 w-1/2'>
                    <div className='flex flex-col items-center justify-center gap-y-5'>
                        <h1 className="font-monsterrat text-center text-2xl md:text-4xl text-white">
                            {homeText[language].transformTitle}
                            <br/>
                            <b>
                                {homeText[language].transformTitle2}
                            </b>
                        </h1>
                        <h3 className="text-base md:text-xl text-white text-center">
                            {homeText[language].weAre}
                        </h3>
                    </div>

                    <div className='flex flex-col gap-y-4 items-center justify-center'>
                        <h5 className='text-white uppercase font-roboto font-bold text-xs'>
                            {homeText[language].findUs}
                        </h5>
                        <div className='flex items-center justify-center gap-x-4'>
                            <a 
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.facebook.com/invasor.ar" 
                                className='h-8 w-8'
                            >
                                <FacebookIcon className='h-8 w-8 fill-white' />
                            </a>
                            <a 
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.facebook.com/invasor.ar" 
                                className='h-8 w-8'
                            >
                                <InstagramIcon className='h-8 w-8 fill-white' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='px-12 w-1/2'> 
                    <div className="relative pb-[56.25%]">
                        <iframe
                            src="https://www.youtube-nocookie.com/embed/ZZO9kIOH5EU"
                            frameBorder="0"
                            allowFullScreen={true}
                            title="Invasor"
                            className="absolute top-0 left-0 w-full h-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    </SplideSlide>
 );
}
 
export default Slide1;