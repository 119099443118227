import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RaceContext } from 'contexts/RaceContext';
import React, { useContext, useState } from 'react';
import { BaseContext } from 'contexts/BaseContext';

const Calendar = () => {
    const { raceDate, setRaceDate } = useContext(RaceContext);
    const { language, locales } = useContext(BaseContext); 
    const [anchorElement, setAnchorElement] = useState(null);
    const menuOpen = Boolean(anchorElement);

    const handleDateChange= (e) => {
        setRaceDate(e)
        if (e.getDate() !== raceDate?.getDate()) {
            setAnchorElement(null);
        }
    }
    return ( 
        <>
            <IconButton
                onClick={(e)=>setAnchorElement(e.currentTarget)}
                className="h-min p-3"
            >
                <span className="material-icons">calendar_month</span>
            </IconButton>
            <Menu
                id="calendar-menu"
                anchorEl={anchorElement}
                open={menuOpen}
                onClose={()=>setAnchorElement(null)}
            >
                <LocalizationProvider 
                    dateAdapter={AdapterDateFns}
                    adapterLocale={locales[language]}    
                >
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        color='secondary'
                        minDate={
                            (location.pathname.split('/')[2] === 'edit') ?
                            new Date(new Date().getTime() - (24*60*60*1000)):
                            new Date('08/01/2019')
                        }
                        views={["year", "month", "day"]}
                        value={raceDate}
                        format='dd/MM/yyyy'
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    >

                    </StaticDatePicker>
                </LocalizationProvider>
            </Menu>
        </> 
    );
}
 
export default Calendar;