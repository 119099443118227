import { gql } from "@apollo/client";

export const GET_JOCKEY_YEAR = gql`
query jockeyByYear($jockey_id: ID!, $year: String!) {
  jockeyByYear(id: $jockey_id, year: $year) {
    racetrack_id
    weatherCode
    date
    distance
    course
    betamt_uom
    status
    type
    prizes {
      prize1
      prize2
      prize3
      prize4
      prize5
      prize6
      prize7
      prize8
    }
    competitor {
      pos
      rail
      bet_amt
      horse_id
      horse_name
    }
  }
}
`