import { BaseContext } from "contexts/BaseContext";
import React, { useContext, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const LanguagePicker = ({
  displayPickerLabel = false,
  labelPosition = "left",
  className = "",
}) => {
  const { language, setLanguage } = useContext(BaseContext);
  const [anchorElement, setAnchorElement] = useState(null);
  const menuOpen = Boolean(anchorElement);

  const languages = [
    {
      code: "es",
      flagCode: "ar",
      name: {
        es: "Español",
        en: "Spanish",
        pt: "Espanhol",
      },
    },
    {
      code: "en",
      flagCode: "us",
      name: {
        es: "Inglés",
        en: "English",
        pt: "Inglês",
      },
    },
    {
      code: "pt",
      flagCode: "br",
      name: {
        es: "Portugués",
        en: "Portuguese",
        pt: "Português",
      },
    },
  ];

  const dataByCode = {
    en: {
      flagCode: "us",
      name: {
        es: "Inglés",
        en: "English",
        pt: "Inglês",
      },
    },
    pt: {
      flagCode: "br",
      name: {
        es: "Portugués",
        en: "Portuguese",
        pt: "Português",
      },
    },
    es: {
      flagCode: "ar",
      name: {
        es: "Español",
        en: "Spanish",
        pt: "Espanhol",
      },
    },
  };

  const handleLanguageClick = (language = "es") => {
    setLanguage(language);
    setAnchorElement(null);
  }
  return (
    <>
      <IconButton 
        onClick={(e)=>setAnchorElement(e.currentTarget)}
      >
        <div className={`flex items-center gap-x-2 w-full ${className}`}>
          {displayPickerLabel && labelPosition === "left" && (
            <p className="uppercase text-xs font-roboto font-medium">
              {dataByCode[language] &&
                dataByCode[language].name &&
                dataByCode[language].name[language]}
            </p>
          )}
          <div className="h-6 w-6">
            <CircleFlag
              countryCode={dataByCode[language].flagCode}
              height="24"
            />
          </div>
          {displayPickerLabel && labelPosition === "right" && (
            <p className="uppercase text-xs font-roboto font-medium">
              {dataByCode[language] &&
                dataByCode[language].name &&
                dataByCode[language].name[language]}
            </p>
          )}
        </div>
      </IconButton>
      <Menu
        id="languages-menu"
        anchorEl={anchorElement}
        open={menuOpen}
        onClose={()=>setAnchorElement(null)}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code}>
            <IconButton onClick={()=>handleLanguageClick(lang.code)}>
              <CircleFlag countryCode={lang.flagCode} height="24" />
            </IconButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguagePicker;
