import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { GET_VIDEOS_BY_DATE_RT } from 'queries/videos';
import { RaceContext } from 'contexts/RaceContext';
import getVideoId from 'utilities/functions/getYoutubeId';

const Video = ({currentRace, dayRacetimes, RT}) => {

    const { raceDate, raceTracks } = useContext(RaceContext);

    const {
        data:dataVideos,
    } = useQuery(
        GET_VIDEOS_BY_DATE_RT, {
            skip: !RT || !raceDate || !dayRacetimes,
            variables:{
                dates:dayRacetimes, 
                racetrack_id:RT
            }
        }
    );

    const getIFrame = () => {
        if(
            !currentRace?.date || 
            currentRace?.suspended || 
            !raceTracks
        ) return null;
        
        let finalUrl = '';
        const selectedRaceDate = new Date(currentRace.date).setHours(0, 0, 0, 0);
        const currentDate = new Date().setHours(0, 0, 0, 0);

        if(currentDate > selectedRaceDate) {
            if(!dataVideos?.videoByDatesRT) return null;
            let currentVideo = dataVideos.videoByDatesRT.find(v=>v.date === currentRace.date);
            const id = getVideoId(currentVideo?.url)
            if(!id) return null;
            finalUrl = `https://www.youtube-nocookie.com/embed/${id}`;
        } else {
            const id = getVideoId(raceTracks[RT]?.url_live);
            finalUrl = `https://www.youtube-nocookie.com/embed/${id}`
        }
        if (!finalUrl){
            return null
        };

        return (
            <div className='h-full w-[16rem] md:w-[22.22rem] bg-black'>
                <iframe
                    src={finalUrl}
                    frameBorder="0"
                    allowFullScreen={true}
                    title="Invasor"
                    className='h-full w-full'
                />
            </div>
        )
    };
    

    return ( 
        <>
            { currentRace && getIFrame() }
        </>
    );
}
 
export default Video;