import React, { useContext, useEffect, useState } from "react";

import { BaseContext } from "contexts/BaseContext";
import signInText from "texts/pages/signInText";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";

import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD_SEND } from "queries/auth";
import { Link } from "react-router-dom";
import { validateEmail } from "utilities/validators";

const RecoverForm = ({ setFormToDisplay }) => {
  const { language } = useContext(BaseContext);
  const [resendTimeout, setResendTimeout] = useState(0);
  const [email, setEmail] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState({ ok: true, error: "" });

  const [
    recoverPassword,
    { data: dataRecover, loading: loadingRecover, error: errorRecover },
  ] = useMutation(FORGOT_PASSWORD_SEND, { onError(e) {} });

  const onChange = ({ target }) => {
    if (!error.ok) {
      setError({ ok: true, error: "" });
    }
    setEmail(target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const cleanEmail = email?.trim()?.toLowerCase();
    const generatedError = validateEmail(cleanEmail);
    if (generatedError.ok) {
      recoverPassword({
        variables: {
          email: cleanEmail,
        },
      });
    } else {
      setError(generatedError);
    }
  };

  const formatSeconds = (seconds) => {
    let mins = 0;
    let auxSeconds = seconds;
    while (auxSeconds >= 60) {
      mins += 1;
      auxSeconds -= 60;
    }
    return `${mins > 9 ? mins : `0${mins}`}:${
      auxSeconds > 9 ? auxSeconds : `0${auxSeconds}`
    }`;
  };

  useEffect(() => {
    let mounted = true;
    if (dataRecover && !loadingRecover && mounted) {
      setResendTimeout(180);
      setOpenSuccess(true);
    }

    return () => (mounted = false);
  }, [dataRecover]);

  useEffect(() => {
    let timer;
    if (resendTimeout > 0) {
      timer = setTimeout(() => setResendTimeout(resendTimeout - 1), 1000);
    }
    return () => clearTimeout(timer);
  });

  return (
    <>
      <form onSubmit={onSubmit} className="pb-4">
        <TextField
          id="email"
          value={email}
          onChange={onChange}
          size="small"
          color="secondary"
          className="mt-4 mb-8 w-full"
          error={!error.ok}
          helperText={error.error}
          label={signInText[language].email}
          variant="standard"
          disabled={loadingRecover}
          InputProps={{
            maxLength: 100,
            endAdornment: (
              <InputAdornment position="start">
                <span className="material-icons text-lg text-black">email</span>
              </InputAdornment>
            ),
          }}
        />
        {resendTimeout > 0 && (
          <p className="my-2 max-w-[350px]">
            {`
                      ${signInText[language].timeout1} 
                      ${formatSeconds(resendTimeout)} 
                      ${signInText[language].timeout2}
                  `}
          </p>
        )}
        <div className="flex justify-center">
          {loadingRecover ? (
            <CircularProgress />
          ) : (
            <Button
              className="rounded-full"
              color="secondary"
              variant="contained"
              disabled={resendTimeout > 0}
              size="large"
              type="submit"
            >
              {signInText[language].send}
            </Button>
          )}
        </div>
      </form>
      <div className="flex items-center justify-center gap-x-2">
        <p className="text-base font-roboto text-dark my-2">
          {signInText[language].noAccount}
        </p>
        <Link
          to="/auth/signup"
          className="text-base font-roboto text-blue-400 underline"
        >
          {signInText[language].signUp}
        </Link>
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <p className="text-base font-roboto text-dark my-2">
          {signInText[language].remembered}
        </p>
        <button
          onClick={() => setFormToDisplay("sign_in")}
          className="text-base font-roboto text-blue-400 underline px-0 my-0 bg-transparent border-none cursor-pointer"
        >
          {signInText[language].signInTitle}
        </button>
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setOpenSuccess(false)}
        message={signInText[language].resendSuccess}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenSuccess(false)}
          >
            close
          </span>
        }
      />
    </>
  );
};

export default RecoverForm;
