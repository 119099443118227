import React from "react";

const NormalStepIcon = ({ icon, dayRaces, currentIndex }) => {
  const sand = dayRaces?.[icon]?.course?.toLowerCase()?.includes("arena");

  return (
    <div className="h-auto">
      {currentIndex === icon ? (
        <span className="text-secondaryD1 material-icons text-sm md:text-base leading-none group-hover:scale-125 transition-all">
          brightness_1
        </span>
      ) : currentIndex > icon || currentIndex === icon ? (
        <span
          className={`
            material-icons text-sm md:text-base leading-none transition-all
            ${ sand ? "text-sand group-hover:text-sandD1 group-hover:scale-125" : "text-grass group-hover:text-grassD1"}
          `}
        >
          brightness_1
        </span>
      ) : (
        <span
          className={`
            material-icons text-sm md:text-base leading-none transition-all
            ${ sand ? "text-sandL1 group-hover:text-sand group-hover:scale-125" : "text-grassL1 group-hover:text-grass"}
          `}
        >
          brightness_1
        </span>
      )}
    </div>
  );
};

export default NormalStepIcon;
