import { gql } from "@apollo/client";

export const GET_HORSE_HISTORY_SEARCH = gql`
  query ($horseIdList: [String!], $date: String!) {
    horseRaceByHorsesAndDate(horseIds: $horseIdList, date: $date) {
      _id
      result {
        date
        racetrack_id
        competitors {
          pos
          withdrawn
        }
      }
    }
  }
`;
