import { gql } from "@apollo/client";
export const GET_PREDICTION_BY_URL = gql`
  query PUBLIC_getPredByUrl($url: String!) {
    predictionByUrl(url: $url) {
      alias
      race_id
      chdate
      crdate
      first
      second
      third
      fourth
      short_url
      weatherCode
      tempC
    }
  }
`;
