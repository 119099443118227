import { useQuery } from "@apollo/client";
import { format, isSameDay } from "date-fns";
import { GET_HORSERACE_BY_DATE_RT } from "queries/horserace";
import { GET_HISTORY_BY_DATE_RT } from "queries/horserace";
import { GET_DAY_RACETRACKS } from "queries/horserace";
import { createContext, useContext, useEffect, useState } from "react";
import { BaseContext } from "./BaseContext";
import { RaceContext } from "./RaceContext";

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const { raceDate } = useContext(RaceContext);
  const { redirect, setRedirect } = useContext(BaseContext);

  const [RT, setRT] = useState("");
  const [dayRTS, setDayRTS] = useState({ list: [], date: "" });
  const [lastCalled, setLastCalled] = useState({
    rts: "",
    basicRace: "",
    history: "",
  });
  const [dayRaces, setDayRaces] = useState([]);
  const [dayHistory, setDayHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentRace = dayRaces?.[currentIndex];

  const {
    data: dataRTS,
    loading: loadingRTS,
    error: errorRTS,
    called: calledRTS,
  } = useQuery(GET_DAY_RACETRACKS, {
    skip: lastCalled?.rts && isSameDay(lastCalled.rts, raceDate),
    variables: { date: format(raceDate, "yyyy-MM-dd") },
    returnPartialData: true,
    fetchPolicy: "network-only",
  });

  const {
    data: dataBasicRace,
    loading: loadingBasicRace,
    called: calledBasicRace,
  } = useQuery(GET_HORSERACE_BY_DATE_RT, {
    skip:
      (lastCalled?.basicRace && isSameDay(raceDate, lastCalled.basicRace)) ||
      !RT ||
      (lastCalled?.rts && !isSameDay(raceDate, lastCalled.rts)),
    variables: {
      date: format(raceDate, "yyyy-MM-dd"),
      racetrack_id: RT,
    },
  });

  const {
    data: dataHistory,
    loading: loadingHistory,
    called: calledHistory,
  } = useQuery(GET_HISTORY_BY_DATE_RT, {
    skip:
      (lastCalled?.history && isSameDay(raceDate, lastCalled.history)) ||
      !RT ||
      !dayRaces?.[0] ||
      (lastCalled?.rts && !isSameDay(raceDate, lastCalled.rts)),
    variables: {
      date: format(raceDate, "yyyy-MM-dd"),
      racetrack_id: RT,
    },
  });

  useEffect(() => {
    let mounted = true;

    if (mounted && dataRTS && !loadingRTS) {
      const dayRacetracks = dataRTS.dayRaceTracks;
      if (dayRacetracks.length > 1) {
        setDayRTS({
          list: dayRacetracks,
          date: raceDate,
        });
        setLastCalled({
          ...lastCalled,
          rts: new Date(raceDate),
        });
        //TODO: Prompt choice between racetracks
      } else if (dayRacetracks.length === 1) {
        setRT(dayRacetracks[0]);
        setDayRTS({
          list: dayRacetracks,
          date: raceDate,
        });
        setLastCalled({
          ...lastCalled,
          rts: new Date(raceDate),
        });
      } else {
        setRT();
        setDayRTS({
          list: [],
          date: raceDate,
        });
        setDayRaces([]);
        setLastCalled({
          ...lastCalled,
          basicRace: new Date(raceDate),
          rts: new Date(raceDate),
        });
      }
    }

    return () => (mounted = false);
  }, [dataRTS, loadingRTS]);

  useEffect(() => {
    let mounted = true;
    if (mounted && dataBasicRace && !loadingBasicRace) {
      if (redirect) {
        const indexAux = dataBasicRace.horseRaceDateRT?.findIndex(
          (r) => `${r.raceno}` === `${redirect.raceNo}`
        );
        setCurrentIndex(indexAux >= 0 ? indexAux : 0);
        setRedirect();
      }
      setDayRaces(dataBasicRace.horseRaceDateRT);
      setLastCalled({
        ...lastCalled,
        basicRace: new Date(raceDate),
      });
    }
    return () => (mounted = false);
  }, [dataBasicRace, loadingBasicRace]);

  useEffect(() => {
    let mounted = true;
    if (mounted && dataHistory && !loadingHistory) {
      setDayHistory(dataHistory.horseRaceDateRT);
      setLastCalled({
        ...lastCalled,
        history: new Date(raceDate),
      });
    }
    return () => {
      mounted = false;
    };
  }, [dataHistory]);

  const value = {
    RT,
    dayRTS,
    dayRaces,
    dayHistory,
    currentIndex,
    setCurrentIndex,
    calledRTS,
    calledBasicRace,
    calledHistory,
    lastCalled,
    loadingRTS,
    loadingBasicRace,
    loadingHistory,
    dataRTS,
    dataBasicRace,
    errorRTS,
    currentRace,
  };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
