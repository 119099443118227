import { BaseContext } from 'contexts/BaseContext';
import React, { useContext } from 'react';

const Footer = () => {
    const { language } = useContext(BaseContext);
    const footerText = { 
        en: {  
            footText:'© 2022 INVASOR.ar made in Argentina, with talent and passion 🏇💜',
        },
        es: {  
            footText:'© 2022 INVASOR.ar hecho en Argentina, con talento y pasión 🏇💜',
        },
        pt: {  
            footText:'© 2022 INVASOR.ar feito na Argentina, com talento e paixão 🏇💜 ',
        },
    }

    return ( 
        <div className='invisible w-full flex items-center justify-between px-4 py-2 h-16 absolute bottom-0 left-0 z-50'>
            <a href="http://qr.afip.gob.ar/?qr=LsErsACV8St2QrS3odmERw,," target="_F960AFIPInfo" className='visible'>
                <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt='qr' border="0" className='h-14 w-11 '/>
            </a>
            <p className="text-base text-white visible"> { footerText[language].footText } </p>
        </div> 
    );
}
 
export default Footer;