const resetErrors = ( setErrors ) => {
  setErrors((errors) => ({
    ...errors,
    email: { ok: true, error: "" },
    password: { ok: true, error: "" },
    general: { ok: true, error: "" },
  }));
};

const handleErrors = (errorMessage, loading, setErrors, text, setResendOpen) => {
  if(
    errorMessage && 
    errorMessage.graphQLErrors && 
    errorMessage.graphQLErrors[0] &&
    errorMessage.graphQLErrors[0].message && 
    !loading
  ) {
    const err = errorMessage.graphQLErrors[0].message.toLowerCase();
    if(err.includes('no user found')){
      setErrors(errors => ({
        ...errors,
        general:{
          ok:false,
          error: text.errorMailNoAccount
        }
      }))
    }

    if(err.includes('password')) {
      setErrors(errors => ({
        ...errors,
        general:{
          ok:false,
          error: text.errorMailWrong
        }
      }))
    } 

    if(err.includes('password')) {
      setErrors(errors => ({
        ...errors,
        general:{
          ok:false,
          error: text.errorMailWrong
        }
      }))
    } 
    
    if(err.includes('not verified')) {
      setResendOpen(true);
    }
  }
}

export {
  resetErrors,
  handleErrors,
};