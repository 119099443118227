import React, { useEffect, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { UPDATE_RACE_FULL } from "queries/horserace";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import getIncidentKey from "utilities/functions/getIncidentKey";
import distanceByKey from "utilities/objects/distanceByKey";
import getSortedByDistance from "utilities/functions/getSortedByDistance";
import { UPDATE_VIDEO } from "queries/videos";

const GeneralRaceData = ({
  editedRaces,
  setEditedRaces,
  currentIndex,
  loadingVideos,
  currentUrl,
}) => {
  const [
    updateRace,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_RACE_FULL, { onError(err) {} });
  const currentRace = editedRaces[currentIndex] || {};
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [generalData, setGeneralData] = useState({
    minutes: currentRace.minutes || "",
    seconds: currentRace.seconds || "",
    milliseconds: currentRace.milliseconds || "",
    status: currentRace.status || "",
    url: currentRace.url || "",
    totalamount: currentRace.totalamount || "",
    suspended: currentRace.suspended || false,
    destacada: currentRace.destacada || false,
  });

  const [updateVideo] = useMutation(UPDATE_VIDEO, { onError(err) {} });

  useEffect(() => {
    let mounted = true;
    if (currentUrl !== undefined) {
      setGeneralData({
        ...generalData,
        url: currentUrl,
      });
    }
    return () => (mounted = false);
  }, [currentUrl]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setGeneralData({
        minutes: currentRace.minutes || "",
        seconds: currentRace.seconds || "",
        milliseconds: currentRace.milliseconds || "",
        status: currentRace.status || "",
        url: currentRace.url || "",
        totalamount: currentRace.totalamount || "",
        suspended: currentRace.suspended || false,
        destacada: currentRace.destacada || false,
      });
    }

    return () => (mounted = false);
  }, [currentIndex]);

  useEffect(() => {
    let mounted = true;

    if (dataUpdate && !loadingUpdate && mounted) {
      setOpenSuccess(true);
      resetChanges();
    }

    return () => (mounted = false);
  }, [dataUpdate, loadingUpdate]);

  const resetChanges = () => {
    if (editedRaces?.[currentIndex]) {
      let auxEditedRaces = structuredClone(editedRaces);
      let auxCurrentRace = auxEditedRaces[currentIndex];
      Object.keys(auxCurrentRace).map((field) => {
        if (auxCurrentRace[`${field}_modified`]) {
          delete auxCurrentRace[`${field}_modified`];
        }
      });
      auxCurrentRace.competitors.forEach((comp) => {
        Object.keys(comp).map((comp_field) => {
          if (comp[`${comp_field}_modified`]) {
            delete comp[`${comp_field}_modified`];
          }
        });
      });

      setEditedRaces(auxEditedRaces);
    }
  };

  const handleSave = () => {
    if (currentRace?.id) {
      updateVideo({
        variables: {
          date: currentRace.date,
          url: generalData.url,
          racetrack_id: currentRace.racetrack_id,
        },
      });
      let mutationInput = {
        id: currentRace.id,
        destacada: currentRace.destacada || false,
        suspended: currentRace.suspended || false,
        course: currentRace.course,
        milliseconds: currentRace.milliseconds,
        minutes: currentRace.minutes,
        seconds: currentRace.seconds,
        status: currentRace.status,
        competitors: [],
      };
      const sortedComps = getSortedByDistance(currentRace.competitors);

      sortedComps?.forEach((comp, index) => {
        let pos = comp.pos;
        let dist_value = "";
        const distanceObject = distanceByKey[comp.wind_dist];
        const isIncident = getIncidentKey(comp);

        if (isIncident && !comp.withdrawn) {
          dist_value = comp.wind_dist;
          pos = distanceObject.disablePos;
        } else if (isIncident === "withdrawn") {
          dist_value = "";
          pos = "0";
        } else {
          if (index - 1 >= 0) {
            const pastComp = sortedComps[index - 1];
            if (pastComp.wind_dist === "4cpom") {
              dist_value = pastComp.cpos;
            } else {
              const pastDistanceObject = distanceByKey[pastComp.wind_dist];
              dist_value = pastDistanceObject.distValue;
            }
          }

          if (comp.pos === "-" && !distanceObject.setPos) {
            pos = "0";
          }
        }

        mutationInput.competitors.push({
          pos: String(pos),
          wind_dist: dist_value,
          horse_id: comp.horse_id,
          jockey_id: comp.jockey_id,
          jockey_name: comp.jockey_name,
          stable_id: comp.stable_id,
          stable_name: comp.stable_name,
          jKg: parseFloat(comp.jKg),
          withdrawn: comp.withdrawn,
          rodo: comp.rodo || dist_value === "rodo",
          desmonta: comp.desmonta || dist_value === "desm.",
        });
      });
      updateRace({ variables: { input: mutationInput } });
    }
  };

  const handleChange = (name, value) => {
    setEditedRaces((oldRaces) => {
      const newRaces = structuredClone(oldRaces);
      if (newRaces[currentIndex]) {
        newRaces[currentIndex][name] = value;
        newRaces[currentIndex][`${name}_modified`] = true;
      }
      return newRaces;
    });
  };

  const debouncedHandler = _.debounce(handleChange, 300);

  const handleInitial = ({ target }) => {
    const { name, value } = target;
    setGeneralData({
      ...generalData,
      [name]: value,
    });
    debouncedHandler(name, value);
  };

  const handleCheckbox = (name) => {
    const value = !generalData?.[name];
    setGeneralData({
      ...generalData,
      [name]: value,
    });

    debouncedHandler(name, value);
  };

  return (
    <div className="flex flex-col gap-y-4 md:gap-y-0 md:flex-row md:items-center py-6 px-4 my-2 border border-solid border-back">
      <div className="flex flex-col md:flex-row gap-x-6 gap-y-4 w-full">
        <TextField
          name="status"
          select
          value={generalData.status || ""}
          onChange={handleInitial}
          size="small"
          label="Tipo de Pista"
          variant="standard"
          color="secondary"
          className={`
                        w-full md:w-32 transition-all ${
                          editedRaces?.[currentIndex]?.status_modified
                            ? "bg-secondaryL1"
                            : ""
                        }`}
        >
          <MenuItem value={"Normal"}>Normal</MenuItem>
          <MenuItem value={"Pesada"}>Pesada</MenuItem>
          <MenuItem value={"Humeda"}>Humeda</MenuItem>
          <MenuItem value={"Fangosa"}>Fangosa</MenuItem>
        </TextField>
        <div className="flex items-center gap-x-4">
          <TextField
            size="small"
            name="minutes"
            label="m"
            variant="standard"
            color="secondary"
            value={generalData?.minutes || ""}
            onChange={handleInitial}
            className={`
                            w-16 transition-all ${
                              editedRaces?.[currentIndex]?.minutes_modified
                                ? "bg-secondaryL1"
                                : ""
                            }`}
          />
          <TextField
            size="small"
            name="seconds"
            label="s"
            variant="standard"
            value={generalData?.seconds || ""}
            color="secondary"
            onChange={handleInitial}
            className={`
                            w-16 transition-all ${
                              editedRaces?.[currentIndex]?.seconds_modified
                                ? "bg-secondaryL1"
                                : ""
                            }`}
          />
          <TextField
            size="small"
            name="milliseconds"
            label="ms"
            variant="standard"
            value={generalData?.milliseconds || ""}
            color="secondary"
            onChange={handleInitial}
            className={`
                            w-16 transition-all ${
                              editedRaces?.[currentIndex]?.milliseconds_modified
                                ? "bg-secondaryL1"
                                : ""
                            }`}
          />
          <TextField
            size="small"
            name="totalamount"
            label="Recaudación"
            variant="standard"
            color="secondary"
            value={generalData?.totalamount || ""}
            onChange={handleInitial}
            className={`
                            w-full md:w-24 transition-all ${
                              editedRaces?.[currentIndex]?.totalamount
                                ? "bg-secondaryL1"
                                : ""
                            }`}
          />
        </div>
        <div className="flex flex-col md:flex-row">
          {loadingVideos ? (
            <div className="w-auto md:w-[28rem] h-12 bg-back animate-pulse" />
          ) : (
            <TextField
              size="small"
              name="url"
              label="Link del video"
              variant="standard"
              color="secondary"
              value={generalData?.url || ""}
              onChange={handleInitial}
              className={`
                                w-auto md:w-[28rem] transition-all ${
                                  editedRaces?.[currentIndex]?.url_modified
                                    ? "bg-secondaryL1"
                                    : ""
                                }`}
            />
          )}
        </div>
        <div className="flex justify-between">
          <FormControlLabel
            label="Suspendida"
            className={`pr-3 ${
              editedRaces?.[currentIndex]?.suspended_modified
                ? "bg-secondaryL1"
                : ""
            }`}
            control={
              <Checkbox
                checked={generalData?.suspended}
                onChange={() => handleCheckbox("suspended")}
              />
            }
          />
          <FormControlLabel
            label="Destacada"
            className={`pr-3 ${
              editedRaces?.[currentIndex]?.destacada_modified
                ? "bg-secondaryL1"
                : ""
            }`}
            control={
              <Checkbox
                checked={generalData?.destacada}
                onChange={() => handleCheckbox("destacada")}
              />
            }
          />
        </div>
      </div>
      {loadingUpdate ? (
        <CircularProgress size={80} color="secondary" />
      ) : (
        <button
          type="button"
          className="
                        material-icons bg-primaryD1 text-white text-5xl p-4 rounded-full shadow-lg hover:bg-primaryD2 
                        transition-colors border-none cursor-pointer
                    "
          onClick={handleSave}
        >
          save
        </button>
      )}
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setOpenSuccess(false)}
        message="¡La carrera se guardó exitosamente!"
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenSuccess(false)}
          >
            close
          </span>
        }
      />
      <Snackbar
        open={openFailure}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#EF5350" } }}
        onClose={() => setOpenFailure(false)}
        message="Ocurrió un error al guardar la carrera"
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenFailure(false)}
          >
            close
          </span>
        }
      />
    </div>
  );
};

export default GeneralRaceData;
