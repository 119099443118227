import React, { useContext } from 'react';

import { ApolloConsumer } from '@apollo/client/react';
import { UserContext } from 'contexts/UserContext';

import signOut from 'utilities/functions/signOut';

const SignOutButton = ({children, className = ""}) => {
    const {setSession} = useContext(UserContext);

    return (
        <ApolloConsumer>
            {
                client => (
                    <a
                        onClick={()=>signOut(client, setSession)}
                        className={`${className} no-underline text-backD2`}
                        href="/auth/signin"  
                    >
                        {children}
                    </a>
                )
            }
        </ApolloConsumer>
    );
}
 
export default SignOutButton;
