import React, { useContext } from "react";
import { format } from "date-fns";

//Locale
import raceNumberText from "texts/general/raceNumberText";

//Context
import { BaseContext } from "contexts/BaseContext";

//Style
// import WeatherIcon from "components/WeatherIcon/WeatherIcon";
import Steps from "./Steps";
import ArrowButton from "components/ui/ArrowButton";
import StatusChips from "./Chips/StatusChips";
import TagsChips from "./Chips/TagsChips";

const Head = ({
  changed,
  currentRace = {},
  currentIndex,
  setCurrentIndex,
  dayRaces,
  currentWeather,
}) => {
  const { language } = useContext(BaseContext);

  const inactive =
    currentRace?.date &&
    (new Date() > new Date(currentRace?.date) || currentRace?.suspended);

  return (
    <div className="max-w-[100vw] overflow-auto">
      <div
        className={`
            flex min-h-[10rem] rounded-b-lg shadow
            ${inactive ? "bg-back" : "bg-white"}    
        `}
      >
        <div className="flex flex-row flex-grow ">
          <div className="md:block hidden h-full p-4">
            <ArrowButton
              disabled={currentIndex === 0}
              setVal={setCurrentIndex}
              direction="left"
            />
          </div>
          <div className=" flex-grow flex flex-col">
            <div className="flex  h-auto">
              <Steps
                dayRaces={dayRaces}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                changed={changed}
              />
            </div>
            <div className="flex flex-row  h-full">
              <div className="block md:hidden h-full p-4">
                <ArrowButton
                  disabled={currentIndex === 0}
                  setVal={setCurrentIndex}
                  direction="left"
                />
              </div>
              <div className=" flex flex-grow ">
                <div className="flex flex-col md:flex-row">
                  <div className="flex items-start">
                    <h1 className="text-secondaryD3 text-5xl font-bold md:text-6xl leading-none my-0">
                      {currentRace.raceno}
                    </h1>
                    <h4 className="text-secondaryD3 text-xl md:text-2xl leading-none my-0">
                      {raceNumberText[language]?.[currentRace.raceno]}
                    </h4>
                  </div>
                  <p className="my-0 text-xl md:hidden">
                    {currentRace.date &&
                      format(new Date(currentRace.date), "HH:mm")}
                  </p>
                </div>
                <div className="px-4 py-2 flex flex-col md:flex-row-reverse flex-grow justify-start items-center md:items-end md:justify-between">
                  <div className="flex flex-col items-center gap-y-2">
                    <p className="my-0 text-lg py-0">{`${currentRace.distance}m`}</p>
                    <div className="hidden md:flex">
                      <StatusChips currentRace={currentRace} />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2 flex-grow">
                    <p className="my-0 text-xl py-0 text-center sm:text-start">
                      {currentRace.name}
                    </p>
                    <div className="hidden md:block">
                      <TagsChips currentRace={currentRace} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="block md:hidden h-full p-4">
                <ArrowButton
                  disabled={currentIndex === dayRaces?.length - 1}
                  setVal={setCurrentIndex}
                  direction="right"
                />
              </div>
            </div>
            <div className="md:hidden mx-auto my-4">
              <StatusChips currentRace={currentRace} />
            </div>
          </div>
          <div className="md:block hidden h-full p-4">
            <ArrowButton
              disabled={currentIndex === dayRaces?.length - 1}
              setVal={setCurrentIndex}
              direction="right"
            />
          </div>
        </div>
      </div>
      <div className="p-2 md:hidden">
        <TagsChips currentRace={currentRace} />
      </div>
    </div>
  );
};

export default Head;
