export const filtersText = { 
    en: {  
        grass:'Grass',
        sand:'Sand',
        track:'Track',
        normal:'Fast Track',
        pesada:'Good Track',
        fangosa:'Muddy Track',
        humeda:'Wet Track',
        clean:'Clear filters',
        filterOn:'Filtering by',
        filterOff:'Filter by',
        racetrack:'Racetrack',
        distance:'Distance',
        jockey:'Rider',
        horse:'Horse',
        weather:'Weather',
        status:'Track conditions',
        year:'Year',
    },
    es: {  
        grass:'Césped',
        sand:'Arena',
        track:'Pista',
        normal:'Pista Normal',
        pesada:'Pista Pesada',
        fangosa:'Pista Fangosa',
        humeda:'Pista Húmeda',
        clean:'Limpiar filtros',
        filterOn:'Filtrando por',
        filterOff:'Filtrar por',
        racetrack:'Hipódromo',
        distance:'Distancia',
        jockey:'Jockey / Jocketa',
        horse:'Ejemplar',
        weather:'Clima',
        status:'Condición de pista',
        year:'Año',
    },
    pt: {  
        grass:'Grama',
        sand:'Areia',
        track:'Track',
        normal:'Pista Rápida',
        pesada:'Pista Boa',
        fangosa:'Pista Encharcada',
        humeda:'Pista Molhada',
        clean:'Limpar filtros',
        filterOn:'Filtrando por',
        filterOff:'Filtrar por',
        racetrack:'Hipódromo',
        distance:'Distancia',
        jockey:'Jóquei / Jocketa',
        horse:'Cavalo',
        weather:'Clima',
        status:'Condições da pista',
        year:'Ano',
    },
}

export const filtersTextAlt = {
    grass:{
        en:'Grass',
        es:'Césped',
        pt:'Grama',
    },
    sand:{
        en:'Sand',
        es:'Arena',
        pt:'Areia',
    },
    track:{
        en:'Track',
        es:'Pista',
        pt:'Track,'
    },
    normal:{
        en:'Fast Track',
        es:'Pista Normal',
        pt:'Pista Rápida',
    },
    pesada:{
        en:'Good Track',
        es:'Pista Pesada',
        pt:'Pista Boa',
    },
    fangosa:{
        en:'Muddy Track',
        es:'Pista Fangosa',
        pt:'Pista Encharcada',
    },
    humeda:{
        en:'Wet Track',
        es:'Pista Húmeda',
        pt:'Pista Molhada',
    },
    clean:{
        en:'Clear filters',
        es:'Limpiar filtros',
        pt:'Limpar filtros',
    },
    filterOn:{
        en:'Filtering by',
        es:'Filtrando por',
        pt:'Filtrando por',
    },
    filterOff:{
        en:'Filter by',
        es:'Filtrar por',
        pt:'Filtrar por',
    },
    racetrack:{
        en:'Racetrack',
        es:'Hipódromo',
        pt:'Hipódromo',
    },
    distance:{
        en:'Distance',
        es:'Distancia',
        pt:'Distancia',
    },
    jockey:{
        en:'Rider',
        es:'Jockey / Jocketa',
        pt:'Jóquei / Jocketa',
    },
    horse:{
        en:'Horse',
        es:'Ejemplar',
        pt:'Anotado',
    },
    weather:{
        en:'Weather',
        es:'Clima',
        pt:'Clima',
    },
    status:{
        en:'Track conditions',
        es:'Condición de pista',
        pt:'Condições da pista',
    },
}