import React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import App from './App';
import { BaseProvider } from './contexts/BaseContext';
import { BrowserRouter } from 'react-router-dom';
import Theme from 'components/ui/Theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <BrowserRouter>
        <BaseProvider>
          <Theme>
            <App />
          </Theme>
        </BaseProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  </React.StrictMode>
);