import { gql } from "@apollo/client";

export const GET_PREDICTION = gql`
  query getPrediction($userId: String!, $raceId: String!, $alias: String!) {
    predictionByRace(userId: $userId, raceId: $raceId, alias: $alias) {
      _id
      race_id
      user_id
      first
      second
      third
      fourth
    }
  }
`;
