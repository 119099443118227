const tableText = { 
    en: {  
        horse:'Horse',
        esp: 'A', // ASF age, sex, fur
		age:'Age',
        years: 'Years Old',
        H: 'Mare',
        M: 'Horse',
        Hshort: 'M',
        Mshort: 'H',
		sex:'Sex',
		fur:'Fur',
        eTooltip:'Age',
        jockey:'Jockey',
        rail: 'Rail',
        kg:'Kg',
        stud:'Stable',
        caretaker:'Trainer',
        performances:'Last Perfomances →',
        commentLabel:'Your comment',
        notNow: 'No yet',
        cancel:'Cancel',
        save:'Save',
        ratingDialogTitle:'Do you want to add a comment to your opinion?',
        ratingDialogDesc:'Share your comments with the community.',
        editSuccess:'Your opinion has been saved successfully.',
        editFailure:'There was an error saving your opinion.',
        noData:'There is no historic data.',
        debut:'Debutant',
        parents:'Pedigree',
        pos:'Pos',
        dist:'Dist',
        cpos:'Cpos',
    },
    es: {  
        horse:'Ejemplar',
        esp: 'E', //ESP edad, sexo, pelaje
		age:'Edad',
        years: 'Años',
        H: 'Hembra',
        M: 'Macho',
        Hshort: 'H',
        Mshort: 'M',
		sex:'Sexo',
		fur:'Pelaje',
        eTooltip:'Edad',
        jockey:'Jockey',
        rail: 'Rail',
        kg:'Kg',
        stud:'Stud/ Haras',
        caretaker:'Entrenador',
        performances:'Últimas Perfomances →',
        commentLabel:'Tu comentario',
        notNow: 'Ahora no',
        cancel:'Cancelar',
        save:'Guardar',
        ratingDialogTitle:'¿Quieres agregar un comentario a tu opinión?',
        ratingDialogDesc:'Comparte tu comentario con la comunidad.',
        editSuccess:'Tu opinión se guardó con éxito.',
        editFailure:'Hubo un error al guardar tu opinión.',
        noData:'No registramos datos históricos de este ejemplar.',
        debut:'Debutante',
        parents:'Pedigree',
        pos:'Pos',
        dist:'Dist',
        cpos:'Cpos',
    },
    pt: {  
        horse:'Anotado',
        esp: 'I', // ISP idade, sexo, pelo
		age:'Idade',
        years: 'Anos',
        H: 'Égua',
        M: 'Cavalo',
        Hshort: 'É',
        Mshort: 'C',
		sex:'Sexo',
		fur:'Pelo',
        eTooltip:'Idade',
        jockey:'Jóquei',
        rail: 'Rail',
        kg:'Kg',
        stud:'Stud/ Haras',
        caretaker:'Treinador',
        performances:'Últimas Perfomances →',
        commentLabel:'Seu comentário',
        notNow: 'Agora não',
        cancel:'Cancelar',
        save:'Salvar',
		ratingDialogTitle: '¿Deseja adicionar um comentário à sua opinião?',
        ratingDialogDesc: 'Compartilhe seu comentário com a comunidade.',
        editSuccess: 'Sua opinião foi salva com sucesso.',
        editFailure: 'Ocorreu um erro ao salvar sua opinião.',
        noData:'Não há dados históricos.',
        debut:'Debutante',
        parents:'Pedigree',
        pos:'Pos',
        dist:'Dist',
        cpos:'Cpos',
    },
}

export default tableText;