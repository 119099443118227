const raceNumberText = { 
    en: {  
        1:'st',
        2: 'nd',
        3: 'rd',
        4: 'th',
        5: 'th',
        6: 'th',
        7: 'th',
        8: 'th',
        9: 'th',
        10: 'th',
        11: 'th',
        12: 'th',
        13: 'th',
        14: 'th',
        15: 'th',
        16: 'th',
        17: 'th',
        18: 'th',
        19: 'th',
        20: 'st',
        21: 'rd',
        22: 'nd',
    },
    es: {  
        1:'a',
        2: 'a',
        3: 'a',
        4: 'a',
        5: 'a',
        6: 'a',
        7: 'a',
        8: 'a',
        9: 'a',
        10: 'a',
        11: 'a',
        12: 'a',
        13: 'a',
        14: 'a',
        15: 'a',
        16: 'a',
        17: 'a',
        18: 'a',
        19: 'a',
        20: 'a',
        21: 'a',
        22: 'a',
    },
    pt: {  
        1:'a',
        2: 'a',
        3: 'a',
        4: 'a',
        5: 'a',
        6: 'a',
        7: 'a',
        8: 'a',
        9: 'a',
        10: 'a',
        11: 'a',
        12: 'a',
        13: 'a',
        14: 'a',
        15: 'a',
        16: 'a',
        17: 'a',
        18: 'a',
        19: 'a',
        20: 'a',
        21: 'a',
        22: 'a',
    },
}

export default raceNumberText;