const INITIAL_DISTANCE_STATS_OBJECT = {
  place: 0,
  first: 0,
  total: 0,
  prizes: 0,
  prizes_first:0,
  prizes_objects: {},
  prizes_times: 0,
  prizes_times_first: 0,
  lastRace:{},
  sand: 0,
  sandFirst: 0,
  sandPlace: 0,
  grassFirst: 0,
  grassPlace: 0,
  grass: 0,
  highest_prizes: false,
  highest_effic: false,
  highest_prizes_vict: false,
  highest_effic_vict: false,
  rising:false,
  detail: {},
};

export const getJockeyStats = (races) => {
  if(races) {

    let yearStats = {
      won: 0,
      no_place: 0,
      place: 0,
      total: 0,
      sand: 0,
      grass: 0,
      earnings_total: 0,
      bets_average: 0,
      bets_min: 0,
      bets_max: 0,
      betamt_uom: "ARS",
      distance: {
        "1mile_fourth": {
          ...JSON.parse(JSON.stringify(INITIAL_DISTANCE_STATS_OBJECT)),
          min: 0,
          max: 800,
          name: "mile_fourth",
          disabled: true,
        },
        "2sprinter": {
          ...JSON.parse(JSON.stringify(INITIAL_DISTANCE_STATS_OBJECT)),
          min: 800,
          max: 1300,
          name: "sprinter",
        },
        "3mile": {
          ...JSON.parse(JSON.stringify(INITIAL_DISTANCE_STATS_OBJECT)),
          min: 1300,
          max: 1600,
          name: "mile",
        },
        "4middle_dist": {
          ...JSON.parse(JSON.stringify(INITIAL_DISTANCE_STATS_OBJECT)),
          min: 1600,
          max: 2000,
          name: "middle_dist",
        },
        "5long_dist": {
          ...JSON.parse(JSON.stringify(INITIAL_DISTANCE_STATS_OBJECT)),
          min: 2000,
          max: 99999999,
          name: "long_dist",
        },
      },
    };
  
    let bets_counter = 0;
    races.forEach((race, index) => {
      const comp = race.competitor;
      if (comp && !comp.withdrawn && !isNaN(parseInt(comp.pos))) {
        yearStats.total++;
        const pos = parseInt(comp.pos);
        if (pos === 1) {
          yearStats.won++;        
        } else if (pos > 5) {
          yearStats.no_place++;
        } else if (pos > 1 && pos <= 5) {
          yearStats.place++;
        }
        yearStats[/arena/i.test(race.course) ? "sand" : "grass"]++;
  
  
        let prizes = Object.values(race.prizes);
        prizes = prizes.slice(1, prizes.length);
  
        const race_distance = parseInt(race.distance);
  
        Object.keys(yearStats.distance).some((distance_category) => {
          let distanceObject = yearStats.distance[distance_category];
          if (
            race_distance >= distanceObject.min &&
            race_distance < distanceObject.max
          ) {
            distanceObject.lastRace = JSON.parse(JSON.stringify(race));
            distanceObject.total++;
            distanceObject[/arena/i.test(race.course) ? "sand" : "grass"]++;
  
            if (pos === 1) {
              distanceObject.first++;
              distanceObject.place++;
              if (/arena/i.test(race.course)) {
                distanceObject.sandFirst++;
                distanceObject.sandPlace++;
              } else {
                distanceObject.grassFirst++;
                distanceObject.grassPlace++;
              }
            } else if (pos <= 5) {
              distanceObject.place++;
              if (/arena/i.test(race.course)) {
                distanceObject.sandPlace++;
              } else {
                distanceObject.grassPlace++;
              }
            }
  
            if (pos === 1 && race.type.toLowerCase() !== "l") {
              distanceObject.prizes_objects[race.type] =
                (distanceObject.prizes_objects[race.type] || 0) + 1;
            }
  
            if (prizes[pos - 1] && !isNaN(prizes[pos - 1])) {
              distanceObject.prizes += prizes[pos - 1];
              distanceObject.prizes_times++;
              if(pos === 1) {
                distanceObject.prizes_first += prizes[pos - 1];
                distanceObject.prizes_times_first++;
              }
            }
  
            if (!distanceObject.detail[race_distance]) {
              distanceObject.detail[race_distance] = {
                total: 0,
                place: 0,
                first: 0,
                sand: 0,
                grass: 0,
                prizes: 0,
                prizes_first: 0,
                prizes_times: 0,
                prizes_times_first: 0,
                rail: {},
                highest_prizes: false,
                highest_effic: false,
                highest_prizes_vict: false,
                highest_effic_vict: false,
                effective_prizes: false,
                effective_prizes_vict: false,
              };
            }
            let detail_distance = distanceObject.detail[race_distance];
            detail_distance.total++;
            detail_distance.place += pos <= 5 ? 1 : 0;
            detail_distance.first += pos === 1 ? 1 : 0;
            detail_distance[/arena/i.test(race.course) ? "sand" : "grass"]++;
            detail_distance.prizes += prizes[pos - 1] || 0;
            detail_distance.prizes_times += prizes[pos - 1] ? 1 : 0;
            if(pos === 1) {
              detail_distance.prizes_first += prizes[pos - 1] || 0;
              detail_distance.prizes_times_first += prizes[pos - 1] ? 1 : 0;
            }
  
            if (!detail_distance.rail[comp.rail]) {
              detail_distance.rail[comp.rail] = { total: 0, grass: 0, sand: 0 };
            }
            detail_distance.rail[comp.rail].total++;
            detail_distance.rail[comp.rail][
              /arena/i.test(race.course) ? "sand" : "grass"
            ]++;
            return true;
          } else {
            return false;
          }
        });
  
        if (prizes[pos - 1] && !isNaN(prizes[pos - 1])) {
          yearStats.earnings_total += prizes[pos - 1];
        }
  
        if (comp.bet_amt && !isNaN(comp.bet_amt)) {
          yearStats.bets_average += comp.bet_amt;
          bets_counter++;
          if (index === 0 || comp.bet_amt > yearStats.bets_max) {
            yearStats.bets_max = comp.bet_amt;
          }
          if (index === 0 || comp.bet_amt < yearStats.bets_max) {
            yearStats.bets_min = comp.bet_amt;
          }
        }
      }
    });
  
    let highest_prizes = { value: 0, name: "" };
    let highest_prizes_vict = { value: 0, name: "" };
    let highest_effic = { value: 0, name: "" };
    let highest_effic_vict = { value: 0, name: "" };
  
    Object.keys(yearStats.distance).forEach((distance_category, index) => {
      const distanceObject = yearStats.distance[distance_category];
      const effValue = distanceObject.place / distanceObject.total;
      const effValueVict = distanceObject.first / distanceObject.total;
  
      if (distanceObject.prizes > highest_prizes.value) {
        highest_prizes.value = distanceObject.prizes;
        highest_prizes.name = `${index + 1}${distanceObject.name}`;
      }
  
      if (distanceObject.prizes_first > highest_prizes_vict.value) {
        highest_prizes_vict.value = distanceObject.prizes_first;
        highest_prizes_vict.name = `${index + 1}${distanceObject.name}`;
      }
  
      if (effValue > highest_effic.value) {
        highest_effic.value = effValue;
        highest_effic.name = `${index + 1}${distanceObject.name}`;
      }
  
      if(effValueVict > highest_effic_vict.value){
        highest_effic_vict.value = effValueVict;
        highest_effic_vict.name = `${index + 1}${distanceObject.name}`;
      }
  
      let highest_prizes_detail = { value: 0, name: "" };
      let highest_prizes_detail_vict = { value: 0, name: "" };
      let highest_effic_detail = { value: 0, name: "" };
      let highest_effic_detail_vict = { value: 0, name: "" };
      let effective_prizes_detail = { value: 0, name: ""};
      let effective_prizes_detail_vict = { value: 0, name: ""};
      
      if(Object.keys(distanceObject.lastRace).length) {
        const isLatestPlace = parseInt(distanceObject.lastRace.competitor.pos) <= 5 && parseInt(distanceObject.lastRace.competitor.pos) > 0;
        if(isLatestPlace && distanceObject.place > 0) {
          let recentEffectivity = (distanceObject.place-1) / distanceObject.total;
          if(recentEffectivity < effValue && effValue !== '.000'){
            distanceObject.rising = true;
          }
        }
      }
  
      Object.keys(distanceObject.detail).forEach((distance_detail) => {
        const detailObject = distanceObject.detail[distance_detail];
        const effValueDetail = detailObject.place / (detailObject.total || 1);
        const effValueDetailVict = detailObject.first / (detailObject.total || 1);
        const effPrizes = detailObject.prizes_times / (detailObject.total || 1);
        const effPrizesVict = detailObject.prizes_times_first / (detailObject.total || 1);
  
        if (detailObject.prizes > highest_prizes_detail.value) {
          highest_prizes_detail.value = detailObject.prizes;
          highest_prizes_detail.name = parseInt(distance_detail);
        }
  
        if (detailObject.prizes_first > highest_prizes_detail_vict.value) {
          highest_prizes_detail_vict.value = detailObject.prizes_first;
          highest_prizes_detail_vict.name = parseInt(distance_detail);
        }
        if (effPrizes > effective_prizes_detail.value) {
          effective_prizes_detail.value = detailObject.prizes_times / detailObject.total;
          effective_prizes_detail.name = parseInt(distance_detail);
        }
        if (effPrizesVict > effective_prizes_detail_vict.value) {
          effective_prizes_detail_vict.value = detailObject.prizes_times_first / detailObject.total;
          effective_prizes_detail_vict.name = parseInt(distance_detail);
        }
  
        if (effValueDetail > highest_effic_detail.value) {
          highest_effic_detail.value = effValueDetail;
          highest_effic_detail.name = parseInt(distance_detail);
        }
  
        if (effValueDetailVict > highest_effic_detail_vict.value) {
          highest_effic_detail_vict.value = effValueDetailVict;
          highest_effic_detail_vict.name = parseInt(distance_detail);
        }
      });
  
      if (distanceObject.detail[highest_prizes_detail.name]) {
        distanceObject.detail[highest_prizes_detail.name].highest_prizes = true;
      } else {
        distanceObject.detail[highest_prizes_detail.name] = { highest_prizes: true };
      }
      if (distanceObject.detail[highest_prizes_detail_vict.name]) {
        distanceObject.detail[highest_prizes_detail_vict.name].highest_prizes_vict = true;
      } else {
        distanceObject.detail[highest_prizes_detail_vict.name] = { highest_prizes_vict: true };
      }
  
      if (distanceObject.detail[highest_effic_detail.name]) {
        distanceObject.detail[highest_effic_detail.name].highest_effic = true;
      } else {
        distanceObject.detail[highest_effic_detail.name] = { highest_effic: true };
      }
      if (distanceObject.detail[highest_effic_detail_vict.name]) {
        distanceObject.detail[highest_effic_detail_vict.name].highest_effic_vict = true;
      } else {
        distanceObject.detail[highest_effic_detail_vict.name] = { highest_effic_vict: true };
      }
  
      if (distanceObject.detail[effective_prizes_detail.name]) {
        distanceObject.detail[effective_prizes_detail.name].effective_prizes = true;
      } else {
        distanceObject.detail[effective_prizes_detail.name] = { effective_prizes: true };
      }
      if (distanceObject.detail[effective_prizes_detail_vict.name]) {
        distanceObject.detail[effective_prizes_detail_vict.name].effective_prizes_vict = true;
      } else {
        distanceObject.detail[effective_prizes_detail_vict.name] = { effective_prizes_vict: true };
      }
    });
  
    if (yearStats.distance[highest_prizes.name]) {
      yearStats.distance[highest_prizes.name].highest_prizes = true;
    } else if(highest_prizes.name){
      yearStats.distance[highest_prizes.name] = { highest_prizes: true };
    }
  
    if (yearStats.distance[highest_prizes_vict.name]) {
      yearStats.distance[highest_prizes_vict.name].highest_prizes_vict = true;
    } else if(highest_prizes_vict.name){
      yearStats.distance[highest_prizes_vict.name] = { highest_prizes_vict: true };
    }
  
    if (yearStats.distance[highest_effic.name]) {
      yearStats.distance[highest_effic.name].highest_effic = true;
    } else if(highest_effic.name){
      yearStats.distance[highest_effic.name] = { highest_effic: true };
    }
  
    if (yearStats.distance[highest_effic_vict.name]) {
      yearStats.distance[highest_effic_vict.name].highest_effic_vict = true;
    } else if(highest_effic_vict.name){
      yearStats.distance[highest_effic_vict.name] = { highest_effic_vict: true };
    }
  
    if (bets_counter > 0) {
      yearStats.bets_average = yearStats.bets_average / bets_counter;
    }
  
    if (races && races.length > 0) {
      yearStats.betamt_uom = races[0].betamt_uom;
    }
    return yearStats;
  }
  return {};
};
