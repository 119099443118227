import React, { useContext, useEffect, useState } from "react";

import { BaseContext } from "contexts/BaseContext";
import recoverBg from "assets/recover_bg.webp";
import signInText from "texts/pages/signInText";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";

import { useMutation } from "@apollo/client";
import { Link, Navigate, useParams } from "react-router-dom";
import { VERIFY_CHANGE_PASSWORD } from "queries/auth";
import { CHANGE_PASSWORD } from "queries/auth";
import forgotPasswordText from "texts/pages/forgotPasswordText";
import { validatePassword } from "utilities/validators";

const Recover = () => {
  const { token } = useParams();

  if (!token) {
    return <Navigate to="/auth/signin" replace />;
  }

  const { language } = useContext(BaseContext);
  const [status, setStatus] = useState();
  const [userData, setUserData] = useState({
    password: "",
    showPassword: false,
    passwordConfirm: "",
    showPasswordConfirm: false,
  });
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errors, setErrors] = useState({
    password: { ok: true, error: "" },
    passwordConfirm: { ok: true, error: "" },
  });

  const [
    verifyToken,
    { data: dataVerify, loading: loadingVerify, error: errorVerify },
  ] = useMutation(VERIFY_CHANGE_PASSWORD, { onError(e) {} });

  const [
    changePassword,
    { data: dataChange, loading: loadingChange, error: errorChange },
  ] = useMutation(CHANGE_PASSWORD, { onError(e) {} });

  const onChange = ({ target }) => {
    const { name, value } = target;
    if (!errors.password?.ok || !errors.passwordConfirm?.ok) {
      setErrors({
        password: { ok: true, error: "" },
        passwordConfirm: { ok: true, error: "" },
      });
    }
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const generatedErrors = {
      password: validatePassword(userData.password),
      passwordConfirm:
        userData?.password === userData?.passwordConfirm
          ? { ok: true, error: "" }
          : { ok: false, error: "Las contraseñas ingresadas no coinciden" },
    };
    if (generatedErrors.password?.ok && generatedErrors.passwordConfirm?.ok) {
      changePassword({variables:{
        id:dataVerify?.verifyForgotPassword?.id,
        password:userData.password
      }})
    } else {
      setErrors(generatedErrors);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (token && mounted) {
      verifyToken({ variables: { token: token } });
    }
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (dataVerify && !loadingVerify && mounted) {
      setStatus("verified");
    }

    return () => (mounted = false);
  }, [dataVerify]);

  useEffect(() => {
    let mounted = true;
    if (dataChange && !loadingChange && mounted) {
      setStatus('changed');
      setOpenSuccess(true);
    }

    return () => (mounted = false);
  }, [dataChange]);

  useEffect(() => {
    let mounted = true;
    if (errorVerify && !loadingVerify && mounted) {
      setStatus("invalid");
    }

    return () => (mounted = false);
  }, [errorVerify]);

  return (
    <div
      className="flex-grow flex flex-col items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${recoverBg})` }}
    >
      <div className="absolute h-full w-full left-0 top-0 bg-black bg-opacity-50 z-0" />
      <div className="mx-4 md:min-w-[26rem] min-h-[25rem] z-10">
        <h1 className="text-3xl text-white font-roboto font-bold py-2">
          {signInText[language].recoverPasswordTitle}
        </h1>
        <div className="bg-white shadow-lg rounded-md p-8 pt-16 flex flex-col">
          {status === "verified" && (
            <form onSubmit={onSubmit} className="pb-4">
              <TextField
                name="password"
                value={userData.password}
                onChange={onChange}
                size="small"
                color="secondary"
                error={!errors.password?.ok}
                helperText={errors.password?.error}
                label={forgotPasswordText[language].password}
                variant="standard"
                disabled={loadingChange}
                type={userData.showPassword ? "text" : "password"}
                InputProps={{
                  maxLength: 100,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setUserData((currentData) => ({
                            ...currentData,
                            showPassword: !currentData.showPassword,
                          }))
                        }
                        className="py-1"
                      >
                        <span className="material-icons text-lg text-black">
                          {userData.showPassword
                            ? "visibility_off"
                            : "visibility"}
                        </span>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className="mt-4 mb-8 w-full"
              />
              <TextField
                name="passwordConfirm"
                value={userData.passwordConfirm}
                onChange={onChange}
                size="small"
                color="secondary"
                error={!errors.passwordConfirm?.ok}
                helperText={errors.passwordConfirm?.error}
                label={forgotPasswordText[language].passwordConfirm}
                variant="standard"
                disabled={loadingChange}
                type={userData.showPasswordConfirm ? "text" : "password"}
                InputProps={{
                  maxLength: 100,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setUserData((currentData) => ({
                            ...currentData,
                            showPasswordConfirm:
                              !currentData.showPasswordConfirm,
                          }))
                        }
                        className="py-1"
                      >
                        <span className="material-icons text-lg text-black">
                          {userData.showPasswordConfirm
                            ? "visibility_off"
                            : "visibility"}
                        </span>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className="mt-4 mb-8 w-full"
              />
              <div className="flex justify-center">
                {loadingChange ? (
                  <CircularProgress />
                ) : (
                  <Button
                    className="rounded-full"
                    color="secondary"
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {signInText[language].send}
                  </Button>
                )}
              </div>
            </form>
          )}
          {status === "invalid" && (
            <p className="text-danger max-w-[352px] text-center">
              Error al validar la cuenta, verifique que el link del mail
              recibido sea el correcto
            </p>
          )}
          {status === "changed" && (
            <div className="flex flex-col justify-center items-center">
              <p className="max-w-[352px] text-center">
                Contraseña cambiada con éxito!
              </p>
              <Link
                to="/auth/signin"
                className="text-base font-roboto text-blue-400 underline"
              >
                Ir a Iniciar Sesión
              </Link>
            </div>
          )}
          {(!status || loadingVerify) && (
            <CircularProgress size={60} className="mx-auto" />
          )}
        </div>
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#15BD93" } }}
        onClose={() => setOpenSuccess(false)}
        message={signInText[language].resendSuccess}
        action={
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenSuccess(false)}
          >
            close
          </span>
        }
      />
    </div>
  );
};

export default Recover;
