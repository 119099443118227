import { gql } from "@apollo/client";

export const UPDATE_RACE_FULL = gql`
  mutation Mutation($input: FullHorseRaceInput) {
    fullHorseRaceEdit(input: $input) {
      competitors {
        pos
        rail
        id
        horse_name
        horse_id
        horse_gender
      }
      suspended
      racetrack_id
      destacada
    }
  }
`;
