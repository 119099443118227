import React, { useContext } from 'react';
import { SplideSlide } from '@splidejs/react-splide';
import horse_bg04 from 'assets/horse_bg04_lower.webp';
import homeText from 'texts/pages/homeText';
import { BaseContext } from 'contexts/BaseContext';

const Slide1 = () => {
    const { language } = useContext(BaseContext);
    return ( 
    <SplideSlide className="h-full">
        <div
            className='h-full w-full bg-cover bg-center'
            style={{backgroundImage:`url(${horse_bg04})`}}
        >
            <div className='h-full w-full absolute z-0 bg-black bg-opacity-50'/>
            <div className='flex h-full pb-10 w-full items-center justify-center relative'>
                <h1 className="font-monsterrat text-2xl md:text-5xl text-white font-bold">
                    {homeText[language].from}
                    <br/>
                    {homeText[language].to}
                </h1>
            </div>
        </div>
    </SplideSlide>
 );
}
 
export default Slide1;