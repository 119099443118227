import React from "react";
import { CircleFlag } from "react-circle-flags";

const Pedigree = ({ details, incident }) => {
  if (!details) return <div className="h-0.5 w-10/12 bg-back" />;

  return (
    <>
      {details.sire && details.dam && (
        <>
          <div className="flex items-center justify-end">
            <p
              className={`text-right my-0 ${
                incident ? "text-white" : "text-blue-300"
              }`}
            >
              {`♂ ${details.sire}`}
            </p>
            <CircleFlag
              countryCode={details.sire_cty?.toLowerCase()}
              height="16"
              className="ml-2"
            />
          </div>
          <div className="w-full h-[1px] bg-back" />
          <div className="flex items-center justify-end">
            <p
              className={`text-right my-0 ${
                incident ? "text-white" : "text-red-300"
              }`}
            >
              {`♀ ${details?.dam}`}
            </p>
            <CircleFlag
              countryCode={details?.dam_cty?.toLowerCase()}
              height="16"
              className="ml-2"
            />
          </div>
        </>
      )}
    </>
  );
};

export default Pedigree;
