import React, { useContext } from "react";
import { BaseContext } from "contexts/BaseContext";

import logo_animated from "assets/icons/logo_animated.gif";
import { Link } from "react-router-dom";
import Calendar from "./Calendar";
import SearchBar from "./SearchBar";
import MenuItems from "./MenuItems";
import SearchBarMobile from "./SearchBarMobile";
import MenuItemsMobile from "./MenuItemsMobile";

const NavBar = () => {
  const { clientWidth } = useContext(BaseContext);
  return (
    <div className="bg-white shadow-lg flex w-full px-4 md:px-6 z-40 justify-between relative h-14 md:h-auto items-center">
      <div className="flex items-center gap-x-8">
        <Calendar />
        <Link
          to="/app/main"
          className="md:block hidden"
        >
          <img
            src={logo_animated}
            alt="invasor_logo"
            className="h-16"
          />
        </Link>
      </div>
      {clientWidth > 768 ? (
        <>
          <SearchBar />
          <MenuItems />
        </>
      ) : (
        <div className="flex flex-grow justify-end items-center gap-x-2">
          <SearchBarMobile />
          <MenuItemsMobile />
        </div>
      )}
    </div>
  );
};

export default NavBar;
