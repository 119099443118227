import React from "react";

const Stepper = ({ children, activeStep }) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isActive: activeStep === index });
    }
    return child;
  });

  return <div className="flex w-full justify-between p-2">{childrenWithProps}</div>;
};

export default Stepper;
