import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { format } from "date-fns";

import "chart.js/auto";

import SideContent from "./SideContent";

//Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_JOCKEY_PROFILE } from "queries/jockey";

import Breadcrumb from 'components/ui/Breadcrumb';
import { BaseContext } from "contexts/BaseContext";
import MainContent from "./MainContent";
import { breadCrumbText } from "texts/components/breadCrumbText";

const JockeyDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [horseToAdd, setHorseToAdd] = useState("");
  const [jockeyProfile, setJockeyProfile] = useState();
  const [previousPath, setPreviousPath] = useState([]);

  const jockey_id = location.pathname.split("/")[3];

  if (!jockey_id) {
    navigate("/app/main");
  }

  const { language } = useContext(BaseContext);
  //graphql
  const [getJockeyData, { data: dataJockey, loading: loadingJockey }] =
    useLazyQuery(GET_JOCKEY_PROFILE);

  //States

  //useEffect
  useEffect(() => {
    var mounted = true;
    let breadCrumbPaths = [];
    if(location && location.state) {
      breadCrumbPaths.push({
        text: breadCrumbText[language].main,
        href: "/app/main",
      });
      
      if(location.state.prev === "search") {
        breadCrumbPaths.push({
          text: breadCrumbText[language].search,
          href: `/app/search/${location.state.param}`,
        });
      }

      if(location.state.prev === "detail") {
        breadCrumbPaths.push({
          text: breadCrumbText[language].detail,
          href: `/app/detail`,
        })
        breadCrumbPaths.push({
          text: breadCrumbText[language].jockey,
          href:""
        })
      }
    }

    if (mounted) {
      setPreviousPath(breadCrumbPaths);
      let oneMonthAgo = new Date();
      let currentYear = format(new Date(), "yyyy");
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      oneMonthAgo = format(new Date(oneMonthAgo), `yyyy-MM-dd`);
      if (jockey_id) {
        getJockeyData({ variables: { jockeyId: jockey_id } });
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    var mounted = true;
    if (dataJockey && !loadingJockey && mounted) {
      var auxJockeyData = { ...dataJockey.jockeyProfile };
      auxJockeyData.next_mounts = {};
      dataJockey.jockeyProfile.next_mounts.forEach((mount, index) => {
        var formattedDate = format(new Date(mount.date), `yyyy-MM-dd`);
        if (
          index > 0 &&
          formattedDate ===
            dataJockey.jockeyProfile.next_mounts[index - 1].date.substring(
              0,
              10
            )
        ) {
          auxJockeyData.next_mounts[formattedDate].push(mount);
        } else {
          auxJockeyData.next_mounts[formattedDate] = [mount];
        }
      });
      setJockeyProfile(auxJockeyData);
    }

    return () => {
      mounted = false;
    };
  }, [dataJockey]);

  return (
    <section className="flex flex-col flex-grow">
      <Breadcrumb
        path={previousPath}
      />
      <div className="block md:flex gap-x-2 flex-grow">
        <div className='w-full lg:w-[368px] shadow-xl flex-shrink-0'>
          <SideContent
            jockeyData={jockeyProfile}
            jockey_id={jockey_id}
            setHorseToAdd={setHorseToAdd}
          />
        </div>
        <MainContent jockey_id={jockey_id}/>
      </div>
    </section>
  );
};

export default JockeyDetail;
