import React, { useContext, useState } from "react";

import { UserContext } from "contexts/UserContext";
import { BaseContext } from "contexts/BaseContext";

import { useNavigate } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

import navBarText from "texts/components/navbarText";
import SignOutButton from "utilities/components/SignOut";

const ProfileMenu = () => {
  const { language } = useContext(BaseContext);
  const { session } = useContext(UserContext);

  const [anchor, setAnchor] = useState(null);
  const menuOpen = Boolean(anchor);
  const navigate = useNavigate();

  const handleRedirect = () => {
    setAnchor(null);
    navigate("/app/profile");
  };

  return (
    <>
      <IconButton
        onClick={(e) => setAnchor(e.currentTarget)}
        className="material-icons h-12 w-12"
      >
        <Avatar
          alt={
            session?.alias
              ? session.alias[0]
              : session?.me?.firstname
              ? session?.me?.firstname[0]
              : "U"
          }
          src={session?.image}
          className="h-8 w-8"
        >
          {`${session?.alias ? session?.alias[0] : "U"}`}
        </Avatar>
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={anchor}
        open={menuOpen}
        onClose={() => setAnchor(null)}
      >
        <MenuItem onClick={handleRedirect}>
          {navBarText[language].profile}
        </MenuItem>
        <MenuItem className="p-0">
          <SignOutButton className="px-4 py-1.5">
            {navBarText[language].logout}
          </SignOutButton>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
