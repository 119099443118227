import { useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { CircleFlag } from "react-circle-flags";

const NotificationCreator = () => {
  const [notifications, setNotifications] = useState({
    en: "",
    es: "",
    pt: "",
  });
  const [test, setTest] = useState(false);

  const langData = {
    en: { name: "Inglés", country: "gb" },
    es: { name: "Español", country: "ar" },
    pt: { name: "Portugués", country: "br" },
  };

  const handleChange = (lang, event) => {
    const { value } = event.target;
    setNotifications({
      ...notifications,
      [lang]: value,
    });
  };

  const handleSave = () => {
    // console.log("Save url");
  };
  
  return (
    <div className="flex flex-col gap-y-4 md:gap-y-0 md:flex-row md:items-center py-6 px-4 bg-white shadow my-2">
      <FormControlLabel
        label="Test"
        labelPlacement="bottom"
        control={ <Checkbox checked={test} onChange={() => setTest(!test)} /> }
      />
      <div className="flex flex-col flex-grow gap-y-4">
        {Object.keys(notifications).map((lang) => (
          <div 
            className="flex flex-grow gap-x-4" 
            key={lang}
          >
            <div className="flex items-center gap-x-2">
              <p>{lang.toUpperCase()}</p>
              <CircleFlag
                countryCode={langData[lang].country?.toLowerCase()}
                height="12"
              />
            </div>
            <div className="flex flex-grow">
              <TextField
                value={notifications[lang]}
                onChange={(e) => handleChange(lang, e)}
                size="small"
                color="secondary"
                label={`Notificación en ${langData[lang].name}`}
                variant="standard"
                inputProps={{ maxLength: 100 }}
                className="w-full"
              />
            </div>
          </div>
        ))}
      </div>
      <button
        className="
                    bg-primaryD1 hover:bg-primaryD2 transition-colors text-white border-none cursor-pointer
                    flex flex-shrink-0 items-center p-3 rounded-full gap-x-2 md:ml-2"
        type="button"
        onClick={handleSave}
      >
        Enviar notificaciones
        <span className="material-icons text-white">send</span>
      </button>
    </div>
  );
};

export default NotificationCreator;
