import { gql } from '@apollo/client'

export const GET_VIDEOS_BY_DATE = gql`
    query ($date: String!){
        video(date:$date)
        {
            url
            racetrack_id
            date
        }
    }
`