import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import logo_desktop from 'assets/icons/logo_desktop.webp'
import logo_mobile from 'assets/icons/logo_mobile.webp';
import MenuItems from './MenuItems';
import logo_animated from 'assets/icons/logo_animated.gif'

const NavBar = () => {
    const location = useLocation();
    return ( 
        <div className="bg-white shadow-lg flex w-full px-4 md:px-8 z-40 justify-end md:justify-between relative h-14 md:h-auto">
            <Link 
                to="/landing" 
                className='
                    absolute md:static left-1/2 md:left-auto transform md:transform-none -translate-x-1/2 md:translate-x-0
                '
            >
                {
                    location.pathname === '/landing/pricing' ? (
                        <img
                            src={logo_animated}
                            alt="invasor_logo"
                            className='h-16 hidden md:block'
                        />
                    ) : (
                        <img
                            src={logo_desktop}
                            alt="invasor_logo"
                            className='h-16 hidden md:block'
                        />
                    )
                }

                <img
                    src={logo_mobile}
                    alt="invasor_logo"
                    className='h-14 block md:hidden'
                />
            </Link>
            <MenuItems />
        </div> 
    );
}
 
export default NavBar;