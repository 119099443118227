import { Avatar } from "@mui/material";
import { BaseContext } from "contexts/BaseContext";
import { format } from "date-fns";
import React, { useContext } from "react";
import profileText from "texts/pages/profileText";
import UploadAvatar from "./UploadAvatar";

const UserCard = ({ userData, setUserData }) => {
  const { language, locales } = useContext(BaseContext);
  return (
    <div className="bg-white rounded-lg w-full mt-[40px] flex flex-col items-center px-8">
      <div className="h-32 w-32 rounded-full shadow-lg flex items-center justify-center material-icons bg-secondaryD1 transform -translate-y-10">
        <Avatar src={userData?.image} className="h-32 w-32">
          <span className="material-icons text-white text-7xl">person</span>
        </Avatar>
      </div>

      <UploadAvatar setUserData={setUserData} userData={userData} />

      <h1 className="text-4xl font-bold text-center mb-0">{`${userData.name} ${userData.lastname}`}</h1>
      {(userData.name || userData.lastname) && (
        <p className="text-backD1 text-center">{profileText[language].name}</p>
      )}

      <h1 className="text-4xl font-bold text-center mb-0">{userData.alias}</h1>
      {userData.alias && (
        <p className="text-backD1 text-center">{profileText[language].alias}</p>
      )}

      <h1 className="text-xl font-bold text-center mb-0">
        {userData.dateOfBirth &&
          format(
            new Date(userData.dateOfBirth),
            language === "en" ? "MMMM d" : "d 'de' MMMM",
            { locale: locales[language] }
          )}
      </h1>
      {userData.dateOfBirth && (
        <p className="material-icons text-2xl text-backD1 text-center my-4">
          cake
        </p>
      )}

      <h1 className="text-xl font-bold text-center mb-0">
        {userData.documentNumber}
      </h1>
      {userData.documentNumber && (
        <p className="text-backD1 text-center">
          {profileText[language].document}
        </p>
      )}

      <h1 className="text-lg font-normal text-center flex-wrap mb-0">
        {userData.about}
      </h1>
      {userData.about && (
        <p className="text-backD1 text-center">{profileText[language].about}</p>
      )}
    </div>
  );
};

export default UserCard;
