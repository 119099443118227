import React, { useContext } from 'react'
import { BaseContext } from 'contexts/BaseContext';

import TableComp from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Row from './Row';

import tableText from 'texts/components/tableText';

const Table = ({currentRace, prediction}) => {
    const { language } = useContext(BaseContext);
    const headerStyle = {
        cell: 'py-0.5 text-center',
        small_cell: 'p-0 text-center',
    };

    return ( 
        <div className='bg-white rounded-lg shadow-lg w-full overflow-hidden'>
            <TableContainer>
                <TableComp>
                    <TableHead>
                        <TableRow>
                            <TableCell className="w-6 p-0"/>
                            <TableCell className="w-6 p-0"/>
                            <TableCell className="w-6 p-0"/>
                            <TableCell className={headerStyle.cell}>{tableText[language].horse}</TableCell>
                            <TableCell className={headerStyle.small_cell}>{tableText[language].age[0]}</TableCell>
                            <TableCell className={headerStyle.small_cell}>{tableText[language].sex[0]}</TableCell>
                            <TableCell className={headerStyle.small_cell}>{tableText[language].fur[0]}</TableCell>
                            <TableCell className={headerStyle.cell}>{tableText[language].jockey}</TableCell>
                            <TableCell className={headerStyle.cell}>kg</TableCell>
                            <TableCell className={headerStyle.cell}>{tableText[language].stud}</TableCell>
                            <TableCell className={headerStyle.cell}>{tableText[language].caretaker}</TableCell>
                            {/* <TableCell className={headerStyle.cell}>{tableText[language].performances}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            currentRace?.competitors?.map((comp = {}, index)=>(
                                <Row
                                    rowData={comp}
                                    competitorIndex={index}
                                    key={`l-${comp.horse_id}`}
                                    currentRace={currentRace}
                                    prediction={prediction}
                                />
                            ))
                        }
                    </TableBody>
                </TableComp>
            </TableContainer>
        </div> 
    );
}
 
export default Table;