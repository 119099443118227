import { gql } from "@apollo/client";

const UPLOAD_PICTURE = gql`
  mutation ($uploadAvatarImage: Upload!) {
    uploadAvatar(image: $uploadAvatarImage) {
      email
      name
      lastname
    }
  }
`;

export default UPLOAD_PICTURE;