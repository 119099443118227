import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState, useEffect, Fragment, useContext } from "react";
import jockeyDetailText from "texts/pages/jockeyText";
import { Line } from "react-chartjs-2";

import "./style.css";
import { format } from "date-fns";
import TooltipComponent from "@mui/material/Tooltip"
import { BaseContext } from "contexts/BaseContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const JockeyChart = ({ data, loading }) => {
  const { language, locales } = useContext(BaseContext);
  
  const [displayMonth, setDisplayMonth] = useState(false);
  const [monthData, setMonthData] = useState({
    labels: [""],
    datasets: [
      {
        label: jockeyDetailText[language].ran,
        data: [],
        borderColor: `#FF9C9A80`,
        segment: {
          borderColor: (ctx) =>
            skipped(ctx, "rgb(0,0,0,0.2)") || down(ctx, "#2196F3"),
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
        // lineTension: 0.5,
        // fill: true,
      },
    ],
  });
  const [weekData, setWeekData] = useState({
    labels: [""],
    datasets: [
      {
        label: jockeyDetailText[language].ran,
        data: [],
        borderColor: `#FF9C9A80`,
        segment: {
          borderColor: (ctx) =>
            skipped(ctx, "rgb(0,0,0,0.2)") || down(ctx, "#2196F3"),
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
      },
    ],
  });

  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

  const buildWeekChartData = () => {
    if (data && !loading) {
      let chartData = {
        labels: [],
        datasets: [
          {
            label: jockeyDetailText[language].ran,
            data: [],
            borderColor: `#5A338E80`,
            segment: {
              borderColor: (ctx) =>
                skipped(ctx, "rgb(0,0,0,0.2)") || down(ctx, "#2196F3"),
              borderDash: (ctx) => skipped(ctx, [6, 6]),
            },
            spanGaps: true,
          },
        ],
      };
      let aWeekAgo = new Date();
      aWeekAgo = new Date(aWeekAgo.setDate(aWeekAgo.getDate() - 7));

      let auxiliaryWeekObject = {};
      let dayBetweenWeeks = new Date(aWeekAgo);
      for (let i = 0; i < 7; i++) {
        dayBetweenWeeks.setDate(dayBetweenWeeks.getDate() + 1);
        chartData.labels.push(
          format(new Date(dayBetweenWeeks), "EEE d", {
            locale: locales[language],
          })
        );
        auxiliaryWeekObject[
          format(new Date(dayBetweenWeeks), "yyyy-MM-dd")
        ] = 0;
      }

      let weekData = data.filter((race) => new Date(race.date) > aWeekAgo);
      weekData.forEach((race) => {
        let formattedRaceDate = format(new Date(race.date), "yyyy-MM-dd");
        if (auxiliaryWeekObject[formattedRaceDate] !== undefined) {
          auxiliaryWeekObject[formattedRaceDate]++;
        }
      });

      let atLeastOne = false;
      Object.keys(auxiliaryWeekObject).forEach((day) => {
        if (auxiliaryWeekObject[day]) {
          chartData.datasets[0].data.push(auxiliaryWeekObject[day]);
          atLeastOne = true;
        } else {
          chartData.datasets[0].data.push(NaN);
        }
      });
      if (!atLeastOne) {
        chartData.datasets[0].data = [];
      }
      setWeekData(chartData);
    }
  };

  const buildMonthChartData = () => {
    if (data && !loading) {
      let chartData = {
        labels: [],
        datasets: [
          {
            label: jockeyDetailText[language].ran,
            data: [],
            borderColor: `#5A338E80`,
            segment: {
              borderColor: (ctx) =>
                skipped(ctx, "rgb(0,0,0,0.2)") || down(ctx, "#2196F3"),
              borderDash: (ctx) => skipped(ctx, [6, 6]),
            },
            spanGaps: true,
          },
        ],
      };

      let aMonthAgo = new Date();
      aMonthAgo = new Date(aMonthAgo.setDate(aMonthAgo.getDate() - 32));

      let auxiliaryMonthObject = {};
      let daySinceMonth = new Date(aMonthAgo);
      for (let i = 0; i < 30; i++) {
        daySinceMonth.setDate(daySinceMonth.getDate() + 1);
        chartData.labels.push([
          format(new Date(daySinceMonth), "dd", {
            locale: locales[language],
          }),
          format(new Date(daySinceMonth), "MMM", {
            locale: locales[language],
          }),
        ]);
        auxiliaryMonthObject[format(new Date(daySinceMonth), "yyyy-MM-dd")] = 0;
      }

      data.forEach((race) => {
        let formattedRaceDate = format(new Date(race.date), "yyyy-MM-dd");
        if (auxiliaryMonthObject[formattedRaceDate] !== undefined) {
          auxiliaryMonthObject[formattedRaceDate]++;
        }
      });

      let atLeastOne = false;
      Object.keys(auxiliaryMonthObject).forEach((day) => {
        if (auxiliaryMonthObject[day]) {
          atLeastOne = true;
          chartData.datasets[0].data.push(auxiliaryMonthObject[day]);
        } else {
          chartData.datasets[0].data.push(NaN);
        }
      });

      if (!atLeastOne) {
        chartData.datasets[0].data = [];
      }
      setMonthData(chartData);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (data && mounted) {
      buildMonthChartData();
      buildWeekChartData();
    }
    return () => {
      mounted = false;
    };
  }, [data]);

  const genericOptions = {
    fill: false,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    radius: 0,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
          stepSize: 1,
          gridLines: {
            display: false,
          },
        },
      },
    },
  };

  return (
    <Fragment>
      <div className="flex justify-between">
        <p className="text-xl font-bold text-left text-backD2 my-0">
          {displayMonth
            ? jockeyDetailText[language].lastMonth
            : jockeyDetailText[language].lastWeek}
        </p>
        <div className="flex gap-3 items-center">
          <p
            className={`text-lg text-backD1 my-0 ${displayMonth ? 'text-primaryD2': 'text-backD2'}`}
          >
            {jockeyDetailText[language].seeMonthly}
          </p>
          <label className="switch">
            <input
              type="checkbox"
              value={displayMonth}
              onChange={() => {
                setDisplayMonth(!displayMonth);
              }}
            />
            <span className="slider round"/>
          </label>
          <TooltipComponent
            title={jockeyDetailText[language].chartInfo}
            position="bottom-left"
          >
            <div className="material-icons text-backD1">info</div>
          </TooltipComponent>
        </div>
      </div>
      <div className="relative h-full w-full py-8 flex items-center justify-center">
        {(displayMonth && !monthData.datasets[0].data.length > 0) ||
        (!displayMonth && !weekData.datasets[0].data.length > 0) ? (
          <p className="text-center text-xl p-4 my-0">
            {displayMonth
              ? jockeyDetailText[language].noRacesMonth
              : jockeyDetailText[language].noRacesWeek}
          </p>
        ) : (
          <Line
            options={genericOptions}
            data={displayMonth ? monthData : weekData}
          />
        )}
      </div>
    </Fragment>
  );
};

export default JockeyChart;
