import { gql } from "@apollo/client"; 

export const GET_VIDEOS_BY_DATE_RT = gql`
    query ($dates: [String!], $racetrack_id: String!){
        videoByDatesRT(dates: $dates, racetrack_id:$racetrack_id)
        {
            url
            racetrack_id
            date
        }
    }
`