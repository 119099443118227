import React, { useContext } from 'react';
import { BaseContext } from 'contexts/BaseContext';

import racestatsText from 'texts/components/RacestatsText';

//Components

const RaceStats = ({raceStats, size = 'md', label = true, loading}) => {
    const sizeStyles = {
        xs:{ text:'text-xs text-center my-0', percentage:'text-xs text-center my-0' },
        sm:{ text:'text-sm text-center my-0', percentage:'text-sm text-center my-0' },
        md:{ text:'text-lg text-center my-0', percentage:'text-lg text-center my-0' },
        lg:{ text:'text-2xl text-center my-0', percentage:'text-2xl text-center my-0' },
    }

    const { language } = useContext(BaseContext);

    const isEmptyStatistics = (raceStats) => {
        return (raceStats.noPlace === 0 && raceStats.first === 0 && raceStats.place === 0)
    }

    const isUndefinedStatistics = (raceStats) => {
        return (raceStats.noPlace === undefined || raceStats.first === undefined || raceStats.place === undefined)
    }

    return (
        <div className='flex-grow block'>
            {
                (loading || isUndefinedStatistics(raceStats))
                    ?   <div className='flex flex-grow'>
                            <div className="bg-dangerL1 pt-0.5 transition-all duration-200 block w-1/3">
                                { label && <p className={sizeStyles[size].text}>NP</p> }
                                <div className='bg-backD2 opacity-80 flex justify-center'>
                                    <div className='bg-gray-300 h-4 w-8 my-0.5 animate-pulse rounded'/>
                                </div>
                                <div className='bg-gray-300 h-4 w-8 my-0.5 animate-pulse rounded mx-auto'/>
                            </div>
                            <div className="bg-warningL1 pt-0.5 transition-all duration-200 block w-1/3">
                                { label && <p className={sizeStyles[size].text}>2 A 5</p> }
                                <div className='bg-backD2 opacity-80 flex justify-center'>
                                    <div className='bg-gray-300 h-4 w-8 my-0.5 animate-pulse rounded'/>
                                </div>
                                <div className='bg-gray-300 h-4 w-8 my-0.5 animate-pulse rounded mx-auto'/>
                            </div>
                            <div className="bg-successL1 pt-0.5 transition-all duration-200 block w-1/3">
                                { label && <p className={sizeStyles[size].text}>1</p> }
                                <div className='bg-backD2 opacity-80 flex justify-center'>
                                    <div className='bg-gray-300 h-4 w-8 my-0.5 animate-pulse rounded'/>
                                </div>
                                <div className='bg-gray-300 h-4 w-8 my-0.5 animate-pulse rounded mx-auto'/>
                            </div>
                        </div>
                    :
                        isEmptyStatistics(raceStats) 
                            ?   <div className='h-14 my-1 flex flex-grow items-center justify-center'>
                                    <p className='my-0'>{racestatsText[language].noRacesFiltered}</p>
                                </div>
                            :   <div className='flex flex-grow'>
                                    <div
                                        className={` 
                                            bg-dangerL1 pt-0.5 transition-all duration-200
                                            ${ raceStats?.noPlace === 0 ? 'hidden' : 'block' }
                                        `}
                                        style={{
                                            width:raceStats 
                                                ? raceStats.noPlace * 100 / (raceStats.noPlace + raceStats.place + raceStats.first) > 20
                                                    ? `${raceStats.noPlace * 100 / (raceStats.noPlace + raceStats.place + raceStats.first)}%`
                                                    : '20%'
                                                : '33%'
                                        }}
                                    >
                                        { label && <p className={sizeStyles[size].text}>NP</p> }
                                        <div className='w-full bg-backD2 opacity-80'>
                                            <p className={`text-white ${sizeStyles[size].percentage} my-0`}>
                                                {`${(raceStats.noPlace * 100 / (raceStats.noPlace + raceStats.place + raceStats.first)).toFixed(1)}%`}
                                            </p>
                                        </div>
                                        <p className={sizeStyles[size].text}>{raceStats?.noPlace || '-'}</p>
                                    </div>
                                    <div
                                        className={` 
                                            bg-warningL1 pt-0.5 transition-all duration-200
                                            ${ raceStats?.place === 0 ? 'hidden' : 'block' }
                                        `}
                                        style={{
                                            width:raceStats 
                                                ? raceStats.place * 100 / (raceStats.noPlace + raceStats.place + raceStats.first) > 20 
                                                    ? `${raceStats.place * 100 / (raceStats.noPlace + raceStats.place + raceStats.first)}%`
                                                    : '20%'
                                                : '33%'
                                        }}
                                    >
                                        { label && <p className={sizeStyles[size].text}>2 A 5</p> }
                                        <div className='w-full bg-backD2 opacity-80'>
                                            <p className={`text-white ${sizeStyles[size].percentage} my-0`}>
                                                {`${(raceStats.place * 100 / (raceStats.noPlace + raceStats.place + raceStats.first)).toFixed(1)}%`}
                                            </p>
                                        </div>
                                        <p className={sizeStyles[size].text}>{raceStats?.place || '-'}</p>
                                    </div>
                                    <div
                                        className={` 
                                            bg-successL1 pt-0.5 transition-all duration-200
                                            ${ raceStats?.first === 0 ? 'hidden' : 'block' }
                                        `}
                                        style={{
                                            width:raceStats 
                                                ? raceStats.first * 100 / (raceStats.noPlace + raceStats.place + raceStats.first) > 20
                                                    ? `${raceStats.first * 100 / (raceStats.noPlace + raceStats.place + raceStats.first)}%`
                                                    : '20%'
                                                : '33%'
                                        }}
                                    >
                                        { label && <p className={sizeStyles[size].text}>1</p> }
                                        <div className='w-full bg-backD2 opacity-80'>
                                            <p className={`text-white ${sizeStyles[size].percentage} my-0`}>
                                                {`${(raceStats.first * 100 / (raceStats.noPlace + raceStats.place + raceStats.first)).toFixed(1)}%`}
                                            </p>
                                        </div>
                                        <p className={sizeStyles[size].text}>{raceStats?.first || '-'}</p>
                                    </div>
                                </div>
            }
            <div className=' bg-secondary h-1 w-full my-2 relative'> 
                <p className="bg-white text-xs absolute left-1/2 transform -translate-x-1/2 -translate-y-1.5 my-0" >
                    100% 
                </p>
            </div>
        </div>
     );
}
 
export default RaceStats;