import { gql } from "@apollo/client";

export const CREATE_PREDICTION = gql`
  mutation setPrediction($input: PredictionInput) {
    prediction(input: $input) {
      chdate
      crdate
    }
  }
`;
