const detailText = { 
    en: {  
        sand:'Sand',
        grass:'Grass',
        meters:'meters',
        lineChartTitle1:'Last',
        lineChartTitle2:'Performances',
        lineChartDesc1:'Chart of the last ',
        lineChartDesc2:'performances.',
        scatterChartTitle:'Distances per Month',
        scatterChartDesc:'Chart of distances by calendar month.',
        noData:'There is no historic data.',
        noChartData:'NO RACES, according to applied filters.',
        source:'Source: ',
        creditsText1:'We thank ',
        creditsText2:' for providing the video',
        blurText:' to access this function.',
        blurTextFilter:' to access to the interactive filters.',
        blurTextHistory:' to access to the historical data.',
        normal:'Fast Track',
        pesada:'Good Track',
        fangosa:'Muddy Track',
        humeda:'Wet Track',
        race:'Race',
        debut:'Debutant',
        toRaceTooltip:'Go to race day',
        grass:'GRASS',
        sand:'SAND',
        total:'TOTAL',
        pos:'Pos',
        dist:'Distance',
        disputed: 'Total',
        won:'Won',
        debutDetail: 'This race is the debut of this competitor.',
    },
    es:{
        sand:'Arena',
        grass:'Cesped',
        meters:'metros',
        lineChartTitle1:'Últimas',
        lineChartTitle2:'Performances',
        lineChartDesc1:'Gráfico de últimas ',
        lineChartDesc2:'performances.',
        scatterChartTitle:'Distancias por Mes',
        scatterChartDesc:'Gráfico de distancias por mes calendario.',
        noData:'No registramos datos históricos de este ejemplar.',
        noChartData:'SIN CARRERAS, según filtros aplicados',
        source:'Fuente: ',
        creditsText1:'Agradecemos a ',
        creditsText2:' por proveer el video',
        blurText:' para acceder a esta función.',
        blurTextFilter:' para acceder a filtros interactivos.',
        blurTextHistory:' para acceder al histórico de datos.',
        normal:'Pista Normal',
        pesada:'Pista Pesada',
        fangosa:'Pista Fangosa',
        humeda:'Pista Húmeda',
        race:'Carrera',
        debut:'Debutante',
        toRaceTooltip:'Ir a día de la carrera',
        grass:'CÉSPED',
        sand:'ARENA',
        total:'TOTAL',
        pos:'Pos',
        dist:'Distancia',
        won:'Ganadas',
        disputed: 'Total',
        debutDetail: 'Esta carrera es el debut de este competidor.',
    },
    pt:{
        sand:'Areia',
        grass:'Grama',
        meters:'metros',
        lineChartTitle1:'Últimas',
        lineChartTitle2:'Performances',
        lineChartDesc1:'Gráfico de',
        lineChartDesc2:'últimos desempenhos.',
        scatterChartTitle:'Distâncias por mês',
        scatterChartDesc:'Gráfico de distâncias por mês de calendário. ',
        noData:'Não há dados históricos.',
        noChartData:'SEM CORRIDAS, de acordo com os filtros aplicados.',
        source:'Fonte: ',
        creditsText1:'Agradecemos a ',
        creditsText2:' por fornecer o vídeo',
        blurText:' para acessar esta funcionalidade.',
        blurTextFilter:' para acessar filtros interativos.',
        blurTextHistory:' para acessar os dados históricos.',
        normal:'Pista Rápida',
        pesada:'Pista Boa',
        fangosa:'Pista Encharcada',
        humeda:'Pista Molhada',
        race:'Corrida',
        debut:'Debutante',
        toRaceTooltip:'Ir para o dia da corrida',
        grass:'GRAMA',
        sand:'AREIA',
        total:'TOTAL',
        pos:'Pos',
        dist:'Distância',
        won:'Ganadas',
        disputed: 'Ganhou',
        debutDetail: 'Esta corrida é a estreia deste concorrente.',
    }
}

export default detailText;