import React, { useContext, useEffect, useState } from 'react';
import { BaseContext } from 'contexts/BaseContext';

import tableText from 'texts/components/tableText';

import TableContainer from '@mui/material/TableContainer';
import TableComp from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Row from './Row';
import getSortedByDistance from 'utilities/functions/getSortedByDistance';
import getIncidentKey from 'utilities/functions/getIncidentKey';


const Table = ({
    editedRaces,
    setEditedRaces,
    currentIndex,
    jockeyOptions,
    distOptions,
    studOptions,
    loadingSelects
}) => {
    const { language } = useContext(BaseContext);
    const [posOptions, setPosOptions] = useState(['-',' ']);

    useEffect(() => {
        let mounted = true;
        let newPosOptions = ['-',' '];
        const competitors = editedRaces?.[currentIndex]?.competitors;
        if(competitors) {
            const ableCompetitors = competitors.filter(comp=>!getIncidentKey(comp));
            let totalCompetitors = ableCompetitors.length || 0;
            for (let i = 0; i < totalCompetitors || 0; i++) newPosOptions.push(i+1);
        }

        if(mounted){
            setPosOptions(newPosOptions)
        }

        return () => {
            mounted = false;
        }
    }, [editedRaces?.[currentIndex]])
    
    const headerStyle = {
        cell: 'py-0.5 text-center',
    };

    const currentRace = editedRaces?.[currentIndex];
    // const sortedCompetitors = getSortedByDistance(currentRace?.competitors);

    return (
        <TableContainer>
            <TableComp >
                <TableHead>
                    <TableRow>
                        <TableCell className={headerStyle.cell}/>
                        <TableCell className={headerStyle.cell}>{tableText[language].pos}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].dist}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].cpos}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].rail}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].horse}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].jockey}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].stud}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].jkg}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        currentRace?.competitors?.map((comp = {}, index)=>(
                           <Row
                                distOptions={distOptions} 
                                jockeyOptions={jockeyOptions} 
                                posOptions={posOptions}
                                studOptions={studOptions}
                                loadingSelects={loadingSelects}
                                rowData={comp}
                                raceIndex={currentIndex}
                                competitorIndex={index}
                                setEditedRaces={setEditedRaces}
                                key={`l-${comp.horse_id}`}
                                currentRace={currentRace}
                            />
                        ))
                    }
                </TableBody>
            </TableComp>
        </TableContainer>
    );
}
 
export default Table;