import React from "react";

const UnsavedNormalStepIcon = ({ icon, dayRaces, currentIndex }) => {
  const sand = dayRaces?.[icon]?.course?.toLowerCase()?.includes("arena");

  return (
    <div>
      {currentIndex === icon ? (
        <span className="text-danger material-icons text-sm md:text-base group-hover:scale-125 transition-all">
          brightness_1
        </span>
      ) : currentIndex > icon || currentIndex === icon ? (
        <span className="material-icons text-sm md:text-base leading-none text-red-400 group-hover:scale-125 transition-all">
          brightness_1
        </span>
      ) : (
        <span className="material-icons text-sm md:text-base leading-none text-dangerL1 group-hover:scale-125 transition-all">
          brightness_1
        </span>
      )}
    </div>
  );
};

export default UnsavedNormalStepIcon;
