import { format } from "date-fns";
import React from "react";

const Steps = ({total_races, currentRace}) => {
  const races = [...Array(total_races-1 || 0).keys()];
  return (
    <>
      {races.map((race, index) => (
        <div className="flex flex-col items-center justify-end gap-y-2 pb-4 h-full" key={`step-${index}`}>
          {index === currentRace.raceno - 1 && (
            <p className="my-0 text-lg font-bold">
              {format(new Date(currentRace.date), "HH:mm")}
            </p>
          )}
          <div
            className={`w-4 h-4 rounded-full ${
              index === currentRace.raceno - 1 ? "bg-secondaryD3" : "bg-back"
            }`}
          />
        </div>
      ))}
    </>
  );
};

export default Steps;
