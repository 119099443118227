import React from 'react';

import LineDistance from './LineDistance';
import LinePosition from './LinePosition';

const UpperSection = ({historyData, setHistoryIndex, currentCompetitor, loading}) => {
    return (  
        <div className='h-auto lg:h-[40%] w-full flex flex-col lg:flex-row gap-x-4 gap-y-2'>
            <div className='h-full w-auto lg:w-7/12 bg-white rounded-lg p-4'>
                {
                    loading ? ( 
                        <div className="h-full w-full rounded-lg bg-backL2 animate-pulse"/>
                    ) : (
                        <LinePosition
                            historyData={historyData}
                            setHistoryIndex={setHistoryIndex}
                            currentCompetitor={currentCompetitor}
                        />
                    )
                }
            </div>
            <div className='h-full w-auto lg:w-5/12 bg-white rounded-lg p-4'>
                {
                    loading ? ( 
                        <div className="h-full w-full rounded-lg bg-backL2 animate-pulse"/>
                    ) : (
                        <LineDistance
                            historyData={historyData}
                            setHistoryIndex={setHistoryIndex}
                        />
                    )
                }
            </div>
        </div>
    );
}
 
export default UpperSection;