import { gql } from "@apollo/client";

export const GET_VIDEOS_DAY_RT = gql`
    query getVideosInDayRT ( $date: String!, $racetrack_id: String!) {
        dayVideosRT(date:$date, racetrack_id: $racetrack_id) {
            url
            racetrack_id
            date
        }
    }
`