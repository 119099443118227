const reviewText = { 
    en: {  
        reviewButton:'Write review',
        commentLabel:'Your comment',
        save:'Save',
        ratingTitle:'Do you want to add a comment to your opinion?',
        ratingDesc:'Share your review with the community',
        editSuccess:'Your opinion has been saved successfully',
        editFailure:'There was an error saving your opinion',
        share:'Share your opinion',
        saveSuccess:'Your opinion has been saved successfully',
        saveFailure:'There was an error saving your opinion',
        commentLabel:'Your comment',
        0.5:'Little Chance',
        1:'Little Chance+',
        1.5:'Chance',
        2:'Chance+',
        2.5:'Wild Card',
        3:'Wild Card+',
        3.5:'Place',
        4:'Place+',
        4.5:'Win',
        5:'Win+',
    },
    es: {  
        reviewButton:'Escribir reseña',
        commentLabel:'Tu comentario',
        save:'Guardar',
        ratingTitle:'¿Quieres agregar un comentario a tu opinión?',
        ratingDesc:'Comparte tu reseña con la comunidad',
        editSuccess:'Tu opinión se guardó con éxito',
        editFailure:'Hubo un error al guardar tu opinión',
        share:'Comparte tu opinión',
        saveSuccess:'Tu opinión se guardó con éxito',
        saveFailure:'Hubo un error al guardar tu opinión',
        commentLabel:'Tu comentario',
        0.5:'Poca Chance',
        1:'Poca Chance+',
        1.5:'Chance',
        2:'Chance+',
        2.5:'Respetado',
        3:'Respetado+',
        3.5:'Rival',
        4:'Rival+',
        4.5:'Favorito',
        5:'Favorito+',
    },
    pt: {  
        reviewButton:'Escribir reseña',
        commentLabel:'Seu comentário',
        save:'Salvar',
		ratingTitle: '¿Deseja adicionar um comentário à sua opinião?',
        ratingDesc: 'Compartilhe seu reveja com a comunidade',
        editSuccess: 'Sua opinião foi salva com sucesso',
        editFailure: 'Ocorreu um erro ao salvar sua opinião',
        commentLabel:'Seu comentário',
        share:'Compartilhe sua opinião',
        saveSuccess: 'Sua opinião foi salva com sucesso',
        saveFailure: 'Ocorreu um erro ao salvar sua opinião',
        0.5:'Pouca Chance',
        1:'Pouca Chance+',
        1.5:'Chance',
        2:'Chance+',
        2.5:'Respeitado',
        3:'Respeitado+',
        3.5:'Rival',
        4:'Rival+',
        4.5:'Favorito',
        5:'Favorito+',
    },
}

export default reviewText;