import {gql} from '@apollo/client';

export const GET_SELECTS_DATA = gql `
query GetSelectData {
    jockeys {
      id
      name
    }
    stables {
      id
      name
    }
}
`;