import React, { useContext } from 'react';
import { BaseContext } from 'contexts/BaseContext';

import tableText from 'texts/components/tableText';

import TableContainer from '@mui/material/TableContainer';
import TableComp from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Row from './Row';
import getSortedByDistance from 'utilities/functions/getSortedByDistance';


const Table = ({currentRace = {}}) => {
    const { language } = useContext(BaseContext);
    
    const headerStyle = {
        cell: 'py-0.5 text-center',
        small_cell: 'p-0 text-center',
    };

    const sortedCompetitors = getSortedByDistance(currentRace?.competitors);

    return (
        <TableContainer className='bg-white rounded-lg shadow-lg'>
            <TableComp >
                <TableHead>
                    <TableRow>
                        <TableCell className={headerStyle.small_cell}/>
                        <TableCell className={headerStyle.small_cell}/>
                        <TableCell className={headerStyle.small_cell}/>
                        <TableCell className={headerStyle.cell}>{tableText[language].horse}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].parents}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].performances}</TableCell>
                        <TableCell className={headerStyle.small_cell}>
                            <Tooltip title={tableText[language].age}>
                                <span>
                                    {tableText[language].age[0]}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={headerStyle.small_cell}>
                            <Tooltip title={tableText[language].sex}>
                                <span>
                                    {tableText[language].sex[0]}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={headerStyle.small_cell}>
                            <Tooltip title={tableText[language].fur}>
                                <span>
                                    {tableText[language].fur[0]}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].jockey}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].kg}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].stud}</TableCell>
                        <TableCell className={headerStyle.cell}>{tableText[language].caretaker}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sortedCompetitors?.map((comp = {}, index)=>(
                            <Row 
                                key={comp.horse_id}
                                competitor={comp}
                                nextCompetitor={sortedCompetitors?.[index+1]}
                                compIndex={index}
                                totalComps={sortedCompetitors?.length}
                                currentRace={currentRace}
                            />
                        ))
                    }
                </TableBody>
            </TableComp>
        </TableContainer>
    );
}
 
export default Table;