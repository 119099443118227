import { gql } from "@apollo/client";

export const GET_HORSE_PROFILE = gql`
query HorseProfile($horseId: ID!) {
  horseProfile(id: $horseId) {
    rating {
      totalCount
      average
    }
    totalWon {
      name
      won
    },
    id
    name
    chdate
    crdate
    isactive
    gender
  }
}`;