const generateFilters = (data, original_template) => {
  if (data) {
    let newFilters = { ...original_template };
    data.forEach((race) => {
      Object.keys(newFilters).forEach((tem_key) => {
        const template = newFilters[String(tem_key)];
        if (template.type === "toggle" && !template.exists) {
          let exists = false;

          if (template.field.split("|").length > 1) {
            const fields = template.field.split("|");
            exists = !template.targetFunction(race[fields[0]]?.[fields[1]]);
          } else {
            exists = !template.targetFunction(race[template.field]);
          }

          if (exists && !template.exists) {
            template.exists = true;
          }
        } else if (template.type === "autocomplete") {
          const contentArr =
            Object.keys(template.content)?.map((key) => String(key)) || [];
          const fields = template.field?.split("|");
          const fieldValue =
            fields.length > 1
              ? race?.[fields[0]]?.[fields[1]]
              : race?.[fields[0]];
          const repeated = contentArr.includes(String(fieldValue));
          if (!repeated && fieldValue) {
            template.content[String(fieldValue)] = {
              value: String(fieldValue),
              active: true,
              important: false,
            };
            template.active = false;
          }
        } else if (template.type === "select") {
          const contentArr =
            Object.keys(template.content)?.map((key) => String(key)) || [];
          const fields = template.field?.split("|");
          const fieldValue =
            fields.length > 1
              ? race?.[fields[0]]?.[fields[1]]
              : race?.[fields[0]];
          const repeated = contentArr.includes(String(fieldValue));
          if (!repeated && fieldValue) {
            template.content[String(fieldValue)] = {
              value: String(fieldValue),
              active: true,
              important: false,
            };
            template.active += 1;
            template.initTotal += 1;
            template.total += 1;
          }
        }
        newFilters[String(tem_key)] = template;
      });
    });

    // setFilterItems(newFilters)
    return newFilters;
  }
};

export default generateFilters;
