import React from 'react';

import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';

const Landing = () => {

    return (
        <div className='flex flex-col h-screen max-h-screen bg-gradient-to-r from-primaryD1 to-secondary'>
            <NavBar />
            <Outlet/>
        </div>
    );
}

export default Landing