import React, { useContext } from "react";

import { BaseContext } from "contexts/BaseContext";
import horseColor from "constants/HorseColor";
import sideContentText from "texts/components/sideContentText";
import { CircleFlag } from "react-circle-flags";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";

const HorseData = ({ competitor = {} }) => {
  const { language } = useContext(BaseContext);
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (
      competitor.horse_id &&
      competitor.horse_name?.toLowerCase() !== "sin asignar"
    ) {
      navigate(`/app/horse/${competitor.horse_id}`, {
        state: { prev: "detail" },
      });
    }
  };

  return (
    <div className="w-full flex gap-x-2 items-center">
      <Avatar
        alt={competitor.horse_name}
        src={`https://storage.googleapis.com/invasor-assets/horses/${competitor.horse_id}.webp`}
        className="h-16 w-16 bg-primaryL1 cursor-pointer"
        onClick={handleRedirect}
        onContextMenu={(e) => e.preventDefault()}
      >
        <Avatar
          alt={competitor.horse_name}
          src={horseColor[competitor.horse_details?.color || "GENERIC"].img}
          className="h-16 w-16 bg-primaryL1"
        />
      </Avatar>
      <div className="flex flex-col gap-y-2 w-full">
        <div className="flex flex-col relative w-full">
          <Chip
            color="inactiveDark"
            variant="outlined"
            label={
              sideContentText[language][competitor.horse_details?.gender || "M"]
            }
            className="text-backD2"
            size="small"
          />
          <CircleFlag
            countryCode={competitor.horse_details?.country?.toLowerCase()}
            height="12"
            className="absolute right-1.5 transform top-1/2 -translate-y-1/2"
          />
        </div>
        <Chip
          color="inactiveDark"
          variant="outlined"
          label={horseColor[competitor.horse_details?.color || "GENERIC"]?.name}
          className="text-backD2"
          size="small"
        />
        <Chip
          color="inactiveDark"
          variant="outlined"
          label={`${competitor.age} ${sideContentText[language].age}`}
          className="text-backD2"
          size="small"
        />
      </div>
    </div>
  );
};

export default HorseData;
