import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import LanguagePicker from 'components/ui/LanguagePicker';
import Collaborate from './Collaborate';
import Notifications from './Notifications';
import ProfileMenu from './ProfileMenu';

const MenuItemsMobile = () => {
    const [anchor, setAnchor] = useState(null);
    const menuOpen = Boolean(anchor);

    return (
        <>
            <IconButton 
                onClick={(e)=>setAnchor(e.currentTarget)} 
                className="material-icons"
            >
                more_vert
            </IconButton>
            <Menu
                id="notifications-menu"
                anchorEl={anchor}
                open={menuOpen}
                onClose={()=>setAnchor(null)}
            >
                <div className="flex flex-col items-center">
                    <LanguagePicker displayLabel labelPosition="right"/>
                    <Collaborate />
                    <Notifications />
                    <ProfileMenu />
                </div>
            </Menu>
        </>
    );
}
 
export default MenuItemsMobile;