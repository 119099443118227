const countryList = {
	en:{
		AF:'Afghanistan',
		AL:'Albania',
		DE:'Germany',
		AD:'Andorra',
		AO:'Angola',
		AI:'Anguilla',
		AQ:'Antarctica',
		AG:'Antigua and Barbuda',
		SA:'Saudi Arabia',
		DZ:'Algeria',
		AR:'Argentina',
		AM:'Armenia',
		AW:'Aruba',
		AU:'Australia',
		AT:'Austria',
		AZ:'Azerbaijan',
		BE:'Belgium',
		BS:'Bahamas',
		BH:'Bahrain',
		BD:'Bangladesh',
		BB:'Barbados',
		BZ:'Belize',
		BJ:'Benin',
		BT:'Bhutan',
		BY:'Belarus',
		MM:'Burma',
		BO:'Bolivia',
		BA:'Bosnia and Herzegovina',
		BW:'Botswana',
		BR:'Brazil',
		BN:'Brunei',
		BG:'Bulgaria',
		BF:'Burkina Faso',
		BI:'Burundi',
		CV:'Cape Verde',
		KH:'Cambodia',
		CM:'Cameroon',
		CA:'Canada',
		TD:'Chad',
		CL:'Chili',
		CN:'China',
		CY:'Cyprus',
		VA:'Vatican City',
		CO:'Colombia',
		KM:'comoros',
		CG:'Republic of Congo',
		CD:'Democratic Republic of Congo',
		KP:'North Korea',
		KR:'South Korea',
		CI:'Ivory Coast',
		CR:'Costa Rica',
		HR:'Croatia',
		CU:'Cuba',
		CW:'Curaçao',
		DK:'Denmark',
		DM:'Dominican',
		EC:'Ecuador',
		EG:'Egypt',
		SV:'El Salvador',
		AE:'United Arab Emirates',
		ER:'Eritrea',
		SK:'Slovakia',
		SI:'Slovenia',
		ES:'Spain',
		US:'USA',
		EE:'Estonia',
		ET:'Ethiopia',
		PH:'Philippines',
		FI:'Finland',
		FJ:'Fiji',
		FR:'France',
		GA:'Gabon',
		GM:'Gambia',
		GE:'Georgia',
		GH:'Ghana',
		GI:'Gibraltar',
		GD:'Grenade',
		GR:'Greece',
		GL:'Greenland',
		GP:'Guadeloupe',
		GU:'Guam',
		GT:'Guatemala',
		GF:'French Guiana',
		GG:'Guernsey',
		GN:'Guinea',
		GQ:'Equatorial Guinea',
		GW:'Guinea-Bissau',
		GY:'Guiana',
		HT:'Haiti',
		HN:'Honduras',
		HK:'Hong Kong',
		HU:'Hungary',
		IN:'India',
		ID:'Indonesia',
		IR:'Iran',
		IQ:'Iraq',
		IE:'Ireland',
		BV:'Bouvet Island',
		IM:'Isle of Man',
		CX:'Christmas Island',
		NF:'Norfolk Island',
		IS:'Iceland',
		BM:'Bermuda Islands',
		KY:'Cayman Islands',
		CC:'Cocos (Keeling) Islands',
		CK:'Cook Islands',
		AX:'Åland Islands',
		FO:'Faroe Islands',
		GS:'South Georgia and the South Sandwich Islands',
		HM:'Heard and McDonald Islands',
		MV:'Maldivas Islands',
		FK:'Falkland Islands',
		MP:'Northern Mariana Islands',
		MH:'Marshall Islands',
		PN:'Pitcairn Islands',
		SB:'Solomon Islands',
		TC:'Turks and Caicos Islands',
		UM:'United States Minor Outlying Islands',
		VG:'British Virgin Islands',
		VI:'United States Virgin Islands',
		IL:'Israel',
		IT:'Italy',
		JM:'Jamaica',
		JP:'Japan',
		JE:'Sweater',
		JO:'Jordan',
		KZ:'Kazakhstan',
		KE:'Kenya',
		KG:'Kyrgyzstan',
		KI:'Kiribati',
		KW:'Kuwait',
		LB:'Lebanon',
		LA:'Laos',
		LS:'Lesotho',
		LV:'Latvia',
		LR:'Liberia',
		LY:'Libya',
		LI:'Liechtenstein',
		LT:'Lithuania',
		LU:'Luxembourg',
		MX:'Mexico',
		MC:'Monaco',
		MO:'Macau',
		MK:'Macedonia',
		MG:'Madagascar',
		MY:'Malaysia',
		MW:'Malawi',
		ML:'Mali',
		MT:'Malt',
		MA:'Morocco',
		MQ:'Martinique',
		MU:'Mauricio',
		MR:'Mauritania',
		YT:'mayotte',
		FM:'Micronesia',
		MD:'Moldova',
		MN:'Mongolia',
		ME:'Montenegro',
		MS:'Montserrat',
		MZ:'Mozambique',
		NA:'Namibia',
		NR:'Nauru',
		NP:'Nepal',
		NI:'Nicaragua',
		NE:'Niger',
		NG:'Nigeria',
		NU:'Niue',
		NO:'Norway',
		NC:'New Caledonia',
		NZ:'New Zealand',
		OM:'Oman',
		NL:'Netherlands',
		PK:'Pakistan',
		PW:'palau',
		PS:'Palestine',
		PA:'Panama',
		PG:'Papua New Guinea',
		PY:'Paraguayan',
		PE:'Peru',
		PF:'French Polynesia',
		PL:'Poland',
		PT:'Portugal',
		PR:'Puerto Rico',
		QA:'Qatar',
		GB:'United Kingdom',
		CF:'Central African Republic',
		CZ:'Czech Republic',
		DO:'Dominican Republic',
		SS:'Republic of South Sudan',
		RE:'Meeting',
		RW:'Rwanda',
		RO:'Romania',
		RU:'Russia',
		EH:'Occidental Sahara',
		WS:'Samoa',
		AS:'American Samoa',
		BL:'San Bartolome',
		KN:'Saint Kitts and Nevis',
		SM:'San Marino',
		MF:'Saint Martin (France)',
		PM:'Saint Pierre and Miquelon',
		VC:'St. Vincent and the Grenadines',
		SH:'St. Helen',
		LC:'St. Lucia',
		ST:'Sao Tome and Principe',
		SN:'Senegal',
		RS:'Serbian',
		SC:'Seychellois',
		SL:'Sierra Leone',
		SG:'Singapore',
		SX:'Sint Maarten',
		SY:'Syria',
		SO:'Somalia',
		LK:'Sri Lanka',
		ZA:'South Africa',
		SD:'Sudan',
		SE:'Sweden',
		CH:'Swiss',
		SR:'Surinam',
		SJ:'Svalbard and Jan Mayen',
		SZ:'Swaziland',
		TJ:'Tajikistan',
		TH:'Thailand',
		TW:'Taiwan',
		TZ:'Tanzania',
		IO:'British Indian Ocean Territory',
		TF:'French Southern and Antarctic Territories',
		TL:'Timor-Leste',
		TG:'Togo',
		TK:'Tokelau',
		TO:'Tong',
		TT:'Trinidad and Tobago',
		TN:'Tunisia',
		TM:'Turkmenistan',
		TR:'Turkey',
		TV:'Tuvalu',
		UA:'Ukraine',
		UG:'Uganda',
		UY:'Uruguay',
		UZ:'Uzbekistan',
		VU:'Vanuatus',
		VE:'Venezuela',
		VN:'Vietnam',
		WF:'Wallis and Futuna',
		YE:'Yemen',
		DJ:'Djibouti',
		ZM:'Zambia',
		ZW:'Zimbabwe'
	},
	es:{
		AF:'Afganistán',
		AL:'Albania',
		DE:'Alemania',
		AD:'Andorra',
		AO:'Angola',
		AI:'Anguila',
		AQ:'Antártida',
		AG:'Antigua y Barbuda',
		SA:'Arabia Saudita',
		DZ:'Argelia',
		AR:'Argentina',
		AM:'Armenia',
		AW:'Aruba',
		AU:'Australia',
		AT:'Austria',
		AZ:'Azerbaiyán',
		BE:'Bélgica',
		BS:'Bahamas',
		BH:'Bahrein',
		BD:'Bangladesh',
		BB:'Barbados',
		BZ:'Belice',
		BJ:'Benín',
		BT:'Bhután',
		BY:'Bielorrusia',
		MM:'Birmania',
		BO:'Bolivia',
		BA:'Bosnia y Herzegovina',
		BW:'Botsuana',
		BR:'Brasil',
		BN:'Brunéi',
		BG:'Bulgaria',
		BF:'Burkina Faso',
		BI:'Burundi',
		CV:'Cabo Verde',
		KH:'Camboya',
		CM:'Camerún',
		CA:'Canadá',
		TD:'Chad',
		CL:'Chile',
		CN:'China',
		CY:'Chipre',
		VA:'Ciudad del Vaticano',
		CO:'Colombia',
		KM:'Comoras',
		CG:'República del Congo',
		CD:'República Democrática del Congo',
		KP:'Corea del Norte',
		KR:'Corea del Sur',
		CI:'Costa de Marfil',
		CR:'Costa Rica',
		HR:'Croacia',
		CU:'Cuba',
		CW:'Curazao',
		DK:'Dinamarca',
		DM:'Dominica',
		EC:'Ecuador',
		EG:'Egipto',
		SV:'El Salvador',
		AE:'Emiratos Árabes Unidos',
		ER:'Eritrea',
		SK:'Eslovaquia',
		SI:'Eslovenia',
		ES:'España',
		US:'Estados Unidos',
		EE:'Estonia',
		ET:'Etiopía',
		PH:'Filipinas',
		FI:'Finlandia',
		FJ:'Fiyi',
		FR:'Francia',
		GA:'Gabón',
		GM:'Gambia',
		GE:'Georgia',
		GH:'Ghana',
		GI:'Gibraltar',
		GD:'Granada',
		GR:'Grecia',
		GL:'Groenlandia',
		GP:'Guadalupe',
		GU:'Guam',
		GT:'Guatemala',
		GF:'Guayana Francesa',
		GG:'Guernsey',
		GN:'Guinea',
		GQ:'Guinea Ecuatorial',
		GW:'Guinea-Bissau',
		GY:'Guyana',
		HT:'Haití',
		HN:'Honduras',
		HK:'Hong kong',
		HU:'Hungría',
		IN:'India',
		ID:'Indonesia',
		IR:'Irán',
		IQ:'Irak',
		IE:'Irlanda',
		BV:'Isla Bouvet',
		IM:'Isla de Man',
		CX:'Isla de Navidad',
		NF:'Isla Norfolk',
		IS:'Islandia',
		BM:'Islas Bermudas',
		KY:'Islas Caimán',
		CC:'Islas Cocos (Keeling)',
		CK:'Islas Cook',
		AX:'Islas de Åland',
		FO:'Islas Feroe',
		GS:'Islas Georgias del Sur y Sandwich del Sur',
		HM:'Islas Heard y McDonald',
		MV:'Islas Maldivas',
		FK:'Islas Malvinas',
		MP:'Islas Marianas del Norte',
		MH:'Islas Marshall',
		PN:'Islas Pitcairn',
		SB:'Islas Salomón',
		TC:'Islas Turcas y Caicos',
		UM:'Islas Ultramarinas Menores de Estados Unidos',
		VG:'Islas Vírgenes Británicas',
		VI:'Islas Vírgenes de los Estados Unidos',
		IL:'Israel',
		IT:'Italia',
		JM:'Jamaica',
		JP:'Japón',
		JE:'Jersey',
		JO:'Jordania',
		KZ:'Kazajistán',
		KE:'Kenia',
		KG:'Kirguistán',
		KI:'Kiribati',
		KW:'Kuwait',
		LB:'Líbano',
		LA:'Laos',
		LS:'Lesoto',
		LV:'Letonia',
		LR:'Liberia',
		LY:'Libia',
		LI:'Liechtenstein',
		LT:'Lituania',
		LU:'Luxemburgo',
		MX:'México',
		MC:'Mónaco',
		MO:'Macao',
		MK:'Macedônia',
		MG:'Madagascar',
		MY:'Malasia',
		MW:'Malawi',
		ML:'Mali',
		MT:'Malta',
		MA:'Marruecos',
		MQ:'Martinica',
		MU:'Mauricio',
		MR:'Mauritania',
		YT:'Mayotte',
		FM:'Micronesia',
		MD:'Moldavia',
		MN:'Mongolia',
		ME:'Montenegro',
		MS:'Montserrat',
		MZ:'Mozambique',
		NA:'Namibia',
		NR:'Nauru',
		NP:'Nepal',
		NI:'Nicaragua',
		NE:'Niger',
		NG:'Nigeria',
		NU:'Niue',
		NO:'Noruega',
		NC:'Nueva Caledonia',
		NZ:'Nueva Zelanda',
		OM:'Omán',
		NL:'Países Bajos',
		PK:'Pakistán',
		PW:'Palau',
		PS:'Palestina',
		PA:'Panamá',
		PG:'Papúa Nueva Guinea',
		PY:'Paraguay',
		PE:'Perú',
		PF:'Polinesia Francesa',
		PL:'Polonia',
		PT:'Portugal',
		PR:'Puerto Rico',
		QA:'Qatar',
		GB:'Reino Unido',
		CF:'República Centroafricana',
		CZ:'República Checa',
		DO:'República Dominicana',
		SS:'República de Sudán del Sur',
		RE:'Reunión',
		RW:'Ruanda',
		RO:'Rumanía',
		RU:'Rusia',
		EH:'Sahara Occidental',
		WS:'Samoa',
		AS:'Samoa Americana',
		BL:'San Bartolomé',
		KN:'San Cristóbal y Nieves',
		SM:'San Marino',
		MF:'San Martín (Francia)',
		PM:'San Pedro y Miquelón',
		VC:'San Vicente y las Granadinas',
		SH:'Santa Elena',
		LC:'Santa Lucía',
		ST:'Santo Tomé y Príncipe',
		SN:'Senegal',
		RS:'Serbia',
		SC:'Seychelles',
		SL:'Sierra Leona',
		SG:'Singapur',
		SX:'Sint Maarten',
		SY:'Siria',
		SO:'Somalia',
		LK:'Sri lanka',
		ZA:'Sudáfrica',
		SD:'Sudán',
		SE:'Suecia',
		CH:'Suiza',
		SR:'Surinám',
		SJ:'Svalbard y Jan Mayen',
		SZ:'Swazilandia',
		TJ:'Tayikistán',
		TH:'Tailandia',
		TW:'Taiwán',
		TZ:'Tanzania',
		IO:'Territorio Británico del Océano Índico',
		TF:'Territorios Australes y Antárticas Franceses',
		TL:'Timor Oriental',
		TG:'Togo',
		TK:'Tokelau',
		TO:'Tonga',
		TT:'Trinidad y Tobago',
		TN:'Tunez',
		TM:'Turkmenistán',
		TR:'Turquía',
		TV:'Tuvalu',
		UA:'Ucrania',
		UG:'Uganda',
		UY:'Uruguay',
		UZ:'Uzbekistán',
		VU:'Vanuatu',
		VE:'Venezuela',
		VN:'Vietnam',
		WF:'Wallis y Futuna',
		YE:'Yemen',
		DJ:'Yibuti',
		ZM:'Zambia',
		ZW:'Zimbabue'
	},
	pt:{
		AF:'Afeganistão',
		AL:'Albânia',
		DE:'Alemanha',
		AD:'Andorra',
		AO:'Angola',
		AI:'Anguila',
		AQ:'Antártica',
		AG:'Antígua e Barbuda',
		SA:'Arábia Saudita',
		DZ:'Argélia',
		AR:'Argentina',
		AM:'Armênia',
		AW:'Aruba',
		AU:'Austrália',
		AT:'Áustria',
		AZ:'Azerbaijão',
		BE:'Bélgica',
		BS:'Bahamas',
		BH:'Bahrein',
		BD:'Bangladesh',
		BB:'Barrados',
		BZ:'Belize',
		BJ:'Benin',
		BT:'Butão',
		BY:'Bielorrússia',
		MM:'Birmânia',
		BO:'Bolívia',
		BA:'Bósnia e Herzegovina',
		BW:'Botsuana',
		BR:'Brasil',
		BN:'Brunei',
		BG:'Bulgária',
		BF:'Burkina Faso',
		BI:'Burundi',
		CV:'Cabo Verde',
		KH:'Camboja',
		CM:'Camarões',
		CA:'Canadá',
		TD:'Chade',
		CL:'Pimenta',
		CN:'China',
		CY:'Chipre',
		VA:'Cidade do Vaticano',
		CO:'Colômbia',
		KM:'comoros',
		CG:'República do Congo',
		CD:'República Democrática do Congo',
		KP:'Coreia do Norte',
		KR:'Coreia do Sul',
		CI:'Costa do Marfim',
		CR:'Costa Rica',
		HR:'Croácia',
		CU:'Cuba',
		CW:'Curaçao',
		DK:'Dinamarca',
		DM:'Dominicano',
		EC:'Equador',
		EG:'Egito',
		SV:'El Salvador',
		AE:'Emirados Árabes Unidos',
		ER:'Eritreia',
		SK:'Eslováquia',
		SI:'Eslovênia',
		ES:'Espanha',
		US:'EUA',
		EE:'Estônia',
		ET:'Etiópia',
		PH:'Filipinas',
		FI:'Finlândia',
		FJ:'Fiji',
		FR:'França',
		GA:'Gabão',
		GM:'Gâmbia',
		GE:'Geórgia',
		GH:'Gana',
		GI:'Gibraltar',
		GD:'Grenade',
		GR:'Grécia',
		GL:'Gronelândia',
		GP:'Guadalupe',
		GU:'Guam',
		GT:'Guatemala',
		GF:'Guiana Francesa',
		GG:'Guernsey',
		GN:'Guiné',
		GQ:'Guiné Equatorial',
		GW:'Guiné-Bissau',
		GY:'Guiana',
		HT:'Haiti',
		HN:'Honduras',
		HK:'Hong Kong',
		HU:'Hungria',
		IN:'Índia',
		ID:'Indonésia',
		IR:'Irã',
		IQ:'Iraque',
		IE:'Irlanda',
		BV:'Ilha Bouvet',
		IM:'Ilha de Man',
		CX:'Ilha do Natal',
		NF:'Ilha Norfolk',
		IS:'Islândia',
		BM:'Ilhas Bermudas',
		KY:'Ilhas Cayman',
		CC:'Ilhas Cocos (Keeling)',
		CK:'Ilhas Cook',
		AX:'Ilhas Aland',
		FO:'Ilhas Faroe',
		GS:'Geórgia do Sul e Ilhas Sandwich do Sul',
		HM:'Ilhas Ouvido e McDonald',
		MV:'Ilhas Maldivas',
		FK:'Ilhas Malvinas',
		MP:'Ilhas Marianas do Norte',
		MH:'Ilhas Marshall',
		PN:'Ilhas Pitcairn',
		SB:'Ilhas Salomão',
		TC:'Ilhas Turcas e Caicos',
		UM:'Ilhas Menores Distantes dos Estados Unidos',
		VG:'Ilhas Virgens Britânicas',
		VI:'Ilhas Virgens dos Estados Unidos',
		IL:'Israel',
		IT:'Itália',
		JM:'Jamaica',
		JP:'Japão',
		JE:'Agasalho',
		JO:'Jordânia',
		KZ:'Cazaquistão',
		KE:'Quênia',
		KG:'Quirguistão',
		KI:'Kiribati',
		KW:'Kuwait',
		LB:'Líbano',
		LA:'Laos',
		LS:'Lesoto',
		LV:'Letônia',
		LR:'Libéria',
		LY:'Líbia',
		LI:'Liechtenstein',
		LT:'Lituânia',
		LU:'Luxemburgo',
		MX:'México',
		MC:'Mônaco',
		MO:'Macau',
		MK:'Macedônia',
		MG:'Madagáscar',
		MY:'Malásia',
		MW:'Malawi',
		ML:'Mali',
		MT:'Malte',
		MA:'Marrocos',
		MQ:'Martinica',
		MU:'Maurício',
		MR:'Mauritânia',
		YT:'maionete',
		FM:'Micronésia',
		MD:'Moldávia',
		MN:'Mongólia',
		ME:'Montenegro',
		MS:'Montserrate',
		MZ:'Moçambique',
		NA:'Namíbia',
		NR:'Nauru',
		NP:'Nepal',
		NI:'Nicarágua',
		NE:'Níger',
		NG:'Nigéria',
		NU:'Niu',
		NO:'Noruega',
		NC:'Nova Caledônia',
		NZ:'Nova Zelândia',
		OM:'Omã',
		NL:'Holanda',
		PK:'Paquistão',
		PW:'pala',
		PS:'Palestina',
		PA:'Panamá',
		PG:'Papua Nova Guiné',
		PY:'Paraguaio',
		PE:'Peru',
		PF:'Polinésia Francesa',
		PL:'Polônia',
		PT:'Portugal',
		PR:'Porto Rico',
		QA:'Catar',
		GB:'Reino Unido',
		CF:"República Centro-Africana",
		CZ:'República Checa',
		DO:'República Dominicana',
		SS:'República do Sudão do Sul',
		RE:'Reunião',
		RW:'Ruanda',
		RO:'Romênia',
		RU:'Rússia',
		EH:'Saara Ocidental',
		WS:'Samoa',
		AS:'Samoa Americana',
		BL:'São Bartolomeu',
		KN:'São Cristóvão e Nevis',
		SM:'São Marinho',
		MF:'São Martinho (França)',
		PM:'São Pedro e Miquelon',
		VC:'St. Vicente e Granadinas',
		SH:'St. Helena',
		LC:'St. Lúcia',
		ST:'São Tomé e Príncipe',
		SN:'Senegal',
		RS:'Sérvio',
		SC:'Seychelles',
		SL:'Serra Leoa',
		SG:'Cingapura',
		SX:'São Martinho',
		SY:'Síria',
		SO:'Somália',
		LK:'Sri Lanka',
		ZA:'África do Sul',
		SD:'Sudão',
		SE:'Suécia',
		CH:'Suíço',
		SR:'Suriname',
		SJ:'Svalbard e Jan Mayen',
		SZ:'Suazilândia',
		TJ:'Tajiquistão',
		TH:'Tailândia',
		TW:'Taiwan',
		TZ:'Tanzânia',
		IO:'Território Britânico do Oceano Índico',
		TF:'Territórios Franceses do Sul e Antártico',
		TL:'Timor-Leste',
		TG:'Ir',
		TK:'Toquela',
		TO:'Língua',
		TT:'Trinidad e Tobago',
		TN:'Tunísia',
		TM:"Turquemenistão",
		TR:'Peru',
		TV:'Tuvalu',
		UA:'Ucrânia',
		UG:'Uganda',
		UY:'Uruguai',
		UZ:'Uzbequistão',
		VU:'Vanuatus',
		VE:'Venezuela',
		VN:'Vietnã',
		WF:'Wallis e Futuna',
		YE:'Iémen',
		DJ:'Djibuti',
		ZM:'Zâmbia',
		ZW:'Zimbábue'	
	}    
};

export default countryList;