import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from 'utilities/components/RequireAuth';
import RequireAdmin from 'utilities/components/RequireAdmin';

import { UserProvider } from "contexts/UserContext";

import LayoutLanding from './components/layouts/Landing';
import Home from './components/pages/Landing/Home';
import Pricing from './components/pages/Landing/Pricing';
import Prediction from 'components/pages/Landing/Prediction';

import LayoutAuth from 'components/layouts/Auth';
import SignIn from 'components/pages/Auth/SignIn';
import SignUp from 'components/pages/Auth/SignUp';
import Recover from 'components/pages/Auth/Recover';
import ValidateEmail from 'components/pages/Auth/ValidateEmail';

import LayoutApp from 'components/layouts/App';
import Main from 'components/pages/App/Main';
import Detail from 'components/pages/App/Detail';
import Edit from 'components/pages/App/Edit';
import Profile from 'components/pages/App/Profile';
import Search from 'components/pages/App/Search';
import LayoutMain from 'components/layouts/App/Main';
import JockeyDetail from 'components/pages/App/JockeyDetail';
import HorseDetail from 'components/pages/App/HorseDetail';


const AppRoutes = () => {
    return ( 
        <UserProvider>
            <Routes>
                <Route path="landing" element={<LayoutLanding />}>
                    <Route index element={<Home/>}/>
                    <Route path="pricing" element={<Pricing/>}/>
                    <Route path=":short_url" element={<Prediction/>}/>
                    <Route path="*" element={<Navigate to="/landing" replace />} />
                </Route>
                    <Route path="auth" element={<LayoutAuth />}>
                        <Route path="signin" element={<SignIn/>}/>
                        <Route path="signup" element={<SignUp/>}/>
                        <Route path="signup/:landing_email" element={<SignUp/>}/>
                        <Route path="forgot-password/:token" element={<Recover/>}/>
                        <Route path="confirmation-email/:token" element={<ValidateEmail/>}/>
                        <Route path="*" element={<Navigate to="/landing" replace />} />
                    </Route>
                    <Route 
                        path="app" 
                        element={(
                            <RequireAuth>
                                <LayoutApp />
                            </RequireAuth>
                        )}
                    >
                        <Route path="main" element={<LayoutMain/>}>
                            <Route index element={<Main/>}/>
                            <Route path="detail" element={<Detail/>}/>
                        </Route>
                        <Route path="jockey/:jockey_id" element={<JockeyDetail/>}/>
                        <Route path="horse/:horse_id" element={<HorseDetail/>}/>
                        <Route path="profile" element={<Profile/>}/>
                        <Route 
                            path="edit" 
                            element={
                                <RequireAdmin>
                                    <Edit/>
                                </RequireAdmin>
                            }
                        />
                        <Route path="search/:search_query" element={<Search/>}/>
                        <Route path="*" element={<Navigate to="/app/main" replace />} />
                    </Route>
                <Route path="*" element={<Navigate to="/landing" replace />} />
            </Routes>
        </UserProvider>
    );
}
 
export default AppRoutes;