import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "contexts/BaseContext";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Chip from "@mui/material/Chip";

import { ReactComponent as MercadoPago } from "assets/icons/svg/mercado-pago.svg";
import { ReactComponent as HeartComp } from "assets/icons/svg/hand-holding-heart-solid.svg";

import { useMutation } from "@apollo/client";
import { CREATE_PREFERENCE } from "queries/collaboration";

import collaborateText from "texts/components/collaborateText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultAmounts = [250, 350, 500];

const Collaborate = ({ text }) => {
  const [amount, setAmount] = useState(250);
  const [other, setOther] = useState(20);
  const [step, setStep] = useState(0);

  const [
    createPreference,
    { loading: loadingPreference, data: dataPreference },
  ] = useMutation(CREATE_PREFERENCE);

  const { language } = useContext(BaseContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = (event, reason) => {
    setDialogOpen(false);
  };

  const handleDialogOpen = (event, reason) => {
    setDialogOpen(true);
  };

  const handleAmount = (value) => {
    setAmount(value);
  };

  const handleOther = ({ target }) => {
    const value = target.value.replace(/[^0-9]/g, "").slice(0, 8);
    setOther(parseInt(value) || 0);
  };

  const goBack = () => {
    setStep(0);
  };

  const handleSubmit = () => {
    var price = 0;
    if (amount === "other") {
      price = other;
    } else {
      price = amount;
    }
    createPreference({ variables: { input: { price: price } } });
  };

  const handleRedirect = () => {
    if (dataPreference.createPreference?.init_point) {
      window.location.href = dataPreference.createPreference?.init_point;
    }
  };

  useEffect(() => {
    let mounted = true;
    if (dataPreference && !loadingPreference && mounted) {
      setStep(1);
    }
    return () => (mounted = false);
  }, [dataPreference, loadingPreference]);

  return (
    <>
      <IconButton onClick={handleDialogOpen} className="p-2.5">
        <HeartComp className="h-6 w-6 fill-primaryL1" />
        {text && <p>{text}</p>}
      </IconButton>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
      >
        <DialogContent>
          <div className="flex justify-between">
            <h4 className="text-xl my-0 text-secondaryD2 font-normal">
              {collaborateText[language].title}
            </h4>
            <h3 className="text-2xl font-bold my-0 text-secondaryD2">
              {" "}
              {amount === "other" ? `$${other}` : `$${amount}`}{" "}
            </h3>
          </div>
          <h5 className="my-2 text-base text-backD1.5 font-normal">
            {collaborateText[language].desc}
          </h5>

          <div className="h-[1px] my-4 w-full bg-back" />

          <div className="flex items-center gap-x-2">
            <p className="my-0">{collaborateText[language].desc2}</p>
            <MercadoPago className="h-6 w-auto" />
          </div>
          <Stepper activeStep={step} alternativeLabel className="py-6 px-0">
            <Step key={0}>
              <StepLabel StepIconComponent={StepIcon}>
                {collaborateText[language].step1}
              </StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel StepIconComponent={StepIcon}>
                {collaborateText[language].step2}
                <p className="my-2 text-base text-center text-secondaryD2">
                  {collaborateText[language].continueMP}
                </p>
              </StepLabel>
            </Step>
          </Stepper>
          {step === 0 && (
            <div className="min-h-[90px]">
              <h4 className="my-0 text-xl font-normal">
                {collaborateText[language].ammTitle}
              </h4>
              <p className="my-0 text-xl font-normal">$ ARS</p>
              <div className="py-4 flex gap-x-5 items-center">
                <div className="flex gap-x-2 flex-wrap">
                  {defaultAmounts.map((item) => (
                    <Chip
                      key={`ammount-${item}`}
                      onClick={() => handleAmount(item)}
                      color={amount === item ? "secondaryDark" : "inactiveDark"}
                      className="text-white"
                      label={item}
                      clickable
                    />
                  ))}
                  <Chip
                    onClick={() => handleAmount("other")}
                    color={
                      amount === "other" ? "secondaryDark" : "inactiveDark"
                    }
                    className="text-white"
                    label={collaborateText[language].other}
                    clickable
                  />
                </div>
                {amount === "other" && (
                  <div className="{classes.otherContainer}">
                    <TextField
                      size="small"
                      value={other}
                      onChange={handleOther}
                      variant="standard"
                      error={amount === "other" && other < 20}
                      helperText={
                        amount === "other" && other < 20
                          ? collaborateText[language].minimum
                          : ""
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="h-0.5 w-full bg-backL2" />
        </DialogContent>
        <DialogActions className="text-danger">
          <Button onClick={goBack} disabled={step === 0}>
            {collaborateText[language].goBack}
          </Button>
          {dataPreference && step === 1 ? (
            <Button onClick={handleRedirect}>
              {collaborateText[language].goToPay}
            </Button>
          ) : loadingPreference ? (
            <div className="w-20 flex justify-center">
                <CircularProgress size={24}/>
            </div>
          ) : (
            <Button
              onClick={handleSubmit}
              disabled={amount === "other" && other < 20}
            >
              {collaborateText[language].continue}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const StepIcon = ({ active, completed }) => {
  return (
    <>
      {completed ? (
        <span className="material-icons text-secondaryD2">done</span>
      ) : (
        <div
          className={`h-4 w-4 rounded-full ${
            active ? "bg-secondaryD2" : "bg-backD1"
          }`}
        />
      )}
    </>
  );
};

export default Collaborate;
