import { gql } from "@apollo/client";

export const GET_HORSE_STATS = gql`
  query getHorseFull($horseId: String!) {
    horseRaceByHorse(horseId: $horseId) {
      _id
      competitors {
        pos
      }
    }
  }
`;
