const collaborateText = { 
    en: {
        title:'My collaboration',
        desc:'Here you choose the price. Your collaboration is not just a reward for the team, it\'s our motivation to keep improving and innovating. Thank you so much.',
        desc2:'For our payments, we trust in',
        ammTitle:'Select a value',
        other:'Other',
        goToPay:'CONFIRM',
        created:'To complete the payment, click on the following link that will redirect you to MercadoPago',
        submit:'Check-out with MercadoPago',
        step1:'Select a value',
        step2:'Check-out',
        continue:'NEXT',
        goBack:'BACK',
        continueMP:<>You will continue the transaction<br/>in Mercadopago.</>,
        minimum:'Collaboration Minimum: AR$20',
    },
    es: {
        title:'Mi colaboración',
        desc:'En Invasor el precio lo ponés vos. Tu colaboración no solo es una recompensa para el equipo por su trabajo, sino nuestra motivación para poder seguir mejorando e innovando. Muchas gracias.',
        desc2:'Para nuestros pagos, utilizamos la interfaz de',
        ammTitle:'Selecciona un monto',
        other:'Otro',
        goToPay:'CONFIRMAR',
        created:'Para completar el pago, hacé click en el siguiente link que te redigirá a MercadoPago',
        submit:'Ir a pagar en MercadoPago',
        step1:'Selecciona un monto',
        step2:'Confirma el monto',
        continue:'CONTINUAR',
        goBack:'VOLVER',
        continueMP:<>Continuarás la transacción<br/>en Mercadopago.</>,
        minimum:'Mínimo de colaboración: AR$20',
    },
    pt: {
        title:'Minha colaboração',
        desc:'Aqui você coloca o preço. Sua colaboração não é apenas um recompensa para a equipe, e nossa motivação para continuar melhorando e inovando. Muito obrigado.',
        desc2:'Para nossos pagamentos, nós confiamos na interface de',
        ammTitle:'Selecione um valor',
        other:'Outro',
        goToPay:'CONFIRMAR',
        created:'Para finalizar o pagamento, clique no link a seguir que o redirecionará ao MercadoPago',
        submit:'Check-out com MercadoPago',
        step1:'Selecione o valor',
        step2:'Confirme o valor',
        continue:'SEGUIR',
        goBack:'VOLTAR',
        continueMP:<>Você continuará a transação<br/>no Mercadopago.</>,
        minimum:'Mínimo de colaboração: AR$20',
    },
}

export default collaborateText;