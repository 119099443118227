import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { BaseContext } from 'contexts/BaseContext';
import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import navBarText from 'texts/components/navbarText';

const SearchBar = () => {
    const { language } = useContext(BaseContext);
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [error, setError] = useState({ok:true, error:""});
    const submitSearch = (e) => {
        e.preventDefault();
        let clean = search.trim();
        clean = clean.replace(/\s+/g, ' ');
        if(search.length < 3) {
            setError({
                ok:false,
                error: navBarText[language].searchError
            });
        } else {
            navigate(`/app/search/${clean}`)
        }
    }

    const onChangeSearch = ({target}) => {
        if(!error.ok) {
            setError({ ok:true, error: ""});
        }
        setSearch(target.value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ0-9@\s]/g,''))
    }

    return ( 
        <>
            <form 
                onSubmit={submitSearch} 
                className='flex items-center justify-center w-1/2 relative transition-all duration-1000 ' 
            >
                <TextField
                    id="search"
                    value={search}
                    onChange={onChangeSearch}
                    color="secondary"
                    placeholder={navBarText[language].searchPlaceholder}
                    InputProps={{
                        maxLength: 100,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type='submit'>
                                    <span className='material-icons leading-none text-2xl text-backD1'>search</span>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    error={!error.ok}
                    size="small"
                    helperText={!error.ok && error.error}
                    fullWidth
                />
            </form>
        </>
    );
}
 
export default SearchBar;