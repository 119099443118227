import { createContext, useEffect, useState } from "react";
import { es, pt } from 'date-fns/locale'
import en from 'date-fns/locale/en-US'

export const BaseContext = createContext();

export const BaseProvider = ({ children }) => {
    const [clientWidth, setClientWidth] = useState(360);
    const [redirect, setRedirect] = useState();
    const [language, setLanguage] = useState('es');
    const locales = {
        'en': en,
        'es': es,
        'pt': pt
    }

    useEffect(() => {
        setClientWidth(window.innerWidth);
    
        window.onresize = () => {
          setClientWidth(window.innerWidth);
        };   
    }, []);

    useEffect(()=>{
        var mounted = true;
        const lang = localStorage.getItem('language');
        if(mounted && lang) {
            setLanguage(lang);
        }
        return() => {
            mounted = false;
        };
    },[])

    const value = {
        clientWidth,
        language,
        setLanguage,
        locales,
        redirect,
        setRedirect,
    };
    return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
}