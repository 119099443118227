import React, { useContext, useEffect, useState } from "react";

import { BaseContext } from "contexts/BaseContext";
import { UserContext } from "contexts/UserContext";

import signInText from "texts/pages/signInText";

import { Link } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { resetErrors, handleErrors } from "./functions";
import getDeviceInfo from "utilities/functions/getDeviceInfo";
import { validateEmail } from "utilities/validators";
import ResendDialog from "./ResendDialog";

const LoginForm = ({ setFormToDisplay }) => {
  const { language } = useContext(BaseContext);
  const {
    signIn,
    loadingSignIn: loading,
    errorSignIn,
  } = useContext(UserContext);
  const [resendOpen, setResendOpen] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [errors, setErrors] = useState({
    email: { ok: true, error: "" },
    password: { ok: true, error: "" },
    general: { ok: true, error: "" },
  });

  const handleChange = ({ target }) => {
    const { id, value } = target;
    if (errors.email || errors.password || errors.general)
      resetErrors(setErrors);

    setUserData({
      ...userData,
      [id]: value,
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted)
      handleErrors(errorSignIn, loading, setErrors, signInText[language], setResendOpen);

    return () => {
      mounted = false;
    };
  }, [errorSignIn, loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let generatedErrors = {
      ...errors,
      email: validateEmail(userData.email),
      noErrors: () =>
        generatedErrors.email &&
        generatedErrors.email.ok &&
        generatedErrors.email &&
        generatedErrors.password.ok &&
        generatedErrors.general &&
        generatedErrors.general.ok,
    };

    if (generatedErrors.noErrors && generatedErrors.noErrors()) {
      try {
        const deviceInfo = getDeviceInfo();
        signIn({
          variables: {
            email: userData?.email?.trim()?.toLowerCase(),
            password: userData?.password,
            device: {
              so: `${deviceInfo.os} ${deviceInfo.osVersion}`,
              browser: `${deviceInfo.browser} ${deviceInfo.browserVersion}`,
            },
          },
        });
      } catch {}
    }
    setErrors(generatedErrors);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
        <TextField
          id="email"
          value={userData.email}
          onChange={handleChange}
          size="small"
          color="secondary"
          error={errors.email && !errors.email.ok}
          helperText={errors.email && errors.email.error}
          label={signInText[language].email}
          variant="standard"
          disabled={loading}
          InputProps={{
            maxLength: 100,
            endAdornment: (
              <InputAdornment position="start">
                <span className="material-icons text-lg text-black">email</span>
              </InputAdornment>
            ),
          }}
          className="w-full"
        />
        <TextField
          id="password"
          value={userData.password}
          onChange={handleChange}
          size="small"
          color="secondary"
          error={errors.password && !errors.password.ok}
          helperText={errors.password && errors.password.error}
          label={signInText[language].password}
          variant="standard"
          disabled={loading}
          type={userData.showPassword ? "text" : "password"}
          InputProps={{
            maxLength: 100,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setUserData((currentData) => ({
                      ...currentData,
                      showPassword: !currentData.showPassword,
                    }))
                  }
                  className="py-1"
                >
                  <span className="material-icons text-lg text-black">
                    {userData.showPassword ? "visibility_off" : "visibility"}
                  </span>
                </IconButton>
              </InputAdornment>
            ),
          }}
          className="w-full"
        />
        {errors.general && !errors.general.ok && (
          <p className="text-danger">
            {errors.general && errors.general.error}
          </p>
        )}
        <div className="flex justify-center">
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              className="rounded-full"
              color="secondary"
              variant="contained"
              size="large"
              type="submit"
            >
              {signInText[language].signIn}
            </Button>
          )}
        </div>
      </form>
      <div className="flex flex-col">
        <div className="flex items-center justify-center gap-x-2">
          <p className="text-base font-roboto text-dark my-0">
            {signInText[language].forgot}
          </p>
          <button
            onClick={() => setFormToDisplay("recover")}
            className="text-base font-roboto text-blue-400 underline cursor-pointer px-0 my-0 bg-transparent border-none"
          >
            {signInText[language].recover}
          </button>
        </div>
        <div className="flex items-center justify-center gap-x-2">
          <p className="text-base font-roboto text-dark">
            {signInText[language].noAccount}
          </p>
          <Link
            to="/auth/signup"
            className="text-base font-roboto text-blue-400 underline"
          >
            {signInText[language].signUp}
          </Link>
        </div>
        <ResendDialog
          open={resendOpen}
          setOpen={setResendOpen}
          email={userData?.email}
        />
      </div>
    </>
  );
};

export default LoginForm;
