import React, { useContext } from 'react';
import { RaceContext } from 'contexts/RaceContext';

import { CircleFlag } from 'react-circle-flags';

const Prizes = ({currentRace, RT}) => {
    
    const { raceTracks } = useContext(RaceContext);
    const inactive = (new Date() > new Date(currentRace?.date) || currentRace?.suspended);

    return (
        <div
            className={`flex flex-wrap my-4 justify-around items-center shadow rounded-lg ${inactive?'bg-back':'bg-white'}`}
        >
            <div className="flex flex-wrap items-center">
                <CircleFlag
                    countryCode={raceTracks[RT]?.country?.toLowerCase()}
                    height="24"
                />
            </div>
            {
                Object.entries(currentRace.prizes).map(([key, value], index) => {
                    if (value && value !== 'Prize')
                        return (
                            <p
                                className={`
                                    m-1 font-medium
                                    ${index === 1 ? 'text-2xl' : ''}
                                    ${index === 2 ? 'text-xl' : ''}
                                    ${index === 3 ? 'text-lg' : ''}
                                    ${index === 4 ? 'text-base' : ''}
                                    ${index === 5 ? 'text-sm' : ''}
                                    ${index >= 6 ? 'text-xl' : ''}
                                `}
                                key={key + index}
                            >
                                {`$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
                            </p>
                        );
                    else
                        return null;
                })
            }
        </div>
    );
}
 
export default Prizes;