import React from "react";
import { CircleFlag } from "react-circle-flags";

const Prizes = ({raceTracks, RT, currentRace}) => {
  return (
    <div className="flex flex-wrap my-4 justify-around items-center shadow rounded-lg bg-white">
      <div className="flex flex-wrap items-center">
        <CircleFlag
          countryCode={raceTracks[RT]?.country?.toLowerCase()}
          height="24"
        />
      </div>
      {Object.entries(currentRace.prizes[0]).map(([key, value], index) => {
        if (value && value !== "Prize")
          return (
            <p
              className={`
                                    m-1 font-medium
                                    ${index === 1 ? "text-2xl" : ""}
                                    ${index === 2 ? "text-xl" : ""}
                                    ${index === 3 ? "text-lg" : ""}
                                    ${index === 4 ? "text-base" : ""}
                                    ${index === 5 ? "text-sm" : ""}
                                    ${index >= 6 ? "text-xl" : ""}
                                `}
              key={key + index}
            >
              {`$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
            </p>
          );
        else return null;
      })}
    </div>
  );
};

export default Prizes;
