import { gql } from "@apollo/client/core";

const SIGN_IN = gql`
mutation($email: String!, $password: String!, $device: DeviceInput!) {
  signIn(email: $email, password: $password, device:$device) {
    token
  }
}
`;

export default SIGN_IN;