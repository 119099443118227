const getSortedByDistance = (competitors)=>{
    if(competitors?.[0]) {
        let sortedCompetitors = [...competitors].sort((a,b)=>{
            let posA = a.pos;
            let posB = b.pos;
            if(a.withdrawn || a.rodo || a.desmonta) {
                posA = competitors?.length + 1;
            } else {
                if (['', 'SA', 'R', '-', ' ', '0', 'DP', 'desm', 'rodo', 'nl', null].includes(posA)) {
                    posA = competitors?.length
                } 
            }
            
            if(b.withdrawn || b.rodo || b.desmonta) {
                posB = competitors?.length + 1;
            } else {
                if (['', 'SA', 'R', '-', ' ', '0', 'DP', 'desm', 'rodo', 'nl', null].includes(posB)) {
                    posB = competitors?.length
                } 
            }
    
            return posA - posB
        })
        return sortedCompetitors;
    } else {
        return [];
    }
}

export default getSortedByDistance;