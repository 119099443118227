import React, { useContext, useEffect, useState } from "react";

import GeneralRaceData from "./GeneralRaceData";
import NotificationCreator from "./NotificationCreator";
// import Table from './Table';
import VideoUrlUpdater from "./VideoUrlUpdater";
import distIcons from "utilities/objects/distanceByKey";
// import distIcons from 'constants/distances.js';

import { GET_SELECTS_DATA } from "queries/jockey";
import { useQuery } from "@apollo/client";
import { GET_DAY_RACETRACKS } from "queries/horserace";
import { format, isSameDay } from "date-fns";
import { RaceContext } from "contexts/RaceContext";
import RaceBanner from "components/ui/RaceBanner";
import { GET_HORSERACE_BY_DATE_RT } from "queries/horserace";
import Head from "components/ui/Head";
import Table from "./Table";
import { GET_VIDEOS_BY_DATE_RT } from "queries/videos";
import getSortedByDistance from "utilities/functions/getSortedByDistance";
import getIncidentKey from "utilities/functions/getIncidentKey";
import getDistanceKey from "utilities/functions/getDistanceKey";
import distanceByKey from "utilities/objects/distanceByKey";
import { GET_VIDEOS_DAY_RT } from "queries/videos";

const EditRace = () => {
  const { raceDate } = useContext(RaceContext);

  const [RT, setRT] = useState("");
  const [dayRTS, setDayRTS] = useState({ list: [], date: "" });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastCalled, setLastCalled] = useState({ rts: "", basicRace: "" });

  const [editedRaces, setEditedRaces] = useState([]);
  const [jockeyOptions, setJockeyOptions] = useState([]);
  const [distOptions, setDistOptions] = useState([]);
  const [studOptions, setStudOptions] = useState([]);
  const [changedHead, setChangedHead] = useState([]);

  const { data: dataVideos, loading: loadingVideos } = useQuery(GET_VIDEOS_DAY_RT, {
    skip: !RT || !raceDate,
    variables: {
      date: format(raceDate, "yyyy-MM-dd"),
      racetrack_id: RT,
    },
  });

  const {
    data: dataRTS,
    loading: loadingRTS,
    error: errorRTS,
    called: calledRTS,
  } = useQuery(GET_DAY_RACETRACKS, {
    skip: lastCalled?.rts && isSameDay(lastCalled.rts, raceDate),
    variables: { date: format(raceDate, "yyyy-MM-dd") },
    returnPartialData: true,
    fetchPolicy: "network-only",
  });

  const {
    data: dataBasicRace,
    loading: loadingBasicRace,
    called: calledBasicRace,
  } = useQuery(GET_HORSERACE_BY_DATE_RT, {
    skip:
      (lastCalled?.basicRace && isSameDay(raceDate, lastCalled.basicRace)) ||
      !RT ||
      (lastCalled?.rts && !isSameDay(raceDate, lastCalled.rts)),
    variables: {
      date: format(raceDate, "yyyy-MM-dd"),
      racetrack_id: RT,
    },
  });

  const { data: dataSelects, loading: loadingSelects } =
    useQuery(GET_SELECTS_DATA);

  useEffect(() => {
    let mounted = true;
    let newDistOptions = {};
    Object.keys(distIcons).map((icon) => {
      if (distIcons[icon].icon) {
        newDistOptions[icon] = (
          <img
            alt={`icon-${icon}`}
            src={distIcons[icon].icon}
            className="w-6 h-6"
          />
        );
      } else {
        newDistOptions[icon] = <div className="w-6 h-6" />;
      }
    });
    if (mounted) {
      setDistOptions(newDistOptions);
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted && dataRTS && !loadingRTS) {
      const dayRacetracks = dataRTS.dayRaceTracks;
      if (dayRacetracks.length > 1) {
        setDayRTS({ list: dayRacetracks, date: raceDate });
        setLastCalled({ ...lastCalled, rts: new Date(raceDate) });
        //TODO: Prompt choice between racetracks
      } else if (dayRacetracks.length === 1) {
        setRT(dayRacetracks[0]);
        setDayRTS({ list: dayRacetracks, date: raceDate });
        setLastCalled({ ...lastCalled, rts: new Date(raceDate) });
      } else {
        setRT();
        setDayRTS({ list: [], date: raceDate });
        setEditedRaces([]);
        setLastCalled({ ...lastCalled, basicRace: new Date(raceDate), rts: new Date(raceDate) });
      }
    }

    return () => (mounted = false);
  }, [dataRTS, loadingRTS]);

  useEffect(() => {
    let mounted = true;
    if (mounted && dataBasicRace && !loadingBasicRace) {
      let auxRaces = structuredClone(dataBasicRace.horseRaceDateRT);
      auxRaces.forEach((race, raceIndex)=>{
        if(dataVideos?.dayVideosRT) {
          const videos = dataVideos?.dayVideosRT;
          const foundVideo = videos.find(video=> video.date === race.date);
          if(foundVideo) {
            race.url = foundVideo.url;
          }
        }

        let sortedComps = getSortedByDistance(race.competitors);
        race.competitors = sortedComps;
        
        sortedComps.forEach((comp, index)=>{
          let wind_dist = '';
          let pos = comp.pos;
          let cpos = '';
          if(pos === '0') {
            pos = '-'
          }
          const isIncident = getIncidentKey(comp);
          const isNotLast = index + 1 < sortedComps.length;
          
          const nextCompetitor = isNotLast && sortedComps[index+1];
          const isIncidentNext = isNotLast && getIncidentKey(nextCompetitor);
          
          const distanceKey = getDistanceKey(comp.wind_dist);
          const nextDistanceKey = isNotLast && getDistanceKey(nextCompetitor?.wind_dist);
          const nextDistanceObject = nextDistanceKey && distanceByKey[nextDistanceKey];

          if(isIncident) {
            wind_dist = distanceKey;
            pos = '-';
          } else {
            if(!nextCompetitor || isIncidentNext) {
              wind_dist = 'last';
            } else {
              if(isNotLast && !nextDistanceObject?.disablePos) {
                wind_dist = nextDistanceKey;
              }
              if(wind_dist === '4cpom') {
                cpos = nextCompetitor?.wind_dist;
              }
            }
          }

          sortedComps[index] = {
            ...sortedComps[index],
            wind_dist: wind_dist,
            cpos: cpos,
            pos: pos,
          }

        });
      });

      setEditedRaces(auxRaces);
      setLastCalled({
        ...lastCalled,
        basicRace: new Date(raceDate),
      });
    }
    return () => (mounted = false);
  }, [dataBasicRace, loadingBasicRace]);

  useEffect(() => {
    if (dataSelects && !loadingSelects) {
      let auxJockeys = {};
      let auxStud = {};
      dataSelects.jockeys.forEach((jockey) => {
        auxJockeys[jockey.id] = jockey.name;
      });
      dataSelects.stables.forEach((stable) => {
        auxStud[stable.id] = stable.name;
      });
      setJockeyOptions(auxJockeys);
      setStudOptions(auxStud);
    }
  }, [dataSelects, loadingSelects]);

  return (
    <>
      <RaceBanner
        RT={RT}
        calledRT={calledRTS}
        loadingRTS={loadingRTS}
        currentRace={editedRaces?.[currentIndex]}
        dayRaces={editedRaces}
      />
      <VideoUrlUpdater racetrack_id={RT}/>
      <NotificationCreator />
      {editedRaces &&
      editedRaces[currentIndex] &&
      isSameDay(lastCalled.basicRace, raceDate) &&
      !loadingBasicRace &&
      !loadingRTS ? (
        <Head
          currentRace={editedRaces?.[currentIndex]}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          dayRaces={editedRaces}
        />
      ) : (
        <div className="flex w-full h-44 shadow-lg bg-backL2 animate-pulse rounded-lg" />
      )}
      <div className="bg-white rounded-lg shadow-lg">
        {
          editedRaces &&
          editedRaces[currentIndex] &&
          isSameDay(lastCalled.basicRace, raceDate) &&
          !loadingBasicRace &&
          !loadingRTS ? (
            <GeneralRaceData
              editedRaces={editedRaces}
              setEditedRaces={setEditedRaces}
              currentIndex={currentIndex}
              loadingVideos={loadingVideos}
              currentUrl={editedRaces?.[currentIndex]?.url}
            />
          ) : (
            <div className="h-20 w-full bg-back animate-pulse"/>
          )
        }
        <Table
          editedRaces={editedRaces}
          setEditedRaces={setEditedRaces}
          currentIndex={currentIndex}
          jockeyOptions={jockeyOptions}
          distOptions={distOptions}
          studOptions={studOptions}
          loadingSelects={loadingSelects}
        />
      </div>
    </>
  );
};

export default EditRace;
