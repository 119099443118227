const profileText = { 
    en: {  
        editProfile:'Edit Profile',
        editText:'Complete your profile',
        alias:'Alias',
        name:'Name',
        firstName:'First Name',
        lastName: 'Last Name',
        birthdate:'Birthdate',
        about: 'About me',
        update: 'Update Profile',
        delete: 'Delete Account',
        male:'Male',
        female:'Female',
        other:'Other',
        profile:'Profile',
        idType:'Id Type',
        document:'Passport',
        gender:'Gender',
        changeAvatar:'Change profile picture',
        success:'Data was successfully updated',
        deleteTitle:'Delete account',
        deleteText:'The account will be permanently deleted in 15 days.',
        cancel:'Cancel',
        save:'Save',
        confirm:'Delete',
        deleteDesc:'To delete your account, write “DELETE” on the following field:',
        deleteConfirm: 'DELETE',
        survey:'Where did you hear about us?',
        agency:'Horse racing agency',
        friend:'A friend',
        horsetrack:'At the racetrack',
        raceTrack:'Racetrack',
        pickNew: 'Choose a new picture',
        newImage: 'New picture',
    },
    es: {  
        editProfile:'Editar Perfil',
        editText:'Completa tu perfil',
        alias:'Alias',
        name:'Nombre',
        firstName:'Nombre',
        lastName: 'Apellido',
        birthdate: 'Fecha de nacimiento',
        about: 'Sobre mí',
        update: 'Actualizar Perfil',
        delete: 'Borrar Cuenta',
        male:'Masculino',
        female:'Femenino',
        other:'Otro',
        profile:'Perfil',
        document:'DNI /Pasaporte',
        idType:'Tipo Id',
        gender:'Género',
        changeAvatar:'Cambiar foto de perfil',
        success:'Datos actualizados con éxito',
        deleteTitle:'Eliminar cuenta',
        deleteText:'La cuenta se eliminará definitivamente en 15 días.',
        cancel:'Cancelar',
        save:'Guardar',
        confirm:'Borrar',
        deleteDesc:'Para eliminar tu cuenta, escribe “BORRAR” en el siguiente campo:',
        deleteConfirm: 'BORRAR',
        survey:'¿Dónde nos conociste?',
        agency:'Agencia hípica',
        friend:'Un conocido',
        horsetrack:'En el hipódromo',
        raceTrack:'Hipódromo',
        pickNew: 'Elegir una nueva foto',
        newImage: 'Nueva imagen',
    },
    pt: {  
        editProfile:'Editar Perfil',
        editText:'Complete seu perfil',
        alias:'Alias',
        name:'Nome',
        firstName:'Nome',
        lastName: 'Sobrenome',
        birthdate: 'Data de nascimento',
        idType:'Tipo Id',
        about: 'Sobre mim',
        update: 'Atualizar',
        delete: 'Deletar minha conta',
        male:'Masculino',
        female:'Feminino',
        other:'Otro',
        profile:'Perfil',
        document:'Passaporte',
        gender:'Género',
        changeAvatar:'Modifique sua foto de perfil',
        success:'Dados atualizados',
        deleteTitle:'Deletar minha conta',
        deleteText:'A conta será excluída permanentemente em 15 dias.',
        cancel:'Cancelar',
        save:'Salvar',
        confirm:'Deletar',
        deleteDesc:'Para deletar sua conta, escreva "APAGAR" no seguinte campo:',
        deleteConfirm: 'APAGAR',
        survey:'Onde nos conheceu?',
        agency:'Agência de apostas',
        friend:'Uma pessoa de contato',
        horsetrack:'No hipódromo',
        raceTrack:'Hipódromo',
        pickNew: 'Escolha uma nova foto',
        newImage: 'Nova foto',
    },
}

export default profileText;