import { gql } from "@apollo/client"

export const GET_WEATHER_BY_DATES = gql`
    query ($dates: [String]){
        weatherByDates(dates:$dates)
        {
            date
            tempC
            weatherCode
        }
    }
`