import React from "react";

const Step = ({ text, icon, isActive, onClick }) => {
  return (
    <div className="flex flex-col group cursor-pointer" onClick={onClick}>
      <p className={`hidden md:block text-sm group-hover:scale-125 transition-all ${ isActive ? "text-xl font-bold my-2" : "my-3" }`} >
        {text}
      </p>
      <div className={`flex items-center justify-center ${isActive ? 'transform scale-125' : ''}`}>{icon}</div>
    </div>
  );
};

export default Step;
